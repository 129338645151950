import React, { useState } from 'react'
import { Pagination } from '../../../../../components/ui/pagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { formatCashAmount, splitArrayIntoChunks } from '../../../../../lib/utils'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { format } from 'date-fns'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { useNavigate } from 'react-router-dom'
import { getOrderDraftState } from '../../../../../lib/general/params'
import ExportRow from '../../../../../components/portal/export/ExportRow'
import { useTranslation } from 'react-i18next'

type Props = {
    collections: AnnouncementDraftSearch[]
}


const CollectionsTable : React.FC<Props> = ({collections}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("order_and_pickup.o_overview_collections.partner"), t("track_and_trace.customer_nr"), t("order_and_pickup.o_overview_collections.customer_code"), t("order_and_pickup.o_overview_collections.customer_p2"), t("order_and_pickup.o_overview_collections.type"), t("order_and_pickup.o_overview_collections.amount"), t("order_and_pickup.o_overview_collections.keepsafe_nr"), t("order_and_pickup.o_overview_collections.date_collection"), t("order_and_pickup.o_overview_collections.date_created"), t("order_and_pickup.o_overview_collections.status_p2"), t("order_and_pickup.o_overview_collections.created_by"), t("order_and_pickup.o_overview_collections.reference"), t("order_and_pickup.o_overview_collections.auto_ref"), "€ 2,00", "€ 1,00", "€ 0,50", "€ 0,20", "€ 0,10", "€ 0,05", "€ 0,02", "€ 0,01", "€ 500", "€ 200", "€ 100", "€ 50", "€ 20", "€ 10", "€ 5"]
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const [currentPage, setcurrentPage] = useState<number>(1);

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(collections, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'cn', 'amount', 'keepsafe', 'date', 'dtcreation', 'status', 'username', 'reference', 'autoreference', 'coupure.2', 'coupure.1', 'coupure.0,5', 'coupure.0,2', 'coupure.0,1', 'coupure.0,05', 'coupure.0,02', 'coupure.0,01', 'coupure.500', 'coupure.200', 'coupure.100', 'coupure.50', 'coupure.20', 'coupure.10', 'coupure.5'],{partners:partners, customers:customers, relnr:'relnr', orderstate:orderdraftstate})))}}/>
    </div>
    } 

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead></TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.customer_p2")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.type")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.amount")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.keepsafe_nr")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.date_collection")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.date_created")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.status_p2")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.created_by")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.reference")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_collections.auto_ref")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {collections && splitArrayIntoChunks(collections, 20)[currentPage - 1] && splitArrayIntoChunks(collections, 20)[currentPage - 1].map((order, index) => {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                    return (
                        <TableRow className='cursor-pointer' key={index}>
                            <TableCell>
                                <div>
                                    <ExportRow id={order.id} type='announcement'/>
                                </div>
                            </TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{partner?.name}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{customer?.clientcode}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{customer?.name}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{order.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_overview_collections.coins") : t("order_and_pickup.o_overview_collections.notes")}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{formatCashAmount(order.amount)}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{order.keepsafe}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{format(convertToDate(order.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(order.tmcreation)} </TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{getOrderDraftState(order.status, orderdraftstate)?.description}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{order.username}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{order.reference}</TableCell>
                            <TableCell onClick={()=>{navigate(`${order.id}`)}}>{order.autoreference}</TableCell>
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={collections.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default CollectionsTable