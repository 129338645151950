import React, { useEffect, useState } from 'react'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox';
import { Input } from '../../../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components/ui/select';
import { DatePicker } from '../../../../components/ui/datepicker';
import { subDays } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth';
import { Toaster, toast } from 'react-hot-toast';
import { Button } from '../../../../components/ui/button';
import { convertToDate, formatDateToYYYYMMDD } from '../../../../lib/date';
import { Loader2 } from 'lucide-react';
import { getFilterDetail } from '../../../../lib/selectors';
import { isInterface } from '../../../../lib/utils';
import { log } from 'console';
import { getCustomerList } from '../../../../lib/fetch/customer';
import WindowsDatePicker from '../../../../components/ui/windows-datepicker';
import { useTranslation } from 'react-i18next';

type Props = {
  onStartSearch: (value:object) => void;
  loading: boolean;
  cancelRequest: () => void;
}

const Deliveries : React.FC<Props> = ({onStartSearch, loading, cancelRequest}) => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const filters = useSelector((state:RootState)=>state.filters)
    const partners = useSelector((state:RootState)=>state.partners);

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [selected_customercode, setselected_customercode] = useState<string>('');
    const [selected_startdate, setselected_startdate] = useState(subDays(new Date(), 3));
    const [selected_enddate, setselected_enddate] = useState(new Date());
    const [selected_reference, setselected_reference] = useState<string>('');
    const [selected_searchby, setselected_searchby] = useState<string>('D');

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();


    useEffect(()=>{
      const sc = getFilterDetail(filters, 'tt', 'search_criteria');
      if(isInterface(sc, {} as FilterDetail)) {
        try {
          const json = JSON.parse(sc.value.toString());
          handlePickPartner(json.partner || null)
          setselected_customer(json.relnr);
          setselected_customercode(json.clientcode);
          setselected_reference(json.reference);
          setselected_searchby(json.searchby ? json.searchby.toUpperCase() : 'D');
          setselected_startdate(convertToDate(json.startdate) || subDays(new Date(), 3))
          setselected_enddate(convertToDate(json.enddate) || new Date())
          onStartSearch({
            token: auth.data?.token,
            partner: json.partner || '',
            relnr: json.relnr,
            clientcode: json.clientcode,
            startdate: formatDateToYYYYMMDD(convertToDate(json.startdate) || subDays(new Date(), 3)),
            enddate: formatDateToYYYYMMDD(convertToDate(json.enddate) || new Date()),
            searchby: json.searchby || 'D',
            reference: json.reference
          })
        } catch (error) {
          console.log(error)
        }
      }
    }, [])

    useEffect(()=>{
      if (auth.data?.partner.split(",").length === 1) {
          handlePickPartner(auth.data.partner.split(",")[0])
      }
    }, [])


    const handlePickPartner = async (relnr:string) => {
      try {
        if(selected_partner!==relnr){setselected_customer('clear')}
        setselected_partner(relnr);
        setselected_customer(null);
        setcustomers_list(null);
        const list = await getCustomerList(auth, relnr)
        if(list){ setcustomers_list(list) }
        if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
      } catch (error) {
        console.log(error)
        toast.error(t("track_and_trace.toast_wrong"))
      }
    }

    const handleSearch = () => {
      if(!selected_searchby){
        toast.error(t("track_and_trace.toast_missing"))
        return;
      }
      const object = {
        token: auth.data?.token,
        partner: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : '',
        relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
        clientcode: selected_customercode,
        reference: selected_reference,
        startdate: formatDateToYYYYMMDD(selected_startdate),
        enddate: formatDateToYYYYMMDD(selected_enddate),
        searchby: selected_searchby
      }
      onStartSearch(object)
    }

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

    return (
    <>
    <Toaster />
    <div>
        <Label htmlFor='partner'>{t("track_and_trace.partner")}</Label>
        <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("track_and_trace.partner_placeholder")} input_placeholder={t("track_and_trace.search_placeholder")}/>
    </div>

    <div>
        <Label htmlFor='end_customer'>{t("track_and_trace.customer_p2")}</Label>
        <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("track_and_trace.customer_placeholder")} input_placeholder={t("track_and_trace.search_placeholder")}/>
    </div>

    <div>
        <Label htmlFor='customer_code'>{t("track_and_trace.customer_code")}</Label>
        <Input value={selected_customercode} onChange={({currentTarget})=>{ setselected_customercode(currentTarget.value) }} id='customer_code'/>
    </div>

    <div className='col-span-full'>
        <Label htmlFor='reference'>{t("track_and_trace.reference")}</Label>
        <Input value={selected_reference} onChange={({currentTarget})=>{ setselected_reference(currentTarget.value) }} id='reference'/>
    </div>

    <div>
        <Label htmlFor='search'>{t("track_and_trace.search_on")}<span className='text-primary ml-1'>*</span></Label>
        <Select value={selected_searchby} onValueChange={(value)=>{ setselected_searchby(value) }}>
          <SelectTrigger id='search'>
            <SelectValue placeholder={t("track_and_trace.search_on_placeholder")}/>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="D">{t("track_and_trace.search_delivered")}</SelectItem>
            <SelectItem value="C">{t("track_and_trace.search_created")}</SelectItem>
            <SelectItem value="M">{t("track_and_trace.search_edited")}</SelectItem>
            <SelectItem value="O">{t("track_and_trace.search_ordered")}</SelectItem>
            <SelectItem value="S">{t("track_and_trace.search_stock")}</SelectItem>
            <SelectItem value="V">{t("track_and_trace.search_validated")}</SelectItem>
          </SelectContent>
        </Select>
    </div>

    <div>
      <Label htmlFor='startdate'>{t("track_and_trace.date_start")}</Label>
      <WindowsDatePicker value={selected_startdate} id='startdate' onDateChange={(value) => { if(value) setselected_startdate(value) }} placeholder={t("track_and_trace.date_placeholder")}/>
    </div>

    <div>
      <Label htmlFor='enddate'>{t("track_and_trace.date_end")}</Label>
      <WindowsDatePicker value={selected_enddate} id='enddate' onDateChange={(value) => { if(value) setselected_enddate(value) }} placeholder={t("track_and_trace.date_placeholder")}/>
    </div>

    <div className='col-span-full flex items-center gap-2'>
      <Button disabled={loading} onClick={()=>{handleSearch()}}>{
        loading
          ? <Loader2 className='animate-spin'/>
          : t("track_and_trace.button_search")
      }</Button>
      {loading && <Button onClick={cancelRequest} variant='secondary'>{t("track_and_trace.button_stop")}</Button>}
    </div> 
    
    </>
  )
}

export default Deliveries