export function setCookie(name: string, value: string, daysToExpire?: number) {
    let cookie = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
    
    if (daysToExpire) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + daysToExpire);
      cookie += `; expires=${expirationDate.toUTCString()}`;
    }
  
    document.cookie = cookie;
}
  
export function getCookie(name: string): string | null {
    const decodedName = decodeURIComponent(name);
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      let cookie = cookies[i];
      while (cookie.charAt(0) === ' ') {
        cookie = cookie.substring(1);
      }
      if (cookie.indexOf(decodedName + '=') === 0) {
        return decodeURIComponent(cookie.substring(decodedName.length + 1));
      }
    }
  
    return null;
}