import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/ui/button'
import { Label } from '../../../components/ui/label'
import { Textarea } from '../../../components/ui/textarea'
import { Combobox } from '../../../components/ui/combobox'
import usePartnerStore from '../../../zustand/usePartnerStore'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select'
import { DatePicker } from '../../../components/ui/datepicker'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { Loader2 } from 'lucide-react'
import { formatDateToYYYYMMDD } from '../../../lib/date'
import { getCustomerList } from '../../../lib/fetch/customer'
import WindowsDatePicker from '../../../components/ui/windows-datepicker'
import { useTranslation } from 'react-i18next'
import { isAfter } from 'date-fns'

type CN = '' | 'c' | 'n'

const Add = () => {
  const partners = useSelector((state:RootState)=>state.partners);
  const { auth } = useAuth();
  const { t } = useTranslation()
  
  const [selected_partner, setselected_partner] = useState<string | null>(null);
  const [selected_customer, setselected_customer] = useState<string | null>(null);
  const [selected_cn, setselected_cn] = useState<CN | undefined>(undefined);
  const [selected_date, setselected_date] = useState<Date | undefined>(undefined);

  const [customers_list, setcustomers_list] = useState<Customer[] | null>()

  const {isFetching, refetch} = useQuery({
    queryFn: async () => {
      try {
        toast.loading(t("planning.o_add.toast_loading"), {id:'transportRequest'})

        if(!selected_date) { throw new Error() }
        if(!selected_customer) { throw new Error() }
        if(!isAfter(selected_date, new Date)){ throw new Error('Ongeldige datum') }

        const {data} = await axios.post(`transport/request`, {
          token: auth.data?.token,
          date: formatDateToYYYYMMDD(selected_date),
          relnr: selected_customer,
          cn: selected_cn,
          recipient: '' //Dient voor testdoeleinden (vul hier een e-mailadres om te testen)
        })
        if(data.errorcode!==0){throw new Error(data.message)}
        toast.success(t("planning.o_add.toast_success"), {id:'transportRequest'})
      } catch (error) {
        console.log(error);
        toast.error(t("planning.o_add.toast_error"), {id:'transportRequest'})
        return null;
      }

      return null
    },
    enabled: false
  })

  useEffect(()=>{
    if(Array.isArray(partners)){
        if(partners.length === 1){
            handlePickPartner(partners[0].relnr.toString())
        }
    }
}, [partners])

  const handlePickPartner = async (relnr:string) => {
    try {
        if(selected_partner!==relnr){setselected_customer('clear')}
        setselected_partner(relnr);
        setcustomers_list(null);
        const list = await getCustomerList(auth, relnr)
        if(list){ setcustomers_list(list) }
        if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
    } catch (error) {
      console.log(error)
      toast.error(t("planning.o_add.toast_wrong"))
    }
  }

  const isDisabled = () => {
    if(isFetching) {return true}
    if(!selected_partner || !selected_customer || !selected_date || selected_cn === undefined) {return true}
    if(!isAfter(selected_date, new Date)){ return true }
    return false
  }

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("planning.o_add.title")}</h1>
            <Link to=".."><Button variant='secondary'>{t("planning.o_add.back")}</Button></Link>
        </div>

        <div className='p-4 shadow-sm border rounded-sm my-4 md:my-8'>
          <h2 className='text-lg'>{t("planning.o_add.data")}</h2>
          <div className='my-4 duration-300 grid md:grid-cols-2 gap-4'>
            <div>
              <Label htmlFor='partner'>{t("planning.o_add.partner")}</Label>
              <Combobox id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("planning.o_add.partner_placeholder") || ""} input_placeholder={t("planning.o_add.search_placeholder") || ""}/>
            </div>

            <div>
              <Label htmlFor='end_customer'>{t("planning.o_add.customer")}<span className='text-primary ml-1'>*</span></Label>
              <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:item.name})) : []} placeholder={t("planning.o_add.customer_placeholder") || ""} input_placeholder={t("planning.o_add.search_placeholder") || ""}/>
            </div>

            <div>
              <Label>{t("planning.o_add.date")}<span className='text-primary ml-1'>*</span></Label>
              <WindowsDatePicker disabledProps={{before:new Date()}} value={selected_date} className='w-full' onDateChange={(date) => { if(date && isAfter(new Date(date), new Date())){ toast.remove('date'); setselected_date(date) }else{ toast.error(`Gelieve een geldige datum te kiezen`, {id:'date'}) } }} placeholder={t("planning.o_add.date_placeholder") || ""}/>
            </div>

            <div>
              <Label>{t("planning.o_add.coins_or_notes")}<span className='text-primary ml-1'>*</span></Label>
              <Select value={selected_cn} onValueChange={(v:CN) => { setselected_cn(v) }} >
                  <SelectTrigger>
                      <SelectValue/>
                  </SelectTrigger>
                  <SelectContent>
                      <SelectItem value="c">{t("planning.o_add.coins")}</SelectItem>
                      <SelectItem value="n">{t("planning.o_add.notes")}</SelectItem>
                      <SelectItem value="">{t("planning.o_add.coins_and_notes")}</SelectItem>
                  </SelectContent>
              </Select>
            </div>

            <div className='col-span-full'>
              <Label htmlFor='reason'>{t("planning.o_add.reason")}</Label>
              <Textarea className='max-h-80' id='reason'/>
            </div>
          </div>
        </div>

        <Button onClick={()=>{refetch()}} disabled={isDisabled()}>{isFetching ? <Loader2 className='animate-spin'/> : t("planning.o_add.button")}</Button>
    </div>
    </>
  )
}

export default Add