import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../../../components/ui/button'
import { Label } from '../../../../../components/ui/label'
import { Combobox } from '../../../../../components/ui/combobox'
import { Input } from '../../../../../components/ui/input'
import { Switch } from '../../../../../components/ui/switch'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog'
import useAuth from '../../../../../hooks/useAuth'
import axios from '../../../../../lib/axios'
import toast from 'react-hot-toast'
import { useMutation, useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { Loader2 } from 'lucide-react'
import { RadioGroup, RadioGroupItem } from '../../../../../components/ui/radio-group'

type CreateNewCurrencyProps = {
    onSuccess?: (new_id:number) => void;
}

const CreateNewCurrency = ({onSuccess}:CreateNewCurrencyProps) => {
    const { auth } = useAuth()

    const [open, setopen] = useState(false);

    const [name, setname] = useState('');

    const { mutate, isLoading } = useMutation({
        mutationFn: async () => {
            try {
                toast.loading('Valuta aan het aanmaken...', { id:'devies-currency' })
                const { data } = await axios.post('deviescurrency/create', {
                    token: auth.data?.token,
                    deviescurrency: name
                })
                if(data.errorcode!==0){ throw new Error(data.message) }
                toast.success('Valuta succesvol aangemaakt', { id:'devies-currency' })
                setopen(false);
                const createdValutaId = data.object.deviescurrencyid
                onSuccess&&onSuccess(createdValutaId)
            } catch (error) {
                console.log(error)
                toast.error('Er is een fout opgetreden bij het aanmaken van deze valuta', { id:'devies-currency' })
            }
        }
    })

    function isComplete() {
        try {
            const s = z.object({
                name: z.string().min(1).max(255)
            })

            s.parse({
                name:name
            })
            return true
        } catch (error) {
            return false
        }
    }

    return (
        <AlertDialog open={open} onOpenChange={(o)=>setopen(o)}>
            <AlertDialogTrigger disabled={isLoading} asChild><button disabled={isLoading} className='text-sm font-medium text-primary' onClick={()=>setopen(true)}>+ Nieuwe valuta</button></AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogHeader>
                    <AlertDialogTitle>Nieuwe valuta aanmaken</AlertDialogTitle>
                    <AlertDialogDescription>Creëer een nieuwe vreemde valuta</AlertDialogDescription>
                </AlertDialogHeader>
                <div>
                    <Label htmlFor='name'>Naam</Label>
                    <Input value={name} onChange={(e)=>setname(e.target.value)} placeholder='Naam...' id='name' />
                </div>
                <AlertDialogFooter>
                    <AlertDialogCancel>Annuleren</AlertDialogCancel>
                    <AlertDialogAction disabled={isLoading || !isComplete()} onClick={()=>{mutate()}}>Aanmaken</AlertDialogAction>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

const AddPage = () => {

    const { auth } = useAuth()

    const { data:deviescurrencies, isLoading:isLoadingDC, refetch:refetchDC } = useQuery<DeviesCurrency[] | null>({
        queryKey: ['devies-currency'],
        queryFn: async () => {
            try {
                const { data } = await axios.get('/deviescurrency/get', { params: {
                    token: auth.data?.token
                }})
                if(data.errorcode!==0){ throw new Error(data.message) }
                return data.object
            } catch (error) {
                console.log(error)
                toast.error('Er is een fout opgetreden bij het ophalen van de vreemde valuta', { id:'devies-currency' })
                return null
            }
        },
        refetchOnWindowFocus: false
    })

    const [selected_valuta, setselected_valuta] = useState<string | undefined>(undefined);
    const [value, setvalue] = useState('');
    const [weight, setweight] = useState('');
    const [order, setorder] = useState(false);
    const [announce, setannounce] = useState(false);
    const [min, setmin] = useState('');
    const [max, setmax] = useState('');
    const [unit, setunit] = useState('');
    const [cn, setcn] = useState('c');

    function clearStates() {
        setselected_valuta(undefined);
        setvalue('');
        setweight('');
        setorder(false);
        setannounce(false);
        setmin('');
        setmax('');
        setunit('');
        setcn('c');
    }

    const { mutate, isLoading } = useMutation({
        mutationFn: async () => {
            try {
                toast.loading('Currency aan het aanmaken...', { id:'devies-currency' })

                const { data } = await axios.post('devies/create', {
                    token: auth.data?.token,
                    deviescurrencyid: selected_valuta,
                    value: value && Number(value),
                    weight: weight && Number(weight),
                    order:order,
                    announce:announce,
                    min: min && Number(min),
                    max: max && Number(max),
                    unit: unit && Number(unit),
                    cn: cn
                })

                if(data.errorcode!==0){ throw new Error(data.message) }

                console.log(data);

                clearStates()
                toast.success('Currency succesvol aangemaakt', { id:'devies-currency' })
            } catch (error) {
                console.log(error)
                toast.error('Er is een fout opgetreden bij het aanmaken van deze currency', { id:'devies-currency' })
            }
        }
    })

    function isComplete() {
        try {
            const s = z.object({
                valuta: z.string().min(1).max(255),
                value: z.number().min(0).max(9999999999999),
                weight: z.number().min(0).max(9999999999999).optional(),
                order: z.boolean(),
                announce: z.boolean(),
                min: z.number().min(0).max(9999999999999),
                max: z.number().min(0).max(9999999999999),
                unit: z.number().min(0).max(9999999999999),
                cn: z.string().min(1).max(1)
            })

            s.parse({
                valuta:selected_valuta,
                value: value && Number(value),
                weight: weight && Number(weight),
                order:order,
                announce:announce,
                min: min && Number(min),
                max: max && Number(max),
                unit: unit && Number(unit),
                cn: cn
            })
            return true
        } catch (error) {
            return false
        }
    }

    const deviescurrenyOptions = Array.isArray(deviescurrencies) ? deviescurrencies.map(dc=>({value:dc.deviescurrency_id, label:dc.deviescurrency_name})) : []
    
  return (
    <div className="w-full p-4 overflow-auto max-h-[100dvh] md:p-8">
        <div className='flex items-center justify-between'>
            <h1 className='text-2xl font-semibold'>Nieuwe vreemde valuta</h1>
            <Link to=".."><Button variant='secondary'>Terug</Button></Link>
        </div>

        <div className='grid grid-cols-2 gap-4 p-4 mt-4 border rounded-sm md:mt-8'>
            <div className='col-span-full'>
                <div className='flex items-end justify-between gap-2 mb-0.5'>
                    <Label className='flex items-center gap-1'>Valuta {isLoadingDC && <Loader2 className='w-3 h-3 animate-spin' />}</Label>
                    <CreateNewCurrency onSuccess={(new_id)=>{refetchDC(); setselected_valuta(new_id.toString())}} />
                </div>
                <Combobox selectedValue={selected_valuta} onValueChange={({value})=>setselected_valuta(value.toString())} disabled={isLoadingDC} options={deviescurrenyOptions} placeholder='Selecteer valuta...' input_placeholder='Zoek valuta...'/>
            </div>

            <div className='col-span-full'>
                <RadioGroup orientation='horizontal' value={cn} onValueChange={(v)=>setcn(v)} className='flex items-center gap-8'>
                    <div className='flex items-center gap-1'><RadioGroupItem value='c'/><Label>Munten</Label></div>
                    <div className='flex items-center gap-1'><RadioGroupItem value='n'/><Label>Biljetten</Label></div>
                </RadioGroup>
            </div>

            <div>
                <Label>Waarde</Label>
                <Input value={value} onChange={(e)=>setvalue(e.target.value)} type="number" />
            </div>

            <div>
                <Label>Gewicht</Label>
                <Input value={weight} onChange={(e)=>setweight(e.target.value)} type="number" />
            </div>

            <div className='flex items-center gap-8 col-span-full'>
                <div className='flex flex-col gap-1'>
                    <Label>Bestellen</Label>
                    <Switch checked={order} onCheckedChange={(v)=>setorder(v)} />
                </div>

                <div className='flex flex-col gap-1'>
                    <Label>Aankondigen</Label>
                    <Switch checked={announce} onCheckedChange={(v)=>setannounce(v)} />
                </div>
            </div>

            

            <div>
                <Label>Min</Label>
                <Input value={min} onChange={(e)=>setmin(e.target.value)} type="number" />
            </div>

            <div>
                <Label>Max</Label>
                <Input  value={max} onChange={(e)=>setmax(e.target.value)} type="number" />
            </div>

            <div>
                <Label>Verpakt per</Label>
                <Input value={unit} onChange={(e)=>setunit(e.target.value)} type="number" />
            </div>

            <div className='col-span-full'>
                <Button onClick={()=>{mutate()}} disabled={!isComplete() || isLoading} className='gap-2'>
                    {isLoading && <Loader2 className='w-4 h-4 animate-spin' />}
                    Opslaan
                </Button>
            </div>
        </div>
    </div>
  )
}

export default AddPage