import axios from "../lib/axios";
import useAuth from "./useAuth";
import lodash from 'lodash'
import { decryptObject } from "../lib/crypt";
import i18n from "../i18n";

export type JWT = {
    username: string;
    password: string;
    expiry: string;
}

export function changeLanguage(lang:'N' | 'F' | 'E') {
    switch (lang) {
        case 'N':
            i18n.changeLanguage('nl')
            break;
        case 'F':
            i18n.changeLanguage('fr')
            break;
        case 'E':
            i18n.changeLanguage('en')
            break;
        default:
            i18n.changeLanguage('nl')
            break;
    }
}

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        try {
            let token = '';
            if(process.env.REACT_APP_SECRET) {
                let jwt = window.sessionStorage.getItem('jwt');
                
                if(jwt){
                    const dc = decryptObject(jwt, process.env.REACT_APP_SECRET) as JWT;
                    if(dc.expiry){
                        if(new Date() > new Date(dc.expiry)){
                            //Token Expired
                            window.sessionStorage.removeItem('jwt')
                            throw new Error('Token expired');
                        }
                    }

                    const { data } = await axios.post('authenticate', dc)
                    if(data.errorcode === 0){
                        //changeLanguage(data.object.user.language.toUpperCase())
                        const obj = data.object.user;
                        try { obj.partner = lodash.uniqWith(obj.partner.split(','), lodash.isEqual).join(',') } catch (e) {}

                        setAuth({status:'authenticated', data: obj })
                        token = data.object.user.token;
                    }
                }
            }
            return token;
        } catch (error) {
            console.error(error)
        }
    }

    return refresh
}

export default useRefreshToken