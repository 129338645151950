import React from 'react'
import logo from '../../../assets/images/box_logo.svg'
import logo_letters from '../../../assets/images/black_letters_logo.svg'
import error from '../../../assets/images/404.svg'
import { Link } from 'react-router-dom'

const NotFound = () => {
  return (
    <div className='bg-slate-50 h-screen relative grid grid-rows-2'>
        <div className='max-w-6xl w-full mx-auto relative z-10'>
            <div className='px-4 py-8 flex justify-between items-center'>
                <div className='flex items-center gap-2'>
                    <img className='h-10' src={logo} alt="Loomis logo"/>
                    <img className='h-8' src={logo_letters} alt="Loomis logo"/>
                </div>
                <div className='flex items-center gap-10'>
                    <Link to="https://www.loomis.be/" className='hidden sm:flex text-slate-700 uppercase font-semibold text-sm'>Website</Link>
                    <Link to="https://www.loomis.be/nl/andere-vacatures-in-uitvoering/" className='hidden sm:flex text-slate-700 uppercase font-semibold text-sm'>Jobs</Link>
                    <Link to="https://www.loomis.be/nl/contactformulier/" className='hidden sm:flex text-slate-700 uppercase font-semibold text-sm'>Contact</Link>
                    <Link to="/" className='bg-primary/10 border border-primary text-primary rounded-full px-4 py-2 uppercase font-semibold text-sm'>Inloggen</Link>
                </div>
            </div>

            <div className='mt-16 flex gap-8 flex-col justify-center items-center'>
                <h1 className='text-2xl font-semibold'>Oops! Why are you here?</h1>
                <p className='text-neutral-500 max-w-xl text-center'>We are very sorry for inconvenience. It looks like you're trying to access a page that either has been deleted or never even existed.</p>
                <Link to="/"><button className='bg-primary text-white rounded-full uppercase py-4 px-6 text-md font-semibold'>Back to home</button></Link>
            </div>
        </div>

        <img className='mx-auto h-full' src={error} alt="Loomis 404"/>
    </div>
  )
}

export default NotFound