import { useNavigate, Link, useLocation } from 'react-router-dom';
import useLogout from '../../hooks/useLogout';
import useUserStore from '../../zustand/useUserStore';
import { useEffect, useState } from 'react';
import { ChevronLeft, LayoutGrid, Ticket, ChevronDown, CornerDownRight, Folders, Timer, MoreVertical, LogOut, Menu, Truck, FileWarning, Mailbox, Users, Settings, Package, RockingChair, Receipt, ChevronsRight, ChevronRight, Globe2 } from 'lucide-react';
import box_logo from "../../assets/images/box_logo.svg";
import white_letters_logo from "../../assets/images/white_letters_logo.svg";
import mobile_logo from "../../assets/images/box_logo.svg";
import useAuth from '../../hooks/useAuth';
import { Tooltip, TooltipProvider, TooltipTrigger } from '../ui/tooltip';
import { TooltipContent } from '@radix-ui/react-tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux';
import { useQuery } from '@tanstack/react-query';
import axios from '../../lib/axios';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger } from '../ui/select';

type Subpage = 'berichtencentrum' | 'track-en-trace' | 'bestellen-en-ophalen' | 'sip' | boolean

const Navigation = () => {
    const {t, i18n} = useTranslation()
    const { auth } = useAuth();
    const navigate = useNavigate();
    const logout = useLogout();
    const location = useLocation();

    const role = useSelector((state:RootState)=>state.role);

    const [open, setopen] = useState(true);
    const [submenu_open, setsubmenu_open] = useState<Subpage>(false);

    const [active_page, setactive_page] = useState<string>('dashboard');

    useEffect(()=>{
      setactive_page(location.pathname.split("/")[1])
      setsubmenu_open(location.pathname.split("/")[1] as Subpage || false)
    }, [location])

    const {data:ableToValidate} = useQuery<boolean>({
      queryKey: [`ableToValidate_${auth.data?.username}`],
      queryFn: async () => {
        let result = false;
        try {
          if(auth.data?.role === 1){ return true }
          if(auth.data?.role !== 2){ return result }
          const {data} = await axios.post(`partner/validation/search`, {
            token: auth.data?.token,
            partner: auth.data?.partner
          })
          if(data.errorcode!==0){throw new Error()}
          if(!data.object.validation){return null;} //empty
          return data.object.validation.flatMap((obj:any)=>obj.users).map((i:any)=>i.user).includes(auth.data.username)
        } catch (error) {
          
        }
        return result
      }
    })

    const {data:partnerrights} = useQuery<PartnerRight[] | null>({
      queryKey: [`partnerrights_${auth.data?.partner}`],
      queryFn: async () => {
          try {
              if(!auth.data?.partner){ return null; }
              const {data} = await axios.post(`partnerrights`, {
                token: auth.data?.token,
                partnerid: auth.data?.partner,
              })
              if(data.errorcode!==0){throw new Error(data.message)}
              if(!data.object.right){ return null; }
              return data.object.right
          } catch (error) {
              console.log(error)
              return null
          }
      },
      enabled: !!auth.data
    })

    const signOut = async () => {
      await logout();
      navigate('/login')
    }

    const LanguageSelector = () => {
      function returnLabel (lang:string){
        switch (lang) {
          case 'nl':
            return 'Nederlands'
          case 'fr':
            return 'Francais'
          case 'en':
            return 'English'
          default:
            return 'Nederlands'
        }
      }

      const changeLanguage = (language:string) => {
        i18n.changeLanguage(language)
      }

      return (
        <Select value={i18n.language} onValueChange={(v)=>{changeLanguage(v)}}>
          <SelectTrigger className='border-none px-0 py-2'><div className='text-white flex items-center gap-2 text-sm hover:underline py-1'><Globe2 className='w-4 h-4'/><p>{returnLabel(i18n.language)}</p></div></SelectTrigger>
          <SelectContent>
            <SelectGroup>
                <SelectItem value="nl">Nederlands</SelectItem>
                <SelectItem value="fr">Francais</SelectItem>
                <SelectItem value="en">English</SelectItem>
            </SelectGroup>
        </SelectContent>
        </Select>
      )
    }

    const ToolTipInfo = () => {
      return (
        <TooltipProvider>
          <Tooltip delayDuration={0}>
            <TooltipTrigger className='w-full'><p className='bg-white/10 text-white font-medium text-sm px-4 py-1 rounded-full flex items-center justify-between gap-2 group'>Loomis contact<ChevronRight className='w-4 h-4 group-hover:-rotate-90 duration-200'/></p></TooltipTrigger>
            <TooltipContent className='bg-white p-2 rounded-sm border text-sm flex flex-col'>
              <p className='font-medium'>{t("navigation.contact")}</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/> Excelsiorlaan 45, 1930 Zaventem</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/> VAT BE 0834.600.965</p>
              <p className='font-medium mt-2'>Helpdesk</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/>be.helpdesk@loomis.com</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/>+32 9 243 70 80</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/>{t("navigation.opening_hours")}</p>
              <p className='font-medium mt-2'>Sales</p>
              <p className='flex items-center gap-2'><ChevronRight className='w-3 h-3'/>be.sales@loomis.com</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      )
    }

    const DashboardButton = () => (
      <>
      <Link to="/dashboard">
        <li className={`${active_page === 'dashboard' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
          <span><LayoutGrid className='text-white w-5 float-left' strokeWidth={1.5}/></span>
          <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.dashboard") || 'Dashboard'}</span>
        </li>
      </Link>
      </>
    )

    const PlanningButton = () => (
      <>
      <Link to="/planning">
        <li className={`${active_page === 'planning' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
          <span><Timer className='text-white w-5 float-left' strokeWidth={1.5}/></span>
          <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.schedule") || 'Schedule'}</span>
        </li>
      </Link>
      </>
    )

    const OrderAndPickupButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].order || partnerrights[0].consumables ||  partnerrights[0].announce))) && <>
        {!(!auth.data?.right.order && !auth.data?.right.consumables && !auth.data?.right.announce) && 
        <div onMouseLeave={()=>{ setsubmenu_open(false)}} onMouseOver={()=>{setsubmenu_open('bestellen-en-ophalen')}}>
          <Link to="/bestellen-en-ophalen">
            <li className={`${active_page === 'bestellen-en-ophalen' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
              <span><Package className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.order_and_pickup") || 'Order & Pick up'}</span>
              <span className={`${!open && 'hidden'}`}>{active_page === 'bestellen-en-ophalen' 
                ? <ChevronsRight className={`${submenu_open === 'bestellen-en-ophalen' && 'rotate-180'} duration-200 text-white w-4`}/>
                : <ChevronDown className={`${submenu_open === 'bestellen-en-ophalen' && 'rotate-180'} duration-200 text-white w-4`}/>
                }
              </span>
            </li>
          </Link>

          {(submenu_open === 'bestellen-en-ophalen' && open) &&
          <ul className='flex flex-col gap-1'>
            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].order)) && <>
              {(auth.data.right.order&&auth.data.role > 2) && 
              <Link to="/bestellen-en-ophalen/bestelling-plaatsen">
                <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                  <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                  <span className='text-white'>{t("navigation.place_order")}</span>
                </li>
              </Link>
              }
            </>}

            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].order)) && <>
              {auth.data.right.order && 
              <Link to="/bestellen-en-ophalen/overzicht-bestellingen">
                <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                  <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                  <span className='text-white'>{t("navigation.overview_orders")}</span>
                </li>
              </Link>
              }
            </>}
            
            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].announce)) && <>
              {(auth.data.right.announce&&auth.data.role > 2) && 
              <Link to="/bestellen-en-ophalen/ophaling-aankondigen">
              <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                <span className='text-white'>{t("navigation.announce_collection")}</span>
              </li>
              </Link>
              }
            </>}

            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].announce)) && <>
              {auth.data.right.announce &&
              <Link to="/bestellen-en-ophalen/overzicht-ophalingen">
              <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                <span className='text-white'>{t("navigation.overview_collections")}</span>
              </li>
              </Link>
              }
            </>}
            
            {(auth.data.role < 3 && ableToValidate) &&
            <Link to="/bestellen-en-ophalen/te-valideren-bestellingen">
            <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
              <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className='text-white'>{t("navigation.to_validate_orders")}</span>
            </li>
            </Link>
            }
            
            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].consumables)) && <>
              {(auth.data.right.consumables && auth.data.role > 2) && 
              <Link to="/bestellen-en-ophalen/verbruiksartikelen-bestellen">
              <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                <span className='text-white'>{t("navigation.order_consumables")}</span>
              </li>
              </Link> 
              }
            </>}

            {((auth.data?.partner==='') || (partnerrights && partnerrights[0].consumables)) && <>
              {auth.data.right.consumables && 
              <Link to="/bestellen-en-ophalen/overzicht-verbruiksartikelen">
              <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                <span className='text-white'>{t("navigation.overview_consumables")}</span>
              </li>
              </Link> 
              }
            </>}
            <div className='h-px w-full bg-neutral-600'></div>
          </ul>
          }
        </div>
        }
      </>}
      </>
    )
    
    const TrackAndTraceButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].ttdelivery || partnerrights[0].ttcollection || partnerrights[0].ttsafepoint ||partnerrights[0].ttatm2t || partnerrights[0].ttatme2e || partnerrights[0].ttstops))) && <>
        {!(!auth.data?.right.ttdelivery && !auth.data?.right.ttcollection && !auth.data?.right.ttsafepoint && !auth.data?.right.ttatm2t && !auth.data?.right.ttatme2e && !auth.data?.right.ttstops) && 
        <div onMouseLeave={()=>{setsubmenu_open(false)}} onMouseOver={()=>{setsubmenu_open('track-en-trace')}} className="relative">
          <Link to="/track-en-trace">
            <li className={`${active_page === 'track-en-trace' ?"" : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
              <span><Truck className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.track_and_trace") || 'Track & Trace'}</span>
              <span className={`${!open && 'hidden'}`}>
                {active_page === 'track-en-trace' 
                ? <ChevronsRight className={`${submenu_open === 'track-en-trace' && 'rotate-180'} duration-200 text-white w-4`}/>
                : <ChevronDown className={`${submenu_open === 'track-en-trace' && 'rotate-180'} duration-200 text-white w-4`}/>
                }
              </span>
            </li>
          </Link>

          {(submenu_open === 'track-en-trace' && open) &&
          <ul className='flex flex-col gap-1'>
            <Link to="/track-en-trace/keepsafe-nummer">
            <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
              <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className='text-white'>{t("navigation.keepsafe_nr")}</span>
            </li>
            </Link>
            <div className='h-px w-full bg-neutral-600'></div>
          </ul>
          }
        </div>
        }
      </>}
      </>
    )

    const SipButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0]?.sipassets || partnerrights[0]?.sipexecutedinterventions || partnerrights[0].sipmanagements || partnerrights[0].sipplanninginterventions))) && <>
        {!(!auth.data?.right.sipexecutedinterventions && !auth.data?.right.sipmanagements && !auth.data?.right.sipassets  && !auth.data?.right.sipplanninginterventions) && 
        <div onMouseLeave={() => {setsubmenu_open(false) }} onMouseOver={() => { setsubmenu_open('sip') }}>
          <Link to="/sip">
            <li className={`${active_page === 'sip' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
              <span><Receipt className='text-white w-5 float-left' strokeWidth={1.5} /></span>
              <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>SIP Info</span>
              
              <span className={`${!open && 'hidden'}`}>{active_page === 'sip' 
                ? <ChevronsRight className={`${submenu_open === 'sip' && 'rotate-180'} duration-200 text-white w-4`}/>
                : <ChevronDown className={`${submenu_open === 'sip' && 'rotate-180'} duration-200 text-white w-4`}/>
                }
              </span>
            </li>
          </Link>

          {(submenu_open === 'sip' && open) &&
            <ul className='flex flex-col gap-1'>
              {((auth.data?.partner==='') || (partnerrights && partnerrights[0]?.sipexecutedinterventions)) && <>
                {auth.data.right.sipexecutedinterventions && 
                <Link to="/sip/uitgevoerde-interventies">
                  <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                    <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5} /></span>
                    <span className='text-white'>{t("navigation.executed_interventions")}</span>
                  </li>
                </Link>
                }
              </>}
              
              {((auth.data?.partner==='') || (partnerrights && partnerrights[0]?.sipmanagements)) && <>
                {auth.data.right.sipmanagements && 
                <Link to="/sip/beheer-atm">
                  <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                    <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5} /></span>
                    <span className='text-white'>{t("navigation.manage_atm")}</span>
                  </li>
                </Link>
                }
              </>}
              
              {((auth.data?.partner==='') || (partnerrights && partnerrights[0]?.sipassets)) && <>
                {auth.data.right.sipassets &&
                <Link to="/sip/assets">
                  <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                    <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5} /></span>
                    <span className='text-white'>{t("navigation.assets")}</span>
                  </li>
                </Link>
                }
              </>}
              
              {((auth.data?.partner==='') || (partnerrights && partnerrights[0]?.sipplanninginterventions)) && <>
                {auth.data.right.sipplanninginterventions && 
                <Link to="/sip/planning-interventies">
                  <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
                    <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5} /></span>
                    <span className='text-white'>{t("navigation.planned_interventions")}</span>
                  </li>
                </Link>
                }
              </>}
              <div className='h-px w-full bg-neutral-600'></div>
            </ul>
          }
        </div>
        }
        </>}
      </>
    )

    const PensionButton = () => (
      <>  
      {(auth.data?.right.pension && auth.data.partner.includes('15287') || auth.data?.role === 1) && 
      <Link to="/pensioenen">
        <li className={`${active_page === 'pensioenen' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
          <span><RockingChair className='text-white w-5 float-left' strokeWidth={1.5}/></span>
          <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.pensions")}</span>
        </li>
      </Link>
      }
      </>
    )

    const ComplaintsButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].complaint))) && <>
        {auth.data?.right.complaint &&
        <Link to="/complaints">
          <li className={`${active_page === 'complaints' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
            <span><FileWarning className='text-white w-5 float-left' strokeWidth={1.5}/></span>
            <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.complaints") || 'Complaints'}</span>
          </li>
        </Link>
        }
      </>}
      </>
    )

    const MessageCenterButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].message))) && <>
        {auth.data?.right.message && 
        <div onMouseLeave={()=>{setsubmenu_open(false)}} onMouseOver={()=>{setsubmenu_open('berichtencentrum')}}>
          <Link to="/berichtencentrum">
          <li className={`${active_page === 'berichtencentrum' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
            <span><Mailbox className='text-white w-5 float-left' strokeWidth={1.5}/></span>
            <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.message_center") || 'Message center'}</span>
            <span className={`${!open && 'hidden'}`}>{active_page === 'berichtencentrum' 
              ? <ChevronsRight className={`${submenu_open === 'berichtencentrum' && 'rotate-180'} duration-200 text-white w-4`}/>
              : <ChevronDown className={`${submenu_open === 'berichtencentrum' && 'rotate-180'} duration-200 text-white w-4`}/>
              }
            </span>
          </li>
          </Link>
          
          {(submenu_open === 'berichtencentrum' && open) &&
          <ul className='flex flex-col gap-1'>
            <Link to="/berichtencentrum/berichtenoverzicht">
            <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
              <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className='text-white'>{t("navigation.message_overview")}</span>
            </li>
            </Link>

            {/* <Link to="/berichtencentrum/berichtendetails">
            <li className='text-sm flex items-center p-2 gap-4 rounded-md opacity-60 hover:bg-slate-700 cursor-pointer'>
              <span><CornerDownRight className='text-white w-5 float-left' strokeWidth={1.5}/></span>
              <span className='text-white'>Berichtendetails</span>
            </li>
            </Link> */}

            <div className='h-px w-full bg-neutral-600'></div>
          </ul>
          }
        </div>
        }
      </>}
      </>
    )

    const UsersButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].userlist))) && <>
        {(auth.data?.right.userlist && auth.data.role < 3) && 
        <Link to="/gebruikers">
          <li className={`${active_page === 'gebruikers' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
            <span><Users className='text-white w-5 float-left' strokeWidth={1.5}/></span>
            <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.users") || 'Users'}</span>
          </li>
        </Link>
        }
      </>}
      </>
    )

    const SettingsButton = () => (
      <>
      {((auth.data?.partner==='') || (partnerrights && (partnerrights[0].adminedit))) && <>
        {(auth.data?.right.adminedit && auth.data.role === 1) && 
        <Link to="/instellingen">
          <li className={`${active_page === 'instellingen' ? 'after:content-[""] after:w-[2px] after:h-4 after:bg-white after:absolute after:-left-3 after:rounded-full' : 'opacity-60'} relative flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md hover:opacity-100`}>
            <span><Settings className='text-white w-5 float-left' strokeWidth={1.5}/></span>
            <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base flex-1`}>{t("navigation.settings") || 'Settings'}</span>
          </li>
        </Link>
        }
      </>}
      </>
    )

    return (
        <>
        <div className={`hidden md:flex sticky z-10 top-0 h-screen bg-slate-800 p-5 pt-8 ${open ? "w-72" : "w-20"} duration-300 relative`}>
            <ChevronLeft onClick={()=>{setopen(!open)}} className={`p-1 bg-white text-primary rounded-full absolute -right-3 top-9 drop-shadow-md cursor-pointer ${!open && 'rotate-180'}`}/>

            <div className='h-full w-full flex flex-col justify-between overflow-y-auto overflow-x-hidden'>
              <div>
                <div className='inline-flex'>
                  <img className='cursor-pointer block float-left mr-5 h-7' src={box_logo} alt="Logo Loomis"/>
                  <img className={`${!open && 'scale-0'} duration-300 h-7`} src={white_letters_logo} alt="White logo Loomis"/>
                </div>
      
                <div className='h-px w-full bg-neutral-600 my-6'></div>
      
                <p className={`${!open && 'hidden'} text-neutral-400 font-semibold uppercase text-sm mb-4`}>Menu</p>   
      
                <ul className='flex flex-col gap-2'>
                  <DashboardButton />

                  <PlanningButton />

                  <OrderAndPickupButton />

                  <TrackAndTraceButton />

                  <SipButton />

                  <PensionButton />

                  <ComplaintsButton />
      
                  <MessageCenterButton />
                  
                  <UsersButton />
      
                  <SettingsButton />
                </ul>
      
                <div className='h-px w-full bg-neutral-600 my-4'></div>
              </div>
      
              <div>
                <div>
                  <div className='h-px w-full bg-neutral-600 my-2'></div>
      
                  <p className={`${!open && 'hidden'} text-neutral-400 font-semibold uppercase text-sm mb-2 whitespace-nowrap`}>{t("navigation.my_profile")}</p>

                  {open && <LanguageSelector /> }
      
                  <div className={`flex items-center ${open ? 'justify-between' : 'justify-center'} `}>
                    <div>
                      <p className={`${!open && 'hidden'} font-semibold text-white whitespace-nowrap`}>{auth.data?.firstname} {auth.data?.lastname}</p>
                      <p className={`${!open && 'hidden'} text-sm text-neutral-400`}>{role[0] && role[0].name}</p>
                      <div className={`${open && 'hidden'} w-8 h-8 rounded-full bg-white flex items-center justify-center select-none`}>
                        <p className='font-semibold'>{auth.data?.firstname.charAt(0) || "?"}</p>
                      </div>
                    </div>
                  </div>
                  {open &&
                  <div className='flex justify-start mt-4'>
                    <ToolTipInfo />
                  </div>
                  }
                </div>
                
                <div className='h-px w-full bg-neutral-600 my-2'></div>
      
                <button onClick={signOut} className='w-full flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md'>
                  <span><LogOut className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                  <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base`}>{t("navigation.logout")}</span>
                </button>
              </div>
            </div>

        </div>
    
        <div className='md:hidden'>
          {/* SPACER */}
          <div className='h-16'></div>
    
          {/* TOP */}
          <div className='fixed z-50 top-0 h-16 w-screen border-b border-neutral-300 bg-white'>
            <div className='flex items-center h-full justify-between p-6'>
              <button onClick={()=>{setopen(!open)}} className='w-10 h-10 flex items-center justify-center -ml-4'>
                <Menu className='w-6'/>
              </button>
    
              <img className='h-6' src={mobile_logo} alt="logo"/>
    
              <i className='w-10'></i>
            </div>
    
            <div className={`fixed bg-slate-800 ${open ? "w-11/12" : "w-0 px-0"} h-screen top-0 z-10 p-5 pt-3 drop-shadow-lg duration-300`}>
    
              <ChevronLeft onClick={()=>{setopen(!open)}} className={`p-1 bg-white text-primary rounded-full absolute -right-3 top-5 drop-shadow-md cursor-pointer ${!open && 'hidden'}`}/>
              
              <div className={`${!open && 'hidden'} flex flex-col justify-between h-full`}>
                <div>
                  <div className={`flex items-center justify-between`}>
                    <div className='flex items-center gap-2'>
                      <div className='w-8 h-8 rounded-full bg-white flex items-center justify-center'>
                        <p className='font-semibold'>{auth.data?.firstname.charAt(0) || '?'}</p>
                      </div>
                      <div className='text-white'>
                        <p className='font-semibold text-white whitespace-nowrap'>{auth.data?.firstname} {auth.data?.lastname}</p>
                        <p className='whitespace-nowrap text-sm text-neutral-400'>{role[0] && role[0].name}</p>
                      </div>
                    </div>
                    {/* <button>
                      <MoreVertical className='w-5 text-white'/>
                    </button> */}
                  </div>
    
                  <div className='h-px w-full bg-neutral-600 my-2'></div>
    
                  <ul className='flex flex-col gap-2'>

                    <DashboardButton />

                    <PlanningButton />

                    <OrderAndPickupButton />

                    <TrackAndTraceButton />

                    <SipButton />

                    <PensionButton />

                    <ComplaintsButton />
    
                    <MessageCenterButton />
    
                    <UsersButton />
                    
                    <SettingsButton />
                  </ul>
    
                  <div className='h-px w-full bg-neutral-600 my-2'></div>
                </div>
    
                <button onClick={signOut} className='w-full flex items-center gap-4 cursor-pointer p-2 hover:bg-slate-700 rounded-md'>
                  <span><LogOut className='text-white w-5 float-left' strokeWidth={1.5}/></span>
                  <span className={`${!open && 'hidden opacity-0'} text-white duration-200 text-base`}>{t("navigation.logout")}</span>
                </button>
              </div>
            </div>        
          </div>
    
          {/* BOTTOM */}
          <div className='fixed z-50 bottom-0 h-16 w-screen bg-slate-800 shadow-md'>
              <ul className='flex justify-evenly px-6 items-center h-full'>
                <Link to="/dashboard">
                <li className={`${active_page === 'dashboard' ? '' : 'opacity-60'} flex flex-col items-center`}>
                  <span><LayoutGrid className='text-white' strokeWidth={1.5}/></span>
                  <span className='text-white'>{t("navigation.dashboard")}</span>
                </li>
                </Link>
    
                <Link to="/planning">
                <li className={`${active_page === 'planning' ? '' : 'opacity-60'} flex flex-col items-center`}>
                  <span><Timer className='text-white' strokeWidth={1.5}/></span>
                  <span className='text-white'>{t("navigation.schedule")}</span>
                </li>
                </Link>
                
                {!(!auth.data?.right.ttdelivery && !auth.data?.right.ttcollection && !auth.data?.right.ttsafepoint && !auth.data?.right.ttatm2t && !auth.data?.right.ttatme2e && !auth.data?.right.ttstops) && 
                <Link to="/track-en-trace">
                <li className={`${active_page === 'track-en-trace' ? '' : 'opacity-60'} flex flex-col items-center`}>
                  <span><Truck className='text-white' strokeWidth={1.5}/></span>
                  <span className='text-white'>{t("navigation.track_and_trace")}</span>
                </li>
                </Link>
                }
              </ul>
          </div> 
        </div>
        </>
    )
}

export default Navigation