import { useEffect, useRef, useState } from 'react'
import { Label } from '../../../../components/ui/label'
import { Input } from '../../../../components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components/ui/select'
import { Combobox } from '../../../../components/ui/combobox'
import { Button } from '../../../../components/ui/button'
import { RadioGroup, RadioGroupItem } from '../../../../components/ui/radio-group'
import { DatePickerWithRange } from '../../../../components/ui/date-picker-with-range'
import Results from './results/Results'
import { Edit, Eye, EyeOff, Loader2, Mail, Trash } from 'lucide-react'
import { Link } from 'react-router-dom'
import { motion as m } from 'framer-motion'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import axios from 'axios'
import useAuth from '../../../../hooks/useAuth'
import Delete from './Delete'
import { Toaster, toast } from 'react-hot-toast'
import SendMail from './SendMail'
import EditUser from './EditUser'
import useRolesStore from '../../../../zustand/useRolesStore'
import usePartnerStore from '../../../../zustand/usePartnerStore'
import { DateRange } from 'react-day-picker'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import { CancelTokenSource } from 'axios'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { getCustomerList } from '../../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'

type DateToggle = 'created' | 'lastlogin'

type SearchProps = {
    username?: string;
    equal?: string;
    role?: string;
    partner?: string;
    relnr?: string;
    verified?: string;
    language?: string;
    createdstart?: string;
    createdend?: string;
    lastloginstart?: string;
    lastloginend?: string;
}

const Overview = () => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const roles = useSelector((state:RootState)=>state.roles)
    const partners = useSelector((state:RootState)=>state.partners)
    const queryClient = useQueryClient()

    const [show_filters, setshow_filters] = useState(true);
    
    const [users, setusers] = useState<User[]>([]);

    const [selected_users, setselected_users] = useState<User[]>([]);
    const [selected_delete, setselected_delete] = useState<User[]>([]);
    const [selected_mail, setselected_mail] = useState<User[]>([]);
    const [selected_edit, setselected_edit] = useState<User | null>();

    const [end_customers, setend_customers] = useState<Customer[]>();

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    const [date_toggle, setdate_toggle] = useState<DateToggle>('created');
    
    // const date_toggle = useRef<DateToggle>('created')
    const search_object = useRef<SearchProps>()

    async function fetchCustomers(partner_id:string) {
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, {
            token: auth.data?.token,
            partner: partner_id,
        });
        return response.data;
    }

    const { mutate:mutateCustomers } = useMutation({
        mutationFn: (partner_id:string) => {
            return fetchCustomers(partner_id)
        },
        onSuccess(data) {
            console.log(data);
            if(data.errorcode !== 0){
                throw new Error(data.message)
            }

            try {
                if(data.object.partner[0].eindklant){
                    setend_customers(data.object.partner[0].eindklant)
                }
            } catch (error) {
                throw new Error(t("users.o_overview.toast_wrong"))
            }
        },
        onError(error) {
            console.error(error);
        }
    })

    const { refetch, fetchStatus, isFetching } = useQuery({
        queryKey: ['users'],
        queryFn: async ({signal}) => {

            return await axios.post(`${process.env.REACT_APP_BASE_URL}user/search`, {
                token: auth.data?.token,
                ...search_object.current
            }, {signal})
        },
        enabled: false,
        onSuccess(data) { 
            if(data.data.errorcode === 0){
                setusers(data.data.object.user as User[])
                setshow_filters(false);
            }   
            else {
                throw new Error(data.data.message)
            } 
        },
        onError(error) { 
            console.error(error) 
        }, 
    })

    const searchUsers = () => {
        refetch()
    }

    const addSearchData = <K extends keyof SearchProps>(key: K, value: SearchProps[K]) => {
        try {
            if(key === 'partner'){
                value = value !== process.env.REACT_APP_RDM_NUM ? value : ''
            }
            if(key === 'relnr'){
                value = value !== process.env.REACT_APP_RDM_NUM ? value : ''
            }
            const updatedUser = { ...search_object.current, [key]: value } as SearchProps;
            search_object.current = updatedUser;
        } catch (error) {
            console.log(error);
        }
    }

    const selectPartner = (partner_id:string) => {
        setend_customers([])
        mutateCustomers(partner_id);
    }

    const selectDate = (range:DateRange | undefined) => {
        if(range?.from){
            if(date_toggle === 'created'){
                addSearchData('createdstart', formatDateToYYYYMMDD(range.from))
            }
            if(date_toggle === 'lastlogin'){
                addSearchData('lastloginstart', formatDateToYYYYMMDD(range.from))
            }
        }
        else {
            if(date_toggle === 'created'){
                addSearchData('createdstart', '')
            }
            if(date_toggle === 'lastlogin'){
                addSearchData('lastloginstart', '')
            }
        }
        
        if(range?.to){
            if(date_toggle === 'created'){
                addSearchData('createdend', formatDateToYYYYMMDD(range.to))
            }
            if(date_toggle === 'lastlogin'){
                addSearchData('lastloginend', formatDateToYYYYMMDD(range.to))
            }
        }
        else {
            if(date_toggle === 'created'){
                addSearchData('createdend', '')
            }
            if(date_toggle === 'lastlogin'){
                addSearchData('lastloginend', '')
            }
        }

        if(!range){
            if(date_toggle === 'created'){
                addSearchData('createdstart', '')
                addSearchData('createdend', '')
            }
            if(date_toggle === 'lastlogin'){
                addSearchData('lastloginstart', '')
                addSearchData('lastloginend', '')
            }
        }
    }

    useEffect(()=>{
        if (auth.data?.partner.split(",")[0]) {
            handlePickPartner(auth.data.partner.split(",")[0])
        }
    }, [])
  
    const handlePickPartner = async (relnr:string) => {
        try {
        if(selected_partner!==relnr){setselected_customer('clear')}
        setselected_partner(relnr);
        setcustomers_list(null);
    
        const list = await getCustomerList(auth, relnr)
        if(list){ setcustomers_list(list) }
        if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }

        } catch (error) {
        console.log(error)
        toast.error(t("users.o_overview.toast_wrong"))
        }
    }

    const cancelRequest = () => {
        toast.success(t("users.o_overview.toast_stop"))
        queryClient.cancelQueries({queryKey:['users']})
    };

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

    function switchFilterDate(value:DateToggle) {
        setdate_toggle(value);
        addSearchData('lastloginstart', '')
        addSearchData('lastloginend', '')
        addSearchData('createdstart', '')
        addSearchData('createdend', '')
    }

  return (
    <>
    <Toaster />

    {selected_delete.length > 0 && <Delete selectedUsers={selected_delete} onSuccess={()=>{ toast.success(t("users.o_overview.toast_deleted")); setselected_delete([]); searchUsers() }} onCancel={()=>{ setselected_delete([]) }} /> }
    {selected_mail.length > 0 && <SendMail selectedUsers={selected_mail} onSuccess={()=>{ toast.success(t("users.o_overview.toast_send")); setselected_mail([]) }} onCancel={()=>{ setselected_mail([]) }}/>} 
    {selected_edit && <EditUser selectedUser={selected_edit} onSuccess={()=>{ toast.success(t("users.o_overview.toast_edit")); setselected_edit(null); searchUsers() }} onCancel={()=>{ setselected_edit(null) }}/>}

    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("users.title")}</h1>

            {auth.data?.right.regnewuser && 
            <div>
                <Link to="add-bulk"><Button variant='ghost'>{t("users.o_overview.button_bulk")}</Button></Link>
                <Link to="add"><Button>{t("users.o_overview.button_new")}</Button></Link>
            </div>
            }
        </div>

        <div className='p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
            <div className='flex justify-between'>
                <h2 className='text-lg'>{t("users.o_overview.filters")}</h2>
                <Button onClick={()=>{ setshow_filters(prev => !prev) }} variant="ghost">{show_filters ? <Eye className='w-5 text-neutral-500'/> : <EyeOff className='w-5 text-neutral-500'/>}</Button>
            </div>
            

            <div onKeyDown={(e)=>{if(e.key === 'Enter'){ searchUsers() }}} className={`${show_filters ? 'max-h-screen my-4' : 'max-h-0'} overflow-hidden duration-300 grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4`}>
                <div>
                    <Label htmlFor='email'>{t("users.o_overview.mail")}</Label>
                    <Input onChange={(e) => { addSearchData('username', e.currentTarget.value) }} id='email'/>
                </div>

                <div>
                    <Label htmlFor='email'>{t("users.o_overview.role")}</Label>
                    <Select onValueChange={(value) => { addSearchData('role', value) }}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            {Array.isArray(roles) && roles.map((role) => {
                                return (
                                    <SelectItem value={role.id.toString()} key={role.id}>{role.name}</SelectItem>
                                )
                            })}
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label htmlFor='partner'>{t("users.o_overview.partner")}</Label>
                    <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { addSearchData('partner', value.value.toString()); handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("users.o_overview.partner_placeholder")} input_placeholder={t("users.o_overview.search_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='end_customer'>{t("users.o_overview.customer")}</Label>
                    <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { addSearchData('relnr', value.value.toString()); setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("users.o_overview.customer_placeholder")} input_placeholder={t("users.o_overview.search_placeholder")}/>
                </div>

                {/* <div>
                    <Label htmlFor='email'>Partner</Label>
                    <Combobox onValueChange={(v) => { selectPartner(v.value.toString()); addSearchData('partner', v.value.toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} input_placeholder="Zoeken..." placeholder="Selecteer een partner..."/>
                </div>

                <div>
                    <Label htmlFor='email'>Eindklant</Label>
                    <Combobox disabled={!(Array.isArray(end_customers) && end_customers.length > 0)} onValueChange={(v)=>{ addSearchData('relnr', v.value.toString()) }} options={Array.isArray(end_customers) ? end_customers.map((item) => ({value:item.relnr, label: item.name})) : []} input_placeholder="Zoeken..." placeholder="Selecteer een partner..."/>
                </div> */}

                <div>
                    <Label htmlFor='language'>{t("users.o_overview.language")}</Label>
                    <Select onValueChange={(v) => { addSearchData('language', v) }}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="N">Nederlands</SelectItem>
                            <SelectItem value="F">Francais</SelectItem>
                            <SelectItem value="E">English</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div className='sm:col-span-full'>
                    <Label>{t("users.o_overview.verification")}</Label>
                    <RadioGroup onValueChange={(v) => { addSearchData('verified', v) }} className='mt-2'>
                        <div className='flex items-center gap-1'>
                            <RadioGroupItem value='1' id='verified'/>
                            <Label htmlFor='verified'>{t("users.o_overview.verified")}</Label>
                        </div>

                        <div className='flex items-center gap-1'>
                            <RadioGroupItem value='0' id='not_verified'/>
                            <Label htmlFor='not_verified'>{t("users.o_overview.not_verified")}</Label>
                        </div>

                        <div className='flex items-center gap-1'>
                            <RadioGroupItem value='' id='both'/>
                            <Label htmlFor='both'>{t("users.o_overview.both")}</Label>
                        </div>
                    </RadioGroup>
                    
                </div>

                <div className='sm:col-span-2'>
                    <Label>{t("users.o_overview.filter_on")}</Label>
                    <RadioGroup onValueChange={(value) => { switchFilterDate(value as DateToggle) }} className='mt-2 flex gap-4' defaultValue='created'>
                        <div className='flex items-center gap-1'>
                            <RadioGroupItem value='created' id='created_on'/>
                            <Label htmlFor='created_on'>{t("users.o_overview.created")}</Label>
                        </div>

                        <div className='flex items-center gap-1'>
                            <RadioGroupItem value='lastlogin' id='logged_in'/>
                            <Label htmlFor='logged_in'>{t("users.o_overview.signin")}</Label>
                        </div>
                    </RadioGroup>

                    {date_toggle === 'created' &&
                    <DatePickerWithRange onDateChange={(date) => { selectDate(date) }} className='mt-4' placeholder={t("users.o_overview.date_placeholder")}/>
                    }

                    {date_toggle === 'lastlogin' &&
                    <DatePickerWithRange onDateChange={(date) => { selectDate(date) }} className='mt-4' placeholder={t("users.o_overview.date_placeholder")}/>
                    }
                </div>
            </div>
            
            <div className='flex gap-2'>
            <Button disabled={fetchStatus === 'fetching'} onClick={searchUsers} className={`${show_filters ? '': 'hidden'}`}>
            {(fetchStatus === 'fetching') ?
            <Loader2 className='animate-spin'/>
            :
            t("users.o_overview.button_filter")
            }</Button>
            
            {isFetching && <Button onClick={()=>{cancelRequest()}} variant='secondary'>{t("users.o_overview.button_stop")}</Button>}
            </div>
            
        </div>

        <div className='sticky max-h-screen overflow-y-auto top-4 z-10 bg-white shadow-sm border rounded-sm mt-4 md:mt-8'>
            <div className='p-4 flex justify-between'>
                <h2 className='text-lg'>{t("users.o_overview.results")} {users?.length} </h2>
            </div>

            <div className={`sticky top-4 z-10 bg-white flex items-center p-2 border mx-4 rounded-sm shadow-sm`}>
                <div className='flex gap-1'>
                    {auth.data?.right.manageusrprofile && <Button onClick={()=>{ setselected_edit(selected_users[0]) }} disabled={selected_users.length !== 1} size="sm" variant='ghost' className={`bg-white flex items-center gap-2`}><Edit strokeWidth={1} className='text-orange-600 w-5'/>{t("users.o_overview.button_edit")}</Button>}
                    {auth.data?.right.deleteuser &&  <Button onClick={()=> { setselected_delete(selected_users) }} size="sm" disabled={selected_users.length === 0} variant='ghost' className='bg-white flex items-center gap-2'><Trash strokeWidth={1} className='text-red-600 w-5'/>{t("users.o_overview.button_delete")}</Button>}
                    <Button onClick={()=> { setselected_mail(selected_users) }} size="sm" disabled={selected_users.length === 0 || !selected_users.every(i=>i.verified===false)} variant='ghost' className='bg-white flex items-center gap-2 whitespace-nowrap'><Mail strokeWidth={1} className='text-blue-600 w-5'/>{t("users.o_overview.button_mail")}</Button>
                </div>
                
                {/* <Input className='bg-white ml-4' placeholder='Gebruiker zoeken...'/> */}
            </div>

            <Results onEditItem={(user) => { setselected_edit(user) }} onMailItem={(user) => { setselected_mail([...selected_mail, user]) }} onDeleteItem={(user) => { setselected_delete([...selected_delete, user]) }} onSelectItem={(users) => { setselected_users(users) }} options={users} className='mt-4 w-full'/>
        </div>
    </div>
    </>
  )
}

export default Overview