import React, { useState } from 'react'
import { Popup, PopupContent } from '../../../../components/ui/popup'
import { Button } from '../../../../components/ui/button'
import { Loader2, Settings } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../../../hooks/useAuth';
import axios from '../../../../lib/axios';
import { Label } from '../../../../components/ui/label';
import { Input } from '../../../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../components/ui/select';
import lodash from "lodash"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface EditProps {
  onCancel: () => void;
  onSuccess?: () => void;
  isLoading?: boolean;
  selectedUser: User
}

const EditUser : React.FC<EditProps> = ({onCancel, onSuccess, isLoading, selectedUser}) => {
  const { auth } = useAuth()
  const { t } = useTranslation()

  const [firstname, setfirstname] = useState(selectedUser.firstname);
  const [lastname, setlastname] = useState(selectedUser.lastname);
  const [language, setlanguage] = useState(selectedUser.language);
  const [role, setrole] = useState(selectedUser.role);

  const { mutate } = useMutation({
    mutationFn: async () => {
      return await axios.post('user/update', {
        token: auth.data?.token,
        username: selectedUser.username,
        firstname: firstname,
        lastname: lastname,
        language: language,
        role: role
      })
    },
    onSuccess(data) {
      const response = data.data;
      if(response.errorcode !== 0){
        throw new Error(response.message)
      }
      onSuccess && onSuccess();
    },
    onError(error) {
      console.error(error)
    }
  })

  return (
    <Popup>
      <PopupContent>
        <h2 className='text-lg font-semibold'>{t("users.o_edit.title")}</h2>
        <p className='text-sm text-muted-foreground mt-2'>{t("users.o_edit.description")}</p>

        <div className='mt-4 flex flex-col gap-2'>
          <div>
            <Label htmlFor='email'>{t("users.o_edit.mail")}</Label>
            <Input value={selectedUser.username} id='email' disabled type="email" placeholder={t("users.o_edit.mail")}/>
          </div>

          <div>
            <Label htmlFor='firstname'>{t("users.o_edit.firstname")}</Label>
            <Input value={firstname} onChange={(e)=>{ setfirstname(e.currentTarget.value) }} id='firstname' placeholder={t("users.o_edit.firstname")}/>
          </div>

          <div>
            <Label htmlFor='lastname'>{t("users.o_edit.lastname")}</Label>
            <Input value={lastname} onChange={(e)=>{ setlastname(e.currentTarget.value) }} id='lastname' placeholder={t("users.o_edit.lastname")}/>
          </div>

          <div> 
            <Label htmlFor='language'>{t("users.o_edit.language")}</Label>
            <Select value={language.toLowerCase()} onValueChange={(v) => { setlanguage(v) }}>
                <SelectTrigger id='language'>
                    <SelectValue />
                </SelectTrigger>
                <SelectContent>
                    <SelectItem value="n">Nederlands</SelectItem>
                    <SelectItem value="f">Francais</SelectItem>
                    <SelectItem value="e">English</SelectItem>
                </SelectContent>
            </Select>
          </div>
        </div>

        <div className='flex items-center gap-2 justify-between mt-4'>
          <Link to={`edit/${selectedUser.username}`}><Button variant='link' className='flex gap-1 -translate-x-4'><Settings className='w-4'/> {t("users.o_edit.button_advanced")}</Button></Link>

          <div className='flex items-center gap-2'>
            <Button onClick={onCancel} variant='outline'>{t("users.o_edit.button_cancel")}</Button>
            <Button disabled={isLoading || lodash.isEqual({firstname:selectedUser.firstname, lastname:selectedUser.lastname, role:selectedUser.role, language:selectedUser.language}, {firstname, lastname, role, language})} onClick={()=> { mutate() }}>
              {
                isLoading ?
                <Loader2 className='animate-spin'/>
                :
                t("users.o_edit.button_edit")
              }
            </Button>
          </div>
        </div>
      </PopupContent>
    </Popup>
  )
}

export default EditUser