export const formatDateToYYYYMMDD = (date:Date) => {
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}${month}${day}`;
}

export function convertToDate(dateString: string): Date | null {
    const formats = [
      'YYYYMMDD',
      'DDMMYYYY',
      'YYYY-MM-DD',
      'YYYY-MM-DDTHH:mm:ss.sssZ',
      'DD/MM/YYYY',
      'DD-MM-YYYY',
      'YYYYMMDDHHmmssSSS'
      // Add more formats if needed
    ];
  
    for (const format of formats) {
      const formatRegex = new RegExp(format
        .replace(/YYYY/g, '\\d{4}')
        .replace(/MM/g, '\\d{2}')
        .replace(/DD/g, '\\d{2}')
        .replace(/HH/g, '\\d{2}')
        .replace(/mm/g, '\\d{2}')
        .replace(/ss/g, '\\d{2}')
        .replace(/sss/g, '\\d{3}')
        .replace(/Z/g, 'Z?')
      );
  
      if (formatRegex.test(dateString)) {
        let yearIndex, monthIndex, dayIndex, hourIndex, minuteIndex, secondIndex, millisecondIndex;
        let year = 0, month = 0, day = 0, hour = 0, minute = 0, second = 0, millisecond = 0;
  
        if (format.includes('YYYY') && format.includes('MM') && format.includes('DD')) {
          yearIndex = format.indexOf('YYYY');
          monthIndex = format.indexOf('MM');
          dayIndex = format.indexOf('DD');
  
          year = parseInt(dateString.slice(yearIndex, yearIndex + 4), 10);
          month = parseInt(dateString.slice(monthIndex, monthIndex + 2), 10) - 1;
          day = parseInt(dateString.slice(dayIndex, dayIndex + 2), 10);
        } else if (format.includes('DD') && format.includes('MM') && format.includes('YYYY')) {
          dayIndex = format.indexOf('DD');
          monthIndex = format.indexOf('MM');
          yearIndex = format.indexOf('YYYY');
  
          day = parseInt(dateString.slice(dayIndex, dayIndex + 2), 10);
          month = parseInt(dateString.slice(monthIndex, monthIndex + 2), 10) - 1;
          year = parseInt(dateString.slice(yearIndex, yearIndex + 4), 10);
        } else if (format.includes('YYYY-MM-DD')) {
          [year, month, day] = dateString.split('-').map(Number);
          month -= 1;
        } else if (format.includes('YYYY-MM-DDTHH:mm:ss.sssZ')) {
          yearIndex = format.indexOf('YYYY');
          monthIndex = format.indexOf('MM');
          dayIndex = format.indexOf('DD');
          hourIndex = format.indexOf('HH');
          minuteIndex = format.indexOf('mm');
          secondIndex = format.indexOf('ss');
          millisecondIndex = format.indexOf('sss');
  
          year = parseInt(dateString.slice(yearIndex, yearIndex + 4), 10);
          month = parseInt(dateString.slice(monthIndex, monthIndex + 2), 10) - 1;
          day = parseInt(dateString.slice(dayIndex, dayIndex + 2), 10);
          hour = parseInt(dateString.slice(hourIndex, hourIndex + 2), 10);
          minute = parseInt(dateString.slice(minuteIndex, minuteIndex + 2), 10);
          second = parseInt(dateString.slice(secondIndex, secondIndex + 2), 10);
          millisecond = parseInt(dateString.slice(millisecondIndex, millisecondIndex + 3), 10);
        } else if (format.includes('DD/MM/YYYY')) {
          dayIndex = format.indexOf('DD');
          monthIndex = format.indexOf('MM');
          yearIndex = format.indexOf('YYYY');
  
          day = parseInt(dateString.slice(dayIndex, dayIndex + 2), 10);
          month = parseInt(dateString.slice(monthIndex, monthIndex + 2), 10) - 1;
          year = parseInt(dateString.slice(yearIndex, yearIndex + 4), 10);
        } else if (format.includes('DD-MM-YYYY')) {
          dayIndex = format.indexOf('DD');
          monthIndex = format.indexOf('MM');
          yearIndex = format.indexOf('YYYY');
  
          day = parseInt(dateString.slice(dayIndex, dayIndex + 2), 10);
          month = parseInt(dateString.slice(monthIndex, monthIndex + 2), 10) - 1;
          year = parseInt(dateString.slice(yearIndex, yearIndex + 4), 10);
        } else if (format.includes('YYYYMMDDHHmmssSSS')) { // New condition for "yyyymmddhhmmssms"
          year = parseInt(dateString.slice(0, 4), 10);
          month = parseInt(dateString.slice(4, 6), 10) - 1;
          day = parseInt(dateString.slice(6, 8), 10);
          hour = parseInt(dateString.slice(8, 10), 10);
          minute = parseInt(dateString.slice(10, 12), 10);
          second = parseInt(dateString.slice(12, 14), 10);
          millisecond = parseInt(dateString.slice(14), 10);
        } else {
          // Add more conditions if needed for additional formats
        }
  
        return new Date(year, month, day, hour, minute, second, millisecond);
      }
    }
  
    return null; // Return null if the string doesn't match any format
}

export function convertToHHMM(timeString: string): string {
  let withoutChar = timeString.replace(/:/g, '')

  const hours = withoutChar.substring(0,2);
  const minutes = withoutChar.substring(2,4);

  return `${hours}:${minutes}`
}