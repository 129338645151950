import React, { useEffect } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import "react-pdf/dist/esm/Page/TextLayer.css";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { Button } from '../../ui/button';
import { Link } from 'react-router-dom';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

type Props = {
    tickets: Ticket[]
}

const Tickets : React.FC<Props> = ({tickets}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

  return (
    <div className='flex gap-8 flex-wrap'>
        {tickets && tickets.map((ticket, index) => {
            return (
                <div key={index} className='flex gap-4'>
                    {auth.data?.right.edoc && 
                    <div className='h-[250px] overflow-hidden border rounded-sm relative'>
                        <object className='h-full w-full' data={ticket.url} type='application/pdf'>
                            <p>{t("components.o_ticket.no_ticket")}</p>
                            <Link to={ticket.url}><Button>{t("components.o_ticket.button_download")}</Button></Link>
                        </object>
                    </div>
                    }

                    <div className='flex flex-col gap-2'>
                        <p><span className='font-semibold'>{t("components.o_ticket.direction")}:</span> {ticket.direction}</p>
                        <p><span className='font-semibold'>{t("components.o_ticket.action")}:</span> {ticket.action}</p>
                        <p><span className='font-semibold'>{t("components.o_ticket.signed")}:</span> {ticket.signed_by_customer ? t("components.o_ticket.yes") : t("components.o_ticket.no")}</p>
                    </div>
                </div>
            )
        })}
    </div>
  )
}

export default Tickets