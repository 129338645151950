import React, { useState } from 'react'
import { Button } from '../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'
import { formatDateToYYYYMMDD } from '../../../lib/date'
import { subYears } from 'date-fns'
import toast, { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const UrgentMessage = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const { data:messages, refetch } = useQuery<Message[] | null>({
        queryKey: [`urgent_messages`],
        queryFn: async () => {
            try {
                const { data } = await axios.post(`message/search`, {
                    token: auth.data?.token,
                    username: auth.data?.username,
                    startdate: formatDateToYYYYMMDD(subYears(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date())
                })
                if(data.errorcode!==0){ throw new Error(data.message) }
                if(!Array.isArray(data.object.message)){ throw new Error('Messages is no array') }
                const filteredMessages = data.object.message.filter((i:any)=>i.checked.toString()==='false')
                if(filteredMessages.length === 0){return null;}
                return filteredMessages
            } catch (error) {
                return null
            }
        }
    })

    const [is_loading, setis_loading] = useState(false);
    async function markRead(message:Message) {
        try {
            setis_loading(true)
            const {data} = await axios.post(`message/check`, {
                token: auth.data?.token,
                messageid: message.id
            })
            if(data.errorcode!==0){ throw new Error(data.message )}
            refetch()
        } catch (error) {
            toast.error(`${t("message_center.toast_error_p2")}`, {id:`read_${message.id}`})
        } finally {
            setis_loading(false)
        }
    }

  return (
    <>
    <Toaster />
    {messages && messages.map((message,index)=>{
        return (
        <div className="border shadow-sm rounded-sm sm:col-span-3 bg-red-50 border-red-100" key={index}>
            <div className="p-4">
                <h2 className="text-lg">Urgent message</h2>
                <div className='my-4'>
                    <p className='font-semibold'>{message.title}</p>
                    <p>{message.message}</p>
                </div>
                <Button disabled={is_loading} onClick={()=>{markRead(message)}}>{t("message_center.button_mark")}</Button>
            </div>
        </div>
        )
    })}
    
    </>
  )
}

export default UrgentMessage