import { Loader2, Save } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../../../../components/ui/button'
import toast, { Toaster } from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'
import NewCheckbox from '../../../../../../components/ui/new-checkbox'
import { Label } from '../../../../../../components/ui/label'
import lodash from'lodash'
import axios from '../../../../../../lib/axios'
import useAuth from '../../../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

type Props = {
    users: User[];
    validaters?: ValidationUser | null;
    partnerid: string;
    refetch: () => void
}

const ValidateOrders : React.FC<Props> = ({users, validaters, partnerid, refetch}) => {
  const {t} = useTranslation()
  const {auth} = useAuth()
  const [main_validaters, setmain_validaters] = useState(lodash.cloneDeep(validaters) || {partner:parseFloat(partnerid), users:[]});
  const [new_validaters, setnew_validaters] = useState(lodash.cloneDeep(validaters) || {partner:parseFloat(partnerid), users:[]});
  const [unchecked_validaters, setunchecked_validaters] = useState<ValidationUser>({partner:parseFloat(partnerid), users:[]});

  useEffect(()=>{
    console.log(validaters)

    if(main_validaters!==validaters && validaters){
      setmain_validaters(lodash.cloneDeep(validaters))
    }
  }, [validaters])

  const {isFetching, refetch:saveSettings} = useQuery({
    queryKey: ['update_validate'],
    queryFn: async () => {
      try {
        toast.loading(t("settings.toast_loading"), {id:'saveChanges'})
        let obj = {
          token: auth.data?.token,
          check: new_validaters.users.map(v=>({partner:partnerid, username:v.user})),
          uncheck: unchecked_validaters.users.map(v=>({partner:partnerid, username:v.user}))
        }
        const {data} = await axios.post(`partner/validation/update`, obj)
        if(data.errorcode!==0){throw new Error(data.message)}
        toast.success(t("settings.toast_success_p2"), {id:'saveChanges'})
        refetch()
      } catch (error) {
        console.log(error);
        toast.error(t("settings.toast_error_p4"), {id:'saveChanges'})
        return null
      }
      return null
    },
    enabled: false
  })

  const updateValidaters = (user:string) => {
    setnew_validaters(prev=>{
      let updatedValidaters = {...prev}

      const index = updatedValidaters.users.findIndex(i=>i.user===user)
      if(index < 0){
        //Add user
        updatedValidaters.users.push({user:user})

        //Remove from unchecked
        setunchecked_validaters(prev=>{
          const updatedUnchecked = {...prev}
          const index = updatedUnchecked.users.findIndex(i=>i.user===user)
          if(index>-1){
            updatedUnchecked.users.splice(index,1)
          }
          return updatedUnchecked
        })
      }
      else {
        //Remove user
        updatedValidaters.users.splice(index, 1)

        //Add to unchecked
        setunchecked_validaters(prev=>{
          const updatedUnchecked = {...prev}
          updatedUnchecked.users.push({user:user})
          return updatedUnchecked
        })
      }

      return updatedValidaters
    })
  }

  return (
    <>
    <Toaster />
    <div className='flex justify-between mb-4'>
        <div>
            <h2 className='font-semibold leading-none'>{t("settings.validate_title")}</h2>
            <p className='text-sm text-neutral-400'>{t("settings.validate_description")}</p>
        </div>
        <Button onClick={()=>{saveSettings()}} disabled={lodash.isEqual(main_validaters, new_validaters) || isFetching} className='gap-2'><Save className='w-5'/>{isFetching ? <Loader2 className='animate-spin'/> : t("settings.validate_button_save")}</Button>
    </div>
    <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4'>
      {users.map((user, index) => {
        return (
          <div key={index} className='flex items-center gap-2'>
            <NewCheckbox onCheckedChange={(v)=>{updateValidaters(user.username)}} checked={new_validaters.users.find(i=>i.user===user.username)!==undefined} id={`${user.id}`}/>
            <Label htmlFor={`${user.id}`}>
              <p className='text-sm font-medium leading-none'>{user.firstname} {user.lastname}</p>
              <p className='text-neutral-400 text-xs text-ellipsis overflow-hidden whitespace-nowrap'>{user.username}</p>
            </Label>
          </div>
        )
      })}
    </div>
    </>
  )
}

export default ValidateOrders