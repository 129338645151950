import Overview from './Overview'
import { Route, Routes } from 'react-router-dom'
import Add from './add/Add'
import EditDetail from './edit/EditDetail'
import AddBulk from './add-bulk/AddBulk'
import Faq from '../../../../components/portal/faq/Faq'
import { useTranslation } from 'react-i18next'

const Users = () => {
  const {t} = useTranslation()

  const faq = [
    {
      label:t("users.faq_roles"),
      url: 'https://sway.office.com/s/evrPzUNHGxLmewBp/embed',
      subtitle_id: 1
    },
    {
      label:t("users.faq_new"),
      url: 'https://sway.office.com/s/wB6GdbbnyHHnovbM/embed',
      subtitle_id: 1
    },
    {
      label:t("users.faq_import"),
      url: 'https://sway.office.com/s/kUCKOzMcrUT3uyY3/embed',
      subtitle_id: 1
    }
  ]

  return (
    <div>
      <Faq title={t("users.title")} items={faq} subtitles={[{id:1, title:t("users.title")}]}/>
      <Routes>
          <Route path="" element={<Overview />} />
          <Route path="add" element={<Add />} />
          <Route path="add-bulk" element={<AddBulk />}/>
          <Route path="edit/:username" element={<EditDetail />} />
      </Routes>
    </div>
  )
}

export default Users