import { Routes, Route } from 'react-router-dom'
import Keepsafe from './_Keepsafe/Keepsafe'
import TrackAndTrace from './TrackAndTrace'

const TrackAndTraceLayout = () => {
  return (
    <>
    <Routes>
      <Route path="/*" element={<TrackAndTrace />} />
      <Route path="/keepsafe-nummer/*" element={<Keepsafe />} />
    </Routes>
    </>
  )
}

export default TrackAndTraceLayout