import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../lib/axios';
import useAuth from '../../../hooks/useAuth';
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date';
import { format, subMonths } from 'date-fns';
import SpinnerFeedback from '../../../components/portal/feedback/SpinnerFeedback';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { formatCashAmount } from '../../../lib/utils';
import { getOrderDraftState } from '../../../lib/general/params';
import { useTranslation } from 'react-i18next';

type Props = {
    selected_customer?: string | null;
}

const OverviewOrders : React.FC<Props> = ({selected_customer}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const {data:orders, refetch:refetchOrders, isFetching:isFetchingOrders} = useQuery<OrderDraft[] | null>({
        queryKey: [`draft_order_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`order/draft/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date()),
                    searchby: 'C'
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.orderdraft){return null}
                return data.object.orderdraft
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: (!!selected_customer && selected_customer!=='clear')
    })

  return (
    <div className='border rounded-sm mt-4'>
        <Table>
            <TableHeader className='bg-slate-100'>
                <TableRow>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.status")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.delivery")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.amount")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.currency")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_orders.type")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {(isFetchingOrders && !orders) && <TableRow><TableCell colSpan={6}><SpinnerFeedback /></TableCell></TableRow>}
                {(!orders && selected_customer && selected_customer !== 'clear' && !isFetchingOrders) && <TableRow><TableCell colSpan={6}>{t("dashboard.o_overview_orders.no_found")}</TableCell></TableRow>}
                {((!orders && !selected_customer && !isFetchingOrders) || (!orders && selected_customer==='clear' && !isFetchingOrders)) && <TableRow><TableCell colSpan={6}>{t("dashboard.o_overview_orders.no_selection")}</TableCell></TableRow>}
                {orders && orders.map((order, index) => {
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                    return (
                        <TableRow key={index}>
                            <TableCell>{orderdraftstate && getOrderDraftState(order.status, orderdraftstate)?.description}</TableCell>
                            <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                            <TableCell>{format(convertToDate(order.dtdelivery) || new Date, "dd/MM/yyyy")}</TableCell>
                            <TableCell>{formatCashAmount(order.amount)}</TableCell>
                            <TableCell>EUR</TableCell>
                            <TableCell>{order.cn.toLowerCase() === 'c' ? t("dashboard.o_overview_orders.coins") : t("dashboard.o_overview_orders.notes")}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>
  )
}

export default OverviewOrders