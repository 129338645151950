"use client"

import React, { useState } from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar, MonthCalendar, YearCalendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import { enUS, fr, nlBE } from "date-fns/locale"
import { Matcher } from "react-day-picker"

interface DatePickerProps extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  onDateChange: (value:Date | undefined) => void;
  locale?: 'nlBE' | 'enUS' | 'fr';
  value?: Date;
  disabled?: boolean;
  disabledProps?: Matcher | Matcher[]
}

const WindowsDatePicker : React.FC<DatePickerProps> = ({placeholder="Pick a date", onDateChange, locale="nlBE", value, className, id, disabled=false, disabledProps}) => {
  const [date, setDate] = React.useState<Date>()
  const [initialDate, setinitialDate] = useState<Date>(new Date());

  const [view, setview] = useState<'daypicker' | 'monthpicker' | 'yearpicker'>('daypicker');

  React.useEffect(() => {
    if(value){
      setDate(value)
    }
  }, [value])

  return (
    <Popover onOpenChange={()=>{setview('daypicker')}}>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          variant={"outline"}
          id={id}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground", className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        {view === 'daypicker' && 
            <Calendar
            mode="single"
            selected={date}
            onSelect={(date) => { onDateChange(date); setDate(date) }}
            initialFocus
            onMonthClick={()=>{setview('monthpicker')}}
            locale={locale === 'nlBE' ? nlBE : locale === 'fr' ? fr : locale === 'enUS' ? enUS : nlBE}
            disabled={disabledProps}
            month={initialDate}
            onMonthChange={(month)=>{setinitialDate(month)}}
            />
        }
        {view === 'monthpicker' &&
            <MonthCalendar 
                selected={date}
                month={initialDate}
                onYearClick={()=>{setview('yearpicker')}}
                onYearChange={(year)=>{setinitialDate(year)}}
                onMonthChange={(month)=>{setview('daypicker'); setinitialDate(month)}}
            />
        }
        {view === 'yearpicker' &&
            <YearCalendar 
                selected={date}
                year={initialDate}
                onPeriodChange={(year)=>{setinitialDate(year)}}
                onYearChange={(year)=>{setview('monthpicker'); setinitialDate(year)}}
            />
        }
      </PopoverContent>
    </Popover>
  )
}

export default WindowsDatePicker