import { Banknote, CircleDollarSign, Landmark, Loader2, PiggyBank, ShieldCheck } from "lucide-react";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Toaster, toast } from "react-hot-toast";
import { z } from "zod";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import { Button } from "../../../components/ui/button";
import logoSmall from "../../../assets/images/logoSmall.jpg";
import { useTranslation } from "react-i18next";
import { useMutation } from "@tanstack/react-query";
import axios from "../../../lib/axios";
import useAuth from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import lottie from 'lottie-web'
import hourglass from '../../../assets/animations/hourglass.json'
import error from '../../../assets/animations/error.json'

type RegistrationCheckProps = {
    email: string;
    registrationcode: string;
}

const Register : React.FC = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()    
    const { auth, setAuth } = useAuth()
    
    const [check_state, setcheck_state] = useState<'idle' | 'loading' | 'error' | 'success'>('idle');

    const [username, setusername] = useState('');
    const [firstname, setfirstname] = useState('');
    const [lastname, setlastname] = useState('');
    const [language, setlanguage] = useState('');
    const [password, setpassword] = useState('');
    const [repeat_password, setrepeat_password] = useState('');

    const [is_same_password, setis_same_password] = useState(false);

    const QuerySchema = z.object({
        registrationcode: z.string().min(2),
        email: z.string().email(),
    })

    const { mutate:mutateCheck } = useMutation({
        mutationFn: async (object:RegistrationCheckProps) => {
            console.log(object)
            return await axios.post(`registration/check`, object)
        },
        onSuccess(data) { 
            if(data.data.errorcode !== 0){
                throw new Error(data.data.message)
            }
            setcheck_state('success'); 
            let response = data.data.object.registration;
            setusername(response.username);
            setfirstname(response.firstname);
            setlastname(response.lastname);
            setlanguage(response.language.toLowerCase())
            console.log(data.data.object.registration) 
        },
        onError(error) { 
            setcheck_state('error'); 
            console.error(error) 
        }
    })

    const { mutate:mutateCreate, isLoading } = useMutation({
        mutationFn: async () => {
            const queryParams = new URLSearchParams(window.location.search);
            const regcode = queryParams.get('regcode');

            return await axios.post('registration/verify', {
                email: username.toLowerCase(),
                registrationcode: regcode,
                firstname: firstname,
                lastname: lastname,
                language: language.toLowerCase(),
                password: password
            })
        },
        onSuccess(data) {
            if(data.data.errorcode !== 0){
                throw new Error(data.data.message)
            }
            setAuth(data.data.object.user)
            navigate("/dashboard", { replace:true });
        },
        onError(error) { console.error(error) }
    })

    useEffect(()=>{
        const queryParams = new URLSearchParams(window.location.search);
        const regcode = queryParams.get('regcode');
        const email = queryParams.get('email');

        try {
            const queries = QuerySchema.parse({registrationcode:regcode, email});
            setcheck_state('loading')
            mutateCheck(queries as RegistrationCheckProps);
        } catch (error) {
            setcheck_state('error')
            console.log(error);
        }
    }, [])

    const changeLanguage = (language:string) => {
        i18n.changeLanguage(language)
    }

    const checkPassword = () => {
        setis_same_password(password === repeat_password && password.replace(/\s/g,'').length > 1)
    }
    useEffect(()=>{
        checkPassword();
    }, [password, repeat_password])

    const lottieRef = useRef() as MutableRefObject<HTMLDivElement>
    const errorRef = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(()=>{
        if(check_state === 'error'){
            const errorAnimation = lottie.loadAnimation({
                container: errorRef.current,
                animationData: error,
                loop: true,
                autoplay: true,
                renderer: 'svg',
            });
            errorAnimation.play()
        }

        if(check_state === 'loading'){
            const animation = lottie.loadAnimation({
                container: lottieRef.current,
                animationData: hourglass,
                loop: true,
                autoplay: true,
                renderer: 'svg',
            });
            animation.play()
        }
    }, [check_state])

    function handlePressEnter(e:any) {
        if(e){
            if(Boolean(isLoading) || !is_same_password || (firstname.replace(/\s/g,'') === "" || lastname.replace(/\s/g,'') === "" || language.replace(/\s/g,'') === "")){ return; }
            if(e.key === 'Enter'){ mutateCreate() }
        }
    }

    return (
        <>
        <Toaster />
        <div className="fixed top-0 h-12 w-full bg-slate-800"></div>
        <img className="fixed mt-6 h-12 left-1/2 -translate-x-1/2" src={logoSmall} alt="logo"/>

        <div className="h-full grid grid-cols-1 md:grid-cols-2">
            <div className="hidden md:flex bg-gradient-to-tr from-[#EAA792] to-primary flex-col justify-between p-10">
                <i></i>

                <h1 className="text-right text-white font-bold text-4xl">
                    
                    {(check_state === 'idle' || check_state === 'success') && t("register.success_state")}
                    {(check_state === 'error') && t("register.error_state")}
                    {(check_state === 'loading') && t("register.loading_state")}
                    
                </h1>

                <ul className="flex justify-between">
                    <li><CircleDollarSign className="h-12 w-12 text-white"/></li>
                    <li><Banknote className="h-12 w-12 text-white"/></li>
                    <li><ShieldCheck className="h-12 w-12 text-white"/></li>
                    <li><Landmark className="h-12 w-12 text-white"/></li>
                    <li><PiggyBank className="h-12 w-12 text-white"/></li>
                </ul>
            </div>
            <div className="bg-slate-100 flex flex-col items-center justify-between mt-12 p-12">
                <div className="w-full flex justify-end">
                    <Select value={i18n.language} onValueChange={(v)=> changeLanguage(v) }>
                        <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Selecteer een taal"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="nl">Nederlands</SelectItem>
                                <SelectItem value="fr">Francais</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                {check_state === 'success' &&
                <div className="flex flex-col gap-4 w-full">
                    <div>
                        <h1 className="text-2xl font-bold">{t("register.title")}</h1>
                        <p>{t("register.description")}</p>
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-4" onKeyDown={(e)=>{handlePressEnter(e)}}>
                        <div className='col-span-full'>
                            <Label htmlFor="email">{t("register.email")}</Label>
                            <Input value={username} disabled className="bg-white" type="email" id="email" placeholder={t("register.email")||""} />
                        </div>

                        <div>
                            <Label htmlFor="firstname">{t("register.firstname")}</Label>
                            <Input value={firstname} onChange={(e)=> { setfirstname(e.currentTarget.value) }} className="bg-white" id="firstname" placeholder={t("register.firstname")||""} />
                        </div>

                        <div>
                            <Label htmlFor="lastname">{t("register.lastname")}</Label>
                            <Input value={lastname} onChange={(e)=> { setlastname(e.currentTarget.value) }} className="bg-white" id="lastname" placeholder={t("register.lastname")||""} />
                        </div>

                        <div className='col-span-full'>
                            <Label htmlFor="language">{t("register.language")}</Label>
                            <Select value={language.toLowerCase()} onValueChange={(v)=> setlanguage(v) }>
                                <SelectTrigger id="language" className="bg-white">
                                    <SelectValue placeholder={t("register.language_placeholder")}/>
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        <SelectItem value="n">Nederlands</SelectItem>
                                        <SelectItem value="f">Francais</SelectItem>
                                        <SelectItem value="e">English</SelectItem>
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        
                        <div>
                            <Label htmlFor="password">{t("register.password")}</Label>
                            <Input onChange={(e)=> { setpassword(e.currentTarget.value) }} className="bg-white" type="password" id="password" placeholder="********" />
                        </div>     

                        <div>
                            <Label htmlFor="repeat_password">{t("register.repeat_password")}</Label>
                            <Input onChange={(e)=> { setrepeat_password(e.currentTarget.value) }} className="bg-white" type="password" id="repeat_password" placeholder="********" />
                        </div>
                    </div>
                    
                    <Button onClick={() => { mutateCreate() }} disabled={Boolean(isLoading) || !is_same_password || (firstname.replace(/\s/g,'') === "" || lastname.replace(/\s/g,'') === "" || language.replace(/\s/g,'') === "")} className="w-fit">
                        {isLoading ? 
                        <Loader2 className='animate-spin'/>
                        : t("register.button")} 
                    </Button>
                </div>
                }

                {check_state === 'error' &&
                <div className="flex flex-col justify-center items-center">
                    <div className='w-[200px] h-[200px] overflow-hidden' ref={errorRef}></div>
                    <h1 className="text-lg font-semibold">{t("register.error_state")}</h1>
                </div>
                }

                {check_state === 'loading' &&
                <div className='w-[100px] h-[100px] overflow-hidden' ref={lottieRef}></div>
                }

                <i className="h-12 w-full"></i>
                
            </div>
        </div>
        </>
    )
}

export default Register;