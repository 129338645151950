import { CircleDollarSign, Banknote, Landmark, ShieldCheck, PiggyBank, Loader2, ArrowLeft } from "lucide-react";
import logoSmall from "../../../assets/images/logoSmall.jpg";
import { Trans, useTranslation } from "react-i18next"

import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Button } from "../../../components/ui/button";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";

import { Link, useNavigate } from "react-router-dom"
import { useQuery } from "@tanstack/react-query";
import axios from "../../../lib/axios";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import lottie from 'lottie-web'
import error from '../../../assets/animations/error.json'

const ResetPassword = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [firstPassword, setfirstPassword] = useState('');
    const [secondPassword, setsecondPassword] = useState('');

    const changeLanguage = (language:string) => {
        i18n.changeLanguage(language)
    }

    async function verifyCode() {
        const queryParams = new URLSearchParams(window.location.search);
        const code = queryParams.get('code');
        return await axios.post('password/check', {
            code: code
        })
    }

    const { data, status } = useQuery(['verify'], verifyCode)

    useEffect(()=>{
        if(data?.data.errorcode !== 0 && status === 'success'){
            const errorAnimation = lottie.loadAnimation({
                container: errorRef.current,
                animationData: error,
                loop: true,
                autoplay: true,
                renderer: 'svg',
            });
            errorAnimation.play()
        }
    }, [data])

    const { data:data_reset, refetch, isFetching } = useQuery<'done' | null>({
        queryKey:[`reset_password`],
        queryFn: async () => {
            try {
                const queryParams = new URLSearchParams(window.location.search);
                const code = queryParams.get('code');
                const {data} = await axios.post(`password/reset`, {
                    code: code,
                    password: firstPassword
                })
                if(data.errorcode === 0){
                    toast.success(t("reset_password.toast_success"))
                    return 'done'
                }
                else {
                    toast.error(t("reset_password.toast_error"))
                    console.log(data)
                }
                return null
            } catch (error) {
                toast.error(t("reset_password.toast_error"))
                console.log(error)
                return null
            }
        },
        enabled: false
    })

    const errorRef = useRef() as MutableRefObject<HTMLDivElement>

  return (
    <>
    <Toaster />
    <div className="fixed top-0 h-12 w-full bg-slate-800"></div>
        <img className="fixed mt-6 h-12 left-1/2 -translate-x-1/2" src={logoSmall} alt="logo"/>

        <div className="h-full grid grid-cols-1 md:grid-cols-2">
            <div className="hidden md:flex bg-gradient-to-tr from-[#EAA792] to-primary flex-col justify-between p-10">
                <i></i>

                <h1 className="text-right text-white font-bold text-4xl">The future of cash<br></br>management is here</h1>

                <ul className="flex justify-between">
                    <li><CircleDollarSign className="h-12 w-12 text-white"/></li>
                    <li><Banknote className="h-12 w-12 text-white"/></li>
                    <li><ShieldCheck className="h-12 w-12 text-white"/></li>
                    <li><Landmark className="h-12 w-12 text-white"/></li>
                    <li><PiggyBank className="h-12 w-12 text-white"/></li>
                </ul>
            </div>
            <div className="bg-slate-100 flex flex-col items-center justify-between mt-12 p-12">
                <div className="w-full flex justify-end">
                    <Select value={i18n.language} onValueChange={(v)=> changeLanguage(v) }>
                        <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Selecteer een taal"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="nl">Nederlands</SelectItem>
                                <SelectItem value="fr">Francais</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                {data?.data.errorcode === 0 && status === 'success' && 
                <div className="flex flex-col gap-4 w-full">
                    <div>
                        <Button className="-ml-4" variant="link" onClick={()=>{ navigate('/') }}>{t("reset_password.back")}</Button>
                        <h1 className="text-2xl font-bold">{t("reset_password.title")}</h1>
                        <p>{t("reset_password.description")}</p>
                    </div>
                    
                    <div>
                        <Label htmlFor="password">{t("reset_password.password")}</Label>
                        <Input disabled={data_reset === 'done'} value={firstPassword} onChange={(e)=> { setfirstPassword(e.currentTarget.value) }} className="bg-white" type="password" id="password" placeholder="*********" />
                    </div>   

                    <div>
                        <Label htmlFor="repeat_password">{t("reset_password.repeat_password")}</Label>
                        <Input disabled={data_reset === 'done'} value={secondPassword} onChange={(e)=> { setsecondPassword(e.currentTarget.value) }} className="bg-white" type="password" id="repeat_password" placeholder="*********" />
                    </div>   
                
                    {data_reset === 'done' 
                    ? <Link to=""><Button className="w-fit">{t("reset_password.back")}</Button></Link>
                    : <Button onClick={()=>{refetch()}} disabled={firstPassword !== secondPassword || firstPassword === '' || isFetching} className="w-fit">
                        {isFetching ? <Loader2 className="animate-spin"/> : t("reset_password.button")}
                    </Button>
                    }
                    
                </div>
                }

                {data?.data.errorcode !== 0 && status === 'success' && 
                <div className="flex flex-col justify-center items-center">
                    <div className='w-[200px] h-[200px] overflow-hidden' ref={errorRef}></div>
                    <h1 className="text-lg font-semibold">{t("reset_password.error_state")}</h1>
                    <Link to="/"><Button variant='link' className="flex items-center gap-1"><ArrowLeft className="w-4"/> {t("reset_password.back")}</Button></Link>
                </div>
                }

                <i className="h-12 w-full"></i>
                
            </div>
        </div>
    </>
    )
}

export default ResetPassword