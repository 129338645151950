import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'
import { format, subMonths } from 'date-fns'
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import SpinnerFeedback from '../../../components/portal/feedback/SpinnerFeedback'
import { useTranslation } from 'react-i18next'

type Props = {
    selected_customer?: string | null
}

const OverviewComplaints : React.FC<Props> = ({selected_customer}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const complaintcode = useSelector((state:RootState)=>state.complaintcode)
    const customers = useSelector((state:RootState)=>state.customers);

    const {data:complaints, isFetching:isFetchingComplaints} = useQuery<Complaint[] | null>({
        queryKey: [`complaints_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`complaint/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date()),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.complaint){return null}
                return data.object.complaint
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: (!!selected_customer && selected_customer!=='clear')
    })

  return (
    <div className='border rounded-sm mt-4'>
        <Table>
            <TableHeader className='bg-slate-100'>
                <TableRow>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_complaints.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_complaints.id")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_complaints.complaint")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_complaints.type")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_complaints.registered")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {(!complaints && isFetchingComplaints) && <TableRow><TableCell colSpan={5}><SpinnerFeedback /></TableCell></TableRow>}
                {(!complaints && selected_customer && selected_customer!=='clear' && !isFetchingComplaints) && <TableRow><TableCell colSpan={5}>{t("dashboard.o_overview_complaints.no_found")}</TableCell></TableRow>}
                {((!complaints && !selected_customer && !isFetchingComplaints) || (!complaints && selected_customer==='clear' && !isFetchingComplaints)) && <TableRow><TableCell colSpan={5}>{t("dashboard.o_overview_complaints.no_selection")}</TableCell></TableRow>}
                {complaints && complaints.map((complaint, index) => {
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === complaint.relnr)

                    return (
                    <TableRow key={index}>
                        <TableCell>{customer?.name}</TableCell>
                        <TableCell>{complaint.id}</TableCell>
                        <TableCell>{complaintcode && complaintcode.find(i=>i.code===complaint.code)?.description}</TableCell>
                        <TableCell>{complaint.cn.toLowerCase() === 'c' ? t("dashboard.o_overview_complaints.coins") : t("dashboard.o_overview_complaints.notes")}</TableCell>
                        <TableCell>{format(convertToDate(complaint.dtregistration) || new Date, "dd/MM/yyyy")}</TableCell>
                    </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    </div>
  )
}

export default OverviewComplaints