import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Button } from '../../../../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import lodash, { isEqual } from 'lodash'
import { Loader2, Save } from 'lucide-react'
import { Label } from '../../../../../../components/ui/label'
import { Switch } from '../../../../../../components/ui/switch'
import { Input } from '../../../../../../components/ui/input'
import axios from '../../../../../../lib/axios'
import useAuth from '../../../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

type Props = {
    ordersettings: Partner;
    denom: PartnerDenom;
    articles: Article[]

    refetch_denom: () => void;
    refetch_ordersettings: () => void;
}

const OrderSettings : React.FC<Props> = ({ordersettings, denom, articles, refetch_denom, refetch_ordersettings}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const [main_ordersettings, setmain_ordersettings] = useState(lodash.cloneDeep(ordersettings));
    const [new_ordersettings, setnew_ordersettings] = useState(lodash.cloneDeep(ordersettings));

    const [new_denom, setnew_denom] = useState(denom);

    useEffect(()=>{
        
        if(!ordersettings.settings){
            const defaultArticles = articles.map((art)=>({articleid:art.id, canorder:true, losper:art.losperdefault, packedby:art.packedby}))
            let defaultOrderSettings = lodash.cloneDeep(ordersettings);
            defaultOrderSettings.settings = {articles:[...defaultArticles], orderdate:'0', ordertime:'00:00', announcementdate:'0', announcementtime:'00:00'}

            setnew_ordersettings(defaultOrderSettings)
            setmain_ordersettings(lodash.cloneDeep(defaultOrderSettings))
        }
        else {
            setmain_ordersettings(lodash.cloneDeep(ordersettings))
            setnew_ordersettings(lodash.cloneDeep(ordersettings))
        }
    }, [ordersettings])

    const {isFetching, refetch:saveUpdates} = useQuery({
        queryKey: ['update_ordersettings'],
        queryFn: async () => {
            if(!isEqual(denom, new_denom)){
                //Save denom settings
                try {
                    toast.loading(t("settings.toast_loading_p2"), {id:'saveDenom'})
                    let obj:any = {
                        token: auth.data?.token,
                        ...new_denom
                    }
                    obj.partner = obj.partnerid
                    delete obj.partnerid
                    delete obj.coins
                    delete obj.notes
                    const {data} = await axios.post(`partner/denom/createorupdate`, obj)
                    if(data.errorcode!==0){throw new Error(data.message)}
                    toast.success(t("settings.toast_success_p3"), {id:'saveDenom'})
                } catch (error) {
                    toast.error(t("settings.toast_error_p5"), {id:'saveDenom'})
                    console.log(error)
                }

                refetch_denom()
            }

            if(!isEqual(main_ordersettings, new_ordersettings)){
                //Save ordersettings
                try {
                    toast.loading(t("settings.toast_loading_p3"), {id:'saveOrdersettings'})
                    let obj:any = {
                        token: auth.data?.token,
                        ...new_ordersettings
                    }
                    obj.partner = obj.relnr
                    obj.settings.ordertime = obj.settings.ordertime.replace(':','')
                    obj.settings.announcementtime = obj.settings.announcementtime.replace(':','')
                    delete obj.relnr
                    const {data} = await axios.post(`partner/settings`, obj)
                    if(data.errorcode!==0){throw new Error(data.message)}
                    toast.success(t("settings.toast_success_p4"), {id:'saveOrdersettings'})
                } catch (error) {
                    toast.error(t("settings.toast_error_p6"), {id:'saveOrdersettings'})
                    console.log(error)
                }

                refetch_ordersettings()
            }

            return null;
        },
        enabled: false
    })

    const updatePDenomProperty = (key:'ordercoins'|'ordernotes'|'announcecoins'|'announcenotes', value:boolean) => {
        setnew_denom(prev => {
            const updatedDenom = { ...prev };
            updatedDenom[key] = value
            return updatedDenom
        })
    }

    const updateOrderSettings = (key:'announcementdate'|'announcementtime'|'orderdate'|'ordertime', value:string) => {
        setnew_ordersettings(prev => {
            const updatedSettings = { ...prev };
            if(updatedSettings.settings){
                updatedSettings.settings[key] = value
            }
            return updatedSettings
        })
    }

    const isSaveable = () => {
        if((!lodash.isEqual(denom, new_denom) || !lodash.isEqual(main_ordersettings, new_ordersettings)) && !isFetching){ return false }
        if(isFetching) { return true }
        return true
    }

  return (
    <>
    <Toaster />
    <div className='flex justify-between mb-4'>
        <div>
            <h2 className='font-semibold leading-none'>{t("settings.title")}</h2>
            <p className='text-sm text-neutral-400'>{t("settings.order_description")}</p>
        </div>
        <Button onClick={()=>{saveUpdates()}} disabled={isSaveable()} className='gap-2'><Save className='w-5'/>{isFetching ? <Loader2 className='animate-spin'/> : t("settings.button_save")}</Button>
    </div>
    <div className='grid grid-cols-2 gap-4 max-w-4xl'>
        <div className='flex flex-col gap-1'>
            <Label htmlFor='order_coins'>{t("settings.order_order")}</Label>
            <Switch checked={new_denom.ordercoins} onCheckedChange={(v)=>{updatePDenomProperty('ordercoins',v)}} id='order_coins'/>
        </div>
        <div className='flex flex-col gap-1'>
            <Label htmlFor='announce_coins'>{t("settings.order_announce")}</Label>
            <Switch checked={new_denom.announcecoins} onCheckedChange={(v)=>{updatePDenomProperty('announcecoins',v)}} id='announce_coins'/>
        </div>
        <div className='flex flex-col gap-1'>
            <Label htmlFor='order_notes'>{t("settings.order_notes")}</Label>
            <Switch checked={new_denom.ordernotes} onCheckedChange={(v)=>{updatePDenomProperty('ordernotes',v)}} id='order_notes'/>
        </div>
        <div className='flex flex-col gap-1'>
            <Label htmlFor='announce_notes'>{t("settings.announce_notes")}</Label>
            <Switch checked={new_denom.announcenotes} onCheckedChange={(v)=>{updatePDenomProperty('announcenotes',v)}} id='announce_notes'/>
        </div>
    </div>
    <div className='my-8 bg-neutral-200 h-px w-full'></div>
    <div className='flex flex-col gap-4'>
        <div>
            <h2 className='font-semibold leading-none'>{t("settings.order_date")}</h2>
            <p className='text-sm text-neutral-400'>{t("settings.order_date_description")}</p>
        </div>
        <div className='grid grid-cols-2 gap-4 max-w-4xl'>
            <div>
                <Label htmlFor='order_day'>{t("settings.order_day")}</Label>
                <Input value={Math.round(parseFloat(new_ordersettings.settings?.orderdate || '0') || 0).toString()} onChange={({currentTarget})=>{updateOrderSettings('orderdate',currentTarget.value)}} type='number' id='order_day'/>
            </div>
            <div>
                <Label htmlFor='order_time'>{t("settings.order_time")}</Label>
                <Input value={new_ordersettings.settings?.ordertime || 0} onChange={({currentTarget})=>{updateOrderSettings('ordertime',currentTarget.value)}} type='time' id='order_time'/>
            </div>
        </div>
    </div>
    <div className='my-8 bg-neutral-200 h-px w-full'></div>
    <div className='flex flex-col gap-4'>
        <div>
            <h2 className='font-semibold leading-none'>{t("settings.announce_date")}</h2>
            <p className='text-sm text-neutral-400'>{t("settings.announce_date_description")}</p>
        </div>
        <div className='grid grid-cols-2 gap-4 max-w-4xl'>
            <div>
                <Label htmlFor='announce_day'>{t("settings.announce_day")}</Label>
                <Input value={new_ordersettings.settings?.announcementdate || 0} onChange={({currentTarget})=>{updateOrderSettings('announcementdate',currentTarget.value)}} type='number' id='announce_day'/>
            </div>
            <div>
                <Label htmlFor='announce_time'>{t("settings.announce_time")}</Label>
                <Input value={new_ordersettings.settings?.announcementtime || 0} onChange={({currentTarget})=>{updateOrderSettings('announcementtime',currentTarget.value)}} type='time' id='announce_time'/>
            </div>
        </div>
    </div>
    </>
  )
}

export default OrderSettings