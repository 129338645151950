import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/ui/button'
import { Label } from '../../../../components/ui/label'
import { Input } from '../../../../components/ui/input'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { Eye, EyeOff, Loader2 } from 'lucide-react'
import toast, { Toaster } from "react-hot-toast"
import KeepsafeTable from './Table'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { addFilter } from '../../../../redux/actions'
import { RootState } from '../../../../redux'
import { getFilterDetail } from '../../../../lib/selectors'
import { isInterface } from '../../../../lib/utils'
import ExportTable from '../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export'
import { useTranslation } from 'react-i18next'

const Overview = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { auth } = useAuth();

    const partners = useSelector((state:RootState) => state.partners)
    const customers = useSelector((state:RootState) => state.customers)
    const filters = useSelector((state:RootState) => state.filters);

    const [show_filters, setshow_filters] = useState(true);
    const [input_number, setinput_number] = useState<string>('');
    const [is_loading, setis_loading] = useState(false);

    const [colli_results, setcolli_results] = useState<Colli[] | null>(null);

    useEffect(()=>{
        const fd = getFilterDetail(filters, 'tt_ks', '')
        if(isInterface(fd, {} as FilterDetail)) { 
            setinput_number(fd.value.toString()) 
        }
    }, [])

    const handleSearch = async () => {
        try {
            setcolli_results(null)
            setis_loading(true)
            const { data } = await axios.post(`colli/search`, {
                token: auth.data?.token,
                barcode: input_number,
                equal: 1
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object.colli)){
                    dispatch(addFilter('tt_ks', {key:'number', value:input_number}))
                    setcolli_results(data.object.colli)
                }
                else {
                    toast.error(t("track_and_trace.o_number.toast_wrong"));
                }
            }
            else {
                toast.error(`Error [${data.errorcode}] - ${data.message}`);
            }
        } catch (error) {
            console.log(error)
            toast.error(t("track_and_trace.o_number.toast_wrong"));
        } finally {
            setis_loading(false)
        }
    }

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <div>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_number.link_tt")}</p>}, {title:<p onClick={()=>{navigate(-1)}} className='cursor-pointer'>{t("track_and_trace.o_number.link_number")}</p>}]}/>
                <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_number.link_number")}</h1>
            </div>
        </div> 

        <div className='shadow-sm border rounded-sm mt-4 md:mt-8'>
          <div className='p-4 flex justify-between'>
              <h2 className='text-lg'>{t("track_and_trace.o_number.filters")}</h2>
              <Button onClick={()=>{ setshow_filters(prev => !prev) }} variant="ghost">{show_filters ? <Eye className='w-5 text-neutral-500'/> : <EyeOff className='w-5 text-neutral-500'/>}</Button>
          </div>

          <div className={`${show_filters ? 'max-h-screen p-4 pt-0' : 'max-h-0 px-4 py-0'} overflow-hidden duration-300 grid grid-cols-3 gap-4`}>
                <div className='col-span-full'>
                  <Label htmlFor='number'>{t("track_and_trace.o_number.link_number")}</Label>
                  <Input value={input_number} onChange={({currentTarget})=>{setinput_number(currentTarget.value)}} placeholder={`${t("track_and_trace.o_number.link_number")}...`} id='number'/>
                </div>
                <Button className='w-fit' disabled={is_loading} onClick={()=>{handleSearch()}}>{
                    is_loading 
                        ? <Loader2 className='animate-spin'/>
                        : t("track_and_trace.o_number.search")
                }</Button>
            </div>
        </div>

        {colli_results &&
        <>
            <KeepsafeTable collis={colli_results}/>
        </>
        }
    </div>
    </>
  )
}

export default Overview