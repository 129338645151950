import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/ui/button'
import MoveRight from '../../../assets/icons/MoveRight'
import useAuth from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

const Sip = () => {
    const {auth} = useAuth()
    const { t } = useTranslation()
    
  return (
      <div className='overflow-auto relative w-full p-4 md:p-8'>
            <h1 className="text-2xl font-semibold mb-4 md:mb-8">{t("sip.title")}</h1>

            <div className="mt-4 md:mt-8 grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4">
                {auth.data?.right.sipexecutedinterventions && 
                <Link to="uitgevoerde-interventies">
                <div className='group flex justify-between items-center gap-4 shadow-sm border rounded-sm p-4 hover:border-primary'>
                    <div>
                        <h3 className='font-semibold'>{t("sip.title_interventions")}</h3>
                        <p className='text-sm text-neutral-500'>{t("sip.description_interventions")}</p>
                    </div>
                      <MoveRight className='text-neutral-400 group-hover:translate-x-1 group-hover:text-primary duration-200'/>
                </div>
                </Link>
                }
                
                {auth.data?.right.sipmanagements && 
                <Link to="beheer-atm">
                <div className='group flex justify-between items-center gap-4 shadow-sm border rounded-sm p-4 hover:border-primary'>
                    <div>
                        <h3 className='font-semibold'>{t("sip.title_atm")}</h3>
                        <p className='text-sm text-neutral-500'>{t("sip.description_atm")}</p>
                    </div>
                    <MoveRight className='text-neutral-400 group-hover:translate-x-1 group-hover:text-primary duration-200' />
                </div>
                </Link>
                }

                {auth.data?.right.sipassets && 
                <Link to="assets">
                <div className='group flex justify-between items-center gap-4 shadow-sm border rounded-sm p-4 hover:border-primary'>
                    <div>
                        <h3 className='font-semibold'>{t("sip.title_assets")}</h3>
                        <p className='text-sm text-neutral-500'>{t("sip.description_assets")}</p>
                    </div>
                    <MoveRight className='text-neutral-400 group-hover:translate-x-1 group-hover:text-primary duration-200' />
                </div>
                </Link>
                }

                {auth.data?.right.sipplanninginterventions && 
                <Link to="planning-interventies">
                <div className='group flex justify-between items-center gap-4 shadow-sm border rounded-sm p-4 hover:border-primary'>
                    <div>
                        <h3 className='font-semibold'>{t("sip.title_planning")}</h3>
                        <p className='text-sm text-neutral-500'>{t("sip.description_planning")}</p>
                    </div>
                    <MoveRight className='text-neutral-400 group-hover:translate-x-1 group-hover:text-primary duration-200' />
                </div>
                </Link>
                }
            </div> 
      </div>
  )
}

export default Sip