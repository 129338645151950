import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Button } from '../../../../../../components/ui/button';
import { Loader2, Save } from 'lucide-react';
import { Tab, TabItem } from '../../../../../../components/ui/tab';
import { Input } from '../../../../../../components/ui/input';
import { Label } from '../../../../../../components/ui/label';
import lodash from 'lodash'
import NewCheckbox from '../../../../../../components/ui/new-checkbox';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../../../../lib/axios';
import useAuth from '../../../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Props = {
    ordersettings: Partner;
    articles: Article[];
    articlesgroup: ArticleGroup[];
    refetch_ordersettings: () => void;
}

const Consumables : React.FC<Props> = ({ordersettings, articles, articlesgroup, refetch_ordersettings}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const [subpage, setsubpage] = useState<string>(articlesgroup[0].id.toString());

    const [main_ordersettings, setmain_ordersettings] = useState(lodash.cloneDeep(ordersettings));
    const [new_ordersettings, setnew_ordersettings] = useState(lodash.cloneDeep(ordersettings));

    const groupedArticles:Article[][] = articles.reduce((result:any[], currentValue)=>{
        const index = result.findIndex((group:any)=>group.length > 0 && group[0].articlegroupid === currentValue.articlegroupid)

        if(index !== -1){
            result[index].push(currentValue)
        } else {
            result.push([currentValue])
        }

        return result;
    }, [])

    useEffect(()=>{
        if(!ordersettings.settings || !ordersettings.settings.articles || (ordersettings.settings.articles && ordersettings.settings.articles.length < 10)){
            let currentArticleIds = ordersettings.settings?.articles ? (ordersettings.settings?.articles.map(i=>i.articleid) || []) : []
            let defaultArticles = articles.map((art)=>{ if(!currentArticleIds.includes(art.id)){return ({articleid:art.id, canorder:true, losper:art.losperdefault, packedby:art.packedby})} })
            if(defaultArticles === undefined) {
                defaultArticles = articles.map((art)=> ({articleid:art.id, canorder:true, losper:art.losperdefault, packedby:art.packedby}) )
            }
            let defaultOrderSettings = lodash.cloneDeep(ordersettings);

            //@ts-ignore
            defaultOrderSettings.settings = {articles:[...defaultArticles], orderdate:'0', ordertime:'00:00', announcementdate:'0', announcementtime:'00:00'}

            setnew_ordersettings(lodash.cloneDeep(defaultOrderSettings))
            setmain_ordersettings(lodash.cloneDeep(defaultOrderSettings))
        }
        else {
            setnew_ordersettings(lodash.cloneDeep(ordersettings))
            setmain_ordersettings(lodash.cloneDeep(ordersettings))
        }
    }, [ordersettings])

    const updateArticles = (articleid:number, key:'packedby'|'losper', value:number) => {
        setnew_ordersettings(prev => {
            const updatedSettings = {...prev}
            if(updatedSettings.settings){
                const index = updatedSettings.settings.articles.findIndex(i=>i.articleid===articleid)
                if(index > -1){
                    updatedSettings.settings.articles[index][key] = value
                }
                else {
                    updatedSettings.settings.articles.push({articleid:articleid, losper:0, packedby:0, canorder:false, [key]:value})
                }
            }
            return updatedSettings
        })

    }

    const updateOrdersettings = (articleid:number, value:boolean) => {
        setnew_ordersettings(prev => {
            const updatedSettings = {...prev}
            if(updatedSettings.settings){
                const index = updatedSettings.settings.articles.findIndex(i=>i.articleid===articleid)
                if(index > -1){
                    updatedSettings.settings.articles[index].canorder = value
                }
            }
            return updatedSettings
        })
    }

    const {isFetching, refetch:saveSettings} = useQuery({
        queryKey: ['update_consumables'],
        queryFn: async () => {
            try {
                toast.loading(t("settings.toast_loading_p3"), {id:'saveOrdersettings'})
                let obj:any = {
                    token: auth.data?.token,
                    ...new_ordersettings
                }
                obj.partner = obj.relnr
                delete obj.relnr
                delete obj.settings.orderdate
                delete obj.settings.ordertime
                delete obj.settings.announcementdate
                delete obj.settings.announcementtime

                const {data} = await axios.post(`partner/settings`, obj)
                if(data.errorcode!==0){throw new Error()}
                toast.success(t("settings.toast_success_p4"), {id:'saveOrdersettings'})
            } catch (error) {
                toast.error(t("settings.toast_error_p6"), {id:'saveOrdersettings'})
                console.log(error)
            }

            refetch_ordersettings()
            
            return null
        },
        enabled: false
    })

  return (
    <>
    <Toaster />
    <div className='flex justify-between mb-4'>
        <Tab onValueChange={(v)=>{setsubpage(v)}} value={subpage}>
            {articlesgroup.map((group, index) => {
                return (
                    <TabItem value={group.id.toString()} key={index}>{group.name}</TabItem>
                )
            })}
        </Tab>
        <Button onClick={()=>{ saveSettings() }} disabled={lodash.isEqual(main_ordersettings, new_ordersettings) || isFetching} className='gap-2'>{isFetching ? <Loader2 className='animate-spin'/> : <><Save className='w-5'/>{t("settings.button_save")}</>}</Button>
    </div>

    <div className='flex flex-col'>
    {(groupedArticles.find(i=>i[0].articlegroupid===parseFloat(subpage)))?.map((v, i)=>{
        return (
            <div className='grid grid-cols-2 border-b py-4 last:border-none last:pb-0 first:pt-0' key={i}>
                <div className='flex items-center gap-2'>
                    <NewCheckbox onCheckedChange={(checked)=>{updateOrdersettings(v.id, checked)}} checked={Array.isArray(new_ordersettings.settings?.articles) ? (new_ordersettings.settings?.articles.find(i=>i.articleid===v.id)?.canorder || false) : false}/>
                    <img className='w-6' src={`https://myloomis.loomis.be/assets/images/consumables/${v.photo}`} alt="icon" />
                    <p>{v.name}</p>
                </div>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='flex items-center gap-2'>
                        <Label htmlFor={`${v.id}_losper`} className='whitespace-nowrap'>{t("settings.los")}:</Label>
                        <Input value={Array.isArray(new_ordersettings.settings?.articles) ? (new_ordersettings.settings?.articles.find(i=>i.articleid===v.id)?.losper || 0) : 0} onChange={({currentTarget})=>{ if(parseFloat(currentTarget.value) > -1){updateArticles(v.id, 'losper', parseFloat(currentTarget.value))} }} id={`${v.id}_losper`} type='number'/>
                    </div>
                    <div className='flex items-center gap-2'>
                        <Label htmlFor={`${v.id}_packedby`} className='whitespace-nowrap'>{t("settings.packed")}:</Label>
                        <Input value={Array.isArray(new_ordersettings.settings?.articles) ? (new_ordersettings.settings?.articles.find(i=>i.articleid===v.id)?.packedby || 0) : 0} onChange={({currentTarget})=>{ if(parseFloat(currentTarget.value) > -1){updateArticles(v.id, 'packedby', parseFloat(currentTarget.value))} }} id={`${v.id}_packedby`} type='number'/>
                    </div>
                </div>
            </div>
        )
    })}
    </div>

    </>
  )
}

export default Consumables