export enum ActionType {
    SET_ROLES = 'set_roles',
    SET_ROLE = 'set_role',
    SET_PARTNERS = 'set_partners',
    SET_CUSTOMERS = 'set_customers',
    ADD_FILTER = 'add_filter',
    EDIT_FILTER = 'edit_filter',
    REMOVE_FILTER = 'remove_filter',
    SET_ORDERSTATE = 'set_orderstate',
    SET_ORDERDRAFTSTATE = 'set_orderdraftstate',
    SET_COUPURE = 'set_coupure',
    SET_COMPLAINTCODE = 'set_complaintcode',
    SET_UNFILTERED_CUSTOMERS = 'set_unfilteredcustomers'
}

export type Action = {
    type: ActionType,
    payload: any
}