import React, { HTMLAttributes } from 'react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog'
import { Download, X } from 'lucide-react'
import { Button } from '../../ui/button'
import { cn } from '../../../lib/utils'
import { useTranslation } from 'react-i18next'

interface Props extends HTMLAttributes<HTMLButtonElement> {
    onDownload: (extension:'XLS'|'XLSX') => void;
}

const ExportTable : React.FC<Props> = ({onDownload, className, ...props}) => {
    const {t} = useTranslation()
  return (
    <AlertDialog>
        <AlertDialogTrigger asChild>
            <Button variant='outline' className={`${cn(className, 'gap-2')}`} {...props}><Download className='w-5' strokeWidth={1.5}/>{t("components.o_export.button_export")}</Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
            <div className='flex justify-between'>
                <h2 className='text-lg font-medium'>{t("components.o_export.title")}</h2>
                <AlertDialogCancel className='p-0 border-none' asChild><button className='w-8 h-8'><X className='w-5' strokeWidth={1.5}/></button></AlertDialogCancel>
            </div>

            <p className='text-sm text-neutral-500'>{t("components.o_export.description")}</p>
            
            <div className='flex justify-between'>
                <AlertDialogAction asChild><Button onClick={()=>{onDownload('XLSX')}}>{t("components.o_export.button_xlsx")}</Button></AlertDialogAction>
                <AlertDialogAction className='bg-secondary text-secondary-foreground hover:bg-secondary/80' asChild><Button onClick={()=>{onDownload('XLS')}}>{t("components.o_export.button_xls")}</Button></AlertDialogAction>
            </div>
        </AlertDialogContent>
    </AlertDialog>
  )
}

export default ExportTable