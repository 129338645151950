import { Loader2 } from 'lucide-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

const SpinnerFeedback = () => {
  const {t} = useTranslation()
  return (
    <p className='flex gap-1 items-center text-sm font-medium text-neutral-500'><span><Loader2 className='w-4 animate-spin'/></span>{t("components.o_feedback.loading_title")}</p>
  )
}

export default SpinnerFeedback