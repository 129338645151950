import React, { useEffect, useState, useRef } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import axios from '../../../../../lib/axios'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../../lib/date'
import { formatCashAmount, isInterface } from '../../../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { format } from 'date-fns'
import { Tabs } from '../../../../../components/ui/tabs'
import { Tablist, TablistItem } from '../../../../../components/ui/tablist'
import Coupure from '../../../../../components/portal/coupure/Coupure'
import History from '../../../../../components/portal/history/History'
import Scans from '../../../../../components/portal/scans/Scans'
import ColliDetail from '../../../../../components/portal/colli/ColliDetail'
import ColliOverview from '../../../../../components/portal/colli/ColliKeepsafeOverview'
import ColliDetailOverview from '../../../../../components/portal/colli/ColliDetailOverview'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import { useTranslation } from 'react-i18next'

type ColliSubpage = 'order' | 'transport' | 'history' | 'scans'

type Transport = {
  destination: Destination
}

const Colli = () => {
  const navigate = useNavigate();
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { orderid, colliid } = useParams()

  const customers = useSelector((state:RootState)=>state.customers);

  const [colli, setcolli] = useState<Colli | StateType>(null);
  const [order, setorder] = useState<Order | StateType>(null);
  

  useEffect(()=>{
    if(orderid && colliid){
      fetch_order()
      fetch_colli()
    }
  }, [])

  useEffect(()=>{
    if(isInterface(colli, {} as Colli)){
      
    }
  }, [colli])

  async function fetch_order() {
    try {
        setorder('loading')
        const { data } = await axios.post(`order/search`, {
            token: auth.data?.token,
            id: orderid,
            startdate: "20010101",
            enddate: formatDateToYYYYMMDD(new Date())
        })
        if(data.errorcode === 0 && Array.isArray(data.object.order)){
            setorder(data.object.order[0])
        }
        else {
            setorder('error')
            toast.error(`[${data.errorcode}] - ${data.message}`)
        }
    } catch (error) {
        console.log(error)
        toast.error(t("track_and_trace.o_detail.toast_wrong_order"))
        setorder('error')
    }
  }

  async function fetch_colli() {
    try {
      setcolli('loading')
      const { data } = await axios.post(`colli/search`, {
        token: auth.data?.token,
        id: colliid,
        orderid: orderid,
        equal: 0
      })

      if(data.errorcode === 0){
        if(Array.isArray(data.object.colli)){
          setcolli(data.object.colli[0])
        }
        else {
          toast.error(t("track_and_trace.o_detail.toast_no_colli"))
          console.log(data)
          setcolli('empty')
        }
      }
      else {
        toast.error(`[${data.errorcode}] - ${data.message}`)
        console.log(data)
        setcolli('error')
      }
    } catch (error) {
      console.log(error)
      toast.error(t("track_and_trace.o_detail.toast_wrong"))
      setcolli('error')
    }
  }


  return (
    <>
      <Toaster />
      <div className="overflow-auto relative w-full p-4 md:p-8">
          <div className='flex flex-col'>
              <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_detail.link_tt")}</p>}, {title:<a onClick={()=>{navigate(`/track-en-trace/delivery/${orderid}`)}} className='cursor-pointer'>{`${t("track_and_trace.o_detail.link_delivery")} ${orderid}`}</a>}, {title:`${t("track_and_trace.o_detail.colli")} ${colliid}`}]}/>
              <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_detail.title_colli")}</h1>
          </div>

          <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            <h2 className='text-lg'>{t("track_and_trace.o_detail.date_delivery")}</h2>
            {order === 'loading' && <SpinnerFeedback />}
            {order === 'empty' && <EmptyFeedback />}
            {order === 'error' && <ErrorFeedback />}
            {isInterface(order, {} as Order) &&
            <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>Id</p>
                    <p className='text-neutral-500'>{order.id}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.date_announced")}</p>
                    <p className='text-neutral-500'>{convertToDate(order.dtannounce) ? format(convertToDate(order.dtannounce) || new Date(), "dd/MM/yyyy") : ''}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.date_created")}</p>
                    <p className='text-neutral-500'>{convertToDate(order.dtcreation) ? format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy") : ''}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.date_out_stock")}</p>
                    <p className='text-neutral-500'>{convertToDate(order.dtstock) ? format(convertToDate(order.dtstock) || new Date(), "dd/MM/yyyy") : ''}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.date_delivery")}</p>
                    <p className='text-neutral-500'>{convertToDate(order.dtdelivery) ? format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy") : ''}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.reference")}</p>
                    <p className='text-neutral-500'>{order.clientref}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.customer")}</p>
                    <p className='text-neutral-500'>{customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)?.name}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.amount")}</p>
                    <p className='text-neutral-500'>{formatCashAmount(order.amount)}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.currency")}</p>
                    <p className='text-neutral-500'>{order.currency}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("track_and_trace.o_detail.type")}</p>
                    <p className='text-neutral-500'>{order.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</p>
                </div>
            </div>
            }   
          </div>

          <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_colli")}</h2>
            {colli === 'loading' && <SpinnerFeedback />}
            {colli === 'empty' && <EmptyFeedback />}
            {colli === 'error' && <ErrorFeedback />}
            {isInterface(colli, {} as Colli) && <ColliDetail colli={colli}/>}
          </div>

          {isInterface(colli, {} as Colli) && isInterface(order, {} as Order) && <ColliDetailOverview colli={colli} order={order}/>}
      </div>
    </>
  )
}

export default Colli