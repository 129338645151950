import { Outlet } from "react-router-dom"
import useRefreshToken from "../../../hooks/useRefreshToken"
import useAuth from "../../../hooks/useAuth"
import { useState, useEffect } from "react"

import { Provider } from 'react-redux';
import { store } from '../../../redux'
import LoadingScreen from "../../../components/portal/feedback/LoadingScreen";

const PersistLogin = () => {
  const [isLoading, setisLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();

  useEffect(()=>{
    let isMounted = true;

    const verifyRefreshToken = async () => {
        
        try {
            await refresh();
        } 
        catch (error) {
            console.error(error);
        }
        finally {
            isMounted && setisLoading(false);
        }
    }

    !auth?.data?.token ? verifyRefreshToken() : setisLoading(false);

    return () => {
        isMounted = false;
    }
  }, [])

  return (
    <>
    <Provider store={store}>
        {!persist 
            ?
            <Outlet />
            : isLoading 
                ?
                <LoadingScreen />
                :
                <Outlet />
        }
    </Provider>
    </>
  )
}

export default PersistLogin