
import { XCircle } from 'lucide-react'
import React, { MutableRefObject, useEffect, useRef } from 'react'
import icon_error from '../../../assets/animations/icon-error.json'
import lottie from 'lottie-web'
import { useTranslation } from 'react-i18next'

const ErrorFeedback = () => {
  const {t} = useTranslation()
    const lottieRef = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(()=>{
      const animation = lottie.loadAnimation({
          container: lottieRef.current,
          animationData: icon_error,
          loop: false,
          autoplay: true,
          renderer: 'svg',
        });

        animation.play()
    
        // Listen for the animation's complete event
        animation.addEventListener('complete', () => {
          animation.pause();
        });
      
        // Clean up event listeners when the component unmounts
        return () => {
            animation.removeEventListener('complete', () => {
              animation.pause();
            });
        };
    }, [])


  return (
    <div className='flex items-center gap-1'>
      <div className='w-[18px] h-[18px] overflow-hidden' ref={lottieRef}></div>
      <p className='flex gap-1 items-center text-sm font-medium text-neutral-500'>{t("components.o_feedback.error_message")}</p>
    </div>
  )
}

export default ErrorFeedback;