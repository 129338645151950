export const calculateTotal = (coupures:CoupureInput[], cn:'c' | 'n') => {
    let total = 0;

    if(coupures) {
        let tmp = [...coupures]
        if(cn === 'c') { tmp = tmp.filter(i =>parseFloat(i.coupure) <= 2); }
        else { tmp = tmp.filter(i => parseFloat(i.coupure) > 2) }
        

        tmp.forEach(item => {
            if(!isNaN(item.qty) && !isNaN(parseFloat(item.coupure))){
                total += item.qty * parseFloat(item.coupure)
            }
        });
    }

    return total;
}