import React, { HTMLAttributes, MutableRefObject, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import hourglass from '../../../assets/animations/hourglass.json'
import { cn } from '../../../lib/utils'
import { useTranslation } from 'react-i18next'

const LoadingScreen : React.FC<HTMLAttributes<HTMLDivElement>> = ({className}) => {
    const {t} = useTranslation()
    const lottieRef = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(()=>{
      const animation = lottie.loadAnimation({
          container: lottieRef.current,
          animationData: hourglass,
          loop: true,
          autoplay: true,
          renderer: 'svg',
        });

        animation.play()
    }, [])

    return (
    <div className={`${cn('p-4 h-screen flex flex-col justify-center items-center', className) }`}>
        <div className='w-[100px] h-[100px] overflow-hidden' ref={lottieRef}></div>
        <p>{t("components.o_feedback.loading_title")}</p>
    </div>
    )
}

export default LoadingScreen