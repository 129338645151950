import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { useQuery } from "@tanstack/react-query";
import axios from "../lib/axios";

const RequireRight = ({ right, rightList } : { right:keyof UserRight, rightList?:(keyof UserRight)[] }) => {
    const { auth } = useAuth();
    const location = useLocation();

    const {data:partnerrights} = useQuery<PartnerRight[] | null>({
        queryKey: [`partnerrights_${auth.data?.partner}`],
        queryFn: async () => {
            try {
                if(!auth.data?.partner){ return null; }
                const {data} = await axios.post(`partnerrights`, {
                  token: auth.data?.token,
                  partnerid: auth.data?.partner,
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.right){ return null; }
                return data.object.right
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!auth.data,
        refetchOnWindowFocus: false
    })

    function isRightValid() {
        if(rightList){
            let result = false;

            rightList.forEach(right => {
                if(partnerrights){
                    if(!partnerrights[0][right as keyof PartnerRight]){ result = false; return }
                } 
                if(auth.data?.right[right]){ result = true; return}
            });

            return result
        } else {
            return auth.data?.right[right]
        }
    }

    return (
        (isRightValid())
            ? <Outlet />
            : auth?.data?.token
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireRight;