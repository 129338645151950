import { format } from 'date-fns'
import React from 'react'
import { convertToDate } from '../../../lib/date'
import { useTranslation } from 'react-i18next'

type Props = {
    transport: {origin:Origin, destination:Destination}
}

const Transport : React.FC<Props> = ({transport}) => {
  const {t} = useTranslation()
  return (
              <div className='flex flex-col gap-4'>
                {transport.origin && 
                <div className='border p-4 pt-2'>
                <p className='border-b text-lg py-1 mb-2'>{t("components.o_transport.sender")}</p>
                  <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.date")}</p>
                      <p className="text-neutral-500">{format(convertToDate(transport.origin.date) || new Date(), "dd/MM/yyyy")}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.customer")}</p>
                      <p className="text-neutral-500">{transport.origin.name}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.route_code")}</p>
                      <p className="text-neutral-500">{transport.origin.routecode}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.route_name")}</p>
                      <p className="text-neutral-500">{transport.origin.routename}</p>
                    </div>
                  </div>
                </div>
                }

                {transport.destination && 
                <div className='border p-4 pt-2'>
                  <p className='border-b text-lg py-1 mb-2'>{t("components.o_transport.receiver")}</p>
                  <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.date")}</p>
                      <p className="text-neutral-500">{format(convertToDate(transport.destination.date) || new Date(), "dd/MM/yyyy")}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.customer")}</p>
                      <p className="text-neutral-500">{transport.destination.name}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.route_code")}</p>
                      <p className="text-neutral-500">{transport.destination.routecode}</p>
                    </div>
                    <div className="flex gap-2">
                      <p className="font-semibold">{t("components.o_transport.route_name")}</p>
                      <p className="text-neutral-500">{transport.destination.routename}</p>
                    </div>
                    <div className="flex gap-2"> 
                      <p className="font-semibold">{t("components.o_transport.stop_id")}</p>
                      <p className="text-neutral-500">{transport.destination.stopid}</p>
                    </div>
                  </div>
                </div>
                }
              </div>
  )
}

export default Transport