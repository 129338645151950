import axios from "../axios";

export async function createDefaultDenom(partnerid:string, auth:Auth) {
    try {
        let result:PartnerDenom = { partnerid:parseFloat(partnerid), ordercoins:true, ordernotes:true, announcecoins:true, announcenotes:true, coins:[], notes:[] }

        const {data:coupure} = await axios.post(`param/coupure`, { token: auth.data?.token })
        if(coupure.errorcode!==0 || !Array.isArray(coupure.object.coupure)){
            throw new Error("Couldn't fetch param coupures")
        }
        else {
            const coins = coupure.object.coupure.map((c:ParamCoupure, i:number)=>{ if(c.cn ==='C'){ return {partnerid:partnerid, id:i, coupure:c.value, minqty:'0', maxqty:'0', perqty:c.unit} } }).filter((v:any)=>v!==undefined)
            const notes = coupure.object.coupure.map((c:ParamCoupure, i:number)=>{ if(c.cn ==='N'){ return {partnerid:partnerid, id:i, coupure:c.value, minqty:'0', maxqty:'0', perqty:c.unit} } }).filter((v:any)=>v!==undefined)
            result.coins = coins;
            result.notes = notes;

            const announceNotes = coupure.object.coupure.find((i:ParamCoupure)=>i.cn ==='N')?.announce || true
            const orderNotes = coupure.object.coupure.find((i:ParamCoupure)=>i.cn ==='N')?.order || true
            const announceCoins = coupure.object.coupure.find((i:ParamCoupure)=>i.cn ==='C')?.announce || true
            const orderCoins = coupure.object.coupure.find((i:ParamCoupure)=>i.cn ==='C')?.order || true
            result.announcecoins = announceCoins
            result.ordercoins = orderCoins
            result.announcenotes = announceNotes
            result.ordernotes = orderNotes

            return result;
        }    
    
    } catch (error) {
        console.log(error);
        throw new Error("Couldn't fetch param coupures")
    }
}

export async function repairCurrentDenom(partnerid:string, auth:Auth, currentDenom:any) {
    try {
        let result:PartnerDenom = { partnerid:parseFloat(partnerid), ordercoins:false, ordernotes:false, announcecoins:false, announcenotes:false, coins:[], notes:[] }
        const {data:coup} = await axios.post(`param/coupure`, { token: auth.data?.token })
        if(coup.errorcode!==0 || !Array.isArray(coup.object.coupure)){
            throw new Error("Couldn't fetch param coupures")
        }
        const coupure = coup.object.coupure

        if(typeof(currentDenom.announcecoins)==='boolean'){ result.announcecoins = currentDenom.announcecoins }
        if(typeof(currentDenom.announcenotes)==='boolean'){ result.announcenotes = currentDenom.announcenotes }
        if(typeof(currentDenom.ordercoins)==='boolean'){ result.ordercoins = currentDenom.ordercoins }
        if(typeof(currentDenom.ordernotes)==='boolean'){ result.ordernotes = currentDenom.ordernotes }

        const coins = coupure.map((c:ParamCoupure, i:number)=>{ if(c.cn === 'C'){ 
            let existingCoup = currentDenom?.coins?.find((i:any)=>i.coupure === c.value)
            if(existingCoup){
                existingCoup.id = i
                return existingCoup
            }
            return {partnerid:partnerid, id:i, coupure:c.value, minqty:'0', maxqty:'0', perqty:c.unit}
        } }).filter((v:any)=>v!==undefined)

        const notes = coupure.map((c:ParamCoupure, i:number)=>{ if(c.cn === 'N'){ 
            let existingCoup = currentDenom?.notes?.find((i:any)=>i.coupure === c.value)
            if(existingCoup){
                existingCoup.id = i
                return existingCoup
            }
            return {partnerid:partnerid, id:i, coupure:c.value, minqty:'0', maxqty:'0', perqty:c.unit}
        } }).filter((v:any)=>v!==undefined)

        result.coins = coins
        result.notes = notes
        
        return result
    } catch (error) {
        console.log(error)
        throw new Error('Something went wrong while repairing denom')
    }
}