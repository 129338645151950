import { Route, Routes } from 'react-router-dom'
import Overview from './Overview'

import Delivery from './Detail/Delivery/Delivery'
import DeliveryColli from './Detail/Delivery/Colli'

import Collection from './Detail/Collection/Collection'

import Safepoint from './Detail/Safepoint/Safepoint'
import SafepointColli from './Detail/Safepoint/Colli'

import E2EManage from './Detail/E2EManage/E2EManage'
import E2EDeliveryColli from './Detail/E2EManage/DeliveryColli'

import ATMTransfer from './Detail/ATMTransfer/ATMTransfer'
import ATMColli from './Detail/ATMTransfer/Colli'
import E2ECollectionColli from './Detail/E2EManage/CollectionColli'
import Stop from './Detail/Stop/Stop'
import StopDeliveryColli from './Detail/Stop/DeliveryColli'
import StopCollectionColli from './Detail/Stop/CollectionColli'

const TrackAndTrace = () => {
  return (
    <Routes>
        <Route path="/*" element={<Overview />}/>

        <Route path="/delivery/*">
            <Route path=":orderid">
                <Route path="*" element={<Delivery />}/>
                <Route path="colli/:colliid" element={<DeliveryColli />}/>
            </Route>
        </Route>

        <Route path="/collection/*">
          <Route path=":barcode">
            <Route path="*" element={<Collection />}/>
          </Route>
        </Route>

        <Route path="/safepoint/*">
          <Route path=":safepoint">
            <Route path="*" element={<Safepoint />}/>
            <Route path="colli/:colliid" element={<SafepointColli />}/>
          </Route>
        </Route>

        <Route path="/atm_e2e_manage/*">
          <Route path=":relnr">
            <Route path="*" element={<E2EManage />}/>
            <Route path="delivery/colli/:colliid" element={<E2EDeliveryColli />}/>
            <Route path="collection/colli/:colliid" element={<E2ECollectionColli />}/>
          </Route>
        </Route>

        <Route path="/atm_transfer/*">
          <Route path=":relnr">
            <Route path="*" element={<ATMTransfer />}/>
            <Route path="colli/:colliid" element={<ATMColli />}/>
          </Route>
        </Route>
        
        <Route path="/stop/*">
          <Route path=":stopid">
            <Route path="*" element={<Stop />}/>
            <Route path="delivery/colli/:colliid" element={<StopDeliveryColli />}/>
            <Route path="collection/colli/:colliid" element={<StopCollectionColli />}/>
          </Route>
        </Route>      
      </Routes>
  )
}

export default TrackAndTrace