import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import PartnerSettings from './partnersettings/PartnerSettings'
import ForeignCurrency from './foreign-currency/ForeignCurrency'

const Settings = () => {
  return (
    <Routes>
        <Route path="" element={<Main />}/>
        <Route path="partnerinstellingen/*" element={<PartnerSettings />}/>
        <Route path="vreemde-valuta/*" element={<ForeignCurrency />}/>
    </Routes>
  )
}

export default Settings