import note_eur_5 from '../../../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../../../assets/images/note-eur-500.png'

import { Equal, X } from 'lucide-react';
import React from 'react'
import { Input } from '../../../../../components/ui/input';
import { formatCashAmount } from '../../../../../lib/utils';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { useTranslation } from 'react-i18next'

function getIconSource(value:number) {
  switch (value) {
      case 5:
          return note_eur_5
      case 10:
          return note_eur_10
      case 20:
          return note_eur_20
      case 50:
          return note_eur_50
      case 100:
          return note_eur_100
      case 200:
          return note_eur_200
      case 500:
          return note_eur_500
      default:
          break;
  }
}

type Props = {
  denom: Denom;
  onChangeInput: (coupure: CoupureType, qty: number) => void;
  coupures: CoupureInput[] | null
  bulk?: boolean
}

const Notes : React.FC<Props> = ({denom, onChangeInput, coupures, bulk=false}) => {
    const {t} = useTranslation()
    const coupure = useSelector((state:RootState)=>state.coupure);

    function filterCoins(coins:Coin[]) {
      const orderableCoupures = coupure.filter(i=>i.order===true&&i.cn==='N')
      const orderableCoins = coins.filter(i=>orderableCoupures.find(j=>j.value===i.coupure)!==undefined)
      orderableCoins.sort((a,b)=>b.coupure-a.coupure)
      return orderableCoins;
    }

    function calculateSubtotal(coupure: CoupureType, qty:number) {
      let total = parseFloat(coupure) * qty;
      return total;
    }

    function calculateTotal() {
      let total = 0;
      let totalArr = coupures?.filter(i=>parseFloat(i.coupure)>2).map(i=>i.qty*parseFloat(i.coupure)) || []
      totalArr.forEach(i=>{total += i});
      return total;
    }

    function calculateWeight() {
        let total = 0;
        let totalArr = coupures?.filter(i=>parseFloat(i.coupure)>2).map(i=>i.qty*(coupure.find(j=>j.value===parseFloat(i.coupure))?.weight || 0)) || []
        totalArr.forEach(i=>{total += i});
        return Math.round(total*1000)/1000
    }

  return (
    <>
    <div className='flex flex-col gap-8'>
        {filterCoins(denom.notes).map((coup, ind) => {
            
            return (
                <div className='flex justify-between md:grid gap-4 md:grid-cols-3' key={ind}>
                    <div className='flex items-center gap-2'>
                        <div className='hidden min-w-[2.5rem] w-10 h-10 border sm:flex justify-center items-center rounded-sm'>
                            <img src={getIconSource(coup.coupure)} alt="coin" />
                        </div>
                        <div>
                            <p className='font-semibold leading-none'>{formatCashAmount(coup.coupure)} Euro</p>                        
                            {!bulk && <p className='whitespace-nowrap text-xs sm:text-sm text-neutral-500'>{t("order_and_pickup.o_place_order.lias_per")} {coup.perqty} {t("order_and_pickup.o_place_order.pieces")}</p>}                        </div>
                    </div>

                    <div className='flex gap-2'>
                        <div className='flex flex-col justify-center items-end min-w-[80px]'>
                            <p className='leading-none text-sm whitespace-nowrap'>min: <span className='font-semibold'>{coup.minqty}</span></p>
                            <p className='leading-none text-sm whitespace-nowrap'>max: <span className='font-semibold'>{coup.maxqty}</span></p>
                        </div>
                        <div>
                            <Input disabled={coup.perqty === 0} 
                                value={coup.perqty > 0 && (((coupures?.find(i=>parseFloat(i.coupure)===coup.coupure)?.qty || 0)/coup.perqty)) || 0} 
                                onChange={({currentTarget})=>{onChangeInput(coup.coupure.toString() as CoupureType, (Math.round(parseFloat(currentTarget.value)) * coup.perqty))}} type='number' className='px-1 sm:px-3 md:min-w-[100px]' 
                                min={coup.minqty} 
                                max={!bulk ? coup.maxqty : (coup.maxqty * (coupure ? (coupure.find(c=>c.value===coup.coupure)?.unit || 1) : 1))}
                            />
                        </div>
                    </div>

                    <div className='flex justify-between items-center'>
                        <div className='hidden md:flex gap-2'>
                            <X className='w-4' strokeWidth={1}/>
                            <p className='font-medium whitespace-nowrap'>€ {formatCashAmount((coup.coupure * coup.perqty))}</p>
                            <Equal className='w-4' strokeWidth={1}/>
                        </div>
                        <p className='font-semibold whitespace-nowrap'>€ {formatCashAmount(
                            calculateSubtotal(coup.coupure.toString() as CoupureType, (coupures?.find(i=>i.coupure === coup.coupure.toString() as CoupureType)?.qty || 0)))}</p>
                    </div>
                </div>
            )
        })}
    </div>
    
    <div className='h-px w-full bg-neutral-300'></div>

    <div className='flex justify-between font-semibold'>
        <p>{t("order_and_pickup.o_place_order.total")}:</p>
        <div className='flex flex-col items-end'>
            <p>€ {formatCashAmount(calculateTotal())}</p>
            <p>{calculateWeight()} kg</p>
        </div>
    </div>
    </>
  )
}

export default Notes