import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'
import axios from '../../../../lib/axios'
import { useQuery } from '@tanstack/react-query'
import useAuth from '../../../../hooks/useAuth'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../lib/date'
import { format, subYears } from 'date-fns'
import { isInterface } from '../../../../lib/utils'
import SpinnerFeedback from '../../../../components/portal/feedback/SpinnerFeedback'
import { Briefcase, Loader2, Mail, MailOpen, Network } from 'lucide-react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from '../../../../components/ui/alert-dialog'
import BookUser from '../../../../assets/icons/BookUser'
import { Sheet, SheetContent, SheetTrigger } from '../../../../components/ui/sheet'
import { Tablist, TablistItem } from '../../../../components/ui/tablist'
import ErrorFeedback from '../../../../components/portal/feedback/ErrorFeedback'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../components/ui/tooltip'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { useTranslation } from 'react-i18next'

const MessageOverview = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const navigate = useNavigate()

    const {data:messages, isFetching, refetch:refetchMessages} = useQuery<Message[] | null>({
        queryKey:['messages'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`message/search`, {
                    token: auth.data?.token,
                    username: auth.data?.username,
                    startdate: formatDateToYYYYMMDD(subYears(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date())
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.message)){throw new Error('Empty')}
                data.object.message.sort((a:Message,b:Message)=>(b.checked === a.checked) ? 0 : b.checked ? -1 : 1)
                return data.object.message
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })
    
    const PartnerTooltip = ({children, partnerid}:{children:React.ReactNode, partnerid:string}) => {
        const [open, setopen] = useState(false);

        const {data:partner, isFetching} = useQuery<Partner | null>({
            queryKey: [`partner_${partnerid}`],
            queryFn: async () => {
                try {
                    const {data} = await axios.post(`partner/search`, {token:auth.data?.token, partner:partnerid})
                    if(data.errorcode!==0 || !Array.isArray(data.object.partner)){throw new Error(data.message)}
                    if(data.object.partner[0].relnr.toString() !== partnerid){ return null }
                    return data.object.partner[0]
                } catch (error) {
                    return null
                }
            },
            enabled: open
        })

        return (
            <TooltipProvider>
                <Tooltip open={open} onOpenChange={(o)=>{setopen(o)}} delayDuration={0}>
                    <TooltipTrigger asChild>{children}</TooltipTrigger>
                    <TooltipContent>
                        {isInterface(partner, {} as Partner) && <p>{partner.name}</p>}
                        {(!isInterface(partner, {} as Partner) && isFetching) && <Loader2 className='w-4 text-neutral-400 animate-spin'/>}
                        {(!isInterface(partner, {} as Partner) && !isFetching) && <ErrorFeedback />}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    const CustomerTooltip = ({children, relnr}:{children:React.ReactNode, relnr:string}) => {
        const customers = useSelector((state:RootState)=>state.customers)
        const [open, setopen] = useState(false);

        let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === parseFloat(relnr))


        return (
            <TooltipProvider>
                <Tooltip open={open} onOpenChange={(o)=>{setopen(o)}} delayDuration={0}>
                    <TooltipTrigger asChild>{children}</TooltipTrigger>
                    <TooltipContent>
                        {isInterface(customer, {} as Customer) ? <p>{customer.name}</p> : <p>Onbekend</p>}
                    </TooltipContent>
                </Tooltip>
            </TooltipProvider>
        )
    }

    const MessagePreview = ({message, children, refetch}:{message:Message, children:React.ReactNode, refetch:()=>void}) => {
        const {isFetching:check_isFetching, refetch:checkMessage} = useQuery({
            queryKey: [`message_${message.id}`],
            queryFn: async () => {
                try {
                    toast.loading(t("message_center.toast_loading_p2"), {id:`${message.id}_checkMessage`})
                    const {data} = await axios.post(`message/check`, {
                        token: auth.data?.token,
                        messageid: message.id
                    })
                    if(data.errorcode!==0){throw new Error(data.message)}
                    toast.success(t("message_center.toast_success_p2"), {id:`${message.id}_checkMessage`})
                    refetch()
                } catch (error) {
                    toast.error(t("message_center.toast_error_p2"), {id:`${message.id}_checkMessage`})
                    console.log(error)
                }
                return null
            },
            enabled: false
        })

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    {children}
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <AlertDialogTitle>{message.title}</AlertDialogTitle>
                    <p>{message.message}</p>
                    <div className='flex justify-end'>
                        <AlertDialogCancel className='border-none' asChild><Button variant='ghost'>{t("message_center.button_close")}</Button></AlertDialogCancel>
                        {message.checked.toString() === 'false' && 
                            <AlertDialogAction onClick={()=>{checkMessage()}} className='w-fit'>{t("message_center.button_mark")}</AlertDialogAction>
                        }
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    const MessageDetail = ({message}:{message:Message}) => {
        const [subpage, setsubpage] = useState('read');
        const [open, setopen] = useState(false);

        const {data:checked, isFetching:checked_isFetching} = useQuery<MessageUser[] | null | 'empty'>({
            queryKey: [`checked_${message.id}`],
            queryFn: async () => {
                try {
                    const {data} = await axios.post(`message/checked`, { token:auth.data?.token, id:message.id})
                    if(data.errorcode!==0){throw new Error(data.message)}
                    if(!Array.isArray(data.object.user)){return 'empty'}
                    return data.object.user
                } catch (error) {
                    console.log(error);
                    return null;
                }
            },
            enabled: open
        })

        const {data:unchecked, isFetching:unchecked_isFetching} = useQuery<MessageUser[] | null | 'empty'>({
            queryKey: [`unchecked_${message.id}`],
            queryFn: async () => {
                try {
                    const {data} = await axios.post(`message/unchecked`, { token:auth.data?.token, id:message.id})
                    if(data.errorcode!==0){throw new Error(data.message)}
                    if(!Array.isArray(data.object.user)){return 'empty'}
                    return data.object.user
                } catch (error) {
                    console.log(error);
                    return null;
                }
            },
            enabled: open
        })

        return (
            <Sheet open={open} onOpenChange={(o)=>{setopen(o)}}>
                <SheetTrigger><BookUser className='w-4 text-neutral-400 hover:text-neutral-800'/>  </SheetTrigger>
                <SheetContent className='overflow-auto w-11/12 max-w-4xl'>
                    <Tablist value={subpage} onValueChange={(v)=>{setsubpage(v)}}>
                        <TablistItem value='read'>{t("message_center.read")}</TablistItem>
                        <TablistItem value='new'>{t("message_center.unread")}</TablistItem>
                    </Tablist>

                    <div className='mt-4'>
                    {subpage === 'read' && 
                    <>
                        {isInterface(checked, {} as MessageUser[]) 
                            ? <div className='flex flex-col gap-4'>
                            {checked.map((check, ind) => {
                                return (
                                    <div key={ind} className='grid grid-cols-[3fr,1fr,1fr]'>
                                        <div className='flex flex-col'>
                                            <p className='font-medium'>{check.firstname !== '' ? check.firstname : t("message_center.unknown")} {check.lastname}</p>
                                            <p className='text-xs text-neutral-400'>{check.username}</p>
                                        </div>
                                        <PartnerTooltip partnerid={check.partner}><p className='flex items-center gap-2'><Network className='w-4'/>{check.partner || 0}</p></PartnerTooltip>
                                        <CustomerTooltip relnr={check.relnr.toString()}><p className='flex items-center gap-2'><Briefcase className='w-4'/> {check.relnr}</p></CustomerTooltip> 
                                    </div>
                                )
                            })}
                            </div> 
                            : <>{(checked_isFetching && (checked !== null || checked !== 'empty')) ? <SpinnerFeedback /> : <ErrorFeedback />}</>
                        }
                    </>
                    }

                    {subpage === 'new' &&
                    <>
                    {isInterface(unchecked, {} as MessageUser[]) 
                        ? <div className='flex flex-col gap-4'>
                        {unchecked.map((check, ind) => {
                            return (
                                <div key={ind} className='grid grid-cols-[3fr,1fr,1fr]'>
                                    <div className='flex flex-col'>
                                        <p className='font-medium'>{check.firstname !== '' ? check.firstname : 'Onbekend'} {check.lastname}</p>
                                        <p className='text-xs text-neutral-400'>{check.username}</p>
                                    </div>
                                    <PartnerTooltip partnerid={check.partner}><p className='flex items-center gap-2'><Network className='w-4'/>{check.partner || 0}</p></PartnerTooltip>
                                    <CustomerTooltip relnr={check.relnr.toString()}><p className='flex items-center gap-2'><Briefcase className='w-4'/> {check.relnr}</p></CustomerTooltip> 
                                </div>
                            )
                        })}
                        </div> 
                        : <>{(unchecked_isFetching && (unchecked !== null || unchecked !== 'empty')) ? <SpinnerFeedback /> : <ErrorFeedback />}</>
                    }
                    </>
                    }
                    </div>
                </SheetContent>
            </Sheet>
        )
    }

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>
        <Breadcrumb items={[{ title: <p onClick={() => { navigate('..') }} className='cursor-pointer'>{t("message_center.link_mc")}</p> }, { title: `${t("message_center.link_overview")}` }]} />
        <div className='flex justify-between'>
            <h1 className='text-2xl font-semibold'>{t("message_center.link_overview")}</h1>
        </div>
        
        <div className='mt-4 md:mt-8 border rounded-sm'>
            <Table>
                <TableHeader>
                    <TableRow>
                        <TableHead className='max-w-[3rem] w-12'></TableHead>
                        <TableHead>{t("message_center.title")}</TableHead>
                        <TableHead>{t("message_center.description")}</TableHead>
                        <TableHead>{t("message_center.date_start")}</TableHead>
                        <TableHead>{t("message_center.date_expired")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {(!messages && !isFetching) && 
                    <TableRow>
                        <TableCell colSpan={5}>{t("message_center.no_messages")}</TableCell>
                    </TableRow>
                    }

                    {(!messages && isFetching) &&
                    <TableRow>
                        <TableCell colSpan={5}><SpinnerFeedback /></TableCell>
                    </TableRow>
                    }

                    {isInterface(messages, {} as Message[]) && messages.map((message, index) => {
                        return (
                            <TableRow key={index} className={`${!message.checked && 'text-primary font-medium bg-primary/5'}`}>
                                <TableCell>
                                    <div className='flex gap-1'>
                                        <MessagePreview refetch={()=>{refetchMessages()}} message={message}>
                                        {message.checked 
                                        ? <MailOpen className='w-4 text-neutral-400'/>
                                        : <Mail className='w-4 text-primary'/>
                                        }       
                                        </MessagePreview>   

                                        {auth.data?.role === 1 && <MessageDetail message={message}/>  }                           
                                    </div>   
                                </TableCell>
                                <TableCell className='cursor-pointer'><MessagePreview refetch={()=>{refetchMessages()}} message={message}><p>{message.title}</p></MessagePreview></TableCell>
                                <TableCell className='cursor-pointer'><MessagePreview refetch={()=>{refetchMessages()}} message={message}><p>{message.message}</p></MessagePreview></TableCell>
                                <TableCell className='cursor-pointer'><MessagePreview refetch={()=>{refetchMessages()}} message={message}><p>{format(convertToDate(message.startdate) || new Date, "dd/MM/yyyy")}</p></MessagePreview></TableCell>
                                <TableCell className='cursor-pointer'><MessagePreview refetch={()=>{refetchMessages()}} message={message}><p>{format(convertToDate(message.enddate) || new Date, "dd/MM/yyyy")}</p></MessagePreview></TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    </div>
    </>
  )
}

export default MessageOverview