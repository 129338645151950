import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../lib/date'
import { format, isValid, subMonths, subWeeks, subYears } from 'date-fns'
import { isInterface } from '../../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import SpinnerFeedback from '../../../../components/portal/feedback/SpinnerFeedback'
import EmptyFeedback from '../../../../components/portal/feedback/EmptyFeedback'
import ErrorFeedback from '../../../../components/portal/feedback/ErrorFeedback'

const DetailTypeA = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.newkeepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.newkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.responsable")}</p>
                    <p className='text-neutral-500'>{complaint?.responsable}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeB = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnoticed")}</p>
                    <p className='text-neutral-500'>{isValid(convertToDate(complaint.dtnoticed)) ? format(convertToDate(complaint.dtnoticed) || new Date, "dd/MM/yyyy") : 'Ongeldige datum'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.denomination")}</p>
                    <p className='text-neutral-500'>{complaint?.denomination}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.mkpsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.motherbag}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedamount")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedamount}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.numnote")}</p>
                    <p className='text-neutral-500'>{complaint?.numfalsenote}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedkeepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.sentnbb")}</p>
                    <p className='text-neutral-500'>{complaint?.sentToNBB ? t("complaints.o_detail.o_sub.yes") : t("complaints.o_detail.o_sub.no")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtdeposit")}</p>
                    <p className='text-neutral-500'>{complaint.dtdeposit ? (isValid(convertToDate(complaint.dtdeposit)) ? format(convertToDate(complaint?.dtdeposit) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeE = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeF = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>Type</p>
                    <p className='text-neutral-500'>{complaint.deliverypicking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>SQS</p>
                    <p className='text-neutral-500'>{complaint?.sqs}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint?.nbkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.boxandseals")}</p>
                    <p className='text-neutral-500'>{complaint?.boxandseals}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.content")}</p>
                    <p className='text-neutral-500'>{complaint?.content}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.countedamount")}</p>
                    <p className='text-neutral-500'>{complaint?.countedamount}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedkeepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.original_keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtdeposit")}</p>
                    <p className='text-neutral-500'>{complaint.dtdeposit ? (isValid(convertToDate(complaint.dtdeposit)) ? format(convertToDate(complaint?.dtdeposit) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeG = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.question")}</p>
                    <p className='text-neutral-500'>{complaint?.question}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.responsable")}</p>
                    <p className='text-neutral-500'>{complaint?.responsable}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeH = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeI = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeJ = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.question")}</p>
                    <p className='text-neutral-500'>{complaint?.question}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeK = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.question")}</p>
                    <p className='text-neutral-500'>{complaint?.question}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeL = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.cause")}</p>
                    <p className='text-neutral-500'>{complaint?.cause}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeM = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.cause")}</p>
                    <p className='text-neutral-500'>{complaint?.cause}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedkeepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtdeposit")}</p>
                    <p className='text-neutral-500'>{complaint.dtdeposit ? (isValid(convertToDate(complaint.dtdeposit)) ? format(convertToDate(complaint?.dtdeposit) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dttodeliver")}</p>
                    <p className='text-neutral-500'>{complaint.dttodeliver ? (isValid(convertToDate(complaint.dttodeliver)) ? format(convertToDate(complaint?.dttodeliver) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeN = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.responsable")}</p>
                    <p className='text-neutral-500'>{complaint?.responsable}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeO = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnoticed")}</p>
                    <p className='text-neutral-500'>{complaint.dtnoticed ? (isValid(convertToDate(complaint.dtnoticed)) ? format(convertToDate(complaint?.dtnoticed) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.denomination")}</p>
                    <p className='text-neutral-500'>{complaint?.denomination}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.mkpsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.motherbag}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedamount")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedamount}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.numnote")}</p>
                    <p className='text-neutral-500'>{complaint?.numfalsenote}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.sentnbb")}</p>
                    <p className='text-neutral-500'>{complaint?.sentToNBB ? t("complaints.o_detail.o_sub.yes") : t("complaints.o_detail.o_sub.no")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtdeposit")}</p>
                    <p className='text-neutral-500'>{complaint.dtdeposit ? (isValid(convertToDate(complaint.dtdeposit)) ? format(convertToDate(complaint?.dtdeposit) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeP = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.reason")}</p>
                    <p className='text-neutral-500'>{complaint.atmreason}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>ATM</p>
                    <p className='text-neutral-500'>{complaint?.atmnr}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.atmsupport")}</p>
                    <p className='text-neutral-500'>{complaint?.atmsupport ? t("complaints.o_detail.o_sub.yes") : t("complaints.o_detail.o_sub.no")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnewpassage")}</p>
                    <p className='text-neutral-500'>{complaint.dtnewpassage ? (isValid(convertToDate(complaint.dtnewpassage)) ? format(convertToDate(complaint?.dtnewpassage) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.remark")}</p>
                    <p className='text-neutral-500'>{complaint?.opmerking}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint?.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.responsable")}</p>
                    <p className='text-neutral-500'>{complaint?.responsable}</p>
                </div>
            </div>
        </div>
    )
}

const DetailTypeQ = ({complaint}:{complaint:Complaint}) => {
    const {t} = useTranslation()
    return (
        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8 overflow-auto'>
            <h2 className='text-lg'>{t("complaints.o_detail.o_sub.title")}</h2>
            <div className='grid lg:grid-cols-2 gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtnoticed")}</p>
                    <p className='text-neutral-500'>{complaint.dtnoticed ? (isValid(convertToDate(complaint.dtnoticed)) ? format(convertToDate(complaint?.dtnoticed) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.denomination")}</p>
                    <p className='text-neutral-500'>{complaint?.denomination}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.keepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.keepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.mkpsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.motherbag}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedamount")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedamount}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.announcedkeepsafe")}</p>
                    <p className='text-neutral-500'>{complaint?.announcedkeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.no_delivered_keepsafes")}</p>
                    <p className='text-neutral-500'>{complaint?.multiplekeepsafe}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.responsable")}</p>
                    <p className='text-neutral-500'>{complaint?.responsable}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.sentnbb")}</p>
                    <p className='text-neutral-500'>{complaint?.sentToNBB ? t("complaints.o_detail.o_sub.yes") : t("complaints.o_detail.o_sub.no")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("complaints.o_detail.o_sub.dtdeposit")}</p>
                    <p className='text-neutral-500'>{complaint.dtdeposit ? (isValid(convertToDate(complaint.dtdeposit)) ? format(convertToDate(complaint?.dtdeposit) || new Date, "dd/MM/yyyy") : 'Ongeldige datum') : '/'}</p>
                </div>
            </div>
        </div>
    )
}


const ComplaintDetail = () => {
    const {state} = useLocation()
    const {auth} = useAuth()
    const {id} = useParams()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const partners = useSelector((state:RootState) => state.partners)
    const customers = useSelector((state:RootState) => state.customers)

    const [selected_startdate, setselected_startdate] = useState(subMonths(new Date(), 2));
    const [selected_enddate, setselected_enddate] = useState(new Date());

    const { data:complaint, isLoading, isError } = useQuery<Complaint | null>({
        queryKey: [`complaint_${id}`],
        queryFn: async () => {
            try {
                let sd = selected_startdate
                let ed = selected_enddate
                if(state.startdate && isValid(new Date(state.startdate))){sd = state.startdate }
                if(state.enddate && isValid(new Date(state.enddate))){ed = state.enddate }
                const { data } = await axios.post(`complaint/search`, {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(sd),
                    enddate: formatDateToYYYYMMDD(ed)
                })
                if(!Array.isArray(data.object.complaint)){ return null}
                const c = data.object.complaint.find((i:Complaint)=>i.id.toString()===id)
                if(!c){ return null }
                return c
            } catch (error) {
                console.log(error)
                return null
            }
        }
    })

    const { data:complaintDocument } = useQuery<ComplaintDocument[] | null>({
        queryKey: [`complaint_doc_${id}`],
        queryFn: async () => {
            try {
                console.log(complaint)
                if(complaint?.code.toLowerCase() !== 'a'){ return null; }
                const { data:result } = await axios.post(`complaint/document`, {
                    token: auth.data?.token,
                    id: complaint?.id
                })
                if(result.errorcode!==0){ throw new Error(result.message) }
                if(!Array.isArray(result.object.document)){ throw new Error('Documents is no array') }
                return result.object.document
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!complaint
    })

    let relation = (Array.isArray(customers) && isInterface(complaint, {} as Complaint)) ? customers.find(obj => obj.eindklant.some(e => e.relnr === complaint.relnr)) : undefined
    let partner = relation ? partners.find(obj => obj.relnr === relation?.id) : undefined
    const customer = (Array.isArray(customers) && isInterface(complaint, {} as Complaint)) ? customers.flatMap(obj => obj.eindklant).find(e => e.relnr === complaint.relnr) : undefined

  return (
    <>
        <Toaster />
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/complaints')}} className='cursor-pointer'>Complaints</p>}, {title:`Complaint ${id}`}]}/>
                <h1 className='text-2xl font-semibold'>{t("complaints.o_detail.title")} {id}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("complaints.o_detail.subtitle")}</h2>
                {(isLoading && !complaint) && <SpinnerFeedback />}
                {(!isLoading && !complaint) && <EmptyFeedback />}
                {(!isLoading && isError) && <ErrorFeedback />}
                {isInterface(complaint, {} as Complaint) && 
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>Id</p>
                        <p className='text-neutral-500'>{id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("complaints.o_detail.partner")}</p>
                        <p className='text-neutral-500'>{partner?.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("complaints.o_detail.customer")}</p>
                        <p className='text-neutral-500'>{customer?.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("complaints.o_detail.customer_code")}</p>
                        <p className='text-neutral-500'>{customer?.clientcode}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("complaints.o_detail.date_register")}</p>
                        <p className='text-neutral-500'>{format(convertToDate(complaint.dtregistration) || new Date, "dd/MM/yyyy")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("complaints.o_detail.type")}</p>
                        <p className='text-neutral-500'>{complaint.cn.toLowerCase()==='c' ? t("complaints.coins") : t("complaints.notes")}</p>
                    </div>
                </div>
                }
            </div>

            {complaint?.code.toLowerCase() === 'a' && <DetailTypeA complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'b' && <DetailTypeB complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'e' && <DetailTypeE complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'f' && <DetailTypeF complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'g' && <DetailTypeG complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'h' && <DetailTypeH complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'i' && <DetailTypeI complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'j' && <DetailTypeJ complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'k' && <DetailTypeK complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'l' && <DetailTypeL complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'm' && <DetailTypeM complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'n' && <DetailTypeN complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'o' && <DetailTypeO complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'p' && <DetailTypeP complaint={complaint}/>}
            {complaint?.code.toLowerCase() === 'q' && <DetailTypeQ complaint={complaint}/>}
            
            {complaint?.code.toLowerCase() === 'a' && 
            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("complaints.o_detail.document")} ({complaintDocument ? complaintDocument.length : 0})</h2>
                {complaintDocument && complaintDocument.map((doc, ind)=>(
                    <div key={ind}>
                        <p className='text-sm text-neutral-400'>{doc.filename}</p>
                        <img className='w-full h-[600px]' src={doc.url} />
                    </div>
                ))}
            </div>
            }

        </div>
    </>
  )
}

export default ComplaintDetail