import React, { useState } from 'react'
import { Pagination } from '../../../../../components/ui/pagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { formatCashAmount, splitArrayIntoChunks } from '../../../../../lib/utils'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { format } from 'date-fns'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { useNavigate } from 'react-router-dom'
import { getOrderDraftState } from '../../../../../lib/general/params'
import useTable from '../../../../../hooks/useTable'
import { Input } from '../../../../../components/ui/input'
import { ChevronsUpDown, Printer } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import ExportRow from '../../../../../components/portal/export/ExportRow'

type Props = {
    orders: OrderDraft[]
}


const OrdersTable : React.FC<Props> = ({orders}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("order_and_pickup.o_overview_orders.partner"), t("track_and_trace.customer_nr"), t("order_and_pickup.o_overview_orders.customer_code"), t("order_and_pickup.o_overview_orders.customer_nr"), t("order_and_pickup.o_overview_orders.type"), t("order_and_pickup.o_overview_orders.amount"), t("order_and_pickup.o_overview_orders.date_delivery"), t("order_and_pickup.o_overview_orders.date_created"), t("order_and_pickup.o_overview_orders.state"), t("order_and_pickup.o_overview_orders.created_by"), t("order_and_pickup.o_overview_orders.reference"), t("order_and_pickup.o_overview_orders.auto_ref")]
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate);

    const {data, sorting, filter, updateFilter, updateSorting} = useTable({data:orders, sorting:{desc:false, id:'id'}})

    const [currentPage, setcurrentPage] = useState<number>(1);

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-between mt-4'>
        <Input onChange={({currentTarget})=>{updateFilter(currentTarget.value)}} placeholder='Filter...'/>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(orders, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'cn', 'amount', 'dtdelivery', 'dtcreation', 'status', 'username', 'reference', 'autoreference'],{partners:partners, customers:customers, relnr:'relnr', orderstate:orderdraftstate})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead></TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.customer_nr")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.type")}</TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'amount', desc:!sorting?.desc})}} className='uppercase group cursor-pointer hover:underline'><div className='flex items-center gap-1'>{t("order_and_pickup.o_overview_orders.amount")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div></TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtdelivery', desc:!sorting?.desc})}} className='uppercase group cursor-pointer hover:underline'><div className='flex items-center gap-1'>{t("order_and_pickup.o_overview_orders.date_delivery")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div></TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtcreation', desc:!sorting?.desc})}} className='uppercase group cursor-pointer hover:underline'><div className='flex items-center gap-1'>{t("order_and_pickup.o_overview_orders.date_created")}<ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div></TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.state")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.created_by")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.reference")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_overview_orders.auto_ref")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data && splitArrayIntoChunks(data, 20)[currentPage - 1] && splitArrayIntoChunks(data, 20)[currentPage - 1].map((order, index) => {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                    return (
                        <TableRow className='cursor-pointer' key={index}>
                            <TableCell>
                                <div>
                                    <ExportRow id={order.id} type='order'/>
                                </div>
                            </TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{partner?.name}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{customer?.name}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{customer?.clientcode}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{order.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_overview_orders.coins") : t("order_and_pickup.o_overview_orders.notes")}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{formatCashAmount(order.amount)}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy")}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(order.tmcreation)} </TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{getOrderDraftState(order.status, orderdraftstate)?.description}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{order.username}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{order.reference}</TableCell>
                            <TableCell onClick={()=>{ navigate(`${order.id}`) }}>{order.autoreference}</TableCell>
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={orders.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default OrdersTable