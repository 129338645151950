"use client"

import * as React from "react"
import { ChevronDown, ChevronLeft, ChevronRight, ChevronUp } from "lucide-react"
import { DayPicker, CaptionProps, useNavigation } from "react-day-picker"

import { cn } from "../../lib/utils"
import { buttonVariants } from "./button"
import { addYears, format, isSameMonth, isSameYear, setMonth, subYears } from "date-fns"

export type CalendarProps = React.ComponentProps<typeof DayPicker>

type CustomCalendarProps =  {
  onMonthClick?: () => void;
} 

function Calendar({
  onMonthClick,
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps & CustomCalendarProps) {

  const CustomCaption = (props: CaptionProps & CustomCalendarProps) => {
    const { goToMonth, nextMonth, previousMonth } = useNavigation();
    return (
      <div className="text-md flex justify-between">
          <button disabled={!previousMonth} onClick={() => previousMonth && goToMonth(previousMonth)} className={cn(buttonVariants({ variant: "outline" }),"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100")}>
            <ChevronLeft className="w-4 h-4" strokeWidth={1.5}/>
          </button>

          <button onClick={()=>{if(onMonthClick){ onMonthClick() }}} className={`${onMonthClick ? 'cursor-pointer hover:opacity-90' : 'cursor-default'} opacity-80 select-none font-medium`}>{format(props.displayMonth, 'MMMM yyy')}</button>
            
          <button disabled={!nextMonth} onClick={() => nextMonth && goToMonth(nextMonth)} className={cn(buttonVariants({ variant: "outline" }),"h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100")}>
            <ChevronRight className="w-4 h-4"  strokeWidth={1.5}/>
          </button>
      </div>
    );
  }

  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption: "flex justify-center pt-1 relative items-center",
        caption_label: "text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button: cn(
          buttonVariants({ variant: "outline" }),
          "h-7 w-7 bg-transparent p-0 opacity-50 hover:opacity-100"
        ),
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: "flex",
        head_cell:
          "text-muted-foreground rounded-md w-9 font-normal text-[0.8rem]",
        row: "flex w-full mt-2",
        cell: "text-center text-sm p-0 relative [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20",
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "h-9 w-9 p-0 font-normal aria-selected:opacity-100"
        ),
        day_selected:
          "bg-primary text-primary-foreground hover:bg-primary hover:text-primary-foreground focus:bg-primary focus:text-primary-foreground",
        day_today: "bg-accent text-accent-foreground",
        day_outside: "text-muted-foreground opacity-50",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
        ...classNames,
      }}
    
      components={{
        IconLeft: ({ ...props }) => <ChevronLeft className="h-4 w-4" />,
        IconRight: ({ ...props }) => <ChevronRight className="h-4 w-4" />,
        Caption: ({...props}) => <CustomCaption {...props}/>
      }}
      {...props}
    />
  )
}
Calendar.displayName = "Calendar"

type MonthCalendarProps = {
  selected?: Date,
  month?: Date,
  onYearChange?: (year:Date) => void,
  onMonthChange?: (month:Date) => void,
  onYearClick?: () => void
}
const MonthCalendar : React.FC<MonthCalendarProps> = ({selected, month, onYearChange, onMonthChange, onYearClick, ...props}) => {
  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="text-md flex justify-between">
          <button onClick={()=>{onYearClick && onYearClick()}} className="opacity-80 hover:opacity-90 cursor-pointer select-none">{month && format(month, "yyyy")}</button>
    
          <div>
            <button className="opacity-50 hover:opacity-100">
              <ChevronUp onClick={()=>{onYearChange && month && onYearChange(subYears(month, 1))}} strokeWidth={1}/>
            </button>
            <button className="opacity-50 hover:opacity-100">
              <ChevronDown onClick={()=>{onYearChange && month && onYearChange(addYears(month, 1))}} strokeWidth={1}/>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-2 mt-4">
          {Array(12).fill('').map((v,i)=>{
            return (
              <button onClick={()=>{onMonthChange && month && onMonthChange(setMonth(month, i))}} className={`${month?.getMonth() === i && 'bg-accent'} ${selected && month && isSameMonth(selected, new Date(2023, i, 1)) && 'bg-primary text-primary-foreground'} inline-flex items-center justify-center rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-10 p-0 font-normal aria-selected:opacity-100`} key={i}>{format(new Date(2023, i, 1), "MMM")}</button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

type YearCalendarProps = {
  selected?: Date,
  year?: Date,
  onPeriodChange?: (year:Date) => void,
  onYearChange?: (year:Date) => void
}
const YearCalendar : React.FC<YearCalendarProps> = ({selected, year, onPeriodChange, onYearChange, ...props}) => {
  return (
    <div className="p-3">
      <div className="flex flex-col">
        <div className="text-md flex justify-between">
          <button className="opacity-80 hover:opacity-90 cursor-pointer select-none">{year && format(subYears(year, 3), "yyyy")} - {year && format(addYears(year, 6), "yyyy")}</button>
    
          <div>
            <button onClick={()=>{onPeriodChange && year && onPeriodChange(subYears(year, 4))}} className="opacity-50 hover:opacity-100">
              <ChevronUp strokeWidth={1}/>
            </button>
            <button onClick={()=>{onPeriodChange && year && onPeriodChange(addYears(year, 4))}} className="opacity-50 hover:opacity-100">
              <ChevronDown strokeWidth={1}/>
            </button>
          </div>
        </div>

        <div className="grid grid-cols-4 gap-2 mt-4">
          {Array(12).fill('').map((v,i)=>{
            let d = year ? subYears(year, 3) : subYears(new Date(),3)
            d = addYears(d, i)
            return (
              <button onClick={()=>{onYearChange && onYearChange(d)}} key={i} className={`${year && isSameYear(d, year) && 'bg-accent'} ${selected && isSameYear(d, selected) && 'bg-primary text-primary-foreground'} inline-flex items-center justify-center rounded-md text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-9 w-12 p-0 font-normal aria-selected:opacity-100`}>{format(d, "yyyy")}</button>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export { Calendar, MonthCalendar, YearCalendar }
