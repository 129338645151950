import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import { Checkbox } from '../../../../../components/ui/checkbox';
import { Edit, Mail, Trash } from 'lucide-react';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../../../components/ui/tooltip';
import useAuth from '../../../../../hooks/useAuth';
import { Pagination } from '../../../../../components/ui/pagination';
import { splitArrayIntoChunks } from '../../../../../lib/utils';
import NewCheckbox from '../../../../../components/ui/new-checkbox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { convertToDate } from '../../../../../lib/date';
import { format, isValid } from 'date-fns';
import { useTranslation } from 'react-i18next'

interface Props extends React.HTMLAttributes<HTMLTableElement> {
    options: User[];
    onSelectItem?: (User:User[]) => void;
    onDeleteItem?: (User:User) => void;
    onEditItem?: (User:User) => void;
    onMailItem?: (User:User) => void;
}

const Results = React.forwardRef<HTMLTableElement, Props>(({ className, options, onSelectItem, onDeleteItem, onEditItem, onMailItem, ...props }, ref) => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const roles = useSelector((state:RootState)=>state.roles)

    const [selections, setselections] = useState<User[]>([]);

    const [currentPage, setcurrentPage] = useState(1);

    const toggleSelection = (user:User, value:boolean) => {
        if(value) {
            setselections(prev => {
                let updated = [...prev]
                const index = selections.findIndex(i=>i.username===user.username);
                if(index === -1){
                    updated.push(user)
                }
                return updated
            })
        } else {
            setselections(prev => {
                let updated = [...prev]
                const index = selections.findIndex(i=>i.username===user.username);
                if(index > -1){
                    updated.splice(index, 1)
                }
                return updated
            })  
        }
    }

    const toggleAll = (value:boolean) => {
        if(value){
            setselections(options)
        } else {
            setselections([])
        }
    }

    useEffect(()=>{
        onSelectItem && onSelectItem(selections)
    }, [selections])

  return (
    <div>
    <Table className={className}>
        <TableHeader className='bg-slate-100 relative'>
            <TableRow>
                <TableHead className='uppercase'><NewCheckbox onCheckedChange={(v)=>{toggleAll(v)}}/></TableHead>
                <TableHead className='uppercase'>{t("users.o_results.name")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.mail")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.partner")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.customer")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.role")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.last_login")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.created_on")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.language")}</TableHead>
                <TableHead className='uppercase'>{t("users.o_results.verified")}</TableHead>
                <TableHead className='uppercase'></TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {options && splitArrayIntoChunks(options, 20)[currentPage-1] && splitArrayIntoChunks(options, 20)[currentPage-1].map((value, index) => {
                return (
                    <TableRow className='relative' key={index}>
                        <TableCell><NewCheckbox checked={selections.includes(value)} onCheckedChange={(checked) => { toggleSelection(value, checked) }}/></TableCell>
                        <TableCell>{value.firstname} {value.lastname}</TableCell>
                        <TableCell>{value.username}</TableCell>
                        <TableCell className='max-w-[250px] overflow-auto'>{value.partner}</TableCell>
                        <TableCell className='max-w-[250px] overflow-auto'>{value.relnr}</TableCell>
                        <TableCell>{Array.isArray(roles) && roles.find(i=>i.id===value.role)?.name}</TableCell>
                        <TableCell>{isValid(new Date(value.lastlogin)) && format(new Date(value.lastlogin), "dd/MM/yyyy HH:mm")}</TableCell>
                        <TableCell>{format(convertToDate(value.dtcreated) || new Date, "dd/MM/yyyy")}</TableCell>
                        <TableCell>{value.language}</TableCell>
                        <TableCell>{value.verified ? t("users.o_results.yes") : t("users.o_results.no")}</TableCell>
                        <TableCell>
                            <div className={`${value.username === auth.data?.username && 'hidden'} flex items-center justify-end`}>
                                <TooltipProvider>

                                    {/* Mail versturen */}
                                    {!value.verified &&
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <button onClick={()=>{ onMailItem && onMailItem(value) }} className='w-8 h-8 flex items-center justify-center rounded-sm hover:bg-slate-200'><Mail className='w-4 text-neutral-600'/></button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>{t("users.o_results.tooltip_mail")}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                    } 

                                    {/* Wijzigen */}
                                    {auth.data?.right.manageusrprofile && 
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <button disabled={!value.verified} onClick={()=>{ onEditItem && onEditItem(value) }} className='w-8 h-8 flex items-center justify-center rounded-sm hover:bg-slate-200 disabled:opacity-50 disabled:cursor-not-allowed'><Edit className='w-4 text-neutral-600'/></button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            {!value.verified ? <p>{t("users.o_results.tooltip_notverified")}</p> : <p>{t("users.o_results.tooltip_edit")}</p>}
                                        </TooltipContent>
                                    </Tooltip>
                                    }

                                    {/* Verwijderen */}
                                    {auth.data?.right.deleteuser && 
                                    <Tooltip>
                                        <TooltipTrigger asChild>
                                            <button onClick={()=>{ onDeleteItem && onDeleteItem(value) }} className='w-8 h-8 flex items-center justify-center rounded-sm hover:bg-slate-200'><Trash className='w-4 text-neutral-600'/></button>
                                        </TooltipTrigger>
                                        <TooltipContent>
                                            <p>{t("users.o_results.tooltip_delete")}</p>
                                        </TooltipContent>
                                    </Tooltip>
                                    }
                                                              
                                </TooltipProvider>    
                            </div>
                        </TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
    </Table>
    {options && options.length > 20 && 
    <div className='p-4'>
        <Pagination totalItems={options.length} onPageChange={(page)=>{setcurrentPage(parseFloat(page))}} page={currentPage} itemsPerPage={20}/>
    </div>
    }
    </div>
  )
})

export default Results