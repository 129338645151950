import React, { useEffect, useState } from 'react'
import { Tablist, TablistItem } from '../../ui/tablist'
import Coupure from '../coupure/Coupure'
import { isInterface } from '../../../lib/utils'
import History from '../history/History'
import Scans from '../scans/Scans'
import { SafepointSubpage } from '../../../app/pages/TrackAndTrace/Detail/Collection/Collection'
import Announcement from '../announcement/Announcement'
import Count from '../count/Count'
import Transport from '../transport/Transport'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'
import { toast } from 'react-hot-toast'
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date'
import { addDays } from 'date-fns'
import SpinnerFeedback from '../feedback/SpinnerFeedback'
import ErrorFeedback from '../feedback/ErrorFeedback'
import EmptyFeedback from '../feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'

type Props = {
  colliid: number;
  keepsafeDate: string;
}

type Transport = {
    origin: Origin;
    destination: Destination
}

const ColliKeepsafeOverview : React.FC<Props> = ({colliid, keepsafeDate}) => {
  const {auth} = useAuth()
  const {t} = useTranslation()
  const [selected_subpage, setselected_subpage] = useState<SafepointSubpage>('announcement');
  const [announcement, setannouncement] = useState<Announcement | StateType>(null);
  const [count, setcount] = useState<Count[] | StateType>(null);
  const [transport, settransport] = useState<Transport | StateType>(null);
  const [history, sethistory] = useState<HistoryProp[] | StateType>(null);
  const [scans, setscans] = useState<Scan[] | StateType>(null);


  useEffect(()=>{
    fetch_announcement()
    fetch_count()
    fetch_transport()
    fetch_history()
    fetch_scans()
  }, [])

  async function fetch_announcement() {
    try {
      setannouncement('loading')
      const { data } = await axios.post(`announcement/search`, {
          token: auth.data?.token,
          colliid: colliid
      })

      if(data.errorcode === 0){  
          if(!Array.isArray(data.object.announcement)){
              //Emtpy
              setannouncement('empty')
          }
          else {
              setannouncement(data.object.announcement[0])
          }
      }
      else {
          console.log(data)
          setannouncement('error')
          toast.error(t("components.o_colli.o_keepsafe.toast_error"))
      }
    } catch (error) {
        console.log(error);
        setannouncement('error')
        toast.error(t("components.o_colli.o_keepsafe.toast_error"))
    }
  }

      async function fetch_count() {
          try {
              setcount('loading')
              console.log("colliid", colliid)
              console.log("keepsafeDate", keepsafeDate)
              const { data } = await axios.post(`colli/count`, {
                  token: auth.data?.token,
                  colliid: colliid,
                  date: formatDateToYYYYMMDD(addDays(convertToDate(keepsafeDate) || new Date(), 7))
              })
              if(data.errorcode === 0) {
                  if(Array.isArray(data.object.count)){
                      setcount(data.object.count[0])
                  }
                  else {
                      setcount('empty')
                  }
              }
              else {
                  setcount('error')
                  console.log(data)
              }   
          } catch (error) {
              console.log(error)
              setcount('error')
              toast.error(t("components.o_colli.o_keepsafe.toast_error_p2"))
          }
      }

      async function fetch_transport() {
          try {
              settransport('loading')
              const { data } = await axios.post(`colli/transport`, {
                  token: auth.data?.token,
                  colliid: colliid
              })
              if(data.errorcode === 0){
                  console.log(data)
                  if(data.object){
                      settransport(data.object)
                  }
                  else {
                      settransport('empty')
                  }
              }
              else {
                  console.log(data)
                  settransport('error')
                  toast.error(t("components.o_colli.o_keepsafe.toast_error_p3"))
              }
          } catch (error) {
              console.log(error)
              settransport('error')
              toast.error(t("components.o_colli.o_keepsafe.toast_error_p3"))
          }
      }

      async function fetch_history() {
          try {
              sethistory('loading')
              const { data } = await axios.post(`colli/history`, {
                  token: auth.data?.token,
                  colliid: colliid
              })
              if(data.errorcode === 0){
                  if(Array.isArray(data.object.history)){
                      sethistory(data.object.history)
                  }
                  else {
                      sethistory('empty')
                  }
              }
              else {
                  console.log(data)
                  sethistory('error')
                  toast.error(t("components.o_colli.o_keepsafe.toast_error_p4"))
              }
          } catch (error) {
              console.log(error)
              sethistory('error')
              toast.error(t("components.o_colli.o_keepsafe.toast_error_p4"))
          }
      }

      async function fetch_scans() {
          try {
              setscans('loading')
              const { data } = await axios.post(`scan/search`, {
                token: auth.data?.token,
                idcolli: colliid,
                startdate: formatDateToYYYYMMDD(convertToDate(keepsafeDate) || new Date()),
                enddate: formatDateToYYYYMMDD(new Date()),
                equal: 1
              })
              if(data.errorcode === 0){
                  if(Array.isArray(data.object.scan)){
                      setscans(data.object.scan)
                  }
                  else {
                      setscans('empty')
                  }
              }
              else {
                console.log(data);
                toast.error(t("components.o_colli.o_keepsafe.toast_error_p5"))
                setscans('error')
              }
          } catch (error) {
            console.log(error)
            setscans('error')
            toast.error(t("components.o_colli.o_keepsafe.toast_error_p5"))
          }
      }
  
  return (
    <>
      <Tablist value={selected_subpage} onValueChange={(value)=>{ setselected_subpage(value as SafepointSubpage) }}>
        <TablistItem value='announcement'>{t("components.o_colli.o_keepsafe.announcement")}</TablistItem>
        <TablistItem value='count'>{t("components.o_colli.o_keepsafe.count")}</TablistItem>
        <TablistItem value='transport'>{t("components.o_colli.o_keepsafe.transport")}</TablistItem>
        <TablistItem value='history'>{t("components.o_colli.o_keepsafe.history")}</TablistItem>
        <TablistItem value='scans'>{t("components.o_colli.o_keepsafe.scannings")}</TablistItem>
      </Tablist>
      <div className='px-4'>
        {selected_subpage === 'announcement' && 
        <>
            {announcement === 'loading' && <SpinnerFeedback />} 
            {announcement === 'error' && <ErrorFeedback />}
            {announcement === 'empty' && <EmptyFeedback />}
            {isInterface(announcement, {} as Announcement) && <Announcement announcement={announcement} />}
        </>
        }

        {selected_subpage === 'count' && 
        <>
            {count === 'loading' && <SpinnerFeedback />} 
            {count === 'error' && <ErrorFeedback />}
            {count === 'empty' && <EmptyFeedback />}
            {isInterface(count, {} as Count) && 
                <div className='border rounded-sm'><Count count={count}/></div>
            }
        </>
        }

        {selected_subpage === 'transport' && 
        <>
            {transport === 'loading' && <SpinnerFeedback />} 
            {transport === 'error' && <ErrorFeedback />}
            {transport === 'empty' && <EmptyFeedback />}
            {isInterface(transport, {} as Transport) &&
                <Transport transport={transport}/>
            }
        </>
        }
        {selected_subpage === 'history' && 
        <>
            {history === 'loading' && <SpinnerFeedback />} 
            {history === 'error' && <ErrorFeedback />}
            {history === 'empty' && <EmptyFeedback />}
            {isInterface(history, {} as HistoryProp[]) &&
                <div className='border rounded-sm'><History history={history}/></div>
            }
        </>
        }
        {selected_subpage === 'scans' && 
        <>
            {scans === 'loading' && <SpinnerFeedback />} 
            {scans === 'error' && <ErrorFeedback />}
            {scans === 'empty' && <EmptyFeedback />}
            {isInterface(scans, {} as Scan[]) &&
                <div className='border rounded-sm'><Scans scans={scans}/></div>
            }
        </>
        }
    </div>
    </>
  )
}

export default ColliKeepsafeOverview