import { useState, useEffect, useRef, MutableRefObject } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, Link } from "react-router-dom"
import axios from "../../../lib/axios";
import { Trans, useTranslation } from "react-i18next"
import useUserStore from "../../../zustand/useUserStore";
import { CircleDollarSign, Banknote, Landmark, ShieldCheck, PiggyBank, Loader2 } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import logoSmall from "../../../assets/images/logoSmall.jpg";
import { Checkbox } from "../../../components/ui/checkbox";
import toast, { Toaster } from "react-hot-toast"
import { decryptObject, encryptObject } from "../../../lib/crypt";
import { addHours, addMinutes } from "date-fns";
import { JWT } from "../../../hooks/useRefreshToken";
import NewCheckbox, { CheckboxIcon } from "../../../components/ui/new-checkbox";
import ReactGA from "react-ga4";
import { changeLanguage as changeLang } from "../../../hooks/useRefreshToken";
import ErrorBoundary from "../../../components/portal/error/ErrorBoundary";
import { AxiosError } from "axios";
import lodash from "lodash"
import { z } from "zod";

const Login : React.FC = () => {
    const { t, i18n } = useTranslation()
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/dashboard"
    
    const { setAuth, persist, setpersist } = useAuth();

    const [email, setemail] = useState('');
    const [password, setpassword] = useState('');
    const [isLoading, setisLoading] = useState(false);

    useEffect(()=>{
        //Pre-fill values when persist is checked
        const persist = sessionStorage.getItem('persist')
        if(persist) {
            if(persist === 'true'){
                let jwt = window.sessionStorage.getItem('jwt');
                if(jwt){
                    const dc = decryptObject(jwt, process.env.REACT_APP_SECRET || '') as JWT;
                    setemail(dc.username);
                    setpassword(dc.password);
                }
            }
        }
    }, [])

    const handleLogin = async () => {
        try {
            setisLoading(true);
            let body:any = {
                username: email.toLowerCase(),
                password: password
            }
            const { data } = await axios.post('authenticate', body)
            setisLoading(false)

            if(data.errorcode !== 0){
                throw new Error(data.errorcode);
            }

            if(persist && process.env.REACT_APP_SECRET){
                const jwt = encryptObject({ username:email, password, expiry:addHours(new Date(), 1)}, process.env.REACT_APP_SECRET)
                window.sessionStorage.setItem("jwt", jwt);
            }
            else {
                const jwt = encryptObject({ username:'', password:'', expiry:addMinutes(new Date(), 30)}, process.env.REACT_APP_SECRET || '')
                window.sessionStorage.setItem("jwt", jwt);
            }

            let userObject = data;

            ReactGA.event({
                category: 'User',
                action: 'login_success',
                label: `${email.replace('@','*').replace('.', '*')}`
            })
            ReactGA.set({ userId: data.object.user.username });

            const obj = data.object.user;
            try { obj.partner = lodash.uniqWith(obj.partner.split(','), lodash.isEqual).join(',') } catch (e) {}

            userObject = {access_token: '', ...obj}
            
            if(window.localStorage.getItem('i18nextLng')==='nl'){
                changeLang(data.object.user.language.toUpperCase())
            }

            setAuth({status:'authenticated', data: userObject})
            navigate(from, { replace:true });

        } catch (error:any | AxiosError) {
            ReactGA.event({
                category: 'User',
                action: 'login_failed',
                label: `${email.replace('@','*').replace('.', '*')}`
            })
            //@ts-ignore
            let message = t(`errors.error-${error.message}`) || ''
            setisLoading(false)
            //@ts-ignore
            toast.error(message ? message : t("login.toast_error"), { duration: 5000 });
        }        
    }

    useEffect(()=> {
        sessionStorage.setItem('persist', persist.toString())
    }, [persist])

    const changeLanguage = (language:string) => {
        i18n.changeLanguage(language)
    }

    function isComplete() {
        try {
            const s = z.object({
                email: z.string().email(),
                password: z.string().min(1)
            })
            s.parse({
                email: email,
                password: password
            })
            return true
        } catch (error) {
            return false
        }
    }

    return (
        <>
        <Toaster />
        <div className="fixed top-0 h-12 w-full bg-slate-800"></div>
        <img className="fixed mt-6 h-12 left-1/2 -translate-x-1/2" src={logoSmall} alt="logo"/>

        <div className="h-full grid grid-cols-1 md:grid-cols-2">
            <div className="hidden md:flex bg-gradient-to-tr from-[#EAA792] to-primary flex-col justify-between p-10">
                <i></i>

                <h1 className="text-right text-white font-bold text-4xl">The future of cash<br></br>management is here</h1>

                <ul className="flex justify-between">
                    <li onClick={()=>{throw new Error()}}><CircleDollarSign className="h-12 w-12 text-white"/></li>
                    <li><Banknote className="h-12 w-12 text-white"/></li>
                    <li><ShieldCheck className="h-12 w-12 text-white"/></li>
                    <li><Landmark className="h-12 w-12 text-white"/></li>
                    <li><PiggyBank className="h-12 w-12 text-white"/></li>
                </ul>
            </div>
            <div className="bg-slate-100 flex flex-col items-center justify-between mt-12 p-12">

                <div className="w-full flex justify-end">
                    <Select value={i18n.language} onValueChange={(v)=> changeLanguage(v) }>
                        <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Selecteer een taal"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="nl">Nederlands</SelectItem>
                                <SelectItem value="fr">Francais</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="flex flex-col gap-4 w-full" onKeyDown={(e)=>{if(e.key === 'Enter'){ handleLogin() }}}>
                    <h1 className="text-2xl font-bold">{t("login.title")}</h1>
                    
                    <div>
                        <Label htmlFor="email">{t("login.email")}</Label>
                        <Input value={email} onChange={(e)=> { setemail(e.currentTarget.value) }} className="bg-white" type="email" id="email" placeholder={t("login.email") || ""} />
                    </div>
                    
                    <div>
                        <Label htmlFor="password">{t("login.password")}</Label>
                        <Input value={password} onChange={(e)=> { setpassword(e.currentTarget.value) }} className="bg-white" type="password" id="password" placeholder={t("login.password")+'...'} />

                        <div className="flex justify-between items-center mt-2">
                            <div className="flex items-center gap-1">
                                <NewCheckbox checked={persist} onCheckedChange={(v) => { if(persist!==v){setpersist(v)} }} id="persist"/>
                                <Label htmlFor="persist">{t("login.remember")}</Label>
                            </div>
                            
                            <Link className="text-sm font-semibold float-right text-primary/50" to="/forgot-password?">{t("login.forgot_password")}</Link>
                        </div>
                        
                    </div>

                        <Button disabled={isLoading || !isComplete()} onClick={handleLogin} className="w-fit">{
                            isLoading ? 
                            <Loader2 className="animate-spin"/ >
                                :
                            t("login.button")
                        }</Button>
                </div>

                <i className="h-12 w-full"></i>
                
            </div>
        </div>
        </>
    )
}

export default Login;