import { useLocation, Navigate, Outlet } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireRelnr = ({ relnr, type='partner' } : { relnr:string, type:'partner' | 'customer' }) => {
    const { auth } = useAuth();
    const location = useLocation();

    function isRightValid() {
        if(auth.data?.role === 1){ return true }
        if(type === 'partner'){
            if(auth.data?.partner.includes(relnr)){
                return true
            }
            else { 
                return false
            }
        }

        if(type === 'customer'){
            if(auth.data?.relnr.includes(relnr)){
                return true
            }
            else { 
                return false
            }
        }
    }

    return (
        (isRightValid())
            ? <Outlet />
            : auth?.data?.token
                ? <Navigate to="/unauthorized" state={{ from: location }} replace />
                : <Navigate to="/login" state={{ from: location }} replace />
    );
}

export default RequireRelnr;