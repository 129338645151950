import coin_eur_001 from '../../../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../../../assets/images/coin-eur-2.png'

import { Input } from '../../../../../components/ui/input'
import { Equal, X } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { formatCashAmount } from '../../../../../lib/utils'
import { useTranslation } from 'react-i18next'

function getIconSource(value:number) {
    switch (value) {
        case 0.01:
            return coin_eur_001
        case 0.02:
            return coin_eur_002
        case 0.05:
            return coin_eur_005
        case 0.1:
            return coin_eur_01
        case 0.2:
            return coin_eur_02
        case 0.5:
            return coin_eur_05
        case 1:
            return coin_eur_1
        case 2:
            return coin_eur_2
        default:
            break;
    }
}

type Props = {
    denom: Denom;
    onChangeInput: (coupure: CoupureType, qty: number) => void;
    coupures: CoupureInput[] | null
    bulk?: boolean
}

const Coins : React.FC<Props> = ({denom, onChangeInput, coupures, bulk=false}) => {
    const {t} = useTranslation()
    const coupure = useSelector((state:RootState)=>state.coupure);

    function filterCoins(coins:Coin[]) {
        const orderableCoupures = coupure.filter(i=>i.order===true&&i.cn==='C')
        const orderableCoins = coins.filter(i=>orderableCoupures.find(j=>j.value===i.coupure)!==undefined)
        return orderableCoins
    }

    function calculateSubtotal(coupure: CoupureType, qty:number) {
        let total = parseFloat(coupure) * qty;
        return total;
    }

    function calculateTotal() {
        let total = 0;
        let totalArr = coupures?.filter(i=>parseFloat(i.coupure)<=2).map(i=>i.qty*parseFloat(i.coupure)) || []
        totalArr.forEach(i=>{total += i});
        total = Math.round(total*100)/100
        return total;
    }

    function calculateWeight() {
        let total = 0;
        let totalArr = coupures?.filter(i=>parseFloat(i.coupure)<=2).map(i=>i.qty*(coupure.find(j=>j.value===parseFloat(i.coupure))?.weight || 0)) || []
        totalArr.forEach(i=>{total += i});
        return Math.round(total*1000)/1000
    }

  return (
    <>
    <div className='flex flex-col gap-8'>
        {filterCoins(denom.coins).map((coup, ind) => {

            function returnValue() {
                let value = (((coupures?.find(i=>parseFloat(i.coupure)===coup.coupure)?.qty || 0)/coup.perqty)) || 0;
                let max = coup.maxqty
                if(bulk){ max = coup.maxqty * (coupure ? (coupure.find(c=>c.value===coup.coupure)?.unit || 1) : 1)}
                if(value > max) { value=max }
                if(value < coup.minqty) { value=0 }
                return value;
            }
            
            function changeValue(value:number) {
                let v = value;
                let max = coup.maxqty
                if(bulk){ max = coup.maxqty * (coupure ? (coupure.find(c=>c.value===coup.coupure)?.unit || 1) : 1)}
                if(v > max) { v = max }
                if(v < coup.minqty) { v = coup.minqty}
                onChangeInput(coup.coupure.toString() as CoupureType, v * coup.perqty)
            }

            return (
                <div className='flex justify-between md:grid gap-4 md:grid-cols-3' key={ind}>
                    <div className='flex items-center gap-2'>
                        <div className='hidden min-w-[2.5rem] w-10 h-10 border sm:flex justify-center items-center rounded-sm'>
                            <img src={getIconSource(coup.coupure)} alt="coin" />
                        </div>
                        <div>
                            <p className='font-semibold leading-none'>{formatCashAmount(coup.coupure)} Euro</p>
                            {!bulk && <p className='whitespace-nowrap text-xs sm:text-sm text-neutral-500'>{t("order_and_pickup.o_place_order.waffle_per")} {coup.perqty} {t("order_and_pickup.o_place_order.pieces")}</p>}
                        </div>
                    </div>

                    <div className='flex gap-2'>
                        <div className='flex flex-col justify-center items-end min-w-[80px]'>
                            <p className='leading-none text-sm whitespace-nowrap'>min: <span className='font-semibold'>{coup.minqty}</span></p>
                            <p className='leading-none text-sm whitespace-nowrap'>max: 
                                {bulk
                                ? <span className='font-semibold'>{coup.maxqty * (coupure ? (coupure.find(c=>c.value===coup.coupure)?.unit || 1) : 1)}</span>
                                : <span className='font-semibold'>{coup.maxqty}</span>
                                }
                                
                            </p>
                        </div>
                        <div>
                            <Input 
                                disabled={coup.perqty === 0} 
                                value={returnValue()} 
                                onChange={({currentTarget})=>{changeValue(Math.round(parseFloat(currentTarget.value) || 0))}} 
                                type='number' className='px-1 sm:px-3 md:min-w-[100px]' 
                                min={coup.minqty} 
                                max={!bulk ? coup.maxqty : (coup.maxqty * (coupure ? (coupure.find(c=>c.value===coup.coupure)?.unit || 1) : 1))}
                            />
                        </div>
                    </div>

                    <div className='flex justify-between items-center'>
                        <div className='hidden md:flex gap-2'>
                            <X className='w-4' strokeWidth={1}/>
                            <p className='font-medium whitespace-nowrap'>€ {formatCashAmount(coup.coupure * coup.perqty)}</p>
                            <Equal className='w-4' strokeWidth={1}/>
                        </div>
                        <p className='font-semibold whitespace-nowrap'>€ {formatCashAmount(calculateSubtotal(coup.coupure.toString() as CoupureType, (coupures?.find(i=>i.coupure === coup.coupure.toString() as CoupureType)?.qty || 0)))}</p>
                    </div>
                </div>
            )
        })}
    </div>
    
    <div className='h-px w-full bg-neutral-300'></div>

    <div className='flex justify-between font-semibold'>
        <p>{t("order_and_pickup.o_place_order.total")}:</p>
        <div className='flex flex-col items-end'>
            <p>€ {calculateTotal()}</p>
            <p>{calculateWeight()} kg</p>
        </div>
    </div>
    </>
  )
}

export default Coins