import { cn } from '../../lib/utils'
import { ChevronRight } from 'lucide-react'
import { HTMLAttributes } from 'react'

type Item = {
    title: React.ReactNode
}

interface BreadcrumbProps extends HTMLAttributes<HTMLDivElement> {
    items: Item[]
}

export const Breadcrumb : React.FC<BreadcrumbProps> = ({items, className, ...props}) => {
    return (
        <div className={cn("flex items-center gap-2", className)} {...props}>
            {items.map((item, index) => { 
                return (
                    <div className={`${index+1 !== items.length && 'opacity-50'} flex items-center gap-2 font-semibold text-sm`} key={index}>
                        {item.title}

                        {index+1 !== items.length &&
                        <ChevronRight className='w-4'/>
                        }
                    </div>
                )
            })}
        </div>
    )
}