import { ActionType, Action } from "../action-types";

const initialState:ParamCoupure[] = [];

const coupureReducer = (state:ParamCoupure[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_COUPURE:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default coupureReducer;