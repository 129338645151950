import { Check, Edit2, X, Equal, Coins } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import TooltipForce from '../../ui/tooltip-force'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog'
import { Label } from '../../ui/label'
import { Input } from '../../ui/input'
import { useDebounce } from '@uidotdev/usehooks'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'

import coin_eur_001 from '../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../assets/images/coin-eur-2.png'

import note_eur_5 from '../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../assets/images/note-eur-500.png'
import { Button } from '../../ui/button'
import { isCoupureCN } from '../../../lib/utils'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

type Props = {
    keepsafe?: AnnouncementKeepsafe;
    onSave: (object:{barcode:string, coupures:CoupureInput[]}) => void;
}

const EditKeepsafe : React.FC<Props> = ({keepsafe, onSave}) => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const [barcode, setbarcode] = useState<string>('');
    const [barcode_error, setbarcode_error] = useState<'correct' | StateType>(null);
    const debouncedBarcode = useDebounce(barcode, 300)

    const [coupure_input, setcoupure_input] = useState<CoupureInput[]>();

    useEffect(()=>{
        if(keepsafe){
            setbarcode(keepsafe.keepsafe);
            setcoupure_input(keepsafe.detail[0].coupures)
        }
    }, [keepsafe])

    useEffect(()=>{
        if(debouncedBarcode !== '' && debouncedBarcode.substring(0,2) === 'BE') {
            const checkBarcode = async () => {
                try {
                    setbarcode_error('loading')
                    const { data } = await axios.post(`barcode/check`, {
                        token: auth.data?.token,
                        barcode: debouncedBarcode
                    })
                    if(data.errorcode === 0){
                        if(data.object.barcode.valid === true && data.object.barcode.remark === ''){
                            setbarcode_error('correct')
                        } else {
                            setbarcode_error('error')
                        }
                    }
                    else {
                        setbarcode_error('error')
                    }
                } catch (error) {
                    setbarcode_error('error')
                    console.log(error)
                }
            }
            checkBarcode()
        }
        else {
            setbarcode_error(null)
        }
    }, [debouncedBarcode])

    const onChangeInput = (coupure:CoupureType, qty:number) => {
        qty = Math.round(qty)
        if(coupure_input) {
            let tmp = [...coupure_input]
            const existingIndex = coupure_input.findIndex(item => item.coupure.toString() === coupure);
            
            if(existingIndex !== -1){
                tmp[existingIndex] = {qty:qty, coupure:coupure}
            }
            else {
                tmp.push({qty:qty, coupure:coupure})
            }
            console.log(tmp)
            setcoupure_input(tmp);
        }
        else {
            setcoupure_input([{coupure:coupure, qty:qty}])
        }
    };

    const calculateTotal = (cn:'c' | 'n') => {
        let total = 0;

        if(coupure_input) {
            let tmp = [...coupure_input]
            if(cn === 'c') { tmp = tmp.filter(i =>parseFloat(i.coupure) <= 2); }
            else { tmp = tmp.filter(i => parseFloat(i.coupure) > 2) }
            

            tmp.forEach(item => {
                if(!isNaN(item.qty) && !isNaN(parseFloat(item.coupure))){
                    total += item.qty * parseFloat(item.coupure)
                }
            });
        }

        return total;
    }

    const calculateSubtotal = (coupure:CoupureType) => {
        let total = 0;
        if(coupure_input) {
            const item = coupure_input.find(i=>i.coupure.toString()===coupure)
            if(item) {
                total = parseFloat(item.coupure) * item.qty
            }
        }
        return Math.round(total * 100)/100 || 0
    }

    const saveKeepsafe = () => {
        if(coupure_input){
            onSave({
                barcode: barcode,
                coupures: coupure_input
            })
        } else {
            toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_found_p3"))
        }
    }

    const CoinsInput = () => {
        return (
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_001} alt='coin_eur_001'/>
                        <p className='font-medium'>0.01 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.01', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.01')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.01')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_002} alt='coin_eur_002'/>
                        <p className='font-medium'>0.02 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.02', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.02')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.02')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_005} alt='coin_eur_002'/>
                        <p className='font-medium'>0.05 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.05', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.05')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.05')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_01} alt='coin_eur_01'/>
                        <p className='font-medium'>0.10 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.1', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.1')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.1')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_02} alt='coin_eur_02'/>
                        <p className='font-medium'>0.20 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.2', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.2')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.2')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_05} alt='coin_eur_05'/>
                        <p className='font-medium'>0.50 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.5', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '0.5')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.5')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_1} alt='coin_eur_1'/>
                        <p className='font-medium'>1 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('1', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '1')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('1')}</p></div>
                   </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_2} alt='coin_eur_2'/>
                        <p className='font-medium'>2 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('2', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '2')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('2')}</p></div>
                    </div>
                </div>

                <div className='flex justify-end border-t py-2 text-sm'>
                    <p>{t("order_and_pickup.o_announce_pickup.o_manual.total")}: <span className='font-medium'>€ {calculateTotal('c')}</span></p> 
                </div>
            </div>
        )
    }

    const NotesInput = () => {
        return (
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_5} alt='note_eur_5'/>
                        <p className='font-medium'>5 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('5', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '5')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('5')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_10} alt='note_eur_10'/>
                        <p className='font-medium'>10 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('10', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '10')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('10')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_20} alt='note_eur_20'/>
                        <p className='font-medium'>20 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('20', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '20')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('20')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_50} alt='note_eur_50'/>
                        <p className='font-medium'>50 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('50', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '50')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('50')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_100} alt='note_eur_100'/>
                        <p className='font-medium'>100 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('100', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '100')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('100')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_200} alt='note_eur_200'/>
                        <p className='font-medium'>200 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('200', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '200')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('200')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_500} alt='note_eur_500'/>
                        <p className='font-medium'>500 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('500', parseFloat(currentTarget.value)) }} defaultValue={coupure_input?.find(i => i.coupure.toString() === '500')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('500')}</p></div>
                    </div>
                </div>

                <div className='flex justify-end border-t py-2 text-sm'>
                    <p>{t("components.o_announcement.o_announcement.total")}: <span className='font-medium'>€ {calculateTotal('n')}</span></p> 
                </div>
            </div>
        )
    }

  return (
    <AlertDialog>
        
        <AlertDialogTrigger asChild>
            <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_manual.tooltip_edit")}</p>}>
            <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-90 duration-200 group'><Edit2 className='w-5 group-hover:text-orange-500'/></button>
            </TooltipForce>
        </AlertDialogTrigger>
        <AlertDialogContent className='max-h-[95vh] overflow-y-auto'>
            <div className='flex justify-between items-center'>
                <h2 className='text-xl font-medium text-black/80'>{t("order_and_pickup.o_announce_pickup.o_manual.edit_title")}</h2>
                <AlertDialogCancel asChild><button className='w-6 h-6 !p-0 bg-transparent border-none opacity-50 hover:bg-transparent hover:opacity-100'><X /></button></AlertDialogCancel>
            </div>

            <div>
                <Label htmlFor='barcode'>{t("order_and_pickup.o_announce_pickup.o_manual.barcode")}<span className='text-primary ml-1'>*</span></Label>
                <Input endEnhancer={
                <>
                    {barcode_error === 'correct' && <div className='w-5 h-5 rounded-full bg-green-400 flex justify-center items-center'><Check className='w-3 text-white'/></div>}
                    {barcode_error === 'error' && <div className='w-5 h-5 rounded-full bg-red-400 flex justify-center items-center'><X className='w-3 text-white'/></div>}
                </>} onChange={({currentTarget})=>setbarcode(currentTarget.value)} value={barcode} id='barcode'/>
                {barcode_error === 'error' && <p className='text-sm text-red-600'>{t("order_and_pickup.o_announce_pickup.o_manual.barcode_error")}</p>}
            </div>

            {isCoupureCN(keepsafe?.detail[0].coupures as Coupure[]) === 'c' 
            ? 
            <CoinsInput />
            :
            <NotesInput />
            }

            <div>
                <AlertDialogAction disabled={barcode === '' || !(calculateTotal('c') > 0 || calculateTotal('n') > 0)} onClick={()=>{saveKeepsafe()}}>{t("order_and_pickup.o_announce_pickup.o_manual.edit_save")}</AlertDialogAction>
                <Button onClick={()=>{setcoupure_input(undefined)}} variant='ghost'>{t("order_and_pickup.o_announce_pickup.o_manual.edit_clear")}</Button>
            </div>
        </AlertDialogContent>
        
    </AlertDialog>
  )
}

export default EditKeepsafe