import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../../../redux'
import { Button } from '../../../../components/ui/button';
import { Download } from 'lucide-react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'
import { splitArrayIntoChunks } from '../../../../lib/utils';
import { Pagination } from '../../../../components/ui/pagination';
import { convertToDate, convertToHHMM } from '../../../../lib/date';
import { format } from 'date-fns';
import ExportTable from '../../../../components/portal/export/ExportTable';
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export';
import useAuth from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Props = {
    stops: Stop[]
}


const StopsTable : React.FC<Props> = ({stops}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("track_and_trace.partner"), t("track_and_trace.customer_nr"), t("track_and_trace.customer_code"), t("track_and_trace.customer"), t("track_and_trace.ride"), t("track_and_trace.route_code"), t("track_and_trace.type"), t("track_and_trace.date_stop"), t("track_and_trace.time_arrived"), t("track_and_trace.time_departure"), t("track_and_trace.executed"), 'Extra stop', t("track_and_trace.error"), t("track_and_trace.catch")]
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

    function returnTypeLabel(type:string) {
        switch (type.toLowerCase()) {
            case 'vpl':
                return t("track_and_trace.vpl")
            case 'oaf':
                return t("track_and_trace.oaf")
            case 'opa':
                return t("track_and_trace.opa")
            case 'rfk':
                return t("track_and_trace.rfk")
            case 'rfc':
                return t("track_and_trace.rfc")
            case 'pri':
                return t("track_and_trace.pri")
            case 'ext':
                return t("track_and_trace.ext")
            default:
                return ''
        }
    }

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>    
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(stops, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'ritid', 'routecode', 'type', 'date', 'tmarrival', 'tmdepart', 'executed', 'extra', 'fault', 'rattrappage'], {partners:partners, customers:customers, relnr:'relnr'})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>{t("track_and_trace.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.ride")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.route_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.type")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.date_stop")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.time_arrived")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.time_departure")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.executed")}</TableHead>
                    <TableHead className='uppercase'>Extra stop</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.error")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.catch")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {stops && splitArrayIntoChunks(stops, 20)[currentPage - 1] && splitArrayIntoChunks(stops, 20)[currentPage - 1].map((stop, index)=>{
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === stop.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === stop.relnr)
                    
                    return (
                    <TableRow className='cursor-pointer' onClick={()=>{navigate(`stop/${stop.id}`, {state:stop})}} key={index}>
                        <TableCell>{partner?.name}</TableCell>
                        <TableCell>{customer?.name}</TableCell>
                        <TableCell>{customer?.clientcode}</TableCell>
                        <TableCell>{stop.ritid}</TableCell>
                        <TableCell>{stop.routecode}</TableCell>
                        <TableCell>{returnTypeLabel(stop.type)}</TableCell>
                        <TableCell>{format(convertToDate(stop.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                        <TableCell>{convertToHHMM(stop.tmarrival)}</TableCell>
                        <TableCell>{convertToHHMM(stop.tmdepart)}</TableCell>
                        <TableCell>{stop.executed ? t("track_and_trace.yes") : t("track_and_trace.no")}</TableCell>
                        <TableCell>{stop.extra ? t("track_and_trace.yes") : t("track_and_trace.no")}</TableCell>
                        <TableCell>{stop.fault}</TableCell>
                        <TableCell>{stop.rattrappage ? t("track_and_trace.yes") : t("track_and_trace.no")}</TableCell>
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>
                
    <Pagination totalItems={stops.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default StopsTable