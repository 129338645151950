import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/ui/button';
import { Label } from '../../../components/ui/label';
import { Input } from '../../../components/ui/input';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import Deliveries from './Filters/Deliveries'
import CollectedKeepsafes from './Filters/CollectedKeepsafes';
import SafepointRetrieves from './Filters/SafepointRetrieves';
import ATME2EManage from './Filters/ATME2EManage';
import ATMDeliveriesManage from './Filters/ATMDeliveriesManage';
import Stops from './Filters/Stops';
import axios, { CancelTokenSource } from 'axios';

import { Toaster, toast } from 'react-hot-toast';

import DeliveriesTable from './Tables/DeliveriesTable';
import CollectedKeepsafesTable from './Tables/CollectedKeepsafesTable';
import SafepointTable from './Tables/SafepointTable';
import AtmE2EManageTable from './Tables/AtmE2EManageTable';
import AtmDeliveriesTable from './Tables/AtmDeliveriesTable';
import StopsTable from './Tables/StopsTable';
import { useDispatch, useSelector } from 'react-redux';
import { addFilter } from '../../../redux/actions';
import { isInterface } from '../../../lib/utils';
import { getFilterDetail } from '../../../lib/selectors';
import { RootState } from '../../../redux';
import { Eye, EyeOff } from 'lucide-react';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import ReactGA from "react-ga4";

export type Categories = 'deliveries' | 'collected_keepsafes' | 'safepoint_retrieves' | 'atm_e2e_manage' | 'atm_deliveries_manage' | 'stops'

const Overview = () => {
  const {t} = useTranslation()
  const {auth} = useAuth()
  const dispatch = useDispatch()
  const filters = useSelector((state:RootState)=>state.filters)

  const [show_filters, setshow_filters] = useState(true);
  const [selected_category, setselected_category] = useState<Categories>();
  const [search_result, setsearch_result] = useState<unknown[] | null>(null);

  const [is_loading, setis_loading] = useState(false);

  const [searchCriteria, setsearchCriteria] = useState<object | null>();

  const [cancelTokenSource, setCancelTokenSource] = useState<CancelTokenSource | null>(null);

  const {data:partnerrights} = useQuery<PartnerRight[] | null>({
    queryKey: [`partnerrights_${auth.data?.partner}`],
    queryFn: async () => {
        try {
            if(!auth.data?.partner){ return null; }
            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}partnerrights`, {
              token: auth.data?.token,
              partnerid: auth.data?.partner,
            })
            if(data.errorcode!==0){throw new Error(data.message)}
            if(!data.object.right){ return null; }
            return data.object.right
        } catch (error) {
            console.log(error)
            return null
        }
    },
    enabled: !!auth.data
  })

  useEffect(()=>{
    const sc = getFilterDetail(filters, 'tt', 'selected_category');
    if(isInterface(sc, {} as FilterDetail)){
      setselected_category(sc.value as Categories)
    }
  }, [])

  const cancelRequest = () => {
    if (cancelTokenSource) {
      cancelTokenSource.cancel('Request canceled');
    }
  };

  const searchOrders = async (object:object, category:Categories) => {
    try {
      setis_loading(true);
      setsearch_result(null)

      const source = axios.CancelToken.source();
      setCancelTokenSource(source);

      let url = '';
      switch (category) {
        case 'deliveries':
          url = `${process.env.REACT_APP_BASE_URL}order/search`
          break;
        case 'collected_keepsafes':
          url = `${process.env.REACT_APP_BASE_URL}collection/search`
          break;
        case 'safepoint_retrieves':
          url = `${process.env.REACT_APP_BASE_URL}safepoint/search`
          break;
        case 'atm_e2e_manage':
          url = `${process.env.REACT_APP_BASE_URL}e2e/search`
          break;
        case 'atm_deliveries_manage':
          url = `${process.env.REACT_APP_BASE_URL}atm2t/search`
          break;
        case 'stops':
          url = `${process.env.REACT_APP_BASE_URL}stop/search`
          break;
        default:
          break;
      }

      dispatch(addFilter('tt', { key:'selected_category', value:selected_category || '' }))
      dispatch(addFilter('tt', { key:'search_criteria', value: JSON.stringify(searchCriteria) }))

      const { data } = await axios.post(`${url}`, object, {cancelToken:source.token})
      if(data.errorcode === 0){
        if(Array.isArray(data.object[Object.keys(data.object)[0]])){
          setsearch_result(data.object[Object.keys(data.object)[0]])
        }
        else {
          toast.error(t("track_and_trace.toast_no_found"))
        }
        ReactGA.event({
          category: 'Track Trace',
          action: 'tt_search_success',
        })
      }
      else {
        toast.error(`Error [${data.errorcode}] - ${data.message}`)
        ReactGA.event({
          category: 'Track Trace',
          action: 'tt_search_failed',
        })
      }

    } catch (error) {
      if(axios.isCancel(error)){
        toast.success(t("track_and_trace.toast_success"))
      }
      else {
        toast.error(t("track_and_trace.toast_wrong"))
        console.log(error)
      }
    } finally {
      setis_loading(false);
      setCancelTokenSource(null);
    }
  }

  useEffect(()=>{
    if(searchCriteria && selected_category){
      searchOrders(searchCriteria, selected_category)
    }
  }, [searchCriteria])

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("track_and_trace.title")}</h1>
        </div> 

        <div className='shadow-sm border rounded-sm mt-4 md:mt-8'>
          <div className='p-4 flex justify-between'>
              <h2 className='text-lg'>{t("track_and_trace.subtitle")}</h2>
              <Button onClick={()=>{ setshow_filters(prev => !prev) }} variant="ghost">{show_filters ? <Eye className='w-5 text-neutral-500'/> : <EyeOff className='w-5 text-neutral-500'/>}</Button>
          </div>          

          <div className={`${show_filters ? 'max-h-screen p-4 pt-0' : 'max-h-0 px-4 py-0'} overflow-hidden duration-300 grid grid-cols-3 gap-4`}>
              <div className='col-span-full'>
                  <Label htmlFor='category'>{t("track_and_trace.category")}</Label>
                  <Select disabled={is_loading} value={selected_category} onValueChange={(value) => { setsearch_result(null); setselected_category(value as Categories) }}>
                      <SelectTrigger>
                          <SelectValue placeholder={t("track_and_trace.category_placeholder")}/>
                      </SelectTrigger>
                      <SelectContent>
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttdelivery)) && auth.data?.right.ttdelivery && <SelectItem value={'deliveries'}>{t("track_and_trace.deliveries")}</SelectItem>}
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttcollection)) && auth.data?.right.ttcollection && <SelectItem value={'collected_keepsafes'}>{t("track_and_trace.collected_keepsafes")}</SelectItem>}
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttsafepoint)) && auth.data?.right.ttsafepoint && <SelectItem value={'safepoint_retrieves'}>{t("track_and_trace.safepoint_collections")}</SelectItem>}
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttatm2t)) && auth.data?.right.ttatm2t && <SelectItem value={'atm_e2e_manage'}>{t("track_and_trace.atm_e2e")}</SelectItem>}
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttatme2e)) && auth.data?.right.ttatme2e && <SelectItem value={'atm_deliveries_manage'}>{t("track_and_trace.atm_deliveries")}</SelectItem>}
                        {((auth.data?.partner==='') || (partnerrights && partnerrights[0].ttstops)) && auth.data?.right.ttstops && <SelectItem value={'stops'}>{t("track_and_trace.stops")}</SelectItem>}         
                      </SelectContent>
                  </Select>
              </div>

              {selected_category === 'deliveries' &&
              <Deliveries cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }

              {selected_category === 'collected_keepsafes' &&
              <CollectedKeepsafes cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }

              {selected_category === 'safepoint_retrieves' &&
              <SafepointRetrieves cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }

              {selected_category === 'atm_e2e_manage' &&
              <ATME2EManage cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }

              {selected_category === 'atm_deliveries_manage' &&
              <ATMDeliveriesManage cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }

              {selected_category === 'stops' &&
              <Stops cancelRequest={cancelRequest} onStartSearch={(value)=>{ setsearchCriteria(value) }} loading={is_loading}/>
              }
          </div>
        </div>

        {(selected_category === 'deliveries' && search_result) &&
          <DeliveriesTable orders={search_result as Order[]}/>
        }
          
        {(selected_category === 'collected_keepsafes' && search_result) &&
          <CollectedKeepsafesTable keepsafes={search_result as Keepsafe[]}/>
        }

        {(selected_category === 'safepoint_retrieves' && search_result) &&
          <SafepointTable safepoints={search_result as Safepoint[]}/>
        }

        {(selected_category === 'atm_e2e_manage' && search_result) &&
          <AtmE2EManageTable e2e={search_result as E2E[]}/>
        }

        {(selected_category === 'atm_deliveries_manage' && search_result) &&
          <AtmDeliveriesTable orders={search_result as Atm2T[]}/>
        }

        {(selected_category === 'stops' && search_result) &&
          <StopsTable stops={search_result as Stop[]} />
        }

    </div>
  </>
  )
}

export default Overview