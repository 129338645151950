import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'
import { convertToDate, convertToHHMM } from '../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type Props = {
  history: HistoryProp[]
}

const History : React.FC<Props> = ({history}) => {
  const {t} = useTranslation()
  return (
    <Table>
      <TableHeader className='bg-slate-100'>
        <TableRow>
          <TableHead>{t("components.o_history.date")}</TableHead>
          <TableHead>{t("components.o_history.time")}</TableHead>
          <TableHead>{t("components.o_history.vault")}</TableHead>
          <TableHead>{t("components.o_history.execution")}</TableHead>
          <TableHead>{t("components.o_history.extra")}</TableHead>
          <TableHead>{t("components.o_history.old")}</TableHead>
          <TableHead>{t("components.o_history.new")}</TableHead>
          <TableHead>{t("components.o_history.reason")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {history && history.map((h, i) => {
          return (
            <TableRow key={i}>
              <TableCell className='whitespace-nowrap'>{format(convertToDate(h.dtcreation) || new Date(), "dd/MM/yyyy")}</TableCell>
              <TableCell>{convertToHHMM(h.tmcreation)}</TableCell>
              <TableCell>{h.vault}</TableCell>
              <TableCell>{h.function}</TableCell>
              <TableCell>{h.extrainfo}</TableCell>
              <TableCell>{h.value_old}</TableCell>
              <TableCell>{h.value}</TableCell>
              <TableCell>{h.reason_onhold}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default History