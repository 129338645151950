import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { isInterface } from '../../../../../lib/utils'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { useQuery } from '@tanstack/react-query'
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { convertToDate } from '../../../../../lib/date'
import { format } from 'date-fns'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { useTranslation } from 'react-i18next'

const Detail = () => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { consumable } = useParams()

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    const [articles, setarticles] = useState<Article[] | StateType>(null);

    const {data:detail, isFetching:isFetching_detail} = useQuery<ConsumableOrder | null>({
        queryKey: [`consumable_${consumable}`],
        queryFn: async () => {
          try {
            const {data} = await axios.post(`consumable/search`, {
              token: auth.data?.token,
              id: consumable
            })
            if(data.errorcode!==0){throw new Error(data.message)}
            if(!data.object.consumableorder){ toast.error(t("order_and_pickup.o_overview_consumables.toast_no_found_p2")); return null; }
            return data.object.consumableorder[0]
          } catch (error) {
            return null
          }
        }
    })

    useEffect(()=>{
        if(!detail){
          const instance = lottie.loadAnimation({
            container: errorContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: errorAnimation
          })
          return () => instance.destroy()
        }
        else {
            getArticles()
        }
    }, [detail])

    const getArticles = async () => {
        try {
            setarticles('loading')
            const arts = detail?.articles;
            if(arts){
                let articlesArr:Article[] = []
                for (let i = 0; i < arts.length; i++) {
                    const {data} = await axios.post(`article/search`, { token:auth.data?.token, articleid:arts[i].articleid })
                    if(data.errorcode===0){
                        articlesArr.push(data.object.article[0])
                    }
                }
                setarticles(articlesArr)
            }
            else { setarticles('empty') }
        } catch (error) {
            toast.error(t("order_and_pickup.o_overview_consumables.toast_no_found_p3"))
            setarticles('error')
        }
    }

  return (
    <>
    <Toaster /> 

    {((isFetching_detail) && (!isInterface(detail, {} as AnnouncemenDetail[]))) && 
    <LoadingScreen />
    }

    {(detail === null) && 
    <div className={`flex flex-col items-center justify-center`}>
      <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
      <h2 className='text-2xl font-semibold mb-4'>{t("order_and_pickup.o_overview_consumables.error_title")}</h2>
      <p className='text-center'>{t("order_and_pickup.o_overview_consumables.error_description")}<br></br>{t("order_and_pickup.o_overview_consumables.error_description_p2")} <Link to='/bestellen-en-ophalen/overzicht-verbruiksartikelen' className='font-medium underline'>{t("order_and_pickup.o_overview_consumables.error_description_p3")}</Link> {t("order_and_pickup.o_overview_consumables.error_description_p4")}</p>
    </div>
    }

    {(isInterface(detail, {} as ConsumableOrder)) && 
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex flex-col'>
            <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_consumables.link_orders")}</p>}, {title:<p onClick={()=>{navigate('/bestellen-en-ophalen/overzicht-verbruiksartikelen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_consumables.link_consumables")}</p>}, {title:`${t("order_and_pickup.o_overview_consumables.link_consumable")} ${consumable}`}]}/>
            <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_overview_consumables.detail_title")}</h1>
        </div>

        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            <h2 className='text-lg'>{t("order_and_pickup.o_overview_consumables.detail_subtitle")}</h2>
            <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.partner_nr")}</p>
                <p className='text-neutral-500'>{detail.partnerid}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.partner")}</p>
                <p className='text-neutral-500'>{detail.partner}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.relation_nr")}</p>
                <p className='text-neutral-500'>{detail.relnr}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.customer_p2")}</p>
                <p className='text-neutral-500'>{detail.relna}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.customer_code")}</p>
                <p className='text-neutral-500'>{detail.customercode}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.created")}</p>
                <p className='text-neutral-500'>{format(convertToDate(detail.dtcreated) || new Date(), "dd/MM/yyyy")}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("order_and_pickup.o_overview_consumables.delivery")}</p>
                <p className='text-neutral-500'>{format(convertToDate(detail.dtdelivery) || new Date(), "dd/MM/yyyy")}</p>
            </div>
            </div>
        </div>

        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            {articles === 'loading' && <p>{t("order_and_pickup.o_overview_consumables.loading")}</p>}
            {articles === 'empty' && <p>{t("order_and_pickup.o_overview_consumables.no_found")}</p>}
            {articles === 'error' && <p>{t("order_and_pickup.o_overview_consumables.error")}</p>}
            {isInterface(articles, {} as Article[]) && 
            <div className='flex flex-col gap-2'>
            {articles.map((article, index) => {    
                return (
                    <div key={index} className='flex gap-4 items-center'>
                        <img src={`https://myloomis.loomis.be/assets/images/consumables/${article.photo}`} className='w-10' alt="article"/>
                        <p>{article.name}</p>
                        <p>{t("order_and_pickup.o_overview_consumables.amount_loose")} {article.packedby / article.losperdefault} (x {article.losperdefault})</p>
                    </div>
                )
            })}
            </div>
            }
        </div>
    </div>
    }
    </>
  )
}

export default Detail