import { forwardRef, useEffect, useState } from "react";
import * as Radix from "@radix-ui/react-primitive";
import { Primitive } from "@radix-ui/react-primitive";

type CheckboxIconProps = {
    isSelected: boolean;
    disableAnimation?: boolean;
    isIndeterminate?: boolean;
}

function CheckIcon(props:CheckboxIconProps) {
    const {isSelected, disableAnimation, ...otherProps} = props;
  
    return (
      <svg className="w-3 z-[1]" aria-hidden="true" role="presentation" viewBox="0 0 17 18" {...otherProps}>
        <polyline
          fill="none"
          points="1 9 7 14 15 4"
          className="stroke-white"
          strokeDasharray={22}
          strokeDashoffset={isSelected ? 44 : 66}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          style={
            !disableAnimation && isSelected
              ? {
                  transition: "stroke-dashoffset 250ms linear 0.2s",
                }
              : {}
          }
        />
      </svg>
    );
}

function IndeterminateIcon(props: CheckboxIconProps) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const {isSelected, disableAnimation, ...otherProps} = props;
  
    return (
      <svg className="stroke-white" strokeWidth={3} viewBox="0 0 24 24" {...otherProps}>
        <line x1="21" x2="3" y1="12" y2="12" />
      </svg>
    );
  }

export function CheckboxIcon(props: CheckboxIconProps) {
    const {isIndeterminate, ...otherProps} = props;
    const BaseIcon = isIndeterminate ? IndeterminateIcon : CheckIcon;
  
    return <BaseIcon {...otherProps} />;
}

type PrimitiveButtonProps = Radix.ComponentPropsWithoutRef<typeof Primitive.button>;
type CheckedState = boolean;

interface CheckboxProps extends Omit<PrimitiveButtonProps, 'checked' | 'defaultChecked'>  {
    checked?: CheckedState;
    defaultChecked?: CheckedState;
    required?: boolean;
    onCheckedChange?(checked: CheckedState): void;
};
  
const NewCheckbox = forwardRef<HTMLButtonElement, CheckboxProps>(({onCheckedChange, ...props}, ref) => {
    const [checked, setchecked] = useState<CheckedState>(props.checked || false);

    useEffect(()=>{
      if(checked !== props.checked){ onCheckedChange && onCheckedChange(checked) }
    }, [checked])

    useEffect(()=>{
      if(typeof(props.checked) === 'boolean') setchecked(props.checked)
    }, [props.checked])

    return (
        <button 
            ref={ref}
            onClick={()=>{setchecked(prev=>!prev)}} 
            className={`relative flex justify-center items-center duration-200 peer h-4 w-4 shrink-0 rounded-sm border border-primary ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=checked]:text-primary-foreground`} 
            {...props}
        >   
            <div className={`${checked ? 'scale-100 opacity-100 rounded-none' : 'scale-0 opacity-0 rounded-full'} duration-200 absolute w-full h-full bg-primary z-[0]`}></div>
            <CheckboxIcon isSelected={checked}/>
        </button>
    );
})

export default NewCheckbox