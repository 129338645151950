import { addDays, addMonths, endOfMonth, endOfWeek, format, isBefore, isSameDay, isSameMonth, isToday, startOfMonth, startOfWeek, subMonths } from 'date-fns';
import { ChevronLeft, ChevronRight, Palmtree, Trash, Trash2, X } from 'lucide-react';
import React, { useState } from 'react'

import smallCoinAndNote from '../../../assets/images/smallCoinAndNote.png'
import smallCoin from '../../../assets/images/smallCoin.png'
import smallNote from '../../../assets/images/smallNote.png'
import { EventProps } from 'react-big-calendar';
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date';
import { Button } from '../../ui/button';
import { isInterface } from '../../../lib/utils';
import { DayObject } from '../stop/calendar/Calendar';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog';
import { Label } from '../../ui/label';
import { Textarea } from '../../ui/textarea';
import TooltipForce from '../../ui/tooltip-force';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../ui/tooltip';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import axios from '../../../lib/axios';
import useAuth from '../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RadioGroup, RadioGroupItem } from '../../ui/radio-group';

type Props = {
    events?: PlanningEvent[] | StateType,
    holiday?: Holiday[] | null,
    selected_customer?: string,

    setcurrentMonth: React.Dispatch<React.SetStateAction<Date>>,
    currentMonth: Date,
    setselectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    selectedDate: Date | undefined,

    onSelectDate: (date:any) => void;
}

const PlanningCalendar : React.FC<Props> = ({events, holiday, selected_customer, setcurrentMonth, currentMonth, setselectedDate, selectedDate, onSelectDate}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    
    const Header = () => {
        const dateFormat = "MMMM yyyy"

        function prevMonth() {
            setcurrentMonth(subMonths(currentMonth, 1))
        }

        function nxtMonth() {
            setcurrentMonth(addMonths(currentMonth, 1))
        }

        return (
            <div className='grid grid-cols-3'>
                <div className='flex gap-1 items-center'>
                    <button onClick={prevMonth} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronLeft className='w-4'/></button>
                    <button onClick={nxtMonth} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronRight className='w-4' /></button>
                </div>
                <div className='flex justify-center items-center capitalize font-semibold'>
                    <p>{format(currentMonth, dateFormat)}</p>
                </div>
                <div className='flex justify-end items-center'>
                    <Button onClick={()=>{setcurrentMonth(new Date())}} variant='link'>{t("components.o_planning.today")}</Button>
                </div>
            </div>
        )
    }
    
    const Days = () => {
        const dateFormat = "EEEE";
        const days = []
        let startDate = startOfWeek(currentMonth)

        for (let i = 0; i < 7; i++) {
            days.push({
                weekday: format(addDays(startDate, i), dateFormat)
            })
        }

        return (
            <div className='grid grid-cols-7 mt-4'>
                {days.map((day, index) => {
                    return (
                        <div className='border capitalize flex justify-center text-sm text-neutral-500 py-4 first:rounded-tl-sm last:rounded-tr-sm' key={index}>{day.weekday}</div>
                    )
                })}
            </div>
        )
    }

    const Cells = () => {
        const dateFormat = "d";

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = []

        let days:DayObject[] = []
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {

                let isSameDayVar = false;
                if(selectedDate){
                    isSameDayVar = isSameDay(selectedDate, day)
                }

                days.push({
                    isSameMonth: isSameMonth(day, monthStart),
                    isToday: isToday(day),
                    isSelected: isSameDayVar,
                    isBeforeToday: isBefore(day, new Date()),
                    day: format(day, dateFormat),
                    date: day,
                    events: searchEvents(day)
                })
                day = addDays(day, 1)
            }
            rows.push(days);
            days = []
        }

        const EventContainer = ({day}:{day:DayObject}) => {
            const {auth} = useAuth();
            const [reason, setreason] = useState('');
            const [selected_cn, setselected_cn] = useState(day.events[0].cn.toLowerCase() || '');

            const {refetch:cancelTransport} = useQuery({
                queryFn: async () => {
                    try {
                        toast.loading(t("components.o_planning.toast_loading"), {id:'deleteStop'})

                        const {data} = await axios.post(`transport/cancel`, {
                            token: auth.data?.token,
                            date: formatDateToYYYYMMDD(day.date),
                            relnr: selected_customer,
                            remark: reason,
                            recipient: '', //Dient voor testdoeleinden -> vul hier een e-mailadres om te testen
                            cn: selected_cn
                        })
                        if(data.errorcode!==0){throw new Error(data.message)}

                        toast.success(t("components.o_planning.toast_success"), {id:'deleteStop'})

                        navigate(-1)
                    } catch (error) {
                        console.log(error);
                        toast.error(t("components.o_planning.toast_error"), {id:'deleteStop'})
                    }
                },
                enabled: false
            })

            return (
                <div className={`${!day.isBeforeToday ? 'bg-red-50 border-red-200': 'bg-neutral-50 border-neutral-200'}  border rounded-sm flex items-center justify-between h-8 px-1`}>
                    {day.events.map(i=>i.cn).sort().join('').toLowerCase() === 'cn' && <img className='w-6' src={smallCoinAndNote} alt="coin and note" />}
                    {day.events.map(i=>i.cn).sort().join('').toLowerCase() === 'n' && <img className='w-6' src={smallNote} alt="note" />}
                    {day.events.map(i=>i.cn).sort().join('').toLowerCase() === 'c' && <img className='w-6' src={smallCoin} alt="coin" />}
                    
                    {(!day.isBeforeToday && auth.data?.right.transportcancel) && 
                    <AlertDialog>
                        <AlertDialogTrigger asChild>
                            <TooltipForce content={<p className='whitespace-nowrap'>{t("components.o_planning.tooltip_cancel")}</p>}>
                                <button className='text-red-500 flex justify-end items-center opacity-50 hover:opacity-100'><Trash2 className='w-4'/></button>
                            </TooltipForce>
                        </AlertDialogTrigger>
                        <AlertDialogContent>
                            <p>{t("components.o_planning.alert_title")}</p>

                            <div>
                                <RadioGroup value={selected_cn} onValueChange={(v)=>{setselected_cn(v)}} orientation='horizontal' className='flex items-center gap-4'>
                                    {day.events.map((i)=>{
                                        return (
                                            <div className='flex items-center gap-1'>
                                                <RadioGroupItem value={i.cn.toLowerCase()} id={i.cn}></RadioGroupItem>
                                                <Label htmlFor={i.cn}>{i.cn.toLowerCase() === 'c' ? t('planning.o_add.coins') : t('planning.o_add.notes') }</Label>
                                            </div>
                                        )
                                    })}
                                </RadioGroup>
                            </div>

                            <div>
                                <Label htmlFor='reason'>{t("components.o_planning.label_reason")}</Label>
                                <Textarea value={reason} onChange={({currentTarget})=>{setreason(currentTarget.value)}} id='reason' className='resize-none'/>
                            </div>
                            <div className='flex justify-between'>
                                <AlertDialogCancel>{t("components.o_planning.button_close")}</AlertDialogCancel>
                                <AlertDialogAction onClick={()=>{cancelTransport()}} disabled={reason.length === 0}>{t("components.o_planning.button_send")}</AlertDialogAction>
                            </div>                        
                        </AlertDialogContent>
                    </AlertDialog>
                    }
                </div>
            )
        }

        return (
            <div>
                {rows.map((row, ind) => {
                    return (
                        <div className='grid grid-cols-7 min-h-[7rem]' key={ind}>
                            {row.map((day, i) => {
                                return (
                                    <div key={i} onClick={()=>{ onSelectDate(day) }} className={`${day.isToday && 'border-primary'} ${!day.isSameMonth && 'bg-slate-50'} flex flex-col border p-1`}>
                                        <div className='flex justify-end w-full'><div className={`${day.isSelected && '!bg-primary !text-white'} ${day.isToday && 'text-primary'} ${!day.isSameMonth && 'text-neutral-400'} w-6 h-6`}><p className='text-md text-right'>{day.day}</p></div> </div>                                   
                                        
                                        {holiday?.find(i=>isSameDay(convertToDate(i.date) || new Date(), day.date)) 
                                        && 
                                        <div className='bg-blue-50 border-blue-200 border rounded-sm flex gap-1 items-center justify-between h-8 px-1'>
                                            <Palmtree className='text-blue-500 w-5 min-w-[20px]'/>
                                            <TooltipProvider delayDuration={0}>
                                                <Tooltip>
                                                    <TooltipTrigger asChild><p className='text-sm font-medium whitespace-nowrap text-ellipsis overflow-hidden'>{holiday?.find(i=>isSameDay(convertToDate(i.date) || new Date(), day.date))?.description}</p></TooltipTrigger>
                                                
                                                    <TooltipContent>
                                                        <p className='text-sm font-medium whitespace-nowrap'>{holiday?.find(i=>isSameDay(convertToDate(i.date) || new Date(), day.date))?.description}</p>
                                                    </TooltipContent>
                                                </Tooltip>
                                            </TooltipProvider>
                                            
                                        </div>
                                        }
                                        <>
                                        {day.events.length > 0 && 
                                            <EventContainer day={day}/>
                                        }
                                        </>                
                                    </div>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    const searchEvents = (day:Date) => {
        const ev:PlanningEvent[] = [];

        if(isInterface(events, {} as PlanningEvent[])){
            events.forEach(event => {
                if(isSameDay(convertToDate(event.date) || new Date(1970,1,1), day) ){
                    ev.push(event)
                }
            });
        }

        return ev;
    }

  return (
    <div className='p-4'>
        <Header />
        <Days />
        <Cells />
    </div>
  )
}

export default PlanningCalendar