import React from 'react'
import { Button } from '../../../components/ui/button'
import { Link, Route, Routes } from 'react-router-dom'
import MoveRight from '../../../assets/icons/MoveRight'
import Main from './Main'
import MessageOverview from './MessageOverview/MessageOverview'

const MessageCenter = () => {
  return (
    <Routes>
        <Route path="" element={<Main />}/>
        <Route path="berichtenoverzicht" element={<MessageOverview />}/>
    </Routes>
  )
}

export default MessageCenter