import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { Input } from '../../../../components/ui/input'
import { DatePicker } from '../../../../components/ui/datepicker'
import { Button } from '../../../../components/ui/button'
import { subWeeks } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import ConsumablesTable from './tables/ConsumablesTable'
import WindowsDatePicker from '../../../../components/ui/windows-datepicker'
import { getCustomerList } from '../../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'

const Main = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const navigate = useNavigate()
    const partners = useSelector((state:RootState)=>state.partners);

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);

    const [selected_clientcode, setselected_clientcode] = useState<string>('');
    const [selected_startdate, setselected_startdate] = useState<Date>(subWeeks(new Date(), 2));
    const [selected_enddate, setselected_enddate] = useState<Date>(new Date());

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    useEffect(()=>{
      if (auth.data?.partner.split(",").length === 1) {
        handlePickPartner(auth.data.partner.split(",")[0])
      }
    }, [])

    const handlePickPartner = async (relnr:string) => {
        try {
          if(selected_partner!==relnr){setselected_customer('clear')}
          setselected_partner(relnr);
          setcustomers_list(null);

          const list = await getCustomerList(auth, relnr)
          if(list){ setcustomers_list(list) }
          if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
          
        } catch (error) {
          console.log(error)
          toast.error(t("order_and_pickup.o_overview_consumables.toast_wrong"))
        }
    }

    const {data:consumables, refetch, isFetching} = useQuery<ConsumableOrder[] | null>({
        queryKey:['consumables'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`consumable/search`, {
                    token: auth.data?.token,
                    partner: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : '',
                    relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
                    clientcode: selected_clientcode,
                    startdate: formatDateToYYYYMMDD(selected_startdate),
                    enddate: formatDateToYYYYMMDD(selected_enddate),
                    searchby: 'C'
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.consumableorder){ toast.error(t("order_and_pickup.o_overview_consumables.toast_no_found")); return null; }
                return data.object.consumableorder
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_overview_consumables.toast_error"))
                return null;
            }
        },
        enabled: false
    })

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>
      <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_consumables.link_orders")}</p>}, {title:t("order_and_pickup.o_overview_consumables.link_consumables")}]}/>
      <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_overview_consumables.title")}</h1>

      <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
          <div>
              <Label htmlFor='partner'>{t("order_and_pickup.o_overview_consumables.partner")}</Label>
              <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("order_and_pickup.o_overview_consumables.partner_placeholder") || ""} input_placeholder={t("order_and_pickup.o_overview_consumables.search_placeholder") || ""}/>
          </div>

          <div>
              <Label htmlFor='end_customer'>{t("order_and_pickup.o_overview_consumables.customer")}</Label>
              <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("order_and_pickup.o_overview_consumables.customer_placeholder") || ""} input_placeholder={t("order_and_pickup.o_overview_consumables.search_placeholder") || ""}/>
          </div>

          <div>
              <Label htmlFor='clientcode'>{t("order_and_pickup.o_overview_consumables.customer_code")}</Label>
              <Input value={selected_clientcode} onChange={({currentTarget})=>setselected_clientcode(currentTarget.value)} id='clientcode'/>
          </div>

          <div>
              <Label htmlFor='startdate'>{t("order_and_pickup.o_overview_consumables.start_date")}</Label>
              <WindowsDatePicker value={selected_startdate} onDateChange={(date)=>{if(date){setselected_startdate(date)}}} id='startdate' placeholder='Kies een datum'/>
          </div>

          <div>
              <Label htmlFor='enddate'>{t("order_and_pickup.o_overview_consumables.end_date")}</Label>
              <WindowsDatePicker value={selected_enddate} onDateChange={(date)=>{if(date){setselected_enddate(date)}}} id='enddate' placeholder='Kies een datum'/>
          </div>
        </div>
        <Button onClick={()=>{refetch()}} disabled={isFetching} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("order_and_pickup.o_overview_consumables.button")}</Button>
      </div>

      {consumables &&
      <ConsumablesTable consumables={consumables}/> 
      }
    </div>
    </>
  )
}

export default Main