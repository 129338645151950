import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import toast, { Toaster } from 'react-hot-toast'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import useAuth from '../../../../hooks/useAuth'
import { Input } from '../../../../components/ui/input'
import { Button } from '../../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import axios from '../../../../lib/axios'
import PartnerRightsTable from './tables/PartnerRightsTable'
import Detail from './partner/Detail'
import { useTranslation } from 'react-i18next'

const PartnerSettings = () => {
  const navigate = useNavigate()
  const {auth} = useAuth()
  const {t} = useTranslation()
  const partners = useSelector((state:RootState)=>state.partners);

  const [selected_partner, setselected_partner] = useState<string | null>(null);
  const [updater, setupdater] = useState<boolean>(false);

  useEffect(()=>{
    if(auth.data){
      setselected_partner(auth.data.partner.split(",")[0])
    }
  }, [])

  const {data:partner_rights, refetch, isFetching} = useQuery<PartnerRight[] | null>({
    queryKey: [`partnerrights_settings_${selected_partner}`],
    queryFn: async () => {
      try {
        if(!Array.isArray(partners) || partners.length===0){ return null; }
        const {data} = await axios.post(`partnerrights`, {
          token: auth.data?.token,
          partnerid: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : ''
        })
        if(data.errorcode!==0){throw new Error(data.message)}
        if(!data.object.right){ 
          // No rights found
          return [
            {
              id:                       selected_partner ? parseFloat(selected_partner) : 1,
              partnerid:                selected_partner ? parseFloat(selected_partner) : 0,
              order:                    false,
              announce:                 false,
              ttdelivery:               false,
              ttcollection:             false,
              ttsafepoint:              false,
              ttatm2t:                  false,
              ttatme2e:                 false,
              ttstops:                  false,
              edoc:                     false,
              complaint:                false,
              requestresearch:          false,
              consumables:              false,
              privatetransport:         false,
              quoteprivatetransport:    false,
              foreign:                  false,
              links:                    false,
              export:                   false,
              message:                  false,
              question:                 false,
              news:                     false,
              faq:                      false,
              regnewuser:               false,
              deleteuser:               false,
              manageusrprofile:         false,
              userlist:                 false,
              log:                      false,
              sipassets:                false,
              sipexecutedinterventions: false,
              sipmanagements:           false,
              sipplanninginterventions: false,
              adminedit:                false,
              pension:                  false,
              transportrequest:         false,
              transportcancel:          false,
            }
          ]
        }
        setupdater(prev=>!prev)
        let res:PartnerRight[] = data.object.right.filter((i:PartnerRight)=>partners.findIndex(p=>p.relnr.toString()===i.partnerid.toString())>-1)

        if(res.length > 1) { 
          // Multiple row table result
          partners.forEach((partner) => {
            const indexInRes = res.findIndex((i)=>i.partnerid===partner.relnr)
            if(indexInRes < 0){
              // Partner not in result
              res.push({
                id:res.length, 
                partnerid:partner.relnr, 
                order:                    false,
                announce:                 false,
                ttdelivery:               false,
                ttcollection:             false,
                ttsafepoint:              false,
                ttatm2t:                  false,
                ttatme2e:                 false,
                ttstops:                  false,
                edoc:                     false,
                complaint:                false,
                requestresearch:          false,
                consumables:              false,
                privatetransport:         false,
                quoteprivatetransport:    false,
                foreign:                  false,
                links:                    false,
                export:                   false,
                message:                  false,
                question:                 false,
                news:                     false,
                faq:                      false,
                regnewuser:               false,
                deleteuser:               false,
                manageusrprofile:         false,
                userlist:                 false,
                log:                      false,
                sipassets:                false,
                sipexecutedinterventions: false,
                sipmanagements:           false,
                sipplanninginterventions: false,
                adminedit:                false,
                pension:                  false,
                transportrequest:         false,
                transportcancel:          false,
              })
            }
          })
        }

        return res
      } catch (error) {
        console.log(error);
        toast.error(t("settings.toast_error_p2"), {id:'partnerRights'})
        return null;
      }
    },
    enabled: false
  })

  let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:`${item.name} - ${item.relnr}`}))) : []
  partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

  return (
    <>
      <Routes>
        
        <Route path=":partnerid" element={<Detail />}/>
        <Route path="" element={
          <>
          <Toaster />
          <div className='overflow-auto relative w-full p-4 md:p-8'>
            <Breadcrumb items={[{title:<p onClick={()=>{navigate('/instellingen')}} className='cursor-pointer'>{t("settings.title")}</p>}, {title:`${t("settings.settings")}`}]}/>
            <h1 className='text-2xl font-semibold'>{t("settings.settings")}</h1>
    
            <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
              <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                <div>
                  <Label htmlFor='partner'>{t("settings.partner")}</Label>
                  <Combobox id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { setselected_partner(value.value.toString()) }} options={partnersArr} placeholder={t("settings.partner_placeholder")} input_placeholder={t("settings.search_placeholder")}/>
                </div>
                {/* <div>
                  <Label htmlFor='partnername'>(Deel van) partnernaam</Label>
                  <Input value={selected_partnername} onChange={({currentTarget})=>{setselected_partnername(currentTarget.value)}} id='partnername'/>
                </div> */}
              </div>
    
              <Button onClick={()=>{refetch()}} disabled={isFetching || (!Array.isArray(partners) || partners.length===0)} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("settings.search")}</Button>
            </div>
    
            {partner_rights && 
            <PartnerRightsTable updater={updater} partnerrights={partner_rights} callRefetch={()=>{ refetch() }}/>
            }
          </div>
          </>
        }/>
      </Routes>
    </>
  )
}

export default PartnerSettings