import React, { HTMLAttributes, useState } from 'react'
import { Table, TableHeader, TableRow, TableHead, TableCell, TableBody, ColumnSort } from '../../../../components/ui/table'
import { format } from 'date-fns'
import { convertToDate } from '../../../../lib/date'
import usePartnerStore from '../../../../zustand/usePartnerStore'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { useNavigate } from 'react-router-dom'
import { formatCashAmount } from '../../../../lib/utils'
import useTable from '../../../../hooks/useTable'
import { ChevronsUpDown } from 'lucide-react'
import ExportTable from '../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export'
import useAuth from '../../../../hooks/useAuth'
import { Input } from '../../../../components/ui/input'
import { useTranslation } from 'react-i18next'

type Props = {
    collis: Colli[]
}


const KeepsafeTable : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({collis, className, ...props}) => {
    const {t} = useTranslation()
    let headerArr = [t("track_and_trace.o_number.barcode"), t("track_and_trace.o_number.mkpsafe"), t("track_and_trace.o_number.type"), t("track_and_trace.o_number.amount"), t("track_and_trace.o_number.department"), t("track_and_trace.o_number.date_created"), t("track_and_trace.o_number.date_collected"), t("track_and_trace.o_number.date_edit"), t("track_and_trace.o_number.date_archived"), t("track_and_trace.o_number.partner"), t("track_and_trace.o_number.customer")]
    const {auth} = useAuth()
    const navigate = useNavigate()

    const partners = useSelector((state:RootState) => state.partners)
    const customers = useSelector((state:RootState) => state.customers)

    const {data, sorting, filter, updateFilter, updateSorting} = useTable({data:collis, sorting:{desc:false, id:'barcode'}})

    const tableHeadClass = 'flex items-center gap-1 group'

  return (
    <>
    {auth.data?.right.export && 
    <div className='mt-4 flex justify-between'>
        <Input onChange={({currentTarget})=>{updateFilter(currentTarget.value)}} placeholder={t("track_and_trace.o_number.filter_placeholder")}/>
        <ExportTable onDownload={(extension)=>{ createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(collis, ['barcode', 'isMkpsafe', 'cn', 'amount', 'department', 'dtcreation', 'dtpickup', 'dtmodif', 'dtarchive', 'partnername', 'customername'], {partners:partners, customers:customers, relnr:'relnr'}))) }}/>
    </div>
    }

    <div className='mt-4 border rounded-sm'>
        <Table className={className} {...props}>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.barcode")}</div>   
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.mkpsafe")}</div>
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.type")}</div>               
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'amount', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>{t("track_and_trace.o_number.amount")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div>        
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.department")}</div>    
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtcreation', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>{t("track_and_trace.o_number.date_created")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div>            
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtpickup', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>{t("track_and_trace.o_number.date_collected")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div>
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtmodif', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>{t("track_and_trace.o_number.date_edit")} <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/></div>       
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={`${tableHeadClass}`}>{t("track_and_trace.o_number.date_archived")}</div>           
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.partner")}</div>    
                    </TableHead>
                    <TableHead className='uppercase'>
                        <div className={tableHeadClass}>{t("track_and_trace.o_number.customer")}</div>    
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data && data.map((colli, index) => {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === colli.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === colli.relnr)
                    
                    return (
                        <TableRow onClick={()=>{navigate(colli.id.toString())}} className='relative cursor-pointer' key={index}>
                            <TableCell>{colli.barcode}</TableCell>
                            <TableCell>{colli.isMkpsafe ? t("track_and_trace.o_number.yes") : t("track_and_trace.o_number.no")}</TableCell>
                            <TableCell>{colli.cn.toLowerCase() === 'c' ? t("track_and_trace.o_number.coins") : t("track_and_trace.o_number.notes")}</TableCell>
                            <TableCell>{formatCashAmount(colli.amount)}</TableCell>
                            <TableCell>{colli.department}</TableCell>
                            <TableCell>{convertToDate(colli.dtcreation.toString()) ? format(convertToDate(colli.dtcreation.toString()) || new Date(), "dd/MM/yyyy") : 'Invalid date'}</TableCell>
                            <TableCell>{(convertToDate(colli.dtpickup.toString()) && colli.dtpickup.toString() !== '0000-00-00') ? format(convertToDate(colli.dtpickup.toString()) || new Date(), "dd/MM/yyyy") : 'n.v.t'}</TableCell>
                            <TableCell>{(convertToDate(colli.dtmodif.toString()) && colli.dtmodif.toString() !== '0000-00-00') ? format(convertToDate(colli.dtmodif.toString()) || new Date(), "dd/MM/yyyy") : 'n.v.t'}</TableCell>
                            <TableCell>{(convertToDate(colli.dtarchive.toString()) && colli.dtarchive.toString() !== '0000-00-00') ? format(convertToDate(colli.dtarchive.toString()) || new Date(), "dd/MM/yyyy") : 'n.v.t'}</TableCell>              
                            <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                            <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>
    </>
  )
}

export default KeepsafeTable