import React, { useState } from 'react'
import lodash from 'lodash'
import NewCheckbox from '../../../../../components/ui/new-checkbox'
import { Label } from '../../../../../components/ui/label'
import { Button } from '../../../../../components/ui/button'
import { Loader2, Save } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
    rights: UserRight
    refetchRights: () => void
}

const Rights : React.FC<Props> = ({rights, refetchRights}) => {
    const { username } = useParams()
    const {auth} = useAuth()
    const { t } = useTranslation()

    const [new_rights, setnew_rights] = useState(lodash.cloneDeep(rights));

    const {isFetching, refetch} = useQuery({
        queryKey: [`update_rights`],
        queryFn: async () => {
            try {
                toast.loading(t("users.o_edit.toast_saving"), {id:'saveRights'})
                const {data} = await axios.post(`right/update`, {
                    token: auth.data?.token,
                    ...new_rights
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.success(t("users.o_edit.toast_saved"), {id:'saveRights'})
                refetchRights()
                return null
            } catch (error) {
                toast.error(t("users.o_edit.toast_error"), {id:'saveRights'})
                console.log(error);
                return null
            }
        },
        enabled:false
    })

    const updateRights = (key:keyof UserRight, value:boolean) => {
        setnew_rights((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    }

  return (
    <div className='flex flex-col gap-4'>
        <div className='flex items-center justify-between'>
            <div>
                <h3 className='font-semibold leading-none'>{t("users.o_edit.title_p2")}</h3>
                <p className='text-sm text-neutral-400'>{t("users.o_edit.description_p2")}</p>
            </div>
            <Button onClick={()=>{refetch()}} disabled={lodash.isEqual(rights, new_rights) || isFetching || auth.data?.username === username} className='gap-2'>{isFetching ? <Loader2 className='w-5 animate-spin'/> : <><Save className='w-5'/> {t("users.o_edit.button_save")}</>}</Button>
        </div>   
        
        <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4'>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('order', checked)}} checked={new_rights.order} id='order'/>
                <Label htmlFor='order'>{t("users.o_edit.label_order")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('announce', checked)}} checked={new_rights.announce} id='announce'/>
                <Label htmlFor='announce'>{t("users.o_edit.label_announce")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttdelivery', checked)}} checked={new_rights.ttdelivery} id='ttdelivery'/>
                <Label htmlFor='ttdelivery'>{t("users.o_edit.label_ttdelivery")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttcollection', checked)}} checked={new_rights.ttcollection} id='ttcollection'/>
                <Label htmlFor='ttcollection'>{t("users.o_edit.label_ttcollection")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttsafepoint', checked)}} checked={new_rights.ttsafepoint} id='ttsafepoint'/>
                <Label htmlFor='ttsafepoint'>{t("users.o_edit.label_ttsafepoint")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttatm2t', checked)}} checked={new_rights.ttatm2t} id='ttatm2t'/>
                <Label htmlFor='ttatm2t'>{t("users.o_edit.label_ttatm2t")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttatme2e', checked)}} checked={new_rights.ttatme2e} id='ttatme2e'/>
                <Label htmlFor='ttatme2e'>{t("users.o_edit.label_ttatme2e")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('ttstops', checked)}} checked={new_rights.ttstops} id='ttstops'/>
                <Label htmlFor='ttstops'>{t("users.o_edit.label_ttstops")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('edoc', checked)}} checked={new_rights.edoc} id='edoc'/>
                <Label htmlFor='edoc'>{t("users.o_edit.label_edoc")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('complaint', checked)}} checked={new_rights.complaint} id='complaint'/>
                <Label htmlFor='complaint'>{t("users.o_edit.label_complaint")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('consumables', checked)}} checked={new_rights.consumables} id='consumables'/>
                <Label htmlFor='consumables'>{t("users.o_edit.label_consumables")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('export', checked)}} checked={new_rights.export} id='export'/>
                <Label htmlFor='export'>{t("users.o_edit.label_export")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('message', checked)}} checked={new_rights.message} id='message'/>
                <Label htmlFor='message'>{t("users.o_edit.label_message")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('faq', checked)}} checked={new_rights.faq} id='faq'/>
                <Label htmlFor='faq'>{t("users.o_edit.label_faq")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('regnewuser', checked)}} checked={new_rights.regnewuser} id='regnewuser'/>
                <Label htmlFor='regnewuser'>{t("users.o_edit.label_regnewuser")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('deleteuser', checked)}} checked={new_rights.deleteuser} id='deleteuser'/>
                <Label htmlFor='deleteuser'>{t("users.o_edit.label_deleteuser")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('manageusrprofile', checked)}} checked={new_rights.manageusrprofile} id='manageusrprofile'/>
                <Label htmlFor='manageusrprofile'>{t("users.o_edit.label_manageusrprofile")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('userlist', checked)}} checked={new_rights.userlist} id='userlist'/>
                <Label htmlFor='userlist'>{t("users.o_edit.label_userlist")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('log', checked)}} checked={new_rights.log} id='log'/>
                <Label htmlFor='log'>{t("users.o_edit.label_log")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('sipassets', checked)}} checked={new_rights.sipassets} id='sipassets'/>
                <Label htmlFor='sipassets'>{t("users.o_edit.label_sipassets")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('sipexecutedinterventions', checked)}} checked={new_rights.sipexecutedinterventions} id='sipexecutedinterventions'/>
                <Label htmlFor='sipexecutedinterventions'>{t("users.o_edit.label_sipexecutedinterventions")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('sipmanagements', checked)}} checked={new_rights.sipmanagements} id='sipmanagements'/>
                <Label htmlFor='sipmanagements'>{t("users.o_edit.label_sipmanagements")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('sipplanninginterventions', checked)}} checked={new_rights.sipplanninginterventions} id='sipplanninginterventions'/>
                <Label htmlFor='sipplanninginterventions'>{t("users.o_edit.label_sipplanninginterventions")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('adminedit', checked)}} checked={new_rights.adminedit} id='settings'/>
                <Label htmlFor='settings'>{t("users.o_edit.label_adminedit")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('pension', checked)}} checked={new_rights.pension} id='pension'/>
                <Label htmlFor='pension'>{t("users.o_edit.label_pension")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('transportrequest', checked)}} checked={new_rights.transportrequest} id='transportrequest'/>
                <Label htmlFor='transportrequest'>{t("users.o_edit.label_transportrequest")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox onCheckedChange={(checked)=>{updateRights('transportcancel', checked)}} checked={new_rights.transportcancel} id='transportcancel'/>
                <Label htmlFor='transportcancel'>{t("users.o_edit.label_transportcancel")}</Label>
            </div>
        </div>

        <div className='mt-8 grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-4'>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.requestresearch} id='requestresearch'/>
                <Label htmlFor='requestresearch'>{t("users.o_edit.label_requestresearch")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.privatetransport} id='privatetransport'/>
                <Label htmlFor='privatetransport'>{t("users.o_edit.label_privatetransport")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.quoteprivatetransport} id='quoteprivatetransport'/>
                <Label htmlFor='quoteprivatetransport'>{t("users.o_edit.label_quoteprivatetransport")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.foreign} id='foreign'/>
                <Label htmlFor='foreign'>{t("users.o_edit.label_foreign")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.links} id='links'/>
                <Label htmlFor='links'>{t("users.o_edit.label_links")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.question} id='question'/>
                <Label htmlFor='question'>{t("users.o_edit.label_question")}</Label>
            </div>
            <div className='flex items-center gap-1'>
                <NewCheckbox disabled checked={new_rights.news} id='news'/>
                <Label htmlFor='news'>{t("users.o_edit.label_links")}</Label>
            </div>
        </div>
    </div>
  )
}

export default Rights