import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/ui/button'
import { BadgeDollarSign, Briefcase } from 'lucide-react'
import Faq from '../../../components/portal/faq/Faq'
import useAuth from '../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'


  

const Main = () => {
  const {auth} = useAuth()
  const {t} = useTranslation()

  const faq = [
    {
      label:t("settings.faq_roles"),
      url: 'https://sway.office.com/s/evrPzUNHGxLmewBp/embed',
      subtitle_id: 1
    },
    {
      label:t("settings.faq_new"),
      url: 'https://sway.office.com/s/wB6GdbbnyHHnovbM/embed',
      subtitle_id: 1
    },
    {
      label:t("settings.faq_settings"),
      url: 'https://sway.office.com/s/3H5sDiYX48BIfXxz/embed',
      subtitle_id: 2
    }
  ]

  return (
    <>
    {auth.data?.right.faq && <Faq title={t("settings.title")} items={faq} subtitles={[{id:1, title:t("settings.users")}, {id:2, title:t("settings.settings")}]}/>}
    <div className='relative w-full p-4 overflow-auto md:p-8'>
        <h1 className="mb-4 text-2xl font-semibold md:mb-8">{t("settings.title")}</h1>

        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 1100: 2, 1500: 3}}>
            <Masonry gutter='1rem'>
                <div className='flex flex-col gap-4 p-4 border rounded-sm shadow-sm'>
                  <h2 className='text-lg'>{t("settings.settings")}</h2>
                  
                  <div className='flex gap-2'>
                      <div className='w-10 h-10 min-w-[40px] rounded-sm flex justify-center items-center bg-red-50'><Briefcase className='text-primary'/></div>
                      <p className='text-sm'>{t("settings.description")} <span className='font-medium'>"{t("settings.description_p2")}"</span> {t("settings.description_p3")} <span className='font-medium'>"{t("settings.description_p4")}"</span> {t("settings.description_p5")} <span className='font-medium'>{t("settings.description_p6")}</span> {t("settings.description_p7")} <span className='font-medium'>{t("settings.description_p8")}</span> {t("settings.description_p9")}</p>
                  </div>

                  <div className='flex gap-2'>
                      <Link to="/instellingen/partnerinstellingen"><Button>{t("settings.link_settings")}</Button></Link>
                  </div>   
                </div>

                {(process.env.NODE_ENV === 'development') &&
                <div className='flex flex-col gap-4 p-4 border rounded-sm shadow-sm'>
                  <h2 className='text-lg'>Vreemde valuta</h2>
                  
                  <div className='flex gap-2'>
                      <div className='w-10 h-10 min-w-[40px] rounded-sm flex justify-center items-center bg-red-50'><BadgeDollarSign className='text-primary'/></div>
                      <p className='text-sm'>Creëer en beheer vreemde valuta. Wijs ze toe aan partners</p>
                  </div>

                  <div className='flex gap-2'>
                      <Link to="/instellingen/vreemde-valuta"><Button>Valuta instellingen consulteren en wijzigen</Button></Link>
                  </div>   
                </div>
                }
            </Masonry>
        </ResponsiveMasonry>
    </div>
    </>
  )
}

export default Main