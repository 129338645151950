import React from "react"
import { cn } from "../../lib/utils"
import * as AlertDialogPrimitive from "@radix-ui/react-alert-dialog"

interface PopupProps extends React.HTMLAttributes<HTMLDivElement> {
  
}

const Popup = AlertDialogPrimitive.Root

const PopupOverlay = React.forwardRef<
React.ElementRef<typeof AlertDialogPrimitive.Overlay>,
React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Overlay>
>(({ className, children, ...props }, ref) => (
    <div
        className={cn(
        "fixed flex items-center justify-center inset-0 z-50 bg-background/80 backdrop-blur-sm transition-opacity animate-in fade-in",
        className
        )}
        {...props}
        ref={ref}
    >
        {children}
    </div>
))

const PopupContent = React.forwardRef<React.ElementRef<typeof AlertDialogPrimitive.Content>, React.ComponentPropsWithoutRef<typeof AlertDialogPrimitive.Content>>(({ className, children, ...props }, ref) => (
    <PopupOverlay>
        <div
        ref={ref}
        className={cn(
            "fixed z-50 grid w-full max-w-lg scale-100 border bg-background p-6 opacity-100 shadow-lg animate-in fade-in-90 slide-in-from-bottom-10 sm:rounded-lg sm:zoom-in-90 sm:slide-in-from-bottom-0 md:w-full",
            className
        )}
        {...props}
        >
            {children}
        </div>
    </PopupOverlay>
))

export { Popup, PopupContent }