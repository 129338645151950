import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ComplaintDetail from './detail/page'
import Main from './page'

const Complaints = () => {
  return (
    <Routes>
        <Route path="" element={<Main />}/>
        <Route path=":id" element={<ComplaintDetail />}/>
    </Routes>
  )
}

export default Complaints