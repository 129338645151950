import CryptoJS from 'crypto-js';

export function encryptObject(object: object, encryptionKey: string): string {
    const jsonString = JSON.stringify(object);
    const encrypted = CryptoJS.AES.encrypt(jsonString, encryptionKey).toString();
    return encrypted;
}

export function decryptObject(encryptedData: string, encryptionKey: string): object {
    const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, encryptionKey);
    const decryptedString = decryptedBytes.toString(CryptoJS.enc.Utf8);
    const decryptedObject = JSON.parse(decryptedString);
    return decryptedObject;
}