import coin_eur_001 from '../../../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../../../assets/images/coin-eur-2.png'

import note_eur_5 from '../../../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../../../assets/images/note-eur-500.png'

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Toaster, toast } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import axios from '../../../../../lib/axios'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../../lib/date'
import { format } from 'date-fns'
import { formatCashAmount, isInterface } from '../../../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import Coupure from '../../../../../components/portal/coupure/Coupure'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../components/ui/button'
import { ChevronLeft } from 'lucide-react'

const Delivery = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { auth } = useAuth()
    const { orderid } = useParams()

    const customers = useSelector((state:RootState)=>state.customers)

    const [searchParams, setSearchParams] = useSearchParams();

    const [coupure, setcoupure] = useState<Coupure[] | StateType>(null);
    const [order, setorder] = useState<Order | StateType>(null);
    const [collis, setcollis] = useState<Colli[] | StateType>(null);

    useEffect(()=>{
        if(orderid){
            fetch_order()
            fetch_coupure()
            fetch_collis()
        }
    }, [orderid])

    async function fetch_order() {
        try {
            setorder('loading')
            const { data } = await axios.post(`order/search`, {
                token: auth.data?.token,
                id: orderid,
                startdate: "20010101",
                enddate: formatDateToYYYYMMDD(new Date()),
                searchby: searchParams.get('sb') ? searchParams.get('sb') : ''
            })
            if(data.errorcode === 0 && Array.isArray(data.object.order)){
                setorder(data.object.order[0])
            }
            else {
                setorder('error')
                if(data.errorcode === 0){ toast.error(`[ERROR] - Geen detail gevonden`) }
                else { toast.error(`[${data.errorcode !== 0 ? data.errorcode : 'ERROR'}] - ${data.message}`) }
                
            }
        } catch (error) {
            console.log(error)
            toast.error(t("track_and_trace.o_detail.toast_wrong_order"))
            setorder('error')
        }
    }

    async function fetch_coupure() {
        try {
            setcoupure('loading')
            const { data } = await axios.post(`order/detail`, {
                token: auth.data?.token,
                orderid: orderid,
            })
            console.log(data)
            if(data.errorcode === 0){
                setcoupure(data.object.coupure)
            }
            else {
                toast.error(`Error [${data.errorcode}]: ${data.message}`)
                setcoupure('error')
            }
        } catch (error) {
            setcoupure('error')
            toast.error(t("track_and_trace.o_detail.toast_wrong_coins"))
            console.log(error)
        }
    }

    async function fetch_collis() {
        try {
            setcollis('loading')
            const { data } = await axios.post(`colli/search`, {
                token: auth.data?.token,
                orderid: orderid,
                equal: 0
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object.colli)){
                    setcollis(data.object.colli)
                }
                else {
                    toast.error(t("track_and_trace.o_detail.toast_no_colli"))
                    setcollis('empty')
                }
            }
            else {
                setcollis('error')
                toast.error(`[${data.errorcode}] - ${data.message}`)
            }
        } catch (error) {
            setcollis('error')
            console.log(error)
            toast.error(t("track_and_trace.o_detail.toast_wrong"))
        }
    }

  return (
    <>
        <Toaster />
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex items-center justify-between'>
                <div className='flex flex-col'>
                    <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_detail.link_tt")}</p>}, {title:`${t("track_and_trace.o_detail.delivery")} ${orderid}`}]}/>
                    <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_detail.title_delivery")}</h1>
                </div>

                <Link to="/track-en-trace"><Button variant='ghost' className='gap-2'><ChevronLeft className='w-4 h-4'/> {t("track_and_trace.o_detail.back")}</Button></Link>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_delivery")}</h2>
                {order === 'loading' && <SpinnerFeedback />}
                {order === 'empty' && <EmptyFeedback />}
                {order === 'error' && <ErrorFeedback />}
                {isInterface(order, {} as Order) &&
                <div className='grid grid-cols-[repeat(auto-fit,minmax(260px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>ID</p>
                        <p className='text-neutral-500'>{order.id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_announced")}</p>
                        <p className='text-neutral-500'>{convertToDate(order.dtannounce) ? format(convertToDate(order.dtannounce) || new Date(), "dd/MM/yyyy") : ''}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_created")}</p>
                        <p className='text-neutral-500'>{convertToDate(order.dtcreation) ? format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy") : ''}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_out_stock")}</p>
                        <p className='text-neutral-500'>{convertToDate(order.dtstock) ? format(convertToDate(order.dtstock) || new Date(), "dd/MM/yyyy") : ''}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_delivery")}</p>
                        <p className='text-neutral-500'>{convertToDate(order.dtdelivery) ? format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy") : ''}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.reference")}</p>
                        <p className='text-neutral-500'>{order.clientref}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.customer")}</p>
                        <p className='text-neutral-500'>{customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)?.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.amount")}</p>
                        <p className='text-neutral-500'>{formatCashAmount(order.amount)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.currency")}</p>
                        <p className='text-neutral-500'>{order.currency}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.type")}</p>
                        <p className='text-neutral-500'>{order.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</p>
                    </div>
                </div>
                }   
            </div>
            
            <div className='grid grid-cols-2 gap-8 mt-4 md:mt-8'>
                <div className='flex border flex-col gap-4 rounded-sm p-4'>
                    <h2 className='text-lg'>{t("track_and_trace.o_detail.total_amount")} {isInterface(order, {} as Order) && (order.cn.toLowerCase() === 'c' ? 'munten' : order.cn.toLowerCase() === 'n' ? 'biljetten' : '')}</h2>
                    {coupure === 'loading' && <SpinnerFeedback />}
                    {coupure === 'empty' && <EmptyFeedback />}
                    {coupure === 'error' && <ErrorFeedback />}
                    {(isInterface(coupure, {} as Coupure[]) && order === 'error') && <p className='text-sm font-medium text-neutral-500'>Coupures succesvol opgehaald, order ophalen mislukt</p>}
                    {isInterface(coupure, {} as Coupure[]) && isInterface(order, {} as Order) &&
                    <div className='flex flex-col gap-4'>
                        <Coupure currency={order.currency} cn={order.cn.toLowerCase() as 'c' | 'n'} coupures={coupure}/>
                    </div>
                    }
                </div>

                <div className='border p-4'>
                    <h2 className='text-lg'>{t("track_and_trace.o_detail.overview_collis")}</h2>
                    {collis === 'loading' && <SpinnerFeedback />}
                    {collis === 'empty' && <EmptyFeedback />}
                    {collis === 'error' && <ErrorFeedback />}
                    {isInterface(collis, {} as Colli[]) &&
                    <div className='border rounded-sm mt-4'>
                        <Table>
                            <TableHeader className='bg-slate-100 relative'>
                                <TableRow>
                                    <TableHead>ID</TableHead>
                                    <TableHead>{t("track_and_trace.barcode")}</TableHead>
                                    <TableHead>{t("track_and_trace.o_detail.keepsafe_id")}</TableHead>
                                    <TableHead>{t("track_and_trace.amount")}</TableHead>
                                </TableRow> 
                            </TableHeader>
                            <TableBody>
                                {collis.map((colli, index)=>{
                                    return (
                                    <TableRow onClick={()=>{navigate(`colli/${colli.id}`)}} className='cursor-pointer' key={index}>
                                        <TableCell>{colli.id}</TableCell>
                                        <TableCell>{colli.barcode}</TableCell>
                                        <TableCell>{colli.mkpsafe}</TableCell>
                                        <TableCell>{isInterface(order, {} as Order) && order.currency} {formatCashAmount(colli.amount)}</TableCell>
                                    </TableRow>
                                    )
                                })}                
                            </TableBody>
                        </Table>
                    </div>
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default Delivery