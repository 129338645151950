import * as React from "react"

import { cn } from "../../lib/utils"
import { cva, type VariantProps } from "class-variance-authority"
import { Eye } from "lucide-react";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, VariantProps<typeof inputVariants> {
  startEnhancer?: React.ReactNode;
  endEnhancer?: React.ReactNode;
} 

const inputVariants = cva(
  "relative",
  {
    variants: {
      variant: {
        default: "",
        dark: ""
      }
    },
    defaultVariants: {
      variant: "default"
    }
  }
)

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ startEnhancer, endEnhancer, variant, className, type, ...props }, ref) => {
    const [type_state, settype_state] = React.useState(type);
    const isEdge = window.navigator.userAgent.indexOf("Edg") > -1

    return (
      <div className={cn(inputVariants({ variant, className }))}>
      {startEnhancer && (
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          {startEnhancer}
        </div>
      )}
      {endEnhancer && (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          {endEnhancer}
        </div>
      )}
      <input
        type={type_state}
        className={cn(
          "flex h-10 w-full rounded-md border border-input bg-transparent px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
      />
      {(type==='password' && !isEdge) && (
        <button onMouseDown={()=>{settype_state('text')}} onTouchStart={()=>{settype_state('text')}} onTouchEnd={()=>{settype_state('password')}} onMouseUp={()=>{settype_state('password')}} className="absolute inset-y-0 right-2 opacity-50 hover:opacity-100 active:text-primary">
          <Eye className="w-4"/>
        </button>
      )}
      </div>
    )
  }
)
Input.displayName = "Input"

export { Input }
