import { addDays, addMonths, endOfMonth, endOfWeek, format, isBefore, isSameMonth, isToday, startOfMonth, startOfWeek, subDays, subMonths } from 'date-fns';
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/ui/button';
import { ChevronLeft, ChevronRight, Loader2, Palmtree } from 'lucide-react';
import useAuth from '../../../hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../lib/axios';
import { formatDateToYYYYMMDD } from '../../../lib/date';
import { HoverCard, HoverCardContent } from '../../../components/ui/hover-card';
import { HoverCardTrigger } from '@radix-ui/react-hover-card';
import { useTranslation } from 'react-i18next';

type Props = {
    selected_partner?: string | null;
    selected_customer?: string | null;
}

const MonthPlanning : React.FC<Props> = ({selected_partner, selected_customer}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const [currentMonth, setcurrentMonth] = useState<Date>(new Date());

    const {data:holidays, refetch:refetchHolidays, isFetching:isFetchingHolidays} = useQuery<Holiday[] | null>({
        queryKey: [`holidays`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`holiday/search`, {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(subMonths(currentMonth, 2)),
                    enddate: formatDateToYYYYMMDD(addMonths(currentMonth, 2)),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.holiday)){return null;} //No holiday
                return data.object.holiday
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    const {data:planning, refetch:refetchPlanning, isFetching:isFetchingPlanning} = useQuery<PlanningEvent[] | null>({
        queryKey: [`planning_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                let obj = {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(subMonths(currentMonth, 2)),
                    enddate: formatDateToYYYYMMDD(addMonths(currentMonth, 2)),
                    relnr: selected_customer,
                    clientcode: ''
                }
                  
                const {data} = await axios.post(`planning/search`, obj)
        
                if(data.errorcode !== 0) { throw new Error(data.message) }
                if(!data.object.planning) { throw new Error('Planning empty'); }
        
                return data.object.planning
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: (!!selected_customer && selected_customer!=='clear')
    })

    useEffect(()=>{
        refetchHolidays()
    }, [currentMonth])

    const Header = () => {
        const dateFormat = "MMMM"

        function prevMonth() {
            setcurrentMonth(subMonths(currentMonth, 1))
        }

        function nxtMonth() {
            setcurrentMonth(addMonths(currentMonth, 1))
        }

        return (
            <div className='flex gap-1 items-center'>
                <div className='flex gap-1 items-center'>
                    <button onClick={prevMonth} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronLeft className='w-4'/></button>
                    <button onClick={nxtMonth} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronRight className='w-4' /></button>
                </div>
                <div className='ml-2 flex justify-center items-center capitalize font-semibold'>
                    <p className='text-lg'>{format(currentMonth, dateFormat)} {(currentMonth.getFullYear() !== new Date().getFullYear()) && format(currentMonth, "yyyy")}</p>
                    {(isFetchingPlanning) && <Loader2 className='ml-2 w-4 text-neutral-400 animate-spin'/>}
                </div>
            </div>
        )
    }

    const Days = () => {
        const dateFormat = "E";
        const days = []
        let startDate = startOfWeek(currentMonth)

        for (let i = 0; i < 7; i++) {
            days.push({
                weekday: format(addDays(startDate, i), dateFormat)
            })
        }

        return (
            <div className='grid grid-cols-7 py-2'>
                {days.map((day, index) => {
                    return (
                        <div className='capitalize flex justify-center text-sm text-neutral-500 first:rounded-tl-sm last:rounded-tr-sm' key={index}>{day.weekday}</div>
                    )
                })}
            </div>
        )
    }

    const Cells = () => {
        const dateFormat = "d";

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = []

        let days:DayObject[] = []
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                days.push({
                    isSameMonth: isSameMonth(day, monthStart),
                    isToday: isToday(day),
                    isBeforeToday: isBefore(day, new Date()),
                    day: format(day, dateFormat),
                    date: day,
                    isHoliday: isHoliday(day),
                    planning: getPlanning(day),
                    //events: searchEvents(day)
                })
                day = addDays(day, 1)
            }
            rows.push(days);
            days = []
        }

        const HoverDetail = ({day}:{day:DayObject}) => {
            const [open, setopen] = useState(false);

            return (
                <HoverCard openDelay={0} closeDelay={0} open={open} onOpenChange={(v)=>{ if(day.isHoliday || day.planning.length > 0){ setopen(v) } }}>
                    <HoverCardTrigger>
                        <div className={`${day.isToday && 'border-primary'} ${!day.isSameMonth && 'bg-slate-50'} flex flex-col px-1 ${!day.isSameMonth && format((endOfMonth(day.date)), "d") === day.day && 'rounded-r-full'} ${!day.isSameMonth && day.day === '1' && 'rounded-l-full'}`}>
                            <div className='relative flex justify-center w-full py-2'>
                                <div className={`${day.isToday && 'text-primary font-medium'} ${!day.isSameMonth && 'text-neutral-400'} w-6 h-6`}>
                                    <p className='text-md text-center'>{day.day}</p>
                                </div> 
                                <div className='absolute bottom-1 flex gap-1 items-center'>
                                    {day.isHoliday && <div className='w-[6px] h-[6px] rounded-full bg-blue-500'></div>}
                                    {(day.planning.length > 0 && !day.isHoliday) && <div className='w-[6px] h-[6px] rounded-full bg-[#84a178]'></div>}
                                </div>                                    
                            </div>                                                                                                    
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent className='flex flex-col gap-2'>
                        {day.isHoliday && 
                        <div className='flex items-center gap-2'>
                            <div className='w-[6px] h-[6px] rounded-full bg-blue-500'></div>
                            <p className='text-sm font-medium whitespace-nowrap'>{holidays?.find(i=>i.date===formatDateToYYYYMMDD(day.date))?.description}</p>
                        </div>}

                        {(day.planning.length > 0 && !day.isHoliday) &&
                        <div className='flex items-center gap-2'>
                            <div className='w-[6px] h-[6px] rounded-full bg-[#84a178]'></div>
                            {day.planning.map(i=>i.cn).sort().join('').toLowerCase() === 'cn' && <p className='text-sm font-medium whitespace-nowrap'>{t("dashboard.o_month_planning.coins_and_notes")}</p>}
                            {day.planning.map(i=>i.cn).sort().join('').toLowerCase() === 'n' && <p className='text-sm font-medium whitespace-nowrap'>{t("dashboard.o_month_planning.notes")}</p>}
                            {day.planning.map(i=>i.cn).sort().join('').toLowerCase() === 'c' && <p className='text-sm font-medium whitespace-nowrap'>{t("dashboard.o_month_planning.coins")}</p>}
                        </div>}
                    </HoverCardContent>
                </HoverCard>
            )
        }

        return (
            <div>
                {rows.map((row, ind) => {
                    return (
                        <div className='grid grid-cols-7' key={ind}>
                            {row.map((day, i) => {
                                return (
                                    <HoverDetail day={day} key={i}/>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

    type DayObject = {
        isSameMonth: boolean;
        isToday: boolean;
        isBeforeToday: boolean;
        day: string;
        date: Date;
        isHoliday: boolean;
        planning: PlanningEvent[]
    }

    function isHoliday(date:Date) {
        let result = false;
        if(holidays){
            const holiday = holidays.find(i=>i.date===formatDateToYYYYMMDD(date))
            if(holiday) { result = true }
        }
        return result
    }

    function getPlanning(date:Date) {
        let result:PlanningEvent[] = []
        if(planning){
            result = planning.filter(i=>i.date===formatDateToYYYYMMDD(date))
        }
        return result;
    }

  return (
    <div className='mt-4 flex flex-col gap-2'>
        <Header />
        <div className='border rounded-sm'>
            <Days />
            <Cells />
        </div>
    </div>
  )
}

export default MonthPlanning