import React, { useState } from 'react'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/ui/button'
import { Download, Loader2 } from 'lucide-react'
import { Input } from '../../../../../components/ui/input'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

const AddBulk = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const navigate = useNavigate()
    
    const [base64, setbase64] = useState('');

    const downloadTemplate = async () => {
        try {
            toast.loading(t("users.o_edit.toast_downloading"), {id:'downloadTemplate'})
            const {data} = await axios.post(`user/import/template`, { token:auth.data?.token })
            if(data.errorcode!==0){throw new Error(data.message)}
            toast.success(t("users.o_edit.toast_downloaded"), {id:'downloadTemplate'})
            window.open(data.object.filename)
        } catch (error) {
            toast.error(t("users.o_edit.toast_error_p4"), {id:'downloadTemplate'})
        }
    }

    const uploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];

        if (file) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const result = e.target?.result as string;
            setbase64(result);
        };

        reader.readAsDataURL(file);
        }
    }

    const { mutate, isLoading } = useMutation({
        mutationFn: async () => {
            try {
                toast.loading(t("users.o_edit.toast_adding"), {id:'uploadUsers'})
                const {data} = await axios.post(`user/import/xls`, { token:auth.data?.token, file:base64.split('base64,')[1] })
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.success(t("users.o_edit.toast_added"), {id:'uploadUsers'})
                navigate(-1)
            } catch (error) {
                toast.error(t("users.o_edit.toast_error_p5"), {id:'uploadUsers'})
            }
        }
    })

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8 flex flex-col gap-4">
        <div className='flex flex-col'>
            <Breadcrumb items={[{title:<p onClick={()=>{navigate('/gebruikers')}} className='cursor-pointer'>{t("users.o_edit.link_users")}</p>}, {title:`${t("users.o_edit.link_add")}`}]}/>
            <h1 className='text-2xl font-semibold'>{t("users.o_edit.link_users")}</h1>
        </div>
        
        <div className='border rounded-sm p-4 flex flex-col gap-4 max-w-3xl'>
            <h2 className='text-lg'>1. {t("users.o_edit.step_template")}</h2>
            <Button onClick={downloadTemplate} className='w-fit gap-2' variant={base64 === '' ? 'default' : 'secondary'}><Download className='w-5'/> {t("users.o_edit.step_template")}</Button>
        </div>

        <div className='border rounded-sm p-4 flex flex-col gap-4 max-w-3xl'>
            <h2 className='text-lg'>2. {t("users.o_edit.step_upload")}</h2>
            <Input onChange={uploadFile} type='file'/>
        </div>

        {base64 !== '' && <Button onClick={()=>{mutate()}} disabled={isLoading} className='w-fit'>{isLoading ? <Loader2 className='animate-spin'/> : t("users.o_edit.button_add")}</Button>}
        
    </div>
    </>
  )
}

export default AddBulk