import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/ui/button';
import { Label } from '../../../components/ui/label';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { Combobox } from '../../../components/ui/combobox';
import usePartnerStore from '../../../zustand/usePartnerStore';
import { Input } from '../../../components/ui/input';
import { RadioGroup, RadioGroupItem } from '../../../components/ui/radio-group';
import { DatePicker } from '../../../components/ui/datepicker';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux';
import { subWeeks } from 'date-fns';
import axios from '../../../lib/axios';
import toast, { Toaster } from 'react-hot-toast';
import useAuth from '../../../hooks/useAuth';
import { useQuery } from '@tanstack/react-query';
import { formatDateToYYYYMMDD } from '../../../lib/date';
import { Eye, EyeOff, Loader2 } from 'lucide-react';
import ComplaintsTable from './tables/ComplaintsTable';
import { getCustomerList } from '../../../lib/fetch/customer';
import { useTranslation } from 'react-i18next';

const Complaints = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const partners = useSelector((state:RootState)=>state.partners);
    const complaintcode = useSelector((state:RootState)=>state.complaintcode);

    const [show_filters, setshow_filters] = useState(true);

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();
    const [selected_complaintcode, setselected_complaintcode] = useState('');
    const [selected_cn, setselected_cn] = useState('');
    const [selected_partner, setselected_partner] = useState('');
    const [selected_customer, setselected_customer] = useState<null | string>('');
    const [selected_clientcode, setselected_clientcode] = useState('');
    const [selected_startdate, setselected_startdate] = useState(subWeeks(new Date(), 2));
    const [selected_enddate, setselected_enddate] = useState(new Date());

    useEffect(()=>{
        if(Array.isArray(partners)){
            if(partners.length === 1){
                handlePickPartner(partners[0].relnr.toString())
            }
        }
    }, [partners])

    const handlePickPartner = async (relnr:string) => {
        try {
          setselected_partner(relnr);
          setcustomers_list(null);
          setselected_customer(null);
          
          const list = await getCustomerList(auth, relnr)
          if(list){ setcustomers_list(list) }
          if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }

        } catch (error) {
          console.log(error)
          toast.error(t("complaints.toast_wrong"))
        }
    }

    const {data:complaints, refetch, isFetching} = useQuery<Complaint[] | null>({
        queryKey: ['complaints'],
        queryFn: async () => {
            try {
                const obj = {
                    token: auth.data?.token,
                    cn: selected_cn,
                    partner: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : '',
                    relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
                    code: selected_complaintcode,
                    startdate: formatDateToYYYYMMDD(selected_startdate),
                    enddate: formatDateToYYYYMMDD(selected_enddate),
                    clientcode: selected_clientcode
                }
                const {data} = await axios.post(`complaint/search`, obj)
                if(data.errorcode !== 0){ throw new Error() }
                if(!data.object.complaint){ toast.error(t("complaints.toast_no_complaints")); throw new Error()}
                return data.object.complaint;
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: false
    })

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("complaints.title")}</h1>
        </div> 

        <div className='shadow-sm border rounded-sm mt-4 md:mt-8'>
            <div className='p-4 flex justify-between'>
                <h2 className='text-lg'>{t("complaints.filters")}</h2>
                <Button onClick={()=>{ setshow_filters(prev => !prev) }} variant="ghost">{show_filters ? <Eye className='w-5 text-neutral-500'/> : <EyeOff className='w-5 text-neutral-500'/>}</Button>
            </div>

            <div className={`${show_filters ? 'max-h-screen p-4 pt-0' : 'max-h-0 px-4 py-0'} overflow-hidden duration-300 grid grid-cols-3 gap-4`}>
                <div className='col-span-full'>
                    <Label htmlFor='type'>{t("complaints.type")}</Label>
                    <Select value={selected_complaintcode} onValueChange={(value) => { setselected_complaintcode(value) }}>
                        <SelectTrigger id='type'>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value={''}>{t("complaints.type_all")}</SelectItem>
                            {complaintcode.map((v, i) => (
                                <SelectItem value={v.code} key={i}>{v.code} - {v.description}</SelectItem>
                            ))}
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label htmlFor='partner'>{t("complaints.partner")}</Label>
                    <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("complaints.partner_placeholder")} input_placeholder={t("complaints.search_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='end_customer'>{t("complaints.customer")}</Label>
                    <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("complaints.customer_placeholder")} input_placeholder={t("complaints.search_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='customer_code'>{t("complaints.customer_code")}</Label>
                    <Input value={selected_clientcode} onChange={({currentTarget})=>{setselected_clientcode(currentTarget.value)}} id='customer_code'/>
                </div>

                <div className='col-span-full'>
                    <Label htmlFor='cn_opt'>{t("complaints.coins_or_notes")}</Label>
                    <RadioGroup value={selected_cn} onValueChange={(value)=>{ setselected_cn(value) }} id='cn_opt'>
                        <div className='flex items-center space-x-2'>
                            <RadioGroupItem id='both' value=''/>
                            <Label htmlFor='both'>{t("complaints.coins_and_notes")}</Label>
                        </div>

                        <div className='flex items-center space-x-2'>
                            <RadioGroupItem id='coins' value='c'/>
                            <Label htmlFor='coins'>{t("complaints.coins")}</Label>
                        </div>

                        <div className='flex items-center space-x-2'>
                            <RadioGroupItem id='notes' value='n'/>
                            <Label htmlFor='notes'>{t("complaints.notes")}</Label>
                        </div>
                    </RadioGroup>
                </div>

                <div>
                    <Label htmlFor='startdate'>{t("complaints.date_start")}</Label>
                    <DatePicker id='startdate' value={selected_startdate} onDateChange={(value) => { if(value){setselected_startdate(value)} }} placeholder={t("complaints.date_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='enddate'>{t("complaints.date_end")}</Label>
                    <DatePicker id='enddate' value={selected_enddate} onDateChange={(value) => { if(value){setselected_enddate(value)} }} placeholder={t("complaints.date_placeholder")}/>
                </div>

            </div>

            <Button onClick={()=>{ refetch() }} disabled={isFetching} className={`${!show_filters && 'hidden'} mb-4 ml-4`}>{isFetching ? <Loader2 className='animate-spin'/> : t("complaints.search")}</Button>
        </div>

        {complaints && <ComplaintsTable complaints={complaints} startdate={selected_startdate} enddate={selected_enddate}/>}
    </div>
    </>
  )
}

export default Complaints