import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'
import { convertToDate } from '../../../lib/date'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
    stopDetail: StopDetail,
    stop: Stop
}

const Delivery : React.FC<Props> = ({stopDetail, stop}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

  return (
    <Table>
        <TableHeader className='bg-slate-100'> 
            <TableRow>
                <TableHead className='uppercase'>Id</TableHead>
                <TableHead className='uppercase'>{t("components.o_stop.o_delivery.barcode")}</TableHead>
                <TableHead className='uppercase'>{t("components.o_stop.o_delivery.keepsafe")}</TableHead>
                <TableHead className='uppercase'>{t("components.o_stop.o_delivery.keepsafe_barcode")}</TableHead>
                <TableHead className='uppercase'>{t("components.o_stop.o_delivery.date_delivery")}</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {stopDetail && stopDetail.delivery && stopDetail.delivery.map((v, index) => {
                return (
                    <TableRow onClick={()=>{navigate(`delivery/colli/${v.colli}`, {state:stop})}} className='cursor-pointer' key={index}>
                        <TableCell>{v.colli}</TableCell>
                        <TableCell>{v.barcode}</TableCell>
                        <TableCell>{v.boxid}</TableCell>
                        <TableCell>{v.boxbarcode}</TableCell>
                        <TableCell>{format(convertToDate(v.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                    </TableRow>
                )
            })}
        </TableBody>
    </Table>
  )
}

export default Delivery