import { ActionType, Action } from "../action-types";

const initialState:ComplaintCode[] = [];

const complaintscodeReducer = (state:ComplaintCode[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_COMPLAINTCODE:
            state = action.payload || []
            return state;
        default:
            return state;
    }
}

export default complaintscodeReducer;