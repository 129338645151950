import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'
import Coupure from '../coupure/Coupure'
import { convertToDate, convertToHHMM } from '../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

type Props = {
    count: Count
}

const Count : React.FC<Props> = ({count}) => {
    const { barcode } = useParams()
    const {t} = useTranslation()
  return (
    <>
    <Table>
        <TableHeader className='bg-slate-100'>
            <TableRow>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.post")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.lot")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.borderel")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.vault")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.date_count")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.time_count")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.date_validated")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.time_validated")}</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            <TableRow >
                <TableCell>{count.post}</TableCell>
                <TableCell>{count.lot}</TableCell>
                <TableCell>{count.borderel}</TableCell>
                <TableCell>{count.vault}</TableCell>
                <TableCell>{format(convertToDate(count.dtcount) || new Date(), "dd/MM/yyyy")}</TableCell>
                <TableCell>{convertToHHMM(count.tmcount)}</TableCell>
                <TableCell>{format(convertToDate(count.dtvalid) || new Date(), "dd/MM/yyyy")}</TableCell>
                <TableCell>{convertToHHMM(count.tmvalid)}</TableCell>
            </TableRow> 
        </TableBody>
    </Table>
    <Table className='border-t'>
        <TableHeader className='bg-slate-100'>
            <TableRow>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.nr_colli")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.type")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.mkpsafe")}</TableHead>
                {barcode && <TableHead className='font-medium align-middle text-muted-foreground uppercase'>Keepsafe</TableHead>}
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.remark")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.currency")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.bgannounced")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.bgcount")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.bgfalse")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.bgdamaged")}</TableHead>
                <TableHead className='font-medium align-middle text-muted-foreground uppercase'>{t("components.o_count.bginkstained")}</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            <TableRow >
                <TableCell>{count.colliid}</TableCell>
                <TableCell>{count.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</TableCell>
                <TableCell>{count.motherks}</TableCell>
                {barcode && <TableCell>{barcode}</TableCell>}
                <TableCell>{count.remark}</TableCell>
                <TableCell>{count.currency}</TableCell>
                <TableCell>{count.bgannounced}</TableCell>
                <TableCell>{count.bgcount}</TableCell>
                <TableCell>{count.bgfalse}</TableCell>
                <TableCell>{count.bgdamaged}</TableCell>
                <TableCell>{count.bginkstained}</TableCell>
            </TableRow>
        </TableBody>
    </Table>

    <div className='h-px w-full bg-neutral-200'></div>

    <div className=''>
        <div className='grid grid-cols-4 border-b uppercase text-sm bg-slate-100 font-medium text-muted-foreground py-2 px-4'>
            <i></i>
            <p className='text-right'>{t("components.o_count.amount_recognized")}</p>
            <p className='text-right'>{t("components.o_count.amount_broken")}</p>
            <p className='text-right'>{t("components.o_count.total")}</p>
        </div>
        <div className='flex flex-col gap-4 p-4'>
            <Coupure cn={count.cn.toLowerCase() as 'c' | 'n'} coupures={count.coupure} show_mutated show_total/>
        </div>
    </div>
    </>
  )
}

export default Count