import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/ui/button'
import { Loader2 } from 'lucide-react'
import { DatePicker } from '../../../../components/ui/datepicker'
import { Label } from '../../../../components/ui/label'
import { Input } from '../../../../components/ui/input'
import { Combobox } from '../../../../components/ui/combobox'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../lib/axios'
import { subDays } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import useAuth from '../../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import { isInterface } from '../../../../lib/utils'
import PlanningTable from './tables/PlanningTable'
import { getCustomerList } from '../../../../lib/fetch/customer'
import WindowsDatePicker from '../../../../components/ui/windows-datepicker'
import { useTranslation } from 'react-i18next'

const Main = () => {
    const navigate = useNavigate()
    const {auth} = useAuth()
    const {t} = useTranslation()
    const partners = useSelector((state: RootState) => state.partners);

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [selected_clientcode, setselected_clientcode] = useState('')
    const [selected_atmid, setselected_atmid] = useState('')
    const [selected_startdate, setselected_startdate] = useState<Date>(subDays(new Date(), 3))
    const [selected_enddate, setselected_enddate] = useState<Date>(new Date())

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    const {data:planning, refetch, isFetching} = useQuery<SIPPlanning[] | null>({
        queryKey: ['sip_planning'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`plannedintervention/search`, {
                    token: auth.data?.token,
                    relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
                    partner: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : '',
                    clientcode: selected_clientcode,
                    atmid: selected_atmid,
                    startdate: formatDateToYYYYMMDD(selected_startdate),
                    enddate: formatDateToYYYYMMDD(selected_enddate)
                })
                if(!data.object.intervention){ toast.error(`No planning interventions found`, {id:'planning-interventions'}); return null; }
                return data.object.intervention
            } catch (error) {
                console.log(error)
                return null;
            }
        },
        enabled: false
    })

    // useEffect(() => {
    //     if (auth.data?.partner.split(",")[0]) {
    //         handlePickPartner(auth.data.partner.split(",")[0])
    //     }
    // }, [])

    const handlePickPartner = async (relnr: string) => {
        try {
            if(selected_partner!==relnr){setselected_customer('clear')}
            setselected_partner(relnr);
            setcustomers_list(null);
            const list = await getCustomerList(auth, relnr)
            if(list){ setcustomers_list(list) }
            if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
        } catch (error) {
            console.log(error)
            toast.error(t("sip.o_planning.toast_wrong"))
        }
    }

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr = partnersArr.filter(i=>i.label.toLowerCase().includes('bpost retail'))
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>

        <Breadcrumb items={[{ title: <p onClick={() => { navigate('..') }} className='cursor-pointer'>{t("sip.o_planning.link_sip")}</p> }, { title: `${t("sip.o_planning.link_interventions")}` }]} />
        <h1 className='text-2xl font-semibold'>{t("sip.o_planning.link_interventions")}</h1>

        <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
            <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                <div>
                    <Label htmlFor='partner'>{t("sip.o_planning.partner")}</Label>
                    <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("sip.o_planning.partner_placeholder")} input_placeholder={t("sip.o_planning.search_placeholder")} />
                </div>

                <div>
                    <Label htmlFor='end_customer'>{t("sip.o_planning.customer")}</Label>
                    <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("sip.o_planning.customer_placeholder")} input_placeholder={t("sip.o_planning.search_placeholder")} />
                </div>

                <div>
                    <Label htmlFor='clientcode'>{t("sip.o_planning.customer_code")}</Label>
                    <Input value={selected_clientcode} onChange={({ currentTarget })=>{ setselected_clientcode(currentTarget.value) }} id='clientcode'/>
                </div>

                <div>
                    <Label htmlFor='atm_id'>{t("sip.o_planning.atm_id")}</Label>
                    <Input value={selected_atmid} onChange={({ currentTarget }) => { setselected_atmid(currentTarget.value) }} id='atm_id' />
                </div>

                <div>
                    <Label htmlFor='startdate'>{t("sip.o_planning.date_start")}</Label>
                    <WindowsDatePicker value={selected_startdate} onDateChange={(value)=>{if(value){setselected_startdate(value)}}} id='startdate' placeholder={t("sip.o_planning.date_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='enddate'>{t("sip.o_planning.date_end")}</Label>
                    <WindowsDatePicker value={selected_enddate} onDateChange={(value) => { if (value) { setselected_enddate(value) } }} id='enddate' placeholder={t("sip.o_planning.date_placeholder")} />
                </div>
            </div>

            <Button onClick={()=>{refetch()}} disabled={isFetching} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("sip.o_planning.search")}</Button>
        </div>

        {isInterface(planning, {} as SIPPlanning[]) &&
        <PlanningTable planning={planning}/>
        }
    </div>
    </>
  )
}

export default Main