import React, { useEffect, useState } from 'react'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { RadioGroup, RadioGroupItem } from '../../../../components/ui/radio-group'
import { DatePicker } from '../../../../components/ui/datepicker'
import { subWeeks } from 'date-fns'
import { Button } from '../../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import CollectionsTable from './tables/CollectionsTable'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import { getCustomerList } from '../../../../lib/fetch/customer'
import WindowsDatePicker from '../../../../components/ui/windows-datepicker'
import { useTranslation } from 'react-i18next'

const Main = () => {
    const { t } = useTranslation() 
    const { auth } = useAuth()
    const navigate = useNavigate()
    const partners = useSelector((state:RootState)=>state.partners);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate);

    const [selected_startdate, setselected_startdate] = useState(subWeeks(new Date(), 2));
    const [selected_enddate, setselected_enddate] = useState(new Date());
    const [selected_filter, setselected_filter] = useState('C');
    const [selected_orderdraftstate, setselected_orderdraftstate] = useState<string | null>('');
    const [selected_partner, setselected_partner] = useState<string | null>('');
    const [selected_customer, setselected_customer] = useState<string | null>('');
    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    const {data:collections, refetch, isFetching} = useQuery<AnnouncementDraftSearch[] | null>({
      queryKey: [''],
      queryFn: async () => {
        try {
          const obj = {
            token: auth.data?.token,
            startdate: formatDateToYYYYMMDD(selected_startdate),
            enddate: formatDateToYYYYMMDD(selected_enddate),
            searchby: selected_filter || '',
            status: selected_orderdraftstate || '',
            partner: selected_partner !== process.env.REACT_APP_RDM_NUM ? selected_partner : '',
            clientcode: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
          }
          const {data} = await axios.post(`announcement/draft/search`, obj)
          console.log(data)
          if(data.errorcode !== 0){ throw new Error(data.message) }
          if(!data.object.announcementdraft) { toast.error(t("order_and_pickup.o_overview_collections.toast_error")); throw new Error('empty') }
          return data.object.announcementdraft;
        } catch (error) {
          return null;
        }
      }, 
      enabled: false
    })

    useEffect(()=>{
      if (auth.data?.partner.split(",").length === 1) {
          handlePickPartner(auth.data.partner.split(",")[0])
      }
    }, [])

    const handlePickPartner = async (relnr:string) => {
        try {
          if(selected_partner!==relnr){setselected_customer('clear')}
          setselected_partner(relnr);
          setcustomers_list(null);

          const list = await getCustomerList(auth, relnr)
          if(list){ setcustomers_list(list) }
          if(list.length === 1){ setselected_customer(list[0].clientcode.toString()) }

        } catch (error) {
          console.log(error)
          toast.error('Something went wrong...')
        }
    }

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.clientcode, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:process.env.REACT_APP_RDM_NUM!, label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>

        <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_collections.link_orders")}</p>}, {title:`${t("order_and_pickup.o_overview_collections.link_collection")}`}]}/>
        <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_overview_collections.link_collection")}</h1>

        <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
          <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
              <div>
                  <Label htmlFor='partner'>{t("order_and_pickup.o_overview_collections.partner")}</Label>
                  <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder='Selecteer een partner...' input_placeholder='Zoeken...'/>
              </div>

              <div>
                  <Label htmlFor='end_customer'>{t("order_and_pickup.o_overview_collections.customer")}</Label>
                  <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder='Selecteer een eindklant...' input_placeholder='Zoeken...'/>
              </div>

              <div>
                  <Label htmlFor='orderdraftstate'>{t("order_and_pickup.o_overview_collections.status")}</Label>
                  <Combobox disabled={!Array.isArray(orderdraftstate)} selectedValue={selected_orderdraftstate ? selected_orderdraftstate : undefined} id='orderdraftstate' onValueChange={(value) => { setselected_orderdraftstate(value.value.toString()) }} options={Array.isArray(orderdraftstate) ? orderdraftstate.map((item)=> ({value:item.code, label:item.description})) : []} placeholder='Selecteer een status...' input_placeholder='Zoeken...'/>
              </div>

              <div className='col-span-full'>
                  <Label htmlFor='filter'>{t("order_and_pickup.o_overview_collections.filter_on")}</Label>
                  <RadioGroup onValueChange={(value)=>{setselected_filter(value)}} value={selected_filter} className='flex gap-4 flex-wrap mt-1'>
                      <div className='flex gap-2'>
                          <RadioGroupItem value='C' id='r_1'/>
                          <Label htmlFor='r_1'>{t("order_and_pickup.o_overview_collections.date_creation")}</Label>
                      </div>
                      <div className='flex gap-2'>
                          <RadioGroupItem value='P' id='r_2'/>
                          <Label htmlFor='r_2'>{t("order_and_pickup.o_overview_collections.date_collection")}</Label>
                      </div>
                  </RadioGroup>
              </div>

              <div>
                  <Label htmlFor='startdate'>{t("order_and_pickup.o_overview_collections.date_start")}</Label>
                  <WindowsDatePicker value={selected_startdate} onDateChange={(date)=>{date && setselected_startdate(date)}}/>
              </div>

              <div>
                  <Label htmlFor='enddate'>{t("order_and_pickup.o_overview_collections.date_end")}</Label>
                  <WindowsDatePicker value={selected_enddate} onDateChange={(date)=>{date && setselected_enddate(date)}}/>
              </div>
          </div> 

          <Button disabled={isFetching} onClick={()=>{refetch()}} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : `${t("order_and_pickup.o_overview_collections.button_search")}`}</Button>
        </div>

        {collections && <CollectionsTable collections={collections}/>}
    </div>
    </>
  )
}

export default Main