export const repairATMService = (atmservice:ATMService) => {
    const requiredOMS = ['order information', 'reject', 'retract', 'added', 'counters', 'surplus', 'remaining', 'reject nok', 'new amount', 'total remaining']
    
    let repaired = {...atmservice}
    repaired.detail = repaired.detail.sort((a, b)=>a.rubrieknr - b.rubrieknr)

    requiredOMS.forEach((oms, index)=>{
        const omsExists = repaired.detail.find(d=>d.rubriekoms.toLowerCase() === oms)
        if(!omsExists){
            // OMS not in detail
            repaired.detail.push({
                keepsafe:         '',
                rubrieknr:        index + 1,
                rubriekoms:       oms,
                NOTES_EUR_5_1:    0,
                AMOUNT_EUR_5_1:   0,
                NOTES_EUR_5_2:    0,
                AMOUNT_EUR_5_2:   0,
                NOTES_EUR_10_1:   0,
                AMOUNT_EUR_10_1:  0,
                NOTES_EUR_10_2:   0,
                AMOUNT_EUR_10_2:  0,
                NOTES_EUR_20_1:   0,
                AMOUNT_EUR_20_1:  0,
                NOTES_EUR_20_2:   0,
                AMOUNT_EUR_20_2:  0,
                NOTES_EUR_50_1:   0,
                AMOUNT_EUR_50_1:  0,
                NOTES_EUR_50_2:   0,
                AMOUNT_EUR_50_2:  0,
                NOTES_EUR_100_1:  0,
                AMOUNT_EUR_100_1: 0,
                NOTES_EUR_100_2:  0,
                AMOUNT_EUR_100_2: 0,
                NOTES_EUR_200_1:  0,
                AMOUNT_EUR_200_1: 0,
                NOTES_EUR_200_2:  0,
                AMOUNT_EUR_200_2: 0,
                NOTES_EUR_500_1:  0,
                AMOUNT_EUR_500_1: 0,
                NOTES_EUR_500_2:  0,
                AMOUNT_EUR_500_2: 0,
            })
        }
    })

    repaired.detail = repaired.detail.sort((a, b)=>a.rubrieknr - b.rubrieknr)

    return repaired
}