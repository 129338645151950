import React, { Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './globals.css';

import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';

import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import LoadingScreen from './components/portal/feedback/LoadingScreen';

import ReactGA from "react-ga4";
import ErrorBoundaryCOMPONENT from './components/portal/error/ErrorBoundary';
import { ErrorBoundary } from "react-error-boundary";

const TRACKING_ID = 'G-XJMFLX7E8C'
ReactGA.initialize(TRACKING_ID)

require('./i18n')

export const queryclient = new QueryClient()

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
    <QueryClientProvider client={queryclient}>
      <Suspense fallback={<LoadingScreen />}>
        <AuthProvider>
          <ErrorBoundary fallback={<ErrorBoundaryCOMPONENT />}>
            <BrowserRouter basename={''}>
              
                <Routes>
                  <Route path="/*" element={<App />} />
                </Routes>
              
            </BrowserRouter>
          </ErrorBoundary>
        </AuthProvider>
      </Suspense>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
);
