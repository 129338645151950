import { ActionType, Action } from "../action-types";

const initialState:OrderDraftState[] = [];

const orderdraftstateReducer = (state:OrderDraftState[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_ORDERDRAFTSTATE:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default orderdraftstateReducer;