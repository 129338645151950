import React, { HTMLAttributes, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'

import { RootState } from '../../../../redux'
import { useSelector } from 'react-redux'
import { splitArrayIntoChunks } from '../../../../lib/utils'
import { Pagination } from '../../../../components/ui/pagination'
import { useNavigate } from 'react-router-dom'
import { convertToDate } from '../../../../lib/date'
import { format } from 'date-fns'
import ExportTable from '../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export'
import useAuth from '../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

type Props = {
    orders: Atm2T[]
}


const AtmDeliveriesTable : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({orders, className, ...props}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("track_and_trace.partner"), t("track_and_trace.customer_nr"), t("track_and_trace.customer_code"), t("track_and_trace.customer"),  t("track_and_trace.date_stop"), t("track_and_trace.route_code"), t("track_and_trace.type"), t("track_and_trace.description"), t("track_and_trace.type")]
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

    function returnTypeLabel(type:string) {
        switch (type.toLowerCase()) {
            case 'vpl':
                return t("track_and_trace.vpl")
            case 'oaf':
                return t("track_and_trace.oaf")
            case 'opa':
                return t("track_and_trace.opa")
            case 'rfk':
                return t("track_and_trace.rfk")
            case 'rfc':
                return t("track_and_trace.rfc")
            case 'pri':
                return t("track_and_trace.pri")
            case 'ext':
                return t("track_and_trace.ext")
            default:
                return ''
        }
    }

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(orders, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'date', 'routecode', 'type', 'description', 'cn'],{partners:partners, customers:customers, relnr:'relnr'})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table className={className} {...props}>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>{t("track_and_trace.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.date_stop")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.route_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.type")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.description")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.type")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {orders && splitArrayIntoChunks(orders, 20)[currentPage - 1] && splitArrayIntoChunks(orders, 20)[currentPage - 1].map((order, index) => {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                    return (
                        <TableRow onClick={()=>{navigate(`atm_transfer/${order.relnr}`, {state:order})}} className='cursor-pointer' key={index}>
                           <TableCell>{partner?.name}</TableCell>
                            <TableCell>{customer?.name}</TableCell>
                            <TableCell>{customer?.clientcode}</TableCell>
                            <TableCell>{format(convertToDate(order.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{order.routecode}</TableCell>
                            <TableCell>{returnTypeLabel(order.type)}</TableCell>
                            <TableCell>{order.description}</TableCell>
                            <TableCell>{order.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</TableCell>
                        </TableRow>
                    )
                })}
                
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={orders.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default AtmDeliveriesTable