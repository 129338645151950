import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../components/ui/table'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'
import { useQuery } from '@tanstack/react-query'
import { format, subMonths } from 'date-fns'
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date'
import SpinnerFeedback from '../../../components/portal/feedback/SpinnerFeedback'
import { Pagination } from '../../../components/ui/pagination'
import { formatCashAmount, splitArrayIntoChunks } from '../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { useTranslation } from 'react-i18next'

type Props = {
    selected_customer?: string | null
}

const OverviewPickup : React.FC<Props> = ({selected_customer}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const [currentPage, setcurrentPage] = useState<number>(1);

    const {data:announcements, refetch:refetchAnnouncements, isFetching:isFetchingAnnouncements} = useQuery<AnnouncementDraftSearch[] | null>({
        queryKey: [`pickup_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`announcement/draft/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date()),
                    searchby: 'C'
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.announcementdraft){return null}
                return data.object.announcementdraft
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: (!!selected_customer && selected_customer!=='clear')
    })

  return (
    <>
    <div className='border rounded-sm mt-4'>
        <Table>
            <TableHeader className='bg-slate-100'>
                <TableRow>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.status")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.delivery")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.amount")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.keepsafe")}</TableHead>
                    <TableHead className='uppercase'>{t("dashboard.o_overview_collections.type")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {(!announcements && isFetchingAnnouncements) && <TableRow><TableCell colSpan={6}><SpinnerFeedback /></TableCell></TableRow>}
                {(!announcements && selected_customer && selected_customer !== 'clear' && !isFetchingAnnouncements) && <TableRow><TableCell colSpan={6}>{t("dashboard.o_overview_collections.no_found")}</TableCell></TableRow>}
                {((!announcements && !selected_customer && !isFetchingAnnouncements) || (!announcements && selected_customer === 'clear' && !isFetchingAnnouncements)) && <TableRow><TableCell colSpan={6}>{t("dashboard.o_overview_collections.no_selection")}</TableCell></TableRow>}
                {announcements && splitArrayIntoChunks(announcements, 5)[currentPage - 1] && splitArrayIntoChunks(announcements, 5)[currentPage - 1].map((announcement, index) => {
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === announcement.relnr)

                    return (
                        <TableRow key={index}>
                            <TableCell>{orderdraftstate && orderdraftstate.find(i=>i.code===announcement.status)?.description}</TableCell>
                            <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                            <TableCell>{format(convertToDate(announcement.date) || new Date, "dd/MM/yyyy")}</TableCell>
                            <TableCell>{formatCashAmount(announcement.amount)}</TableCell>
                            <TableCell>{announcement.keepsafe}</TableCell>
                            <TableCell>{announcement.cn.toLowerCase() === 'c' ? t("dashboard.o_overview_collections.coins") : t("dashboard.o_overview_collections.notes")}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        
    </div>
    {announcements && 
    <div className='mt-4'>
        <Pagination totalItems={announcements.length} itemsPerPage={5} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </div>
    }
    </>
  )
}

export default OverviewPickup