import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { Button } from '../../../../components/ui/button'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { Tablist, TablistItem } from '../../../../components/ui/tablist'
import SpinnerFeedback from '../../../../components/portal/feedback/SpinnerFeedback'
import { Loader2, Plus, ShoppingCart, Trash2 } from 'lucide-react'
import { Label } from '../../../../components/ui/label'
import { Input } from '../../../../components/ui/input'
import ErrorFeedback from '../../../../components/portal/feedback/ErrorFeedback'
import EmptyFeedback from '../../../../components/portal/feedback/EmptyFeedback'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from '../../../../components/ui/alert-dialog'
import { Combobox } from '../../../../components/ui/combobox'
import PlannedStopsCalendar from '../../../../components/portal/stop/PlannedStopsCalendar'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { addFilter, removeFilter } from '../../../../redux/actions'
import { getFilterDetail } from '../../../../lib/selectors'
import { isInterface } from '../../../../lib/utils'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import { getCustomerList } from '../../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'

const OrderConsumables = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const navigate = useNavigate()

    const [selected_group, setselected_group] = useState('');
    const [selected_articles, setselected_articles] = useState<{articleid:string, amount_losper:number, amount_packedby:number}[]>([]);
    const [selected_date, setselected_date] = useState<Date | undefined>();
    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);

    const {data:articlegroup, isFetching:articlegroup_isFetching} = useQuery<ArticleGroup[] | null>({
        queryKey:['articlegroup'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`articlegroup/search`, {token:auth.data?.token})
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.articlegroup){throw new Error('empty')}
                if(selected_group === '') {
                setselected_group(data.object.articlegroup[0].id.toString())
                }
                return data.object.articlegroup
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_order_consumables.toast_error"))
                return null
            }
        }
    })

    const {data:articles, isFetching:articles_isFetching} = useQuery<Article[] | null>({
        queryKey: ['articles'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`article/search`, {token:auth.data?.token})
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.article){throw new Error('empty')}
                return data.object.article
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_order_consumables.toast_error_p2"))
                return null
            }
        }
    })

    const {data:partnersettings, isFetching:partnersettings_isFetching} = useQuery<Partner | null>({
        queryKey:['partnersettings'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/search`, {token:auth.data?.token, partner:auth.data?.partner})
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.partner){throw new Error('Empty')}
                return data.object.partner[0]
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_order_consumables.toast_error_p3"))
                return null;
            }
        }
    })

    const {mutate:confirmOrder, isLoading} = useMutation({
        mutationFn: async () => {
            try {
                toast.loading(t("order_and_pickup.o_order_consumables.toast_loading"), {id:'confirmOrder'})
                if(!selected_date){ toast.error(t("order_and_pickup.o_order_consumables.toast_error_p4")); return null; }
                const obj = {
                    token: auth.data?.token,
                    partner: selected_partner,
                    relnr: (selected_customer !== 'clear' ? selected_customer : '') || '',
                    dtdelivery: formatDateToYYYYMMDD(selected_date),
                    language: auth.data?.language,
                    articles: [...selected_articles]
                }
                const {data} = await axios.post(`consumable/order`, obj)
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.success(t("order_and_pickup.o_order_consumables.toast_success"), {id:'confirmOrder'})
            } catch (error) {
                console.log(error);
                toast.error(t("order_and_pickup.o_order_consumables.toast_error_p5"), {id:'confirmOrder'})
            }
        }
    })

    function filterArticles(articles:Article[]) {
        console.log(articles)
        let updated = [...articles]
        updated = articles.filter(i=>i.articlegroupid.toString()===selected_group)
        console.log(partnersettings)

        let canorderIds = partnersettings?.settings?.articles?.filter(i=>i.canorder).map(i=>i.articleid)
        updated = updated.filter(i=>canorderIds?.includes(i.id))

        return updated
    }

    const updateArticles = (articleid:string, amount_losper:number, amount_packedby:number) => {
        setselected_articles(prev => {
            let updated = [...prev]
            updated.push({articleid, amount_losper, amount_packedby})
            return updated
        })
    }

    const removeArticle = (articleid:string) => {
        setselected_articles(prev => {
            let updated = [...prev]
            const index = updated.findIndex(i=>i.articleid===articleid)
            if(index>-1){ updated.splice(index, 1) }
            return updated
        })
    }

    const Article = ({article, partnersettings, addArticle}:{article:Article, partnersettings:Partner, addArticle:(id:string, losper:number, packedby:number)=>void}) => {
        const dispatch = useDispatch()
        const filter = useSelector((state:RootState)=>state.filters)

        const [losper, setlosper] = useState(0);
        const [packedby, setpackedby] = useState(0);

        useEffect(()=>{
            const defaultLosper = getFilterDetail(filter, 'order_consumables', `${article.id}_losper`)
            const defaultPackedby = getFilterDetail(filter, 'order_consumables', `${article.id}_packedby`)
            if(isInterface(defaultLosper, {} as FilterDetail) && typeof defaultLosper.value === 'number'){ setlosper(defaultLosper.value) }
            if(isInterface(defaultPackedby, {} as FilterDetail) && typeof defaultPackedby.value === 'number'){ setpackedby(defaultPackedby.value) }
        }, [])

        const changeValue = (key:'losper'|'packedby', amount:number) => {
            if(key==='losper'){
                setlosper(amount)  
            }
            if(key==='packedby'){
                setpackedby(amount)
            }
            dispatch(addFilter('order_consumables', {key:`${article.id}_${key}`, value:amount}))
        }

        const addItem = () => {
            addArticle(article.id.toString(), losper, packedby)
            setlosper(0)
            setpackedby(0)
            dispatch(removeFilter('order_consumables', `${article.id}_losper`))
            dispatch(removeFilter('order_consumables', `${article.id}_packedby`))
        }

        return (
            <div className='border rounded-sm p-2'>
                <div className='flex justify-between'>
                    <div className='flex gap-2 items-center'>
                        <img className='w-10' src={`https://myloomis.loomis.be/assets/images/consumables/${article.photo}`} alt={article.name} />
                        <div>
                            <p>{article.name}</p>
                            <p className='text-sm text-neutral-400'>{article.description}</p>
                        </div>
                    </div>
                    <Button onClick={()=>{addItem()}} disabled={losper === 0 && packedby === 0} variant='dark' className='gap-1'><Plus className='w-4'/> {t("order_and_pickup.o_order_consumables.button_add")}</Button>
                </div>
                
                <div className='mt-2 grid grid-cols-2 gap-2'>
                    <div>
                        <Label>{t("order_and_pickup.o_order_consumables.los")} {partnersettings.settings?.articles.find(i=>i.articleid===article.id)?.losper}</Label>
                        <Input value={losper} onChange={({currentTarget})=>{parseFloat(currentTarget.value) > -1 && changeValue('losper', parseFloat(currentTarget.value))}} type='number' min={0}/>
                    </div>

                    <div>
                        <Label>{t("order_and_pickup.o_order_consumables.packed")} {partnersettings.settings?.articles.find(i=>i.articleid===article.id)?.packedby}</Label>
                        <Input value={packedby} onChange={({currentTarget})=>{parseFloat(currentTarget.value) > -1 && changeValue('packedby', parseFloat(currentTarget.value))}} type='number' min={0} />
                    </div>
                </div>
            </div>
        )
    }

    const CompleteOrder = () => {
        const partners = useSelector((state:RootState)=>state.partners)

        const [customers_list, setcustomers_list] = useState<Customer[] | null>();

        useEffect(()=>{
            if (auth.data?.partner.split(",")[0]) {
              handlePickPartner(auth.data.partner.split(",")[0])
            }
          }, [])
      
        const handlePickPartner = async (relnr:string) => {
            try {
                if(selected_partner!==relnr){setselected_customer('clear')}
                setselected_partner(relnr);
                setcustomers_list(null);

                const list = await getCustomerList(auth, relnr)
                if(list){ setcustomers_list(list) }
                if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
            } catch (error) {
              console.log(error)
              toast.error(t("order_and_pickup.o_order_consumables.toast_error_p6"))
            }
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild><Button disabled={selected_articles.length===0 || isLoading} className='gap-2'>{isLoading ? <Loader2 className='w-4 animate-spin'/> : <ShoppingCart className='w-4'/>} {t("order_and_pickup.o_order_consumables.button_confirm")}</Button></AlertDialogTrigger>
                <AlertDialogContent className='max-w-3xl max-h-[95dvh] overflow-auto'>
                    <AlertDialogTitle>{t("order_and_pickup.o_order_consumables.title")}</AlertDialogTitle>
                    
                    <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                        <div>
                            <Label htmlFor='partner'>{t("order_and_pickup.o_order_consumables.partner")}</Label>
                            <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("order_and_pickup.o_order_consumables.partner_placeholder")} input_placeholder={t("order_and_pickup.o_order_consumables.search_placeholder")}/>
                        </div>

                        <div>
                            <Label htmlFor='end_customer'>{t("order_and_pickup.o_order_consumables.customer")}</Label>
                            <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:item.name})) : []} placeholder={t("order_and_pickup.o_order_consumables.customer_placeholder")} input_placeholder={t("order_and_pickup.o_order_consumables.search_placeholder")}/>
                        </div>

                        <div>
                            <Label htmlFor='deliverydate'>{t("order_and_pickup.o_order_consumables.date_delivery")}</Label>
                            <PlannedStopsCalendar page='order' onSelectDay={(v)=>{console.log(v)}} disabled={!(selected_partner !== null && selected_customer !== null)} partnerid={selected_partner ? parseFloat(selected_partner) : 0} clientrelnr={selected_customer ? parseFloat(selected_customer) : 0} setselectedDate={setselected_date} selectedDate={selected_date}/>
                        </div>
                    </div>

                    <div className='w-full h-px bg-neutral-200'></div>

                    {selected_articles.map((article, index) => {
                        return (
                            <div key={index} className='border bg-slate-50 rounded-sm p-2'>                          
                                <p className='text-md'>{articles?.find(i=>i.id.toString()===article.articleid)?.name}</p>
                                <div className='flex justify-between items-end gap-2'>
                                    <div>
                                    <p className='text-sm text-neutral-400'>{t("order_and_pickup.o_order_consumables.amount_loose")}: {article.amount_losper}</p>
                                    <p className='text-sm text-neutral-400'>{t("order_and_pickup.o_order_consumables.amount_packed")}: {article.amount_packedby}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                    <div className='flex justify-between'>
                        <AlertDialogCancel>{t("order_and_pickup.o_order_consumables.button_cancel")}</AlertDialogCancel>
                        <AlertDialogAction onClick={()=>{confirmOrder()}} disabled={selected_date===undefined}>{t("order_and_pickup.o_order_consumables.button_confirm")}</AlertDialogAction>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

  return (
    <div className='flex'>
        <Toaster />
        <div className='overflow-auto relative w-full p-4 md:p-8'>
            <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_order_consumables.link_orders")}</p>}, {title:`${t("order_and_pickup.o_order_consumables.link_consumables")}`}]}/>
            <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_order_consumables.link_consumables")}</h1>

            <div className='flex flex-col gap-4 py-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
                <Tablist value={selected_group} onValueChange={(v)=>{setselected_group(v)}}>
                    {articlegroup && articlegroup.map((group, index) => {
                        return (
                            <TablistItem value={group.id.toString()} key={index}>{group.name}</TablistItem>
                        )
                    })}
                </Tablist>
                <div className='px-4'>
                    {((articlegroup_isFetching || articles_isFetching || partnersettings_isFetching) && !articles) && <SpinnerFeedback />}
                    {articles && partnersettings && 
                    <div className='flex flex-col gap-4'>
                        {filterArticles(articles).length > 0 ? filterArticles(articles).map((article, index) => {
                            return (
                                <Article addArticle={(id, losper, packedby)=>{updateArticles(id, losper, packedby)}} article={article} partnersettings={partnersettings} key={index}/>
                            )
                        }) : <EmptyFeedback />}
                    </div>
                    }
                </div>
            </div>
        </div>

        <div className='h-[calc(100vh-128px)] md:h-screen top-0 right-0 p-4 border bg-white shadow-sm max-w-xs w-full flex flex-col justify-between'>
            <div>
                <h3 className='text-lg mb-4'>{t("order_and_pickup.o_order_consumables.title_overview")}</h3>
                <div className='flex flex-col gap-4'>
                {selected_articles.map((article, index) => {
                    return (
                        <div key={index} className='border bg-slate-50 rounded-sm p-2 hover:border-red-600'>
                            
                            <p className='text-md'>{articles?.find(i=>i.id.toString()===article.articleid)?.name}</p>
                            <div className='flex justify-between items-end gap-2'>
                                <div>
                                <p className='text-sm text-neutral-400'>{t("order_and_pickup.o_order_consumables.amount_loose")}: {article.amount_losper}</p>
                                <p className='text-sm text-neutral-400'>{t("order_and_pickup.o_order_consumables.amount_packed")}: {article.amount_packedby}</p>
                                </div>
                            
                                <button onClick={()=>{removeArticle(article.articleid)}} className='rounded-sm w-8 h-8 flex justify-center items-center opacity-50 translate-x-2 translate-y-2 hover:opacity-100 hover:text-red-700'><Trash2 className='w-4'/></button>
                            </div>
                        </div>
                    )
                })}
                </div>
            </div>            
        
            <CompleteOrder />
            
        </div>
    </div>
  )
}

export default OrderConsumables