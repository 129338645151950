import React from 'react'

const ProgressSteps = ({current, children, className, ...props} : ProgressStepsProps & React.HTMLAttributes<HTMLDivElement>) => {
    const numChildren = React.Children.count(children);

    const modifiedChildren = React.Children.map(children, (child: any, index) => {
        if(!child) return;

        const isActive = child.props.isActive !== undefined ? child.props.isActive : index === current;

        return React.cloneElement(child, {
            isLast: index === numChildren - 1,
            // @ts-ignore
            isCompleted: index < current,
            isActive,
            step: index + 1,
        } as StepProps)
    });

    const combinedClassName = className ? `flex gap-12 ${className}` : 'flex gap-12';

  return (
    <div className={combinedClassName} {...props}>
        {modifiedChildren}
    </div>
  )
}

ProgressSteps.defaultProps = {
    current: 0,
};

function NumberedStep({isCompleted, isActive, isLast, title, step, children, className, ...props}: NumberedStepProps & React.HTMLAttributes<HTMLDivElement>) {
    const combinedClassName = className ? `relative flex items-center gap-3 ${className}` : 'relative flex items-center gap-3';

    return (
        <div className={combinedClassName} {...props}>
            <div className={`${(isActive || isCompleted) ? 'bg-primary' : 'border'} w-8 h-8  rounded-full flex items-center justify-center`}>
                <span className={`${(isActive || isCompleted) ? 'text-white' : 'text-neutral-500'} font-semibold`}>{step}</span>
            </div>
                

            {!isLast && <svg className='absolute -right-8 top-1/2 -translate-y-1/2' width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 12L10 8L6 4" stroke="#878B95" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/></svg>}

            <div className={`${(!isActive && !isCompleted && 'opacity-50')} flex flex-col max-w-sm`}>            
                {title}
                {children &&
                <>
                  <br></br>
                  {children}
                </>}
                
                
            </div>
        </div>
    )
}

NumberedStep.defaultProps = {
  isCompleted: false,
  isLast: false,
};

export { ProgressSteps, NumberedStep }
  
type ProgressStepsProps = {
    children?: React.ReactNode;
    /** Defines the current active step index. */
    current?: number;
};
  
type StepProps = {
    /** The title of the Step. */
    title?: React.ReactNode;
    /** Defines if the step is completed. Overriden by ProgressSteps, if used. */
    isCompleted?: boolean;
    /** Defines if the step is currently active. */
    isActive?: boolean;
    /** Defines if the step is the last item displayed. Overriden by ProgressSteps, if used. */
    isLast?: boolean;
    children?: React.ReactNode;
};
  
type NumberedStepProps = {
    /** The title of the Step. */
    title?:any;
    /** Defines if the step is completed. */
    isCompleted?: boolean;
    /** Defines if the step is currently active. */
    isActive?: boolean;
    /** Defines if the step is the last item displayed. */
    isLast?: boolean;
    children?: React.ReactNode;
    /** The number displayed as the step number */
    step?: React.ReactNode;
};