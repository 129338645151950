import { Link, useNavigate } from 'react-router-dom'
import { Button } from '../../../../../components/ui/button'
import { Label } from '../../../../../components/ui/label'
import { Input } from '../../../../../components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../../components/ui/select'
import { NumberedStep, ProgressSteps } from '../../../../../components/ui/progress-steps'
import { useEffect, useState } from 'react'
import { z } from 'zod';
import { Combobox } from '../../../../../components/ui/combobox'
import { Checkbox } from '../../../../../components/ui/checkbox'
import { Loader2 } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import toast, { Toaster } from "react-hot-toast"
import usePartnerStore from '../../../../../zustand/usePartnerStore'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { useDebounce } from '@uidotdev/usehooks'
import NewCheckbox from '../../../../../components/ui/new-checkbox'
import Rights from './Rights'
import Partners from './Partners'
import { useTranslation } from 'react-i18next'
import ReactGA from "react-ga4";

interface NewUser {
    firstname?: string,
    lastname?: string,
    email: string,
    language: string,
    role?: number,
    partner?: string,
    relnr?: string
}

const Add = () => {
    const navigate = useNavigate();
    const {auth} = useAuth()
    const {t} = useTranslation()

    const partners = useSelector((state:RootState)=>state.partners)
    const [current, setCurrent] = useState(0);

    const UserSchema = z.object({
        email: z.string().email(),
        language: z.string().max(1),
        role: z.number().max(5)
    })
    const [user, setuser] = useState<NewUser>({firstname:'',lastname:'',email:'',language:'n'});
    const [valid_user, setvalid_user] = useState(false);
    const [new_rights, setnew_rights] = useState<UserRight>();

    const { mutate, isLoading } = useMutation(
        {
            mutationFn: async (user:NewUser) => {
                console.log(user);
                toast.loading(t("users.o_edit.toast_creating"), {id:'createUser'})
                return await axios.post(`${process.env.REACT_APP_BASE_URL}registration/create`, {token:auth.data?.token, ...user})
            },
            onSuccess(data) { 
                if(data.data.errorcode === 0){ toast.success(t("users.o_edit.toast_created"), {id:'createUser'}); navigate('..') }
                else { toast.error(t("users.o_edit.toast_error_p6"), {id:'createUser'}) }

                axios.post(`${process.env.REACT_APP_BASE_URL}right/update`, {token:auth.data?.token, username:user?.email, ...new_rights}).then(({data})=>{
                    if(data.errorcode!==0){ toast.error(t("users.o_edit.toast_error_p7"))}
                })

                ReactGA.event({
                    category: 'Management',
                    action: 'user_create_success',
                })
            },
            onError(error) { 
                ReactGA.event({
                    category: 'Management',
                    action: 'user_create_failed',
                })
                console.error(error) 
            }
        }
    )

    const addUserData = <K extends keyof NewUser>(key: K, value: NewUser[K]) => {
        try {
            // const updatedUser = { ...user, [key]: value } as NewUser;
            // setuser(updatedUser);

            setuser(prev => {
                let updatedUser = {...prev}
                updatedUser[key] = value
                return updatedUser
            })
        } catch (error) {
            if(error instanceof z.ZodError){
                //console.log(error)
                setvalid_user(false)
            }
        }
    }
    
    const createUser = async () => {
        try {
            mutate(user);
        } catch (error) {
            if(error instanceof z.ZodError){
                //Something went wrong with validation
                console.log(error);
            }
            else {
                //Something else went wrong
                console.error(error)
            }
        }
    }

    const [email_exists, setemail_exists] = useState(false);
    const [email_valid, setemail_valid] = useState(true);
    const [email, setemail] = useState('');


    const debouncedEmail = useDebounce(email, 300)

    useEffect(()=>{
        if(debouncedEmail){
            checkEmailExists()
            const EmailSchema = z.object({ email:z.string().email() })
            const res = EmailSchema.safeParse({email:email})
            setemail_valid(res.success)
        } else { setemail_exists(false) }
    }, [debouncedEmail])

    async function checkEmailExists() {
        setemail_exists(false);
        try {
            const {data} = await axios.post(`${process.env.REACT_APP_BASE_URL}user/search`, {token:auth.data?.token, username:email})
            if(data.errorcode!==0){throw new Error(data.message)}
            if(data.object.user){
                const find = data.object.user.find((i:User)=>i.username.toLowerCase()===email.toLowerCase())
                if(find){ setemail_exists(true) }
            }
        } catch (error) { console.log(error) }
    }

    function isAbleNext() {
        let result = false;

        if(current === 0){
            try {
                UserSchema.parse(user);
                result = true
            } catch (error) {}
        }

        if(current === 1){
            if(user.role === 2 || user.role === 3){
                //Check has partner
                if(user.partner && user.partner.length > 0){
                    result = true
                }
            }
            if(user.role === 4 || user.role === 5){
                console.log(user)
                //Check has partner & relnr
                if(user.partner && user.partner.length > 0 && user.relnr && user.relnr.length > 0){
                    result = true
                }
            }
        }

        return !result
    }

  return (
    <>
    <Toaster/>
    <div className="overflow-auto relative w-full h-full min-h-screen p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("users.o_edit.title_p4")}</h1>
            <Link to={'..'}><Button variant='secondary'>{t("users.o_edit.button_back")}</Button></Link>
        </div>

        {/* Progress */}
        <div className='overflow-auto'>
            {user?.role !== 1 ?
            <ProgressSteps current={current} className='mt-4 md:mt-8'>
                <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.data")}</p>}></NumberedStep>
                <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.settings")}</p>}></NumberedStep>     
                <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.rights")}</p>}></NumberedStep>
            </ProgressSteps>
            :
            <ProgressSteps current={current} className='mt-4 md:mt-8'>
                <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.data")}</p>}></NumberedStep>                
                <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.rights")}</p>}></NumberedStep>
            </ProgressSteps>
            }
        </div>

        {/* Gegevens */}
        <div className={`${current !== 0 && 'hidden'} p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>
            <div className='flex justify-between'>
                <h2 className='text-lg'>{t("users.o_edit.data")}</h2>
            </div>
            
            <div className={`my-4 duration-300 grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4`}>
                <div className='sm:col-span-full'>
                    <Label htmlFor='email'>{t("users.o_edit.mail")}<span className='text-primary ml-1'>*</span> 
                        <span className='text-primary text-xs'>{email_exists && 'Deze gebruiker bestaat al!'}</span>
                        {!email_valid && <span className='text-primary text-xs ml-1'>{t("users.o_edit.email_wrong_format")}</span>}
                    </Label>
                    <Input value={email} onChange={(e) => { 
                        let v = e.currentTarget.value;
                        v = v.replace(/\s/g, '')
                        setemail(v.toLowerCase()); 
                        addUserData('email', v.toLowerCase()) }} 
                        id='email'
                    />
                </div>

                <div>
                    <Label htmlFor='firstname'>{t("users.o_edit.firstname")}</Label>
                    <Input onChange={(e) => { addUserData('firstname', e.currentTarget.value) }} id='firstname'/>
                </div>

                <div>
                    <Label htmlFor='lastname'>{t("users.o_edit.lastname")}</Label>
                    <Input onChange={(e) => { addUserData('lastname', e.currentTarget.value) }} id='lastname'/>
                </div>

                <div>
                    <Label htmlFor='email'>{t("users.o_edit.role")}<span className='text-primary ml-1'>*</span></Label>
                    <Select value={user ? user.role ? user.role.toString() : undefined : undefined} onValueChange={(v) => { addUserData('role', parseFloat(v)) }}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            {auth.data?.role === 1 && 
                            <>
                            <SelectItem value={"1"}>Loomis Admin</SelectItem>
                            <SelectItem value={"2"}>Partner Admin</SelectItem>
                            </>
                            }    
                            <SelectItem value={"3"}>Partner Superuser</SelectItem>
                            <SelectItem value={"4"}>User</SelectItem>
                            <SelectItem value={"5"}>Lite User</SelectItem>
                        </SelectContent>
                    </Select>
                </div>

                <div>
                    <Label htmlFor='email'>{t("users.o_edit.language")}<span className='text-primary ml-1'>*</span></Label>
                    <Select value={user.language} onValueChange={(v) => { addUserData('language', v) }}>
                        <SelectTrigger>
                            <SelectValue />
                        </SelectTrigger>
                        <SelectContent>
                            <SelectItem value="n">Nederlands</SelectItem>
                            <SelectItem value="f">Francais</SelectItem>
                            <SelectItem value="e">English</SelectItem>
                        </SelectContent>
                    </Select>
                </div>
            </div>
        </div>
        
        {/* Partner Instellingen */}
        <div className={`${(current !== 1 || user?.role === 1) && 'hidden'} p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>
            <div className='flex justify-between'>
                <h2 className='text-lg'>{t("users.o_edit.settings")}</h2>
            </div>

            <Partners changeCustomer={(value)=>{addUserData('relnr', value)}} changePartner={(value)=>{addUserData('partner', value)}} role={user ? user.role ? user.role.toString() : '' : ''}/>
        </div>
        
        {/* Gebruikersrechten */}
        <div className={`${((current !== 2 && user?.role !== 1) || (current !== 1 && user?.role === 1)) && 'hidden'} p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>
            <div className='flex justify-between'>
                <h2 className='text-lg'>{t("users.o_edit.rights")}</h2>
            </div>
            <Rights role={user ? user.role ? user.role.toString() : '' : ''} new_rights={new_rights} setnew_rights={setnew_rights}/>
        </div>

        {/* Footer */}
        <div className='absolute w-full left-0 bottom-0 z-10 flex items-center justify-between border-t bg-white p-4 md:p-8'>
            {current === 0 ?
                <Link to={'..'}><Button variant='outline'>{t("users.o_edit.button_cancel")}</Button></Link>
                :
                <Button disabled={isLoading} onClick={() => { setCurrent(prev => prev - 1) }} variant='outline'>{t("users.o_edit.button_previous")}</Button>
            }
            
            {((current !== 2 && user?.role !== 1) || (current !== 1 && user?.role === 1)) ?
            <Button onClick={() => { setCurrent(prev => prev + 1) }} disabled={isAbleNext()}>{t("users.o_edit.button_next")}</Button>
            :
            <Button onClick={createUser} disabled={isLoading}>
                {
                isLoading ? 
                <Loader2 className='animate-spin'/>
                :
                t("users.o_edit.button_create")
                }
            </Button>
            }
            
        </div>
    </div>
    </>
  )
}

export default Add