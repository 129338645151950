import { useQuery } from '@tanstack/react-query'
import React, { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import useAuth from '../../../../../hooks/useAuth'
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date'
import { format, subMonths } from 'date-fns'
import axios from '../../../../../lib/axios'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { formatCashAmount } from '../../../../../lib/utils'
import { useNavigate } from 'react-router-dom'
import { getOrderDraftState } from '../../../../../lib/general/params'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { Eye, EyeOff } from 'lucide-react'
import { useTranslation } from 'react-i18next'

const PreviousPickups = () => {
    const {auth} = useAuth();
    const {t} = useTranslation()
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate);

    const [startdate, setstartdate] = useState(subMonths(new Date(), 1));
    const [enddate, setenddate] = useState(new Date());

    const [view_full, setview_full] = useState<boolean>(true);

    const { data:announcements, isFetching } = useQuery<AnnouncementDraftSearch[] | null>({
        queryKey: ['announcement_previous'],
        queryFn: async () => {
            try {
                const object = {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(startdate),
                    enddate: formatDateToYYYYMMDD(enddate),
                    partner: auth.data?.partner,
                    status: '009',
                    searchby: 'C'
                }
                const { data } = await axios.post(`announcement/draft/search`, object)
                //console.log(data)
                if(data.errorcode !== 0) { throw new Error(data.message)}
                else {
                    if(!Array.isArray(data.object.announcementdraft)) { return [] }
                    return data.object.announcementdraft
                }
            } catch (error) {
                toast.error(t("order_and_pickup.o_announce_pickup.o_previous.toast_error"))
            }
        },
    })

  return (
    <>
    <Toaster />
    <div className={`p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>
        <div className='flex justify-between'>
            <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_previous.title")} <span className='text-sm text-neutral-500'>({t("order_and_pickup.o_announce_pickup.o_previous.subtitle")} {format(startdate, "dd/MM/yyyy")} {t("order_and_pickup.o_announce_pickup.o_previous.subtitle_p2")} {format(enddate, "dd/MM/yyyy")})</span></h2>
            {view_full ? <button onClick={()=>{setview_full(prev=>!prev)}} className='w-10 h-10 flex justify-between items-center'><Eye className='w-5 text-neutral-500'/></button> : <button onClick={()=>{setview_full(prev=>!prev)}} className='w-10 h-10 flex justify-between items-center'><EyeOff className='w-5 text-neutral-500'/></button>}
        </div>

        <div className={`${view_full ? 'max-h-screen' : 'max-h-[0px]'} overflow-hidden duration-300`}>
        {(!announcements || announcements.length < 1) 
        ? <>{isFetching ? <LoadingScreen className='h-[250px]'/> : <p>{t("order_and_pickup.o_announce_pickup.o_previous.no_found")}</p>}</>
        :
        <div className='border rounded-sm mt-4'>
            <Table className='border-collapse'>
                <TableHeader className='bg-slate-100'>
                    <TableRow className='uppercase text-muted-foreground font-medium'>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.partner")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.customer")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.customer_code")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.type")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.amount")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.keepsafe")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.date_collection")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.date_created")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.status")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.created_by")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.reference")}</TableHead>
                        <TableHead>{t("order_and_pickup.o_announce_pickup.o_previous.auto_ref")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {announcements.slice(0,5).map((announcement, index) => {
                        let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === announcement.relnr))
                        let partner = partners.find(obj => obj.relnr === relation?.id)
                        let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === announcement.relnr)

                        return (
                            <TableRow onClick={()=>{navigate(`${announcement.id}`)}} key={index} className='cursor-pointer'>
                                <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                <TableCell className='whitespace-nowrap'>{announcement.name}</TableCell>
                                <TableCell>{customer?.clientcode}</TableCell>
                                <TableCell>{announcement.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_announce_pickup.o_previous.coins") : t("order_and_pickup.o_announce_pickup.o_previous.notes")}</TableCell>
                                <TableCell>{formatCashAmount(announcement.amount)}</TableCell>
                                <TableCell>{announcement.keepsafe}</TableCell>
                                <TableCell>{format(convertToDate(announcement.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                                <TableCell>{format(convertToDate(announcement.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(announcement.tmcreation)}</TableCell>
                                <TableCell>{getOrderDraftState(announcement.status, orderdraftstate)?.description}</TableCell>
                                <TableCell>{announcement.username}</TableCell>
                                <TableCell>{announcement.reference}</TableCell>
                                <TableCell>{announcement.autoreference}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
        }
        </div>
    </div>
    </>
  )
}

export default PreviousPickups