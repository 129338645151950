import React, { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { NumberedStep, ProgressSteps } from '../../../../../components/ui/progress-steps'
import { Button } from '../../../../../components/ui/button';
import { cn } from '../../../../../lib/utils';
import { Download, Undo, UploadIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Input } from '../../../../../components/ui/input';
import Spreadsheet, { Matrix } from 'react-spreadsheet'
import {read, utils, write} from 'xlsx';
import toast, { Toaster } from 'react-hot-toast';
import axios from '../../../../../lib/axios';
import useAuth from '../../../../../hooks/useAuth';
import { Label } from '../../../../../components/ui/label';
import { Combobox } from '../../../../../components/ui/combobox';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { useQuery } from '@tanstack/react-query';
import { subMonths } from 'date-fns';
import { formatDateToYYYYMMDD } from '../../../../../lib/date';
import { useTranslation } from 'react-i18next';

type Props = {
}

const Spread : React.FC<Props> = ({}) => {
  const partners = useSelector((state:RootState)=>state.partners);
  const {t} = useTranslation()
  const {auth} = useAuth()

  const [excel_data, setexcel_data] = useState<any>();
  const [layout_data, setlayout_data] = useState<any>();
  const changed_data = useRef<any>();
  const [is_uploading, setis_uploading] = useState(false);

  const [selected_partner, setselected_partner] = useState<string | null>(null);

  const { refetch } = useQuery<AnnouncementDraftSearch[] | null>({
    queryKey:['announcement_concept'], 
    queryFn: async () => {
        try {
            const object = {
                token: auth.data?.token,
                startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                enddate: formatDateToYYYYMMDD(new Date()),
                partner: auth.data?.partner,
                searchby: 'C',
                status: '001'
            }
            const { data } = await axios.post(`announcement/draft/search`, object)
            //console.log(object)
            //console.log(data)
            if(data.errorcode === 0 && Array.isArray(data.object.announcementdraft)){
                return data.object.announcementdraft;
            }
            else {
                throw new Error(data.message)
            }
        } catch (error) {
            toast.error(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_error"))
            //console.log(error)
            
        }
        return null;
    },
    enabled: !!auth.data
  })

  useEffect(()=>{
    if(partners && !selected_partner && partners[0]) {
      setselected_partner(partners[0].relnr.toString())
    }
  }, [partners])

  const downloadTemplate = async () => {
    try {
      toast.loading(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_loading"), {id:'downloadTemplate'})
      const {data} = await axios.post(`announcement/draft/template`, {token:auth.data?.token})
      if(data.errorcode === 0){window.open(data.object.filename, '_blank'); toast.success(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_success"), {id:'downloadTemplate'}) }
      else { console.log(data); toast.error(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_error_p2"), {id:'downloadTemplate'}) }
    } catch (error) {
      toast.error(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_error_p2"), {id:'downloadTemplate'})
      console.log(error);
    }
  }

  interface UploadProps extends HTMLAttributes<HTMLDivElement> {}
  const Upload : React.FC<UploadProps> = ({className, ...props}) => {
    return (
      <div className={cn('flex flex-col gap-4', className)} {...props}>
        <div className='flex flex-col gap-1'>
            <Label htmlFor='partner'>{t("order_and_pickup.o_announce_pickup.o_spreadsheet.partner")}</Label>
            <Combobox className='w-fit' id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { setselected_partner(value.value.toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("order_and_pickup.o_announce_pickup.o_spreadsheet.partner_placeholder") || ""} input_placeholder={t("order_and_pickup.o_announce_pickup.o_spreadsheet.search_placeholder") || ""}/>
        </div>

        {!excel_data && 
        <div className='flex gap-4 flex-wrap'>
        <Input onChange={handleFileInputChange} type='file' accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" className='cursor-pointer w-fit'/> 
        <Button onClick={downloadTemplate} variant='secondary' className='flex gap-1'><Download className='w-5' strokeWidth={1.5}/>{t("order_and_pickup.o_announce_pickup.o_spreadsheet.button_download")}</Button>
        </div>
        }
        {excel_data && 
        <>
          <Button onClick={()=>{setexcel_data(undefined)}} variant='outline' className='w-fit flex gap-2'><Undo className='w-5' strokeWidth={1.5}/>{t("order_and_pickup.o_announce_pickup.o_spreadsheet.button_undo")}</Button>
          <Spreadsheet data={excel_data} onChange={(data)=>{changed_data.current = data}} className='!overflow-auto'/>
        </>
        }
      </div>
    )
  }

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = e.target?.result;
        if (typeof data === 'string') {
          const workbook = read(data, { type: 'binary' });

          const layoutSheet =  workbook.SheetNames[0]
          const layout = workbook.Sheets[layoutSheet];
          const layoutData = utils.sheet_to_json(layout, {
            header: 1,
            defval: '', // Set empty cells to an empty string
          }).map((row: any) =>
            row.map((cell: any) => ({
              value: cell || '', // Ensure each value is an object with the specified structure
            }))
          );
          setlayout_data(layoutData)

          const sheetName = workbook.SheetNames[1];
          const sheet = workbook.Sheets[sheetName];
          const sheetData = utils.sheet_to_json(sheet, {
            header: 1,
            defval: '', // Set empty cells to an empty string
          }).map((row: any) =>
            row.map((cell: any) => ({
              value: cell || '', // Ensure each value is an object with the specified structure
            }))
          );

          if(!sheetData[1]) {
            sheetData[1] = Array(sheetData[0].length).fill({value:''})
          }
        
          setexcel_data(sheetData)
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleConvertToExcel = (data:any) => {
    const layoutSheet = utils.aoa_to_sheet(
      layout_data.map((row: any[]) => row.map((cell: any) => cell.value))
    );
    const worksheet = utils.aoa_to_sheet(
      data.map((row: any[]) => row.map((cell: any) => cell.value))
    );

    const newWorkbook = utils.book_new();
    utils.book_append_sheet(newWorkbook, layoutSheet, 'LAYOUT');
    utils.book_append_sheet(newWorkbook, worksheet, 'ANNOUNCE');

    const excelData = write(newWorkbook, { bookType: 'xlsx', type: 'base64' });
    return excelData;
  };

  const onUpload = async () => {
    //Convert excel arrays to base64
    let base = ''
    if(changed_data.current) { 
      //Data is changed
      base = handleConvertToExcel(changed_data.current)
      setexcel_data(changed_data.current)
    } else {
      //Use uploaded file
      base = handleConvertToExcel(excel_data)
    }

    await importAnnouncement(base);
    refetch()

    setlayout_data(null);
    setexcel_data(undefined);
    changed_data.current = null;
  }

  const importAnnouncement = async (base:string) => {
    try {
      toast.loading(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_loading_p2"), { id:'importAnnouncement' })
      setis_uploading(true)
      const {data} = await axios.post(`announcement/draft/import`, {
        token: auth.data?.token,
        file: base,
        partner: selected_partner
      })
      if(data.errorcode === 0) {
        toast.success(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_success_p2"), { id:'importAnnouncement' })
      }
      else {
        console.log(data);
        toast.error(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_error_p3"), { id:'importAnnouncement', duration:5000 })
      }
    } catch (error) {
      console.log(error);
      toast.error(t("order_and_pickup.o_announce_pickup.o_spreadsheet.toast_error_p3"), { id:'importAnnouncement', duration:5000 })
    } finally {
      setis_uploading(false)
    }
  }
  
  return (
    <>
    <Toaster />
    <div>
      <div className='mb-4'>
        <Upload />
      </div>
      
      <Button disabled={excel_data === undefined || is_uploading || selected_partner === null } onClick={()=>{onUpload()}}>{!is_uploading ? t("order_and_pickup.o_announce_pickup.o_spreadsheet.button_upload") : t("order_and_pickup.o_announce_pickup.o_spreadsheet.button_loading")}</Button>
    </div>
    </>
  )
}

export default Spread