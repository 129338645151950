import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'

import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Tablist, TablistItem } from '../../../../../components/ui/tablist'
import Denom from './detail/Denom'
import { isInterface } from '../../../../../lib/utils'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import OrderSettings from './detail/OrderSettings'
import Consumables from './detail/Consumables'
import ValidateOrders from './detail/ValidateOrders'
import { createDefaultDenom, repairCurrentDenom } from '../../../../../lib/fetch/denom'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'

const Detail = () => {
    const {partnerid} = useParams()
    const {auth} = useAuth()
    const {t} = useTranslation()
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    let partner = partners.find(obj => obj.relnr === parseFloat(partnerid || ''))

    const [selected_subpage, setselected_subpage] = useState<Subpage>('denom');

    const {data:denom, isFetching:denom_isFetching, refetch:refetch_denom} = useQuery<PartnerDenom | null>({
        queryKey: [`denom_${partnerid}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/denom/search`, {
                    token: auth.data?.token,
                    partner: partnerid
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(data.object==='' || !data.object[0].denom){
                    //Empty
                    const defaultDenom = await createDefaultDenom(partnerid || '', auth)
                    defaultDenom.coins.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})
                    defaultDenom.notes.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})
                    return defaultDenom;
                }

                let result = data.object[0].denom
                if(!result.notes || !result.coins || (Array.isArray(result.notes) && result?.notes?.length < 7) || (Array.isArray(result?.coins) && result?.coins.length < 8) || !Object.keys(result).includes('announcecoins') || !Object.keys(result).includes('announcenotes') || !Object.keys(result).includes('ordercoins') || !Object.keys(result).includes('ordernotes')){
                    // Denom not complete
                    const repairedDenom = await repairCurrentDenom(partnerid ||'', auth, result)
                    repairedDenom.coins.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})
                    repairedDenom.notes.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})
                    return repairedDenom
                }

                result.coins.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})
                result.notes.sort((a:PDenom,b:PDenom)=>{return b.coupure - a.coupure})

                return result
            } catch (error) {
                console.log(error)
                return null;
            }
        }
    })

    const {data:partner_data, isFetching:partner_isFetching, refetch:refetch_partner} = useQuery<Partner | null>({
        queryKey: [`partner_${partnerid}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/search`, {
                    token: auth.data?.token,
                    partner: partnerid
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.partner){throw new Error('empty')}
                return data.object.partner[0]
            } catch (error) {
                console.log(error)
                return null
            }
        }
    })

    const {data:articles, isFetching:articles_isFetching} = useQuery<Article[] | null>({
        queryKey: ['articles'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`article/search`, {token:auth.data?.token})
                if(data.errorcode!==0){throw new Error(data.message)}
                return data.object.article
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    const {data:articles_group, isFetching:articlesgroup_isFetching} = useQuery<ArticleGroup[] | null>({
        queryKey: ['articlesgroup'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`articlegroup/search`, {token:auth.data?.token})
                if(data.errorcode!==0){throw new Error(data.message)}
                return data.object.articlegroup
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    const {data:partner_users, isFetching:partnerusers_isFetching} = useQuery<User[] | 'empty' | null>({
        queryKey: [`partner_users_${partnerid}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`user/search`, {
                    token: auth.data?.token,
                    role: 2, //partner admin
                    partner: partnerid
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.user){return 'empty';} //No users found with partnerid
                return data.object.user
            } catch (error) {
                console.log(error)
                return null;
            }
        }
    })

    const {data:validate_users, isFetching:validateusers_isFetching, refetch:refetchValidaters} = useQuery<ValidationUser | null>({
        queryKey: [`validate_user_${partnerid}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/validation/search`, {
                    token: auth.data?.token,
                    partner: partnerid
                })

                if(data.errorcode!==0){throw new Error()}
                if(!data.object.validation){return null;} //empty

                const groupedArray:ValidationUser[] = [];

                data.object.validation.forEach((item:any) => {
                    const existingGroup = groupedArray.find(group => group.partner === item.partner);

                    if (existingGroup) {
                        existingGroup.users.push({ user: item.users[0].user });
                    } else {
                        groupedArray.push({
                            partner: item.partner,
                            users: [{ user: item.users[0].user }],
                        });
                    }
                });

                if(!groupedArray[0]){ throw new Error('empty') }
                const i = groupedArray[0]

                return i
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    useEffect(()=>{
        if(!denom){
          const instance = lottie.loadAnimation({
            container: errorContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: errorAnimation
          })
          return () => instance.destroy()
        }
      }, [denom])

  return (
    <>
        <Toaster />        
        
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/instellingen')}} className='cursor-pointer'>{t("settings.title")}</p>}, {title:<p onClick={()=>{navigate('..')}} className='cursor-pointer'>{t("settings.settings")}</p>}, {title:`${t("settings.partner")} ${partnerid}`}]}/>
                <h1 className='text-2xl font-semibold'>{t("settings.settings")}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("settings.title_detail")}</h2>
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("settings.partner_nr")}</p>
                        <p className='text-neutral-500'>{partnerid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("settings.partner")}</p>
                        <p className='text-neutral-500'>{partner?.name}</p>
                    </div>
                </div>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm py-4 mt-4 md:mt-8'>
                <Tablist value={selected_subpage} onValueChange={(value)=>{ setselected_subpage(value as Subpage) }}>
                    <TablistItem value='denom'>{t("settings.denom")}</TablistItem>
                    <TablistItem value='order'>{t("settings.order")}</TablistItem>
                    <TablistItem value='consumables'>{t("settings.consumables")}</TablistItem>
                    <TablistItem value='validate'>{t("settings.validate")}</TablistItem>
                </Tablist>
                <div className='px-4'>
                    {selected_subpage === 'denom' && 
                        <>{isInterface(denom, {} as PartnerDenom) 
                        ? <Denom denom={denom} refetch={()=>{refetch_denom()}}/> 
                        : <>{denom_isFetching 
                            ? <SpinnerFeedback /> 
                            : <ErrorFeedback />}</>}
                        </>
                    }

                    {selected_subpage === 'order' && 
                    <>
                        <>{(isInterface(partner_data, {} as Partner) && isInterface(denom, {} as PartnerDenom) && isInterface(articles, {} as Article[])) && <OrderSettings articles={articles} ordersettings={partner_data} denom={denom} refetch_denom={()=>{refetch_denom()}} refetch_ordersettings={()=>{refetch_partner()}} />}</>
                        <>{(!isInterface(partner_data, {} as Partner) || !isInterface(denom, {} as PartnerDenom)) && ((partner_isFetching || denom_isFetching) ? <SpinnerFeedback /> : <ErrorFeedback />)}</>
                    </>
                    }

                    {selected_subpage === 'consumables' &&
                    <>
                    {(isInterface(partner_data, {} as Partner) && isInterface(articles, {} as Article[]) && isInterface(articles_group, {} as ArticleGroup[]))
                    ?
                    <Consumables ordersettings={partner_data} articlesgroup={articles_group} articles={articles} refetch_ordersettings={()=>{refetch_partner()}}/>
                    :
                    <>
                    {(partner_isFetching || articles_isFetching || articlesgroup_isFetching) 
                        ? <SpinnerFeedback /> 
                        : <ErrorFeedback />
                    }
                    </> 
                    }
                    </>
                    }

                    {selected_subpage === 'validate' &&
                    <>
                        {(isInterface(partner_users, {} as User[])) && <ValidateOrders partnerid={partnerid || ''} validaters={validate_users} users={partner_users} refetch={()=>{refetchValidaters()}}/>}
                        {(!isInterface(partner_users, {} as User[])) && 
                            <>{(partnerusers_isFetching || validateusers_isFetching)
                            ? <SpinnerFeedback /> 
                            : <>{partner_users === 'empty' ? <EmptyFeedback message={t("settings.no_partneradmin")}/> : <ErrorFeedback />} </>}
                            </>
                        }
                    </>
                    }
                </div>
            </div>
        </div>
        
        
    </>
  )
}

type Subpage = 'denom' | 'order' | 'consumables' | 'validate'

export default Detail