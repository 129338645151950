import axios from '../../../../lib/axios'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import useAuth from '../../../../hooks/useAuth'
import { Toaster, toast } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { convertToDate } from '../../../../lib/date'
import { format, isValid } from 'date-fns'
import { Loader2, Zap } from 'lucide-react'
import { formatCashAmount, isInterface } from '../../../../lib/utils'
import SpinnerFeedback from '../../../../components/portal/feedback/SpinnerFeedback'
import ErrorFeedback from '../../../../components/portal/feedback/ErrorFeedback'
import EmptyFeedback from '../../../../components/portal/feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'

const visibleKeys = ["id", "barcode", "relnr", "amount", "state", "dtcreated", "ismkpsafe", "type", "department", "dtmodif", "dtarchive"]

interface History {
    id:            number;
    dtcreation:    Date;
    tmcreation:    string;
    value_old:     string;
    value:         string;
    vault:         string;
    function_code: string;
    function:      string;
    boxid:         number;
    boxbarcode:    string;
    extrainfo:     string;
    reason_onhold: string;
}

const Detail = () => {
    const { t } = useTranslation()
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { colliid } = useParams()
    
    const [colli, setcolli] = useState<Colli | StateType>(null);
    const [history, sethistory] = useState<History[] | StateType>(null);

    useEffect(()=>{
        if(colliid){
            fetch_colli()
            fetch_history()
        }
    }, [colliid])

    async function fetch_history() {
        try {
            sethistory('loading')
            const { data } = await axios.post(`colli/history`, {
                token: auth.data?.token,
                colliid: colliid,
                language: auth.data?.language
            })
            if(data.errorcode === 0){
                sethistory(data.object.history)
            }
            else {
                toast.error(`Error [${data.errorcode}]: ${data.message}`)
                sethistory('error')
            }
        } catch (error) {
            toast.error(t("track_and_trace.o_number.toast_wrong_history"))
            sethistory('error')
            console.log(error)
        }
    }

    async function fetch_colli() {
        try {
            setcolli('loading')
            const { data } = await axios.post(`colli/search`, {
                token: auth.data?.token,
                id: colliid,
                equal: 1
            })
            if(data.errorcode === 0) {
                if(Array.isArray(data.object.colli)){
                    setcolli(data.object.colli[0])
                }
                else {
                    setcolli('error')
                    toast.error(t("track_and_trace.o_number.toast_wrong_colli"))
                }
            }
            else {
                toast.error(`Error [${data.errorcode}]: ${data.message}`)
                setcolli('error')
            }
        } catch (error) {
            toast.error(t("track_and_trace.o_number.toast_wrong_colli"))
            console.log(error)
        }
    }

    return (
        <>
        <Toaster />
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_number.link_tt")}</p>}, {title:<p onClick={()=>{navigate(-1)}} className='cursor-pointer'>{t("track_and_trace.o_number.link_number")}</p>}, {title:`${colliid}`}]}/>
                <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_number.link_tt")}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_number.title_detail_colli")}</h2>
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    {colli === 'loading' && <SpinnerFeedback />} 
                    {colli === 'error' && <ErrorFeedback />}
                    {colli === 'empty' && <EmptyFeedback />}
                    {isInterface(colli, {} as Colli) && 
                    <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>Id</p>
                            <p className='text-neutral-500'>{colli.id}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.barcode")}</p>
                            <p className='text-neutral-500'>{colli.barcode}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.mkpsafe")}</p>
                            <p className='text-neutral-500'>{colli.isMkpsafe ? t("track_and_trace.o_number.yes") : t("track_and_trace.o_number.no")}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.relation")}</p>
                            <p className='text-neutral-500'>{colli.relnr}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.department")}</p>
                            <p className='text-neutral-500'>{colli.department}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.amount")}</p>
                            <p className='text-neutral-500'>{formatCashAmount(colli.amount)}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.date_edit")}</p>
                            <p className='text-neutral-500'>{isValid(new Date(colli.dtmodif)) && format(new Date(colli.dtmodif), "dd/MM/yyyy")}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.type")}</p>
                            <p className='text-neutral-500'>{colli.type}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.status")}</p>
                            <p className='text-neutral-500'>{colli.state}</p>
                        </div>
                        <div className='flex gap-2'>
                            <p className='font-semibold'>{t("track_and_trace.o_number.date_archived")}</p>
                            <p className='text-neutral-500'>{colli.dtarchive}</p>
                        </div>
                    </div>
                    }
                </div>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_number.history")}</h2>
                {history === 'loading' && <SpinnerFeedback />}
                {history === 'error' && <ErrorFeedback />}
                {history === 'empty' && <EmptyFeedback />}
                {isInterface(history, {} as History[]) && 
                <div className='flex flex-col gap-4'>
                    {history.map((event, index) => {
                        const date = convertToDate(event.dtcreation.toString())
                        return (

                            <div key={index} className='flex gap-4'>
                                <div className='flex flex-col items-center gap-2'>
                                    <div className='w-7 h-7 bg-primary/25 border border-primary flex justify-center items-center rounded-full'><Zap className='w-4 text-red-800'/></div>
                                    <div className='bg-neutral-300 w-px h-full'></div>
                                </div>
                                <div className='w-full'>
                                    <div className='flex items-center gap-1'>
                                        <p className='font-semibold'>{event.function}</p>
                                        <p className='text-neutral-500 text-sm'>op</p>
                                        <p className='font-semibold'>{date && format(date, "dd/MM/yyyy")} <span className='text-neutral-500 font-normal text-sm'>om</span> {event.tmcreation.substring(0,5)}</p>
                                    </div>
                                    
                                    
                                    <div className='bg-neutral-50 p-4 rounded-sm border'>
                                        {event.extrainfo && <p className='mb-2'>{event.extrainfo}</p>}
                                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 '>
                                            <div className='w-full border bg-white rounded-sm p-2'>
                                                <p className='bg-orange-100 text-orange-700 font-semibold text-sm rounded-full w-fit px-2'>{t("track_and_trace.o_number.old_value")}</p>
                                                <p className='ml-2'>{event.value_old}</p>
                                            </div>
                                            <div className='w-full border bg-white rounded-sm p-2'>
                                                <p className='bg-green-100 text-green-700 font-semibold text-sm rounded-full w-fit px-2'>{t("track_and_trace.o_number.new_value")}</p>
                                                <p className='ml-2'>{event.value}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                }
            </div>

        </div>
        </>
    )
}

export default Detail