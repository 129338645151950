import { ActionType, Action } from "../action-types";

const initialState:Filter[] = [] 

const filtersReducer = (state:Filter[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.ADD_FILTER:
            const existingFilterIndex = state.findIndex(
                (item) => item.page === action.payload.page
              );
        
              if (existingFilterIndex !== -1) {
                const updatedFilter = state.map((item, index) => {
                  if (index === existingFilterIndex) {
                    const updatedFilterDetailIndex = item.filter.findIndex(
                      (detail) => detail.key === action.payload.filterDetail.key
                    );
        
                    if (updatedFilterDetailIndex !== -1) {
                      return {
                        ...item,
                        filter: item.filter.map((detail, detailIndex) => {
                          if (detailIndex === updatedFilterDetailIndex) {
                            return {
                              ...detail,
                              value: action.payload.filterDetail.value,
                            };
                          }
                          return detail;
                        }),
                      };
                    }
        
                    return {
                      ...item,
                      filter: [...item.filter, action.payload.filterDetail],
                    };
                  }
                  return item;
                });
        
                return updatedFilter;
              }
        
              return [
                ...state,
                {
                  page: action.payload.page,
                  filter: [action.payload.filterDetail],
                },
              ];

        case ActionType.EDIT_FILTER:
            return state.map((item) => {
                if (item.page === action.payload.page) {
                  const updatedFilter = item.filter.map((detail) => {
                    if (detail.key === action.payload.filterDetail.key) {
                      return {
                        ...detail,
                        value: action.payload.filterDetail.value,
                      };
                    }
                    return detail;
                  });
                  return {
                    ...item,
                    filter: updatedFilter,
                  };
                }
                return item;
            });
        
        case ActionType.REMOVE_FILTER:
            return state.map((item) => {
                if (item.page === action.payload.page) {
                  const updatedFilter = item.filter.filter(
                    (detail) => detail.key !== action.payload.key
                  );
                  return {
                    ...item,
                    filter: updatedFilter,
                  };
                }
                return item;
            });

        default:
            return state;
    }
}

export default filtersReducer;