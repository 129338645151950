import { ActionType, Action } from "../action-types";

const initialState:PartnerCustomers[] = [];

const customersReducer = (state:PartnerCustomers[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_CUSTOMERS:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default customersReducer;