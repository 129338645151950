import { useQuery } from '@tanstack/react-query'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast';
import { Link, useNavigate, useParams } from 'react-router-dom'
import { formatCashAmount, isInterface } from '../../../../../lib/utils';
import { Breadcrumb } from '../../../../../components/ui/breadcrumb';
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import axios from '../../../../../lib/axios';
import useAuth from '../../../../../hooks/useAuth';
import { convertToDate, formatDateToYYYYMMDD } from '../../../../../lib/date';
import { addDays, format } from 'date-fns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { Tablist, TablistItem } from '../../../../../components/ui/tablist';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table';
import { Button } from '../../../../../components/ui/button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { repairATMService } from '../../../../../lib/atm';

const Detail = () => {
    const {atmdate, atmid, interventioncode} = useParams();
    const {t} = useTranslation()
    const {auth} = useAuth()
    const navigate = useNavigate();
    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.customers);

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    const [selected_subpage, setselected_subpage] = useState('d')
    const [selected_document, setselected_document] = useState(0);

    const {data:detail, isFetching} = useQuery<ATMService | null>({
        queryKey:[`trloadatm2t_${atmid}_${atmdate}_${interventioncode}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`trloadatm2t/search`, {
                    token: auth.data?.token,
                    atmid: atmid,
                    startdate: atmdate,
                    enddate: formatDateToYYYYMMDD(addDays(convertToDate(atmdate || '') || new Date(), 3))
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.service){toast.error(t("sip.o_atm.toast_no_detail")); return null;}

                let date = '';
                if(atmdate){ date = format((convertToDate(atmdate) || new Date), "yyyy-MM-dd") }
                
                let result = data.object.service
                result = result.filter((s:ATMService) => s.date === date) || []
                result = result.filter((s:ATMService) => s.idautomate === atmid) || []
                result = result.filter((s:ATMService) => s.interventioncode === interventioncode) || []
                if(result.length === 1){ 
                    const repaired = repairATMService(result[0])
                    return repaired
                }

                const repaired = repairATMService(data.object.service[0])
                return repaired
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    const {data:document, isFetching:isFetching_document} = useQuery<string[] | null>({
        queryKey:[`intervention_document_${atmid}_${atmdate}_${interventioncode}`],
        queryFn: async () => {
            try {
                if(!detail?.interventioncode){ return null;}
                toast.loading(`${t("sip.o_atm.toast_loading_rapport")}`, {id:'interventionDocument'})
                const {data} = await axios.post(`intervention/document`, {
                    token: auth.data?.token,
                    code: detail?.interventioncode
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.remove(`interventionDocument`)
                if(!data.object.filename){return null}
                return data.object.filename
            } catch (error) {
                toast.error(`${t("sip.o_atm.toast_error_rapport")}`, {id:'interventionDocument'})
                console.log(error);
                return null
            }
        },
        enabled: !!detail,
        refetchOnWindowFocus: false
    })

    let relation = detail && customers.find(obj => obj.eindklant.some(e => e.clientcode === detail.clientcode))
    let partner = partners.find(obj => obj.relnr === relation?.id)

    useEffect(() => {
        if (!detail) {
            const instance = lottie.loadAnimation({
                container: errorContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: errorAnimation
            })
            return () => instance.destroy()
        }
    }, [detail])

    const Detail = () => {
        function calculateSum(detail:ATMServiceDetail) {
            let total = 0;
            total = detail.AMOUNT_EUR_5_1 + detail.AMOUNT_EUR_5_2 + detail.AMOUNT_EUR_10_1 + detail.AMOUNT_EUR_10_2 + detail.AMOUNT_EUR_20_1 + detail.AMOUNT_EUR_20_2 + detail.AMOUNT_EUR_50_1 + detail.AMOUNT_EUR_50_2 + detail.AMOUNT_EUR_100_1 + detail.AMOUNT_EUR_100_2 + detail.AMOUNT_EUR_200_1 + detail.AMOUNT_EUR_200_2 + detail.AMOUNT_EUR_500_1 + detail.AMOUNT_EUR_500_2
            return total;
        }

        return (
            <div className='rounded-sm'>
                <Table>
                    <TableHeader className='bg-slate-100 relative'>
                        <TableRow>
                            <TableHead className='border uppercase'></TableHead>
                            <TableHead className='text-slate-700 border whitespace-nowrap'>{t("sip.o_atm.keepsafe_nr")}</TableHead>
                            <TableHead colSpan={2} className='text-slate-800 border whitespace-nowrap'>{t("sip.o_atm.count")} €5</TableHead>
                            <TableHead colSpan={2} className='text-slate-800 border whitespace-nowrap'>{t("sip.o_atm.count")} €10</TableHead>
                            <TableHead colSpan={2} className='text-slate-800 border whitespace-nowrap'>{t("sip.o_atm.count")} €20</TableHead>
                            <TableHead colSpan={2} className='text-slate-800 border whitespace-nowrap'>{t("sip.o_atm.count")} €50</TableHead>
                            <TableHead colSpan={2} className='text-slate-700 border whitespace-nowrap'>{t("sip.o_atm.count")} €100</TableHead>
                            <TableHead colSpan={2} className='text-slate-700 border whitespace-nowrap'>{t("sip.o_atm.count")} €200</TableHead>
                            <TableHead colSpan={2} className='text-slate-700 border whitespace-nowrap'>{t("sip.o_atm.count")} €500</TableHead>
                            <TableHead className='text-slate-700 border whitespace-nowrap'>{t("sip.o_atm.total")} €</TableHead>
                        </TableRow>
                        <TableRow>
                            <TableHead className='h-fit p-3 border uppercase'></TableHead>
                            <TableHead className='h-fit p-3 border uppercase'></TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 1</TableHead>
                            <TableHead className='h-fit p-3 border uppercase text-xs whitespace-nowrap'>{t("sip.o_atm.cassette")} 2</TableHead>
                            <TableHead className='h-fit p-3 border uppercase'></TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody className='text-sm'>
                        {detail?.detail.map((value, index) => {
                            return (
                                <TableRow className={`${index%2 && 'bg-red-50'}`} key={index}>
                                    <TableCell className='p-3 border whitespace-nowrap capitalize'>{value.rubriekoms}</TableCell>
                                    <TableCell className='text-left p-3 border'>{value.keepsafe}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_5_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_5_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_10_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_10_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_20_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_20_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_50_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_50_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_100_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_100_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_200_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_200_2}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_500_1}</TableCell>
                                    <TableCell className='text-right p-3 border'>{value.NOTES_EUR_500_2}</TableCell>
                                    <TableCell className='text-right p-3 border font-semibold'>{formatCashAmount(calculateSum(value))}</TableCell>
                                </TableRow>
                            )
                        })}             
                    </TableBody>
                </Table>
            </div>
        )
    }

    const Rapport = () => {
        return (
            <div className='flex flex-col gap-4'>
                {(!document && isFetching_document) && <p>{t("sip.o_atm.loading")}</p>}
                {document && 
                <>
                {document.length > 1 && 
                <div className='flex gap-2'>
                    <button disabled={selected_document===0} onClick={()=>{setselected_document(prev=>prev-1)}} className='border rounded-full h-10 w-10 flex justify-center items-center text-neutral-500 hover:text-primary hover:bg-red-50 duration-100 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronLeft className='w-5' /></button>
                    <button disabled={selected_document===(document.length-1)} onClick={()=>{setselected_document(prev=>prev+1)}} className='border rounded-full h-10 w-10 flex justify-center items-center text-neutral-500 hover:text-primary hover:bg-red-50 duration-100 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronRight className='w-5' /></button>
                </div> 
                }
                <div className='min-h-[500px] overflow-hidden border rounded-sm relative'>
                    {(document[selected_document].toLowerCase().includes('jpg') || document[selected_document].toLowerCase().includes('jpeg') || document[selected_document].toLowerCase().includes('png')) 
                    ? <img src={document[selected_document]} />
                    :
                    <object className='min-h-[500px] h-full w-full' data={document[selected_document]} type='application/pdf'>
                        <p>{t("sip.o_atm.no_preview")}</p>
                        <Link to={document[selected_document]}><Button>{t("sip.o_atm.download")}</Button></Link>
                    </object>
                    }
                    
                </div>
                </>
                }
            </div>
        )
    }

  return (
    <>
    <Toaster />

    {((isFetching) && (!isInterface(detail, {} as ATMService))) &&
        <LoadingScreen />
    }

    {(detail === null) &&
        <div className={`flex flex-col items-center justify-center`}>
            <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
            <h2 className='text-2xl font-semibold mb-4'>{t("sip.o_atm.error_title")}</h2>
            <p className='text-center'>{t("sip.o_atm.error_description")}<br></br>{t("sip.o_atm.error_description_p2")} <Link to='/sip/beheer-atm' className='font-medium underline'>{t("sip.o_atm.error_description_p3")}</Link> {t("sip.o_atm.error_description_p4")}</p>
        </div>
    }

    {(isInterface(detail, {} as ATMService)) &&
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{ title: <p onClick={() => { navigate('/sip') }} className='cursor-pointer'>{t("sip.o_atm.link_sip")}</p> }, { title: <p onClick={() => { navigate('/sip/beheer-atm') }} className='cursor-pointer'>{t("sip.o_atm.link_atm")}</p> }, { title: `${t("sip.o_atm.link_detail")} ${atmid}` }]} />
                <h1 className='text-2xl font-semibold'>{t("sip.o_atm.title_detail")}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("sip.o_atm.title_detail")}</h2>
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_atm.partner")}</p>
                        <p className='text-neutral-500'>{partner?.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_atm.customer")}</p>
                        <p className='text-neutral-500'>{detail.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_atm.atm_id")}</p>
                        <p className='text-neutral-500'>{atmid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_atm.date_intervention")}</p>
                        <p className='text-neutral-500'>{format(convertToDate(atmdate ||'') || new Date(), "dd/MM/yyyy")}</p>
                    </div>
                </div>
            </div>

            <div className='flex border flex-col rounded-sm pt-3 mt-4 md:mt-8'>
                <Tablist value={selected_subpage} onValueChange={(v)=>{setselected_subpage(v)}}>
                    <TablistItem value='d'>{t("sip.o_atm.detail")}</TablistItem>
                    {document && <TablistItem value='r'>{t("sip.o_atm.rapport")}</TablistItem>}
                </Tablist>

                <div className='p-4'>
                    {selected_subpage === 'd' && <Detail />}
                    {selected_subpage === 'r' && <Rapport />}
                </div>
            </div>
        </div>
    }
    </>
  )
}

export default Detail