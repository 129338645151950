import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import useAuth from '../../../../../hooks/useAuth'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { splitArrayIntoChunks } from '../../../../../lib/utils'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Pagination } from '../../../../../components/ui/pagination'
import { useTranslation } from 'react-i18next'

type Props = {
    assets: Asset[]
}

const AssetsTable : React.FC<Props> = ({assets}) => {
    const {auth} = useAuth();
    const {t} = useTranslation()
    const navigate = useNavigate()
    const headerArr = [t("track_and_trace.partner_nr"), t("sip.o_assets.partner"), t("track_and_trace.customer_nr"), t("sip.o_assets.customer_code"), t("sip.o_assets.customer_p2"), t("sip.o_assets.atm_id"), t("sip.o_assets.brand"), t("sip.o_assets.model"), t("sip.o_assets.machinetype"), t("sip.o_assets.service_point"), t("sip.o_assets.amount_cassettes"), 'Denom cassette 1', 'Denom cassette 2', 'Denom cassette 3', 'Denom cassette 4', 'Denom cassette 5']


    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

  return (
    <>
        {auth.data?.right.export &&
            <div className='flex justify-end mt-4'>
                <ExportTable onDownload={(extension) => { createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(assets, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername',  'idautomate', 'brand', 'devicetype', 'machinetype', 'servicepoint', 'k7_number', 'k7_1_denomination', 'k7_2_denomination', 'k7_3_denomination', 'k7_4_denomination', 'k7_5_denomination'], {partners:partners, customers:customers, relnr:'relnr'}))) }} />
            </div>
        }

        <div className='my-4 border rounded-sm'>
            <Table>
                <TableHeader className='bg-slate-100 relative'>
                    <TableRow>
                        <TableHead className='uppercase'>{t("sip.o_assets.partner")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.customer_p2")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.customer_code")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.atm_id")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.brand")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.model")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.machinetype")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.service_point")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_assets.amount_cassettes")}</TableHead>
                        <TableHead className='uppercase'>Denom cassette 1</TableHead>
                        <TableHead className='uppercase'>Denom cassette 2</TableHead>
                        <TableHead className='uppercase'>Denom cassette 3</TableHead>
                        <TableHead className='uppercase'>Denom cassette 4</TableHead>
                        <TableHead className='uppercase'>Denom cassette 5</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                        {assets && splitArrayIntoChunks(assets, 20)[currentPage - 1] && splitArrayIntoChunks(assets, 20)[currentPage - 1].map((asset, index) => {
                            let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === asset.relnr))
                            let partner = partners.find(obj => obj.relnr === relation?.id)
                            let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === asset.relnr)

                            return (
                                <TableRow key={index}>
                                    <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                    <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                                    <TableCell>{customer?.clientcode}</TableCell>
                                    <TableCell>{asset.idautomate}</TableCell>
                                    <TableCell>{asset.brand}</TableCell>
                                    <TableCell>{asset.devicetype}</TableCell>
                                    <TableCell>{asset.machinetype}</TableCell>
                                    <TableCell>{asset.servicepoint}</TableCell>
                                    <TableCell>{asset.k7_number}</TableCell>
                                    <TableCell>{asset.k7_1_denomination}</TableCell>
                                    <TableCell>{asset.k7_2_denomination}</TableCell>
                                    <TableCell>{asset.k7_3_denomination}</TableCell>
                                    <TableCell>{asset.k7_4_denomination}</TableCell>
                                    <TableCell>{asset.k7_5_denomination}</TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </div>

        <Pagination totalItems={assets.length} itemsPerPage={20} page={currentPage} onPageChange={(page) => { setcurrentPage(parseFloat(page)) }} />
    </>
  )
}

export default AssetsTable