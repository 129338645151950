import { useEffect, useState } from "react";
import { ColumnSort } from "../components/ui/table";
import { filterArrayByValue } from "../lib/filter";
import { useSelector } from "react-redux";
import { RootState } from "../redux";
import lodash from 'lodash'

type Props<T, K extends keyof T = keyof T> = {
    data: T[];
    sorting?: ColumnSort;
    filter?: string;
    keysToFilterOn?: K[];
};

function useTable<T> (options: Props<T>) {
    const partners = useSelector((state:RootState)=>state.partners)
    const customers = useSelector((state:RootState)=>state.customers)

    const [data, setData] = useState(options.data);
    const [sorting, setSorting] = useState(options.sorting);
    const [filter, setFilter] = useState(options.filter);

    useEffect(() => {
        // Create a copy of the initial data to avoid modifying the original array
        let newData = [...options.data];

        // Sort the data based on the sorting state
        if (sorting) {
            // Sort the data based on the sorting state
            newData.sort((a, b) => {
                if (sorting) {
                    const { id, desc } = sorting;
                    if (typeof a[id as keyof T] === 'string' && typeof b[id as keyof T] === 'string') {
                        // For string columns, sort alphabetically
                        return desc ? (a[id as keyof T] as string).localeCompare(b[id as keyof T] as string) : (b[id as keyof T] as string).localeCompare(a[id as keyof T] as string);
                    } else {
                        // For numeric or other types, use regular comparison
                        return desc ? (b[id as keyof T] as number) - (a[id as keyof T] as number) : (a[id as keyof T] as number) - (b[id as keyof T] as number);
                    }
                } else {
                    return 0; // Sorting not provided, no change in order
                }
            });

            let customerFilter = filterCustomers(newData, filter || '')
            let partnerFilter = filterPartners(newData, filter || '')
            newData = filterArrayByValue(newData as object[], filter || '', (options.keysToFilterOn ? options.keysToFilterOn as string[] : undefined)) as T[]

            let combineAndRemoveDups = lodash.union(customerFilter, partnerFilter, newData)

            setData(combineAndRemoveDups);
        }

    }, [options.data, sorting, filter])

    // Function to update the sorting state
    const updateSorting = (newSorting: ColumnSort) => {
        setSorting(newSorting);
    };

    // Function to update the filter state
    const updateFilter = (newFilter:string) => {
        setFilter(newFilter);
    };

    function filterCustomers<T>(newData: T[],filter: string): T[] {
        let result = [...newData]

        try {
            //@ts-ignore
            const newDataRelnrs = newData.map(i=>i.relnr)
            //@ts-ignore
            const filteredCustomers = customers.flatMap(obj => obj.eindklant).filter(i=>newDataRelnrs.includes(i.relnr)).filter(i=>i.name.toLowerCase().includes(filter.toLowerCase())).map(i=>i.relnr)
            //@ts-ignore
            result = newData.filter(i=>filteredCustomers.includes(i.relnr))
        } catch (error) { }
      
        return result;
    }

    function filterPartners<T>(newData: T[],filter: string): T[] {
        let result = [...newData]

        try {
            //@ts-ignore
            const newDataRelnrs = newData.map(i=>i.relnr)

            const filteredPartnerIds = customers.flatMap(obj => obj.eindklant).filter(i=>newDataRelnrs.includes(i.relnr)).map(i=>i.partner)

            const filteredPartnerIdsByName = partners.filter(i=>filteredPartnerIds.includes(i.relnr)).filter(i=>i.name.toLowerCase().includes(filter.toLowerCase())).map(i=>i.relnr)

            const filteredCustomers = customers.filter(i=>filteredPartnerIdsByName.includes(i.id)).flatMap(obj => obj.eindklant).filter(i=>newDataRelnrs.includes(i.relnr)).map(i=>i.relnr)
            
            //@ts-ignore
            result = (result.filter(i=>filteredCustomers.includes(i.relnr)))
        } catch (error) {}
        
        return result
    }


    return { data, sorting, filter, updateSorting, updateFilter };
}


export default useTable;