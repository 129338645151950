import React, { useEffect, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date'
import { addDays, format, isValid, subDays, subWeeks } from 'date-fns'
import { isInterface } from '../../../../../lib/utils'
import { useQuery } from '@tanstack/react-query'
import { Button } from '../../../../../components/ui/button'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import lodash from 'lodash'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'
type Props = {
    user: User
}

const Logs : React.FC<Props> = ({user}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const { ref, inView } = useInView()

    const [page, setpage] = useState(0);
    const [all_logs, setall_logs] = useState<Log[]>([]);

    const initial_startdate = useRef(subWeeks(new Date(), 1))
    const initial_enddate = useRef(addDays(new Date(), 1))

    const {data, isFetching, refetch} = useQuery<Log[] | null | 'empty'>({
        queryKey: [`logs_${user.username}`],
        queryFn: async () => {
            try {
                let obj = {
                    token: auth.data?.token,
                    username: user.username,
                    startdate: formatDateToYYYYMMDD(initial_startdate.current),
                    enddate: formatDateToYYYYMMDD(initial_enddate.current)
                }
                const {data} = await axios.post(`log/search`, obj)
                
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.log){return 'empty'} //No logs found
                setall_logs(prev=>{ return lodash.uniqWith(prev.concat(data.object.log.reverse()), lodash.isEqual)})
            } catch (error) {
                console.log(error)
            }
            return null
        },
        enabled:false
    }) 

    useEffect(() => {
        if (inView && !isFetching) {
          setpage(prev=>prev+1)
        }
    }, [inView])

    useEffect(()=>{
        if(page !== 0){
            initial_startdate.current = subWeeks(initial_startdate.current, 1)
            initial_enddate.current = subWeeks(initial_enddate.current, 1)
        }
        refetch()
    }, [page])

  return (
    <>
    <div className='border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100'>
                <TableRow>
                    <TableHead className='uppercase'>{t("users.o_edit.endpoint")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.user")}</TableHead>
                    <TableHead className='uppercase'>Id</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.date_logged")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.time_logged")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.date_start")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.date_end")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.date")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.records")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.relnr")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.coins_or_notes")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.search_on")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.barcode")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.colli_nr")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.reference")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.order_nr")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.code")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.title_p3")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.vault")}</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.route_code")}</TableHead>
                    <TableHead className='uppercase'>ID PDA</TableHead>
                    <TableHead className='uppercase'>{t("users.o_edit.case_nr")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {(all_logs.length === 0 && data === 'empty') && <TableRow><TableCell colSpan={23}><EmptyFeedback /></TableCell></TableRow>}
                {all_logs.map((log, index) => {
                    const logdate = convertToDate(log.logdate)
                    const startdate = convertToDate(log.startdate)
                    const enddate = convertToDate(log.enddate)
                    const date = convertToDate(log.date)
                    const logtime = convertToHHMM(log.logtime)

                    return (
                        <TableRow key={index}>
                            <TableCell>{log.endpoint}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{log.id}</TableCell>
                            <TableCell>{(logdate && isValid(logdate)) ? format(logdate, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{logtime.length > 1 ? logtime : ''}</TableCell>
                            <TableCell>{(startdate && isValid(startdate)) ? format(startdate, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{(enddate && isValid(enddate)) ? format(enddate, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{(date && isValid(date)) ? format(date, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{log.nbrecord}</TableCell>
                            <TableCell>{log.relnr}</TableCell>
                            <TableCell>{log.partner}</TableCell>
                            <TableCell>{log.clientcode}</TableCell>
                            <TableCell>{log.cn}</TableCell>
                            <TableCell>{log.searchby}</TableCell>
                            <TableCell>{log.barcode}</TableCell>
                            <TableCell>{log.colliid}</TableCell>
                            <TableCell>{log.reference}</TableCell>
                            <TableCell>{log.orderid}</TableCell>
                            <TableCell>{log.code}</TableCell>
                            <TableCell>{log.title}</TableCell>
                            <TableCell>{log.vault}</TableCell>
                            <TableCell>{log.routecode}</TableCell>
                            <TableCell>{log.idpda}</TableCell>
                            <TableCell>{log.boxid}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>

    {!(all_logs.length === 0 && data === 'empty') && 
    <div className='flex justify-center mt-4'>
        <Button disabled={isFetching} ref={ref} variant='ghost'>{isFetching ? t("users.o_edit.loading") : t("users.o_edit.more")}</Button>
    </div>
    }
    </>
  )
}

export default Logs