import React from 'react'
import { Button } from '../../../../components/ui/button'
import { AlertTriangle, Box, CheckCircle, Clock, Gem, Truck } from 'lucide-react'
import { Link } from 'react-router-dom'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Order from './Order/Order'
import useAuth from '../../../../hooks/useAuth'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../lib/axios'
import Faq from '../../../../components/portal/faq/Faq'
import { useTranslation } from 'react-i18next'


const OrderAndPickup = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const faq = [
        {
            label: t("order_and_pickup.faq_interesting"),
            url: 'https://sway.office.com/s/60XmLbB1Qn8MFvH5/embed',
            subtitle_id: 0
        },
        {
            label: t("order_and_pickup.faq_technical"),
            url: 'https://sway.office.com/s/UuZlIjBJeBfQjn5A/embed',
            subtitle_id: 0
        }
    ]

    if(auth.data?.right.order){
        faq.unshift({
            label: t("order_and_pickup.faq_how_order"),
            url: 'https://sway.office.com/s/y7Al6yQQEBMEyTcM/embed',
            subtitle_id: 0
        })
    }

    if(auth.data?.right.announce){
        faq.push({
            label: t("order_and_pickup.faq_how_announce"),
            url: 'https://sway.office.com/s/RB9oFg9cxhWqQpYw/embed',
            subtitle_id: 1
        })
    }

  return (
    <>
    {auth.data?.right.faq && <Faq items={faq} subtitles={[{id:0, title:t("order_and_pickup.faq_title_order")}, {id:1, title:t("order_and_pickup.faq_title_announce")}]}/>} 
    <div className='overflow-auto relative w-full p-4 md:p-8'>
        <h1 className="text-2xl font-semibold mb-4 md:mb-8">{t("order_and_pickup.title")}</h1>

        <ResponsiveMasonry columnsCountBreakPoints={{350: 1, 1100: 2, 1500: 3}}>
            <Masonry gutter='1rem'>
                {auth.data?.right.order && 
                <Order />
                }

                {auth.data?.right.announce &&
                <div className='flex flex-col gap-4 shadow-sm border rounded-sm p-4'>
                    <h2 className='text-lg'>{t("order_and_pickup.announce_title")}</h2>
                    
                    <div className='flex gap-2 items-center'>
                        <div className='w-10 h-10 rounded-sm flex justify-center items-center bg-red-50'><Truck className='text-primary'/></div>
                        <h3 className='font-semibold text-2xl'></h3>
                    </div>
                    
                    <div className='flex gap-2'>
                        {(auth.data?.right.announce&&auth.data.role > 2) && <Link to="/bestellen-en-ophalen/ophaling-aankondigen"><Button>{t("order_and_pickup.announce_order_button")}</Button></Link>}
                        <Link to="/bestellen-en-ophalen/overzicht-ophalingen"><Button variant='outline'>{t("order_and_pickup.announce_search_button")}</Button></Link>
                    </div>   
                </div>
                }

                {auth.data?.right.consumables && 
                <div className='flex flex-col gap-4 shadow-sm border rounded-sm p-4'>
                    <h2 className='text-lg'>{t("order_and_pickup.consumable_title")}</h2>
                    
                    <div className='flex gap-2 items-center'>
                        <div className='w-10 h-10 rounded-sm flex justify-center items-center bg-red-50'><Box className='text-primary'/></div>
                        <h3 className='font-semibold text-2xl'></h3>
                    </div>

                    <div className='flex gap-2'>
                    {(auth.data && auth.data.right.consumables && auth.data.role > 2) && 
                        <Link to="/bestellen-en-ophalen/verbruiksartikelen-bestellen"><Button>{t("order_and_pickup.consumable_order_button")}</Button></Link>
                    }
                        <Link to="/bestellen-en-ophalen/overzicht-verbruiksartikelen"><Button variant='outline'>{t("order_and_pickup.consumable_search_button")}</Button></Link>
                    </div>   
                </div>
                }
            </Masonry>
        </ResponsiveMasonry>
    </div>
    </>
  )
}

export default OrderAndPickup