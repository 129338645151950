export const getFilterDetail = (state:Filter[], page:string, key:string | null = null): FilterDetail[] | FilterDetail | null => {
  const filterPage = state.find((item) => item.page === page);

  if (filterPage) {
    if (key !== null) {
      const filterDetail = filterPage.filter.find((detail) => detail.key === key);
      return filterDetail ? filterDetail : null;
    } else {
      return filterPage.filter;
    }
  }

  return null;
};