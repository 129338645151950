import React from 'react'
import { Popup, PopupContent } from '../../../../components/ui/popup'
import { Button } from '../../../../components/ui/button'
import { Loader2 } from 'lucide-react';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../../../hooks/useAuth';
import axios from '../../../../lib/axios';
import { useTranslation } from 'react-i18next';

interface MailProps {
  onCancel: () => void;
  onSuccess?: () => void;
  selectedUsers: User[]
}

const SendMail : React.FC<MailProps> = ({onCancel, onSuccess, selectedUsers}) => {
  const { auth } = useAuth()
  const { t } = useTranslation()

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      return await Promise.all(
        selectedUsers.map(async (user) => {
          await axios.post('registration/update', {
            token: auth.data?.token,
            username: user.username
          })
        })
      )
    },
    onSuccess(data) {
      onSuccess && onSuccess()
    },
    onError(error) {
      console.error(error)
    }
  })

  return (
    <Popup>
      <PopupContent>
        <h2 className='text-lg font-semibold'>{t("users.o_mail.title")}</h2>
        <p className='text-sm text-muted-foreground mt-2'>{t("users.o_mail.description")}</p>

        <div className='flex flex-col gap-2 mt-4 max-h-96 overflow-auto'>
          {selectedUsers.map((user, index) => {
            return (
              <div key={index} className='bg-slate-50 p-2 border rounded-sm'>
                <p className='font-medium leading-none'>{user.firstname} {user.lastname}</p>
                <p className='text-sm text-neutral-400'>{user.username}</p>
              </div>
            )
          })}
        </div>

        <div className='flex items-center gap-2 justify-end mt-4'>
          <Button onClick={onCancel} variant='outline'>{t("users.o_mail.button_cancel")}</Button>
          <Button disabled={isLoading} onClick={()=> { mutate() }}>
            {
              isLoading ?
              <Loader2 className='animate-spin'/>
              :
              t("users.o_mail.button_send")
            }
          </Button>
        </div>
      </PopupContent>
    </Popup>
  )
}

export default SendMail