import React, { useEffect, useRef, useState } from 'react'
import { Label } from '../../../../../components/ui/label'
import { Combobox } from '../../../../../components/ui/combobox'
import axios from '../../../../../lib/axios'
import toast, { Toaster } from 'react-hot-toast'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Button } from '../../../../../components/ui/button'
import { Plus, Trash2 } from 'lucide-react'
import lodash from 'lodash'
import { getCustomerList } from '../../../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'

type Props = {
  role: string;
  changePartner: (value:string) => void;
  changeCustomer: (value:string) => void;

  selectedRelnrs?: string;
  selectedPartners?: string
}

type Selection = {
  partner?: Partner
  customer?: Customer
}

const Partners : React.FC<Props> = ({role, changePartner, changeCustomer, selectedRelnrs, selectedPartners}) => {
  const {auth} = useAuth()
  const {t} = useTranslation()
  const partners = useSelector((state:RootState)=>state.partners)
  const customers = useSelector((state:RootState)=>state.customers)

  const isInit = useRef(false)

  const [selected_partner, setselected_partner] = useState<string | null>(null);
  const [selected_customer, setselected_customer] = useState<string | null>(null);
  const [customers_list, setcustomers_list] = useState<Customer[] | null>(null);

  const [selections, setselections] = useState<Selection[]>([]);

  useEffect(()=>{
    setselections([])
  }, [role])

  useEffect(()=>{
    console.log(selectedPartners, selectedRelnrs, isInit.current)
    if(selectedPartners && Array.isArray(partners) && partners.length > 0 && !isInit.current){
      const getSelections = async () => {
        let relnrsArr = selectedRelnrs ? selectedRelnrs.split(',') : [] // ['12111', '23255', '24210']
        let partsArr = selectedPartners.split(',') // ['39', '3958', '24122']

        let newSelections:Selection[] = []
        await Promise.all(partsArr.map(async (_, index) => {
          try {
            const { data } = await axios.post(`customer/search`, {token:auth.data?.token, partner: partsArr[index]})
            if(data.errorcode===0){ 
              let customer = selectedRelnrs && selectedRelnrs[index] && (data.object.partner[0].eindklant.find((i:Customer)=>i.relnr===parseFloat(relnrsArr[index])))
              let partner = partners.find(i=>i.relnr===parseFloat(partsArr[index]))
              
              newSelections.push({partner:partner, customer:customer})
            }
          } catch (error) {
            console.log(index, partsArr[index] ,"fetch failed")
          }
          
        }))

        if(!lodash.isEqual(selections, newSelections)){
          isInit.current = true
          setselections(prev => {
            let updated = [...prev]
            updated = lodash.union(updated, newSelections)
            return updated
          })
        }
      }

      getSelections()
    } 
  }, [selectedPartners, selectedRelnrs, partners])

  useEffect(()=>{
    let partnerString = lodash.uniqWith(selections.map(i=>i.partner?.relnr), lodash.isEqual).join(",")
    changePartner(partnerString)

    if(role === '4' || role === '5'){
      let customerString = lodash.uniqWith(selections.map(i=>i.customer?.relnr), lodash.isEqual).join(",")
      changeCustomer(customerString)
    }
  }, [selections])

  const handlePickPartner = async (relnr:string) => {
    try {
      if(selected_partner!==relnr){setselected_customer('clear')}
      setselected_partner(relnr);
      setcustomers_list(null);

      const list = await getCustomerList(auth, relnr)
      if(list){ setcustomers_list(list) }
      if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
    } catch (error) {
      console.log(error)
      toast.error(t("users.o_edit.toast_wrong"))
    }
  }

  function addInput() {
    setselections(prev => {
      let updated = [...prev]

      let newObj:Selection = {}
      if(selected_partner){
        const partner = partners.find(i=>i.relnr === parseFloat(selected_partner))
        if(partner){ newObj.partner = partner }
      }
      if(selected_customer){
        const customer = customers_list ? customers_list.find(i=>i.relnr===parseFloat(selected_customer)) : undefined
        if(customer){ newObj.customer = customer }
      }

      updated.push(newObj)
      updated = lodash.uniqWith(updated, lodash.isEqual)

      return updated
    })

    setselected_customer('clear');
    setselected_partner('clear');
    setcustomers_list(null);
  }

  function removeInput(index:number) {
    setselections(prev => {
      let updated = [...prev]
      updated.splice(index, 1)
      return updated
    })
  }

  function isDisabled() {
    let result = true;

    if(role === '2' || role === '3'){
      if(selected_partner && selected_partner !== 'clear'){
        result = false
      }
    } 

    if(role === '4' || role === '5'){
      if(selected_customer && selected_partner && selected_customer !== 'clear' && selected_partner !== 'clear'){
        result = false
      }
    } 

    return result
  }

  return (
    <>
    <Toaster />
    <div className='mt-4 grid grid-cols-1 lg:grid-cols-3 gap-4'>
      <div>
        <Label htmlFor='partner'>{t("users.o_edit.partner")}</Label>
        <Combobox id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("users.o_edit.partner_placeholder")} input_placeholder={t("users.o_edit.search_placeholder")}/>
      </div>

      {(role === '4' || role === '5') && 
      <div>
        <Label htmlFor='end_customer'>{t("users.o_edit.customer")}</Label>
        <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []} placeholder={t("users.o_edit.customer_placeholder")} input_placeholder={t("users.o_edit.search_placeholder")}/>
      </div>
      }

      <div className='flex items-end'>
        <Button onClick={()=>{addInput()}} className='gap-2' disabled={isDisabled()}><Plus className='w-4'/> {t("users.o_edit.button_add_p2")}</Button>
      </div>
      
    </div>
    <div className='my-4 h-px w-full bg-neutral-200'></div>
    <div>
      {<p>{t("users.o_edit.selected_partners")} {(role ==='4' || role ==='5') && `${t("users.o_edit.selected_customers")}`}</p>}
      <div className='mt-2 flex flex-col gap-2 max-h-[60vh] overflow-auto'>
        {selections.length === 0 && <p className='text-sm text-neutral-400'>{t("users.o_edit.no_partners")} {(role ==='4' || role ==='5') && `${t("users.o_edit.selected_customers")}`} {t("users.o_edit.selected")}</p>}
        {selections.length > 0 && selections.map((selection, index) => {
          return (
          <div className='bg-slate-50 rounded-sm border p-2 grid grid-cols-[3fr,3fr,1fr]' key={index}>
            <div className='flex items-center gap-2'>
              <button onClick={()=>{removeInput(index)}} className='opacity-50 hover:opacity-100 hover:text-primary'><Trash2 className='w-4'/></button>
              <p className='whitespace-nowrap'>{selection.partner?.name}</p>
            </div>
            <div>
              <p>{selection.customer?.name}</p>
            </div>
            <div>
              <p>{selection.customer?.clientcode}</p>
            </div>
          </div>
          )
        })}
      </div>
    </div>
    </>
  )
}

export default Partners