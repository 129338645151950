import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../components/ui/table'
import useAuth from '../../../../hooks/useAuth'
import ExportTable from '../../../../components/portal/export/ExportTable';
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { splitArrayIntoChunks } from '../../../../lib/utils';
import { Pagination } from '../../../../components/ui/pagination';
import { convertToDate } from '../../../../lib/date';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';


type Props = {
    complaints: Complaint[]
    startdate: Date
    enddate: Date
}

const ComplaintsTable : React.FC<Props> = ({complaints, startdate, enddate}) => {
    const {auth} = useAuth();
    const navigate = useNavigate()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("complaints.partner"), t("track_and_trace.customer_nr"), t("complaints.customer_code"), t("complaints.customer_p2"), t("complaints.registered"), 'Id', t("complaints.complaint"), t("complaints.type_p2")]

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const complaintcode = useSelector((state:RootState)=>state.complaintcode);

    const [currentPage, setcurrentPage] = useState<number>(1);

    function returnTypeLabel(type:string) {
        if(Array.isArray(complaintcode)){
            const c = complaintcode.find(i=>i.code.toLowerCase()===type.toLowerCase())
            if(c){ return c.description }
            return type
        }
        else {
            return type
        }
    }

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(complaints, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'dtregistration', 'id', 'code', 'cn'],{partners:partners, customers:customers, relnr:'relnr', complaintcode:complaintcode})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>{t("complaints.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("complaints.customer_p2")}</TableHead>
                    <TableHead className='uppercase'>{t("complaints.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("complaints.registered")}</TableHead>
                    <TableHead className='uppercase'>Id</TableHead>
                    <TableHead className='uppercase'>{t("complaints.complaint")}</TableHead>
                    <TableHead className='uppercase'>{t("complaints.type")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {complaints && splitArrayIntoChunks(complaints, 20)[currentPage - 1] && splitArrayIntoChunks(complaints, 20)[currentPage - 1].map((complaint, index)=>{
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === complaint.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === complaint.relnr)

                    return (
                        <TableRow onClick={()=>{navigate(`${complaint.id}`, {state:{startdate:startdate, enddate:enddate}})}} className='cursor-pointer' key={index}>
                            <TableCell>{partner?.name}</TableCell>
                            <TableCell>{customer?.name}</TableCell>
                            <TableCell>{customer?.clientcode}</TableCell>
                            <TableCell>{complaint.dtregistration ? format(convertToDate(complaint.dtregistration) || new Date, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{complaint.id}</TableCell>
                            <TableCell>{returnTypeLabel(complaint.code)}</TableCell>
                            <TableCell>{complaint.cn.toLowerCase() === 'c' ? t("complaints.coins") : t("complaints.notes")}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={complaints.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default ComplaintsTable