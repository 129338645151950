import { Routes, Route } from 'react-router-dom'
import Overview from './Overview'
import Add from './Add'

const Planning = () => {
  return (
    <Routes>
      <Route path="" element={<Overview />} />
      <Route path="add" element={<Add />} />
    </Routes>
  )
}

export default Planning