import React, { HTMLAttributes, ReactNode } from 'react';

interface TooltipForceProps {
    content: ReactNode;
    children: ReactNode;
}

const TooltipForce = React.forwardRef<HTMLDivElement, TooltipForceProps>(
  ({ content, children, ...props }, ref) => {
    return (
      <div className='relative group' ref={ref} {...props}>
          <div className='hidden opacity-0 group-hover:opacity-100 duration-100 group-hover:inline-block z-50 text-sm absolute -top-1 -left-1/2 -translate-y-full px-3 py-1.5 rounded-md shadow-md bg-popover border animate-in fade-in-50 data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1'>{content}</div>
          {children}
      </div>
    );
  }
);

export default TooltipForce;
