import { useQuery } from '@tanstack/react-query'
import React from 'react'
import useAuth from '../../../../../hooks/useAuth'
import { Toaster, toast } from 'react-hot-toast';
import axios from '../../../../../lib/axios';
import { format, subMonths } from 'date-fns';
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { Check, CheckCircle, Clock, Edit2, Eye, Trash2 } from 'lucide-react';
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog';
import TooltipForce from '../../../../../components/ui/tooltip-force';
import { formatCashAmount } from '../../../../../lib/utils';
import { getOrderDraftState } from '../../../../../lib/general/params';
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen';
import { useTranslation } from 'react-i18next';
import { CONFIG } from '../../../../../config';
import { Button } from '../../../../../components/ui/button';

type Props = {
    onEdit: (draft:AnnouncementDraftSearch) => void;
}

const Concept : React.FC<Props> = ({onEdit}) => {
    const {auth} = useAuth();
    const {t} = useTranslation()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const { data:drafts, refetch, isFetching } = useQuery<AnnouncementDraftSearch[] | null>({
        queryKey:['announcement_concept'], 
        queryFn: async () => {
            try {
                const object = {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date()),
                    partner: auth.data?.partner,
                    searchby: 'C',
                    status: '001'
                }
                const { data } = await axios.post(`announcement/draft/search`, object)
                if(data.errorcode === 0 && Array.isArray(data.object.announcementdraft)){
                    return data.object.announcementdraft;
                }
                else {
                    //if(data.errorcode === 0){ toast.error('Geen concepten gevonden'); return null; }
                    console.log(data);
                    throw new Error(data.message)
                }
            } catch (error) {
                toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error"))
                //console.log(error)
            }
            return null;
        },
    })

    const { data:announcements, refetch:refetch_previous } = useQuery<AnnouncementDraftSearch[] | null>({
        queryKey: ['announcement_previous'],
        queryFn: async () => {
            try {
                const object = {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date()),
                    partner: auth.data?.partner,
                    status: '002',
                    searchby: 'C'
                }
                const { data } = await axios.post(`announcement/draft/search`, object)
                if(data.errorcode !== 0) { throw new Error(data.message)}
                else {
                    if(!Array.isArray(data.object.announcementdraft)) { return [] }
                    return data.object.announcementdraft
                }
            } catch (error) {
                toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p2"))
            }
        },
        enabled:false
    })

    const ValidateButton = ({draft, partner}:{draft:AnnouncementDraftSearch, partner?:Partner}) => {
        
        function isRequiredReference () {
            if(!partner){ return false }
            const includes = CONFIG.order_and_collect.order.requiredReference_relnrs.includes(partner.relnr.toString())
            return includes
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_validate")}</p>}>
                        <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-green-500'><CheckCircle className='w-5'/></button>
                    </TooltipForce>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <div className='flex flex-col gap-4'>
                        
                        <h2 className='text-xl font-semibold'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_title")}</h2>
                        <p className='text-neutral-500 text-sm'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_description")}</p>
                        <div className='flex justify-end gap-2'>
                            <AlertDialogCancel>{t("order_and_pickup.o_announce_pickup.o_concept.alert_cancel")}</AlertDialogCancel>
                            {(isRequiredReference() && draft.reference.length < 1) 
                            ? <Button disabled>{t('order_and_pickup.o_announce_pickup.o_concept.no_reference_found')}</Button>
                            : <AlertDialogAction onClick={()=>{validateConcept(draft)}}>{t("order_and_pickup.o_announce_pickup.o_concept.alert_action")}</AlertDialogAction>}
                            
                        </div>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    const RemoveButton = ({draft}:{draft:AnnouncementDraftSearch}) => {
        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_delete")}</p>}>
                        <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-red-500'><Trash2 className='w-5'/></button>
                    </TooltipForce>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <div className='flex flex-col gap-4'>
                        <h2 className='text-xl font-semibold'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_title")}</h2>
                        <p className='text-neutral-500 text-sm'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_description_p2")}</p>
                        <div className='flex justify-end gap-2'>
                            <AlertDialogCancel>{t("order_and_pickup.o_announce_pickup.o_concept.alert_cancel")}</AlertDialogCancel>
                            <AlertDialogAction onClick={()=>{deleteConcept(draft)}}>{t("order_and_pickup.o_announce_pickup.o_concept.alert_action_p2")}</AlertDialogAction>
                        </div>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    async function deleteConcept(draft:AnnouncementDraftSearch) {
        try {
            toast.loading(t("order_and_pickup.o_announce_pickup.o_concept.toast_loading"), { id:'deleteConcept' })
            const { data } = await axios.post(`announcement/draft/delete`, {
                token: auth.data?.token,
                id: draft.id
            })
            if(data.errorcode === 0){
                toast.success(t("order_and_pickup.o_announce_pickup.o_concept.toast_success"), { id:'deleteConcept' })
                refetch()
            } else {
                console.log(data);
                toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p3"), { id:'deleteConcept' })
            }
        } catch (error) {
            console.log(error);
            toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p3"), { id:'deleteConcept' })
        }
    }

    async function validateConcept(draft:AnnouncementDraftSearch) {
        try {
            toast.loading(t("order_and_pickup.o_announce_pickup.o_concept.toast_loading_p2"), { id:'validateConcept' })
            const { data } = await axios.post(`announcement/draft/validate`, {
                token: auth.data?.token,
                id: draft.id
            })
            if(data.errorcode === 0){
                toast.success(t("order_and_pickup.o_announce_pickup.o_concept.toast_success_p2"), { id:'validateConcept' })
                refetch()
                refetch_previous()
            } else {
                console.log(data);
                console.log(draft)
                toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p4"), { id:'validateConcept' })
            }
        } catch (error) {
            console.log(error);
            toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p4"), { id:'validateConcept' })
        }
    }

  return (
    <>
    <Toaster />
    <div className='p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
        <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_concept.title")} ({drafts?.length || 0})</h2>

        <div>
            {!drafts 
            ? <>{isFetching ? <LoadingScreen className='h-[250px]'/> : <p>{t("order_and_pickup.o_announce_pickup.o_concept.no_found")}</p>}</>
            : 
            <div className='border rounded-sm mt-4'>
                <Table className='border-collapse'>
                    <TableHeader className='bg-slate-100'>
                        <TableRow className='uppercase text-muted-foreground font-medium'>
                            <TableHead></TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.partner")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.customer")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.customer_code")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.type")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.amount")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.keepsafe")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.date_collection")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.date_created")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.status")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.created_by")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.reference")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_announce_pickup.o_concept.auto_ref")}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {drafts.map((draft, index) => {
                            let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === draft.relnr))
                            let partner = partners.find(obj => obj.relnr === relation?.id)
                            let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === draft.relnr)

                            return (
                                <TableRow key={index}>
                                    <TableCell className='flex items-center'>
                                        <ValidateButton draft={draft} partner={partner}/>
                                        <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_edit")}</p>}><button onClick={()=>{onEdit(draft)}} className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-orange-500'><Edit2 className='w-5'/></button></TooltipForce>
                                        <RemoveButton draft={draft}/> 
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                    <TableCell className='whitespace-nowrap'>{draft.name}</TableCell>
                                    <TableCell>{customer?.clientcode}</TableCell>
                                    <TableCell>{draft.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_announce_pickup.o_concept.coins") : t("order_and_pickup.o_announce_pickup.o_concept.notes")}</TableCell>
                                    <TableCell>{formatCashAmount(draft.amount)}</TableCell>
                                    <TableCell>{draft.keepsafe}</TableCell>
                                    <TableCell>{format(convertToDate(draft.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{format(convertToDate(draft.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(draft.tmcreation)}</TableCell>
                                    <TableCell>{getOrderDraftState(draft.status, orderdraftstate)?.description}</TableCell>
                                    <TableCell>{draft.username}</TableCell>
                                    <TableCell>{draft.reference}</TableCell>
                                    <TableCell>{draft.autoreference}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
            }
        </div>
    </div>
    </>
  )
}

export default Concept