import { pagination } from '../../lib/pagination';
import { ChevronLeft, ChevronRight } from 'lucide-react'

interface PaginationProps {
    onPageChange: (page:string) => void;
    totalItems: number;
    itemsPerPage: number;
    page: number;
    showNavigation?: boolean;
}

export const Pagination : React.FC<PaginationProps> = ({onPageChange, totalItems, itemsPerPage, page, showNavigation=true}) => {
    
    const buttonStyle = 'w-10 h-10 min-w-[40px] min-h-[40px] border rounded-sm flex justify-center items-center text-neutral-400 duration-200 hover:bg-red-100 font-semibold text-sm disabled:opacity-50 disabled:cursor-not-allowed'

    let pages;

    if(totalItems <= itemsPerPage) { return null; }
    else {
        pages = pagination({ total: totalItems, perPage: itemsPerPage, current: page })
    }
    const max = pages[pages.length - 1].value;
    
    return (
        <div className='flex items-center gap-2'>
            {showNavigation && 
            <div>
                <button onClick={()=>{ onPageChange((page - 1).toString()) }} className={`${buttonStyle}`} disabled={page === 1} ><ChevronLeft className='w-4 text-neutral-500'/></button>
            </div>
            }

            {pages.map((page, index) => (
                <div key={`pagination_${index}`}>
                    <button onClick={()=>{ if(page.value !== '...' && page.value){ onPageChange(page.value.toString()) } }} className={`${buttonStyle} ${page.isSelected && 'bg-red-100 text-red-600 border-red-200'}`} key={page.value}>{page.value}</button>
                </div>
            ))}
            
            {showNavigation && 
            <div>
                <button onClick={()=>{ onPageChange((page + 1).toString()) }} className={`${buttonStyle}`} disabled={page === max}><ChevronRight className='w-4 text-neutral-500'/></button>
            </div>
            }
        </div>
    )
}