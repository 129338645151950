import axios, { AxiosStatic } from 'axios'

const BASE_URL = process.env.REACT_APP_BASE_URL ||'https://api.loomis.be/Loomis/'


export default axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' },
})



export const axiosPrivate = axios.create({
    baseURL: BASE_URL,
    headers: { 'Content-Type': 'application/json' }
})

