import React, { useState } from 'react'
import { Button } from '../../../components/ui/button'
import { Link } from 'react-router-dom'
import MoveRight from '../../../assets/icons/MoveRight'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from '../../../components/ui/alert-dialog'
import { Label } from '../../../components/ui/label'
import { Input } from '../../../components/ui/input'
import { Textarea } from '../../../components/ui/textarea'
import { DatePickerWithRange } from '../../../components/ui/date-picker-with-range'
import { addMonths, differenceInDays, format, subYears } from 'date-fns'
import { useQuery } from '@tanstack/react-query'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date'
import { AlertCircle, Calendar, Check, Eye, Loader2, Mail, MailOpen } from 'lucide-react'
import SpinnerFeedback from '../../../components/portal/feedback/SpinnerFeedback'
import { isInterface, splitArrayIntoChunks } from '../../../lib/utils'
import { Pagination } from '../../../components/ui/pagination'
import { useTranslation } from 'react-i18next'
import ReactGA from "react-ga4";

const CreateMessage = ({refetchMessages}:{refetchMessages:()=>void}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const [title, settitle] = useState('');
    const [description, setdescription] = useState('');
    const [startdate, setstartdate] = useState<Date | undefined>(new Date());
    const [enddate, setenddate] = useState<Date | undefined>(addMonths(new Date(), 1));

    const {refetch, isFetching} = useQuery({
        queryKey: [`cm`],
        queryFn: async () => {
            try {
                toast.loading(t("message_center.toast_loading"), {id:'createMessage'})
                const {data} = await axios.post(`message/add`, {
                    token: auth.data?.token,
                    title: title,
                    text: description,
                    startdate: formatDateToYYYYMMDD(startdate || new Date()),
                    enddate: formatDateToYYYYMMDD(enddate || addMonths(new Date(), 1))
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.success(t("message_center.toast_success"), {id:'createMessage'})
                refetchMessages()
                ReactGA.event({
                    category: 'Message Center',
                    action: 'message_create_success',
                    label: `${auth.data?.username}`
                })
            } catch (error) {
                console.log(error);
                toast.error(t("message_center.toast_error"), {id:'createMessage'})
            }
            return null
        },
        enabled: false
    })

    return (
        <>
        <Toaster />
        <AlertDialog>
            <AlertDialogTrigger asChild><Button disabled={isFetching}>{isFetching ? <Loader2 className='animate-spin'/> : t("message_center.button_create")}</Button></AlertDialogTrigger>
            <AlertDialogContent>
                <h2 className='text-lg'>{t("message_center.alert_title")}</h2>

                <div>
                    <Label htmlFor='title'>{t("message_center.title")}<span className='ml-1 text-primary'>*</span></Label>
                    <Input onChange={({currentTarget})=>{settitle(currentTarget.value)}} value={title} id='title'/>
                </div>

                <div>
                    <Label htmlFor='description'>{t("message_center.description")}<span className='ml-1 text-primary'>*</span></Label>
                    <Textarea onChange={({currentTarget})=>{setdescription(currentTarget.value)}} value={description} className='max-h-[250px]' id='description'/>
                </div>

                <div>
                    <Label htmlFor='date'>{t("message_center.date_visible")}</Label>
                    <DatePickerWithRange value={{from:startdate, to:enddate}} id='date' onDateChange={(value)=>{setstartdate(value?.from); setenddate(value?.to)}} placeholder={t("message_center.date_placeholder")}/>
                </div>

                <div className='flex justify-between'>
                    <AlertDialogCancel>{t("message_center.button_cancel")}</AlertDialogCancel>
                    <AlertDialogAction onClick={()=>{refetch()}} disabled={title===''||description===''}>{t("message_center.button_save")}</AlertDialogAction>
                </div>
            </AlertDialogContent>
        </AlertDialog>
        </>
    )
}

const NewMessage = ({message, refetch}:{message:Message, refetch:()=>void}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const {isFetching:check_isFetching, refetch:checkMessage} = useQuery({
        queryKey: [`message_${message.id}`],
        queryFn: async () => {
            try {
                toast.loading(t("message_center.toast_loading_p2"), {id:`${message.id}_checkMessage`})
                const {data} = await axios.post(`message/check`, {
                    token: auth.data?.token,
                    messageid: message.id
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                toast.success(t("message_center.toast_success_p2"), {id:`${message.id}_checkMessage`})
                refetch()
            } catch (error) {
                toast.error(t("message_center.toast_error_p2"), {id:`${message.id}_checkMessage`})
                console.log(error)
            }
            return null
        },
        enabled: false
    })

    const isEnddateClose = () => {
        const end = convertToDate(message.enddate) || new Date;
        const now = new Date

        const distance = differenceInDays(end, now)
        if(distance < 7){ return true }
        return false
    }

    return (
        <>
        <Toaster />
        <AlertDialog>
            <AlertDialogTrigger disabled={check_isFetching}>
                <div className='border p-2 rounded-sm flex justify-between items-center group cursor-pointer hover:border-primary overflow-hidden'>
                    <div className='text-left'>
                        <p className='font-semibold flex items-center gap-1'>{isEnddateClose() && <AlertCircle className='w-4 text-red-600'/>} {message.title}</p>
                        <p className='flex items-center gap-1 text-sm text-neutral-400'><Calendar strokeWidth={1.5} className='w-4'/> {format(convertToDate(message.startdate) || new Date, "dd/MM/yyyy")}</p>
                        <p className='text-sm text-neutral-500 line-clamp-2'>{message.message}</p>
                    </div>

                    {check_isFetching ? <Loader2 className='w-5 text-neutral-400 animate-spin'/> : 
                    <>
                    <Mail className='w-5 group-hover:hidden text-neutral-400'/>
                    <MailOpen className='w-5 hidden text-primary -translate-y-[2px] group-hover:block'/>
                    </>
                    }
                    
                </div>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogTitle>{message.title}</AlertDialogTitle>
                <p>{message.message}</p>
                <div className='flex justify-end'>
                    <AlertDialogCancel className='border-none' asChild><Button variant='ghost'>{t("message_center.button_close")}</Button></AlertDialogCancel>
                    <AlertDialogAction onClick={()=>{checkMessage()}} className='w-fit'>{t("message_center.button_mark")}</AlertDialogAction>
                </div>
            </AlertDialogContent>
        </AlertDialog>
        </>
    )
}

const OldMessage = ({message}:{message:Message}) => {
    const {t} = useTranslation()
    return (
        <AlertDialog>
            <AlertDialogTrigger>
                <div className='border p-2 rounded-sm flex justify-between items-center group cursor-pointer hover:border-primary'>
                    <div className='text-left'>
                        <p className='font-semibold'>{message.title}</p>
                        <p className='flex items-center gap-1 text-sm text-neutral-400'><Calendar strokeWidth={1.5} className='w-4'/> {format(convertToDate(message.startdate) || new Date, "dd/MM/yyyy")}</p>
                        <p className='text-sm text-neutral-500 line-clamp-2'>{message.message}</p>
                    </div>
                    
                    <Eye className='w-5 text-neutral-400 group-hover:text-primary'/>
                </div>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <AlertDialogTitle>{message.title}</AlertDialogTitle>
                <p>{message.message}</p>
                <div className='flex justify-end'>
                    <AlertDialogCancel className='border-none' asChild><Button variant='secondary'>{t("message_center.button_close")}</Button></AlertDialogCancel>
                </div>
            </AlertDialogContent>
        </AlertDialog>
    )
}

const Main = () => {
    const {auth} = useAuth();
    const {t} = useTranslation()

    const [new_page, setnew_page] = useState(1);
    const [old_page, setold_page] = useState(1);

    const {data:new_messages, isFetching:new_isFetching, refetch:refetchNewMessages} = useQuery<Message[] | null>({
        queryKey:['new_messages'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`message/search`, {
                    token: auth.data?.token,
                    username: auth.data?.username,
                    startdate: formatDateToYYYYMMDD(subYears(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date())
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.message)){throw new Error('Empty')}
                const filteredMessages = data.object.message.filter((i:any)=>i.checked.toString()==='false')
                if(filteredMessages.length === 0){return null;}
                return filteredMessages
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

    const {data:old_messages, isFetching:old_isFetching, refetch:refetchOldMessages} = useQuery<Message[] | null>({
        queryKey:['old_messages'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`message/search`, {
                    token: auth.data?.token,
                    username: auth.data?.username,
                    startdate: formatDateToYYYYMMDD(subYears(new Date(), 1)),
                    enddate: formatDateToYYYYMMDD(new Date())
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.message)){throw new Error('Empty')}
                const filteredMessages = data.object.message.filter((i:any)=>i.checked.toString()==='true')
                if(filteredMessages.length === 0){return null;}
                return filteredMessages
            } catch (error) {
                console.log(error)
                return null
            }
        }
    })

  return (
    <>
    <div className='overflow-auto relative w-full p-4 md:p-8'>
        <div className="flex justify-between items-center">
            <h1 className="text-2xl font-semibold">{t("message_center.title_p2")}</h1>
            {auth.data?.right.adminedit && 
            <CreateMessage refetchMessages={()=>{refetchNewMessages()}}/>
            }
        </div>
 
        <div className='mt-4 md:mt-8 grid gap-4 grid-cols-[repeat(auto-fit,minmax(300px,400px))]'>
            <Link to="berichtenoverzicht">
                <div className='group flex justify-between items-center gap-4 shadow-sm border rounded-sm p-4 hover:border-primary'>
                    <div>
                        <h3 className='font-semibold'>{t("message_center.title_overview")}</h3>
                        <p className='text-sm text-neutral-500'>{t("message_center.overview_description")}</p>
                    </div>
                    <MoveRight className='text-neutral-400 group-hover:translate-x-1 group-hover:text-primary duration-200'/>
                </div>
            </Link>
        </div>

        <div className='my-4 md:my-8 w-full h-px bg-neutral-200'></div>

        <div className="grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4">
            <div className="border shadow-sm rounded-sm">
                <div className="p-4">
                    <h2 className='text-lg mb-2'>{t("message_center.title_new")}</h2>
                    {(!new_messages && new_isFetching) && <SpinnerFeedback />}
                    {(!new_messages && !new_isFetching) && <p className='text-sm text-neutral-400'>{t("message_center.no_messages")}</p>}
                    {isInterface(new_messages, {} as Message[]) && 
                    <div className='flex flex-col gap-2'>
                    {splitArrayIntoChunks(new_messages, 5)[new_page-1] && splitArrayIntoChunks(new_messages, 5)[new_page-1].map((message, index) => {
                        return (
                            <NewMessage message={message} key={index} refetch={()=>{refetchNewMessages(); refetchOldMessages()}}/>
                        )
                    })}
                    <Pagination page={new_page} itemsPerPage={5} totalItems={new_messages.length} onPageChange={(page)=>{setnew_page(parseFloat(page))}}/>
                    </div>
                    }
                </div>
            </div>

            <div className="border shadow-sm rounded-sm">
                <div className="p-4">
                    <h2 className='text-lg mb-2'>{t("message_center.title_previous")}</h2>
                    {(!old_messages && old_isFetching) && <SpinnerFeedback />}
                    {(!old_messages && !old_isFetching) && <p className='text-sm text-neutral-400'>{t("message_center.no_previous")}</p>}
                    {isInterface(old_messages, {} as Message[]) && 
                    <div className='flex flex-col gap-2'>
                    {splitArrayIntoChunks(old_messages, 5)[old_page-1] && splitArrayIntoChunks(old_messages, 5)[old_page-1].map((message, index) => {
                        return (
                            <OldMessage message={message} key={index}/>
                        )
                    })}
                    <Pagination page={old_page} itemsPerPage={5} totalItems={old_messages.length} onPageChange={(page)=>{setold_page(parseFloat(page))}}/>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Main