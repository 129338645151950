import React, { MutableRefObject, useEffect, useRef } from 'react'
import lottie from 'lottie-web'
import error from '../../../assets/animations/error.json'
import { Button } from '../../ui/button'
import MoveRight from '../../../assets/icons/MoveRight'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const ErrorScreen = () => {
    const {t} = useTranslation()
    const navigate = useNavigate();
    const lottieRef = useRef() as MutableRefObject<HTMLDivElement>

    useEffect(()=>{
      const animation = lottie.loadAnimation({
          container: lottieRef.current,
          animationData: error,
          loop: true,
          autoplay: true,
          renderer: 'svg',
        });

        animation.play()
    }, [])

    return (
    <div className='p-4 h-screen flex flex-col justify-center items-center'>
        <div className='w-[100px] h-[100px] overflow-hidden' ref={lottieRef}></div>
        <p>{t("components.o_feedback.title")}</p>
        <Button onClick={()=>{navigate(-1)}} className='mt-4 gap-2' variant='outline'><MoveRight className='rotate-180 w-4' strokeWidth={1.5}/> {t("components.o_feedback.button_back")}</Button>
    </div>
    )
}

export default ErrorScreen