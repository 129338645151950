"use client"

import * as React from "react"
import { format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import { enUS, fr, nlBE } from "date-fns/locale"

interface DatePickerProps extends React.HTMLAttributes<HTMLDivElement> {
    placeholder?: string;
    onDateChange: (value:Date | undefined) => void;
    locale?: 'nlBE' | 'enUS' | 'fr';
    value?: Date;
    disabled?: boolean;
}

export const DatePicker : React.FC<DatePickerProps> = ({placeholder="Pick a date", onDateChange, locale="nlBE", value, className, id, disabled=false}) => {
  const [date, setDate] = React.useState<Date>()

  React.useEffect(() => {
    if(value){
      setDate(value)
    }
  }, [value])

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          disabled={disabled}
          id={id}
          variant={"outline"}
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground", className
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>{placeholder}</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
            mode="single"
            selected={date}
            onSelect={(date) => { onDateChange(date); setDate(date) }}
            initialFocus
            locale={locale === 'nlBE' ? nlBE : locale === 'fr' ? fr : locale === 'enUS' ? enUS : nlBE}
        />
      </PopoverContent>
    </Popover>
  )
}
