import coin_eur_001 from '../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../assets/images/coin-eur-2.png'

import note_eur_5 from '../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../assets/images/note-eur-500.png'

import React from 'react'
import { formatCashAmount } from '../../../lib/utils'

type Props = {
    cn: 'c' | 'n',
    coupures: Coupure[],
    currency?: string,
    show_total?: boolean,
    show_mutated?: boolean
}

const Coupure : React.FC<Props> = ({cn, coupures, currency='EUR', show_total=false, show_mutated=false}) => {

    let amountCols = 2;
    if(show_total) { amountCols++ }
    if(show_mutated) { amountCols++ }

  return (
    <>
        {cn === 'c' &&
        <>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_001} alt="Coin"/></div>
                <p>{currency} 0.01</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.01)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.01)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.01)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.01)?.qty_mut || 0))*0.01)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_002} alt="Coin"/></div>
                <p>{currency} 0.02</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.02)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.02)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.02)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.02)?.qty_mut || 0))*0.02)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_005} alt="Coin"/></div>
                <p>{currency} 0.05</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.05)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.05)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.05)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.05)?.qty_mut || 0))*0.05)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_01} alt="Coin"/></div>
                <p>{currency} 0.1</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.1)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.1)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.1)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.1)?.qty_mut || 0))*0.1)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_02} alt="Coin"/></div>
                <p>{currency} 0.2</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.2)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.2)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.2)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.2)?.qty_mut || 0))*0.2)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_05} alt="Coin"/></div>
                <p>{currency} 0.5</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 0.5)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 0.5)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 0.5)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 0.5)?.qty_mut || 0))*0.5)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_1} alt="Coin"/></div>
                <p>{currency} 1</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 1)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 1)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 1)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 1)?.qty_mut || 0))*1)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_2} alt="Coin"/></div>
                <p>{currency} 2</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 2)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 2)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 2)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 2)?.qty_mut || 0))*2)}</p></div>}
        </div>
        </>
        }

        {cn === 'n' &&
        <>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_5} alt="Note"/></div>
                <p>{currency} 5</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 5)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 5)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 5)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 5)?.qty_mut || 0))*5)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_10} alt="Note"/></div>
                <p>{currency} 10</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 10)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 10)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 10)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 10)?.qty_mut || 0))*10)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_20} alt="Note"/></div>
                <p>{currency} 20</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 20)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 20)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 20)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 20)?.qty_mut || 0))*20)}</p></div>}
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_50} alt="Note"/></div>
                <p>{currency} 50</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 50)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 50)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 50)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 50)?.qty_mut || 0))*50)}</p></div>}  
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_100} alt="Note"/></div>
                <p>{currency} 100</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 100)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 100)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 100)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 100)?.qty_mut || 0))*100)}</p></div>}   
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_200} alt="Note"/></div>
                <p>{currency} 200</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 200)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 200)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 200)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 200)?.qty_mut || 0))*200)}</p></div>}
            
        </div>
        <div className={`grid grid-cols-${amountCols}`}>
            <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_500} alt="Note"/></div>
                <p>{currency} 500</p>
            </div>
            <div className='flex items-center justify-end'><p className='font-semibold'>{(coupures.find((i)=>parseFloat(i.coupure) === 500)?.qty || 0)}</p></div>
            {show_mutated && <div className='flex items-center justify-end'><p className='font-semibold'>{coupures.find((i)=>parseFloat(i.coupure) === 500)?.qty_mut || 0}</p></div>}
            {show_total && <div className='flex items-center justify-end'><p className='font-semibold'>{formatCashAmount(((coupures.find((i)=>parseFloat(i.coupure) === 500)?.qty || 0)+(coupures.find((i)=>parseFloat(i.coupure) === 500)?.qty_mut || 0))*500)}</p></div>}
        </div>
        </>
        }
    </>
  )
}

export default Coupure