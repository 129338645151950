import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Main from './Main'
import Detail from './pickups/Detail'

const AnnouncePickup = () => {
  return (
    <Routes>
      <Route path="" element={<Main />}/>
      <Route path=":announcement" element={<Detail />} />
    </Routes>
  )
}

export default AnnouncePickup