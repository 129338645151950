import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import Detail from './collections/Detail'

const OverviewCollections = () => {
  return (
    <Routes>
      <Route path="" element={<Main />}/>
      <Route path=":announcement" element={<Detail />} />
    </Routes>
  )
}

export default OverviewCollections