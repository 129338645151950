import { useQuery } from '@tanstack/react-query'
import { format, subMonths } from 'date-fns';
import React, { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import axios from '../../../../../lib/axios';
import useAuth from '../../../../../hooks/useAuth';
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table';
import { formatCashAmount } from '../../../../../lib/utils';
import { getOrderDraftState } from '../../../../../lib/general/params';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen';
import { useTranslation } from 'react-i18next';

const PreviousOrders = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {auth} = useAuth()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate);

    const [startdate, setstartdate] = useState(subMonths(new Date(), 1));
    const [enddate, setenddate] = useState(new Date());

    const {data:orders, isFetching} = useQuery<OrderDraft[] | null>({
        queryKey: ['previous_orders'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`order/draft/search`, {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(startdate),
                    enddate: formatDateToYYYYMMDD(enddate),
                    partner: auth.data?.partner,
                    status: '',
                    searchby: 'C'
                })
                if(data.errorcode === 0 && Array.isArray(data.object.orderdraft)) {
                    let res = data.object.orderdraft
                    res = res.filter((i:OrderDraft)=>i.status!=='001')
                    return res
                }
                else { return null; }
            } catch (error) {
                console.log(error)
                return null
            }
        }
    })

  return (
    <>
    <Toaster />
    <div className='p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
        <h2 className='text-lg'>{t("order_and_pickup.o_place_order.previous_title")} {orders && orders?.length > 0 && `(${orders.length})`} <span className='text-sm text-neutral-500'>({t("order_and_pickup.o_place_order.previous_subtitle")} {format(startdate, "dd/MM/yyyy")} {t("order_and_pickup.o_place_order.previous_subtitle_p2")} {format(enddate, "dd/MM/yyyy")})</span></h2>
    
        <div>
            {!orders 
            ? <>{isFetching ? <LoadingScreen className='h-[250px]'/> : <p>{t("order_and_pickup.o_place_order.previous_none_orders")}</p>}</>
            : 
            <div className='border rounded-sm mt-4 max-h-[60dvh] overflow-auto'>
                <Table className='border-collapse'>
                    <TableHeader className='bg-slate-100'>
                        <TableRow className='uppercase text-muted-foreground font-medium'>
                            <TableHead>{t("order_and_pickup.o_place_order.partner")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.customer_p2")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.customer_code")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.type")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.amount")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.date_delivery")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.date_created")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.status")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.created_by")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.reference")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.auto_ref")}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {orders.map((order, index)=> {
                            let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                            let partner = partners.find(obj => obj.relnr === relation?.id)
                            let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                            return (
                                <TableRow onClick={()=>{navigate(`${order.id}`)}} key={index} className='cursor-pointer'>
                                    <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                    <TableCell className='whitespace-nowrap'>{order.name}</TableCell>
                                    <TableCell>{customer?.clientcode}</TableCell>
                                    <TableCell>{order.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_place_order.coins") : t("order_and_pickup.o_place_order.notes")}</TableCell>
                                    <TableCell className='text-right'>{formatCashAmount(order.amount)}</TableCell>
                                    <TableCell>{format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(order.tmcreation)}</TableCell>
                                    <TableCell>{getOrderDraftState(order.status, orderdraftstate)?.description}</TableCell>
                                    <TableCell>{order.username}</TableCell>
                                    <TableCell>{order.reference}</TableCell>
                                    <TableCell>{order.autoreference}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
            }
        </div>
    </div>
    </>
  )
}

export default PreviousOrders