import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Toaster, toast } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import axios from '../../../../../lib/axios'
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date'
import { addDays, format } from 'date-fns'
import useAuth from '../../../../../hooks/useAuth'
import { isInterface } from '../../../../../lib/utils'
import Tickets from '../../../../../components/portal/ticket/Tickets'
import Pickup from '../../../../../components/portal/stop/Pickup'
import Delivery from '../../../../../components/portal/stop/Delivery'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../components/ui/button'
import { ChevronLeft } from 'lucide-react'

const ATMTransfer = () => {
  const location = useLocation()
  const {relnr} = useParams()
  const navigate = useNavigate();
  const {auth} = useAuth()
  const {t} = useTranslation()

  const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

  const [has_state, sethas_state] = useState(true);

  const [stop, setstop] = useState<Stop | StateType>(null);
  const [stop_detail, setstop_detail] = useState<StopDetail | StateType>(null);
  const [tickets, settickets] = useState<Ticket[] | StateType>(null);

  useEffect(()=>{
    if(!location.state){
        //User did not navigate to this page via the table (state is empty, state contains the keepsafe object)
        sethas_state(false)
        return;
    }

    fetch_stop()
  }, [])

  useEffect(()=>{
      if(!has_state){
          const instance = lottie.loadAnimation({
              container: errorContainer.current,
              renderer: 'svg',
              loop: true,
              autoplay: true,
              animationData: errorAnimation
          })
          return () => instance.destroy()
      }
  }, [has_state])

  async function fetch_stop() {
    try {
        setstop_detail('loading')
        setstop('loading')
        const { data } = await axios.post(`stop/search`, {
            token: auth.data?.token,
            relnr: relnr,
            startdate: formatDateToYYYYMMDD(convertToDate(location.state.date) || new Date()),
            enddate: formatDateToYYYYMMDD(addDays(convertToDate(location.state.date) || new Date(), 7) || new Date())
        })

        if(data.errorcode === 0){
            if(Array.isArray(data.object.stop)){
                const stop = data.object.stop[0] as Stop;
                setstop(stop)
                if(stop.id){
                    fetch_stop_detail(stop.id)
                }
                fetch_tickets({
                    token:auth.data?.token, 
                    relnr:relnr, 
                    routecode:stop.routecode, 
                    startdate: formatDateToYYYYMMDD(convertToDate(location.state.date) || new Date()),
                    enddate: formatDateToYYYYMMDD(addDays(convertToDate(location.state.date) || new Date(), 7) || new Date())
                })
            }
            else {
                setstop('empty')
                setstop_detail('empty')
                settickets('empty')
            }
        }
        else {
            setstop('error')
            console.log(data)
            toast.error(t("track_and_trace.o_detail.toast_wrong_details"))
        }
    } catch (error) {
        console.log(error);
        setstop('error')
        toast.error(t("track_and_trace.o_detail.toast_wrong_details"))
    }
  }

  async function fetch_stop_detail(id:number) {
    try {
        
        const { data } = await axios.post(`stop/detail`, {
            token: auth.data?.token,
            id: id
        })
        if(data.errorcode === 0){
            if(isInterface(data.object.stopdetail, {} as StopDetail)){
                setstop_detail(data.object.stopdetail)
            }
            else {
                setstop_detail('empty')
                console.log(data)
            }                
        }
        else {
            console.log(data);
            setstop_detail('error')
            toast.error(t("track_and_trace.o_detail.toast_wrong_collections"))
        }
    } catch (error) {
        console.log(error);
        setstop_detail('error')
        toast.error(t("track_and_trace.o_detail.toast_wrong_collections"))
    }
  }

  async function fetch_tickets(object:object) {
      try {
          settickets('loading')
          const { data } = await axios.post(`ticket/search`, object)
          if(data.errorcode === 0){
              if(isInterface(data.object.ticket, {} as Ticket[])){
                  settickets(data.object.ticket)
              }
              else {
                  settickets('empty')
              }
          }
          else {
              console.log(data);
              settickets('error')
              toast.error(t("track_and_trace.o_detail.toast_wrong_tickets"))
          }
      } catch (error) {
          settickets('error')
          console.log(error)
          toast.error(t("track_and_trace.o_detail.toast_wrong_tickets"))
      }
  }

    function returnTypeLabel(type:string) {
        switch (type.toLowerCase()) {
            case 'vpl':
                return t("track_and_trace.vpl")
            case 'oaf':
                return t("track_and_trace.oaf")
            case 'opa':
                return t("track_and_trace.opa")
            case 'rfk':
                return t("track_and_trace.rfk")
            case 'rfc':
                return t("track_and_trace.rfc")
            case 'pri':
                return t("track_and_trace.pri")
            case 'ext':
                return t("track_and_trace.ext")
            default:
                return ''
        }
    }

  return (
    <>
        <Toaster />
        
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex items-center justify-between'>
                <div className='flex flex-col'>
                    <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_detail.link_tt")}</p>}, {title:`${t("track_and_trace.o_detail.link_atm_delivery")} ${relnr}`}]}/>
                    <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_detail.title_atm_delivery")}</h1>
                </div>

                <Link to="/track-en-trace"><Button variant='ghost' className='gap-2'><ChevronLeft className='w-4 h-4'/> {t("track_and_trace.o_detail.back")}</Button></Link>
            </div>

            {has_state &&
            <>
            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_atm_delivery")}</h2>
                {stop === 'loading' && <SpinnerFeedback />} 
                {stop === 'error' && <ErrorFeedback />}
                {stop === 'empty' && <EmptyFeedback />}
                {isInterface(stop, {} as Stop) &&
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>ID</p>
                        <p className='text-neutral-500'>{stop.id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.relation_nr")}</p>
                        <p className='text-neutral-500'>{stop.relnr}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_stop")}</p>
                        <p className='text-neutral-500'>{format(convertToDate(stop.date) || new Date(), "dd/MM/yyyy")}</p>
                    </div> 
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.executed")}</p>
                        <p className='text-neutral-500'>{stop.executed ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.ride")}</p>
                        <p className='text-neutral-500'>{stop.ritid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.customer")}</p>
                        <p className='text-neutral-500'>{stop.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.arrived")}</p>
                        <p className='text-neutral-500'>{convertToHHMM(stop.tmarrival)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.departure")}</p>
                        <p className='text-neutral-500'>{convertToHHMM(stop.tmdepart)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.extra")}</p>
                        <p className='text-neutral-500'>{stop.extra ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.route_code")}</p>
                        <p className='text-neutral-500'>{stop.routecode}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.description")}</p>
                        <p className='text-neutral-500'>{stop.description}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.error")}</p>
                        <p className='text-neutral-500'>{stop.fault}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.type")}</p>
                        <p className='text-neutral-500'>{returnTypeLabel(stop.type)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.catch")}</p>
                        <p className='text-neutral-500'>{stop.rattrappage ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                </div>
                }
            </div>

            <div className='grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-8 mt-4 md:mt-8'>
                <div className='flex border flex-col gap-4 rounded-sm p-4'>
                    <h2 className='text-lg'>{t("track_and_trace.o_detail.title_deliveries")}</h2>
                    {stop_detail === 'loading' && <SpinnerFeedback />} 
                    {stop_detail === 'error' && <ErrorFeedback />}
                    {stop_detail === 'empty' && <EmptyFeedback />}
                    {(isInterface(stop_detail, {} as StopDetail) && !stop_detail.delivery) && <p>{t("track_and_trace.o_detail.no_deliveries")}</p>}

                    {(isInterface(stop_detail, {} as StopDetail) && isInterface(stop, {} as Stop) && stop_detail.delivery) &&
                    <div className='border rounded-sm'>
                        <Delivery stopDetail={stop_detail} stop={stop}/>
                    </div>
                    }
                </div>

                <div className='flex border flex-col gap-4 rounded-sm p-4'>
                    <h2 className='text-lg'>{t("track_and_trace.o_detail.title_collections")}</h2>
                    {stop_detail === 'loading' && <SpinnerFeedback />} 
                    {stop_detail === 'error' && <ErrorFeedback />}
                    {(stop_detail === 'empty' || (isInterface(stop_detail, {} as StopDetail) && !stop_detail.pickup)) && <EmptyFeedback />}

                    {(isInterface(stop_detail, {} as StopDetail) && isInterface(stop, {} as Stop) && stop_detail.pickup) &&
                    <div className='border rounded-sm'>
                        <Pickup stopDetail={stop_detail} stop={stop}/>
                    </div>
                    }
                    
                </div>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_tickets")}</h2>
                {tickets === 'loading' && <SpinnerFeedback />} 
                {tickets === 'error' && <ErrorFeedback />}
                {tickets === 'empty' && <EmptyFeedback />}
                {isInterface(tickets, {} as Ticket[]) && 
                <div>
                    <p>{t("track_and_trace.o_detail.tickets_description")}</p>
                    <Tickets tickets={tickets}/>
                </div>
                }
            </div>
            </>
            }

            {!has_state &&
            <div className={`flex flex-col items-center justify-center`}>
              <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
              <h2 className='text-2xl font-semibold mb-4'>{t("track_and_trace.o_detail.error_title")}</h2>
              <p className='text-center'>{t("track_and_trace.o_detail.error_description_p9")}<br></br>{t("track_and_trace.o_detail.error_description_p2")} <Link to='/track-en-trace' className='font-medium underline'>{t("track_and_trace.o_detail.error_description_p3")}</Link> {t("track_and_trace.o_detail.error_description_p4")}</p>
            </div>
            }
        </div>
    </>
  )
}

export default ATMTransfer