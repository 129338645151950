import { useQuery } from '@tanstack/react-query'
import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'

import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { isCoupureCN, isInterface } from '../../../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import Coupure from '../../../../../components/portal/coupure/Coupure'
import { format } from 'date-fns'
import { convertToDate } from '../../../../../lib/date'
import { Pagination } from '../../../../../components/ui/pagination'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { useTranslation } from 'react-i18next'
import { getOrderDraftState } from '../../../../../lib/general/params'

const Detail = () => {
  const navigate = useNavigate()
  const { auth } = useAuth()
  const { t } = useTranslation()
  const { announcement } = useParams()
  
  const partners = useSelector((state:RootState)=>state.partners);
  const customers = useSelector((state:RootState)=>state.customers);
  const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate);

  const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

  const [selected_index, setselected_index] = useState(0);

  const {data:detail, isFetching:isFetching_detail} = useQuery<AnnouncemenDetail[] | null>({
    queryKey: ['announcement_detail'],
    queryFn: async () => {
      try {
        const {data} = await axios.post(`announcement/draft/detail`, {
          token: auth.data?.token,
          id: announcement
        })
        if(data.errorcode === 0){ return data.object.detail }
        else { throw new Error(data.object) }
      } catch (error) {
        return null
      }
    }
  })

  const {data:detail_info, isFetching:isFetching_info} = useQuery<AnnouncementDraftSearch | null>({
    queryKey: ['announcement_detail_info'],
    queryFn: async () => {
      try {
        const {data} = await axios.post(`announcement/draft/search`, {
          token: auth.data?.token,
          id: announcement
        })
        if(data.errorcode === 0){ return data.object.announcementdraft[0] }
        else { throw new Error(data.message) }
      } catch (error) {
        return null
      }
    }
  })

  useEffect(()=>{
    if(!detail || !detail_info){
      const instance = lottie.loadAnimation({
        container: errorContainer.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: errorAnimation
      })
      return () => instance.destroy()
    }
  }, [detail, detail_info])

  let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === detail_info?.relnr))
  let partner = partners.find(obj => obj.relnr === relation?.id)
  let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === detail_info?.relnr)
    
  return (
  <>
    <Toaster /> 

    {((isFetching_detail || isFetching_info) && (!isInterface(detail, {} as AnnouncemenDetail[]))) && 
    <LoadingScreen />
    }

    {(detail === null || detail_info === null) && 
    <div className={`flex flex-col items-center justify-center`}>
      <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
      <h2 className='text-2xl font-semibold mb-4'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.error_title")}</h2>
      <p className='text-center'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.error_description")}<br></br>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.error_description_p2")} <Link to='/bestellen-en-ophalen/ophaling-aankondigen' className='font-medium underline'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.error_description_p3")}</Link> {t("order_and_pickup.o_announce_pickup.o_pickup_detail.error_description_p4")}</p>
    </div>
    }

    {(isInterface(detail, {} as AnnouncemenDetail[]) && isInterface(detail_info, {} as AnnouncementDraftSearch)) && 
    <div className="overflow-auto relative w-full p-4 md:p-8">
      <div className='flex flex-col'>
          <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.link_orders")}</p>}, {title:<p onClick={()=>{navigate('/bestellen-en-ophalen/ophaling-aankondigen')}} className='cursor-pointer'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.link_announce")}</p>}, {title:`${t("order_and_pickup.o_announce_pickup.o_pickup_detail.link_announcement")} ${announcement}`}]}/>
          <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.title")}</h1>
      </div>

      <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
        <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.subtitle")}</h2>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.partner_nr")}</p>
            <p className='text-neutral-500'>{partner?.relnr}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.partner")}</p>
            <p className='text-neutral-500'>{partner?.name}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.relation_nr")}</p>
            <p className='text-neutral-500'>{detail_info.relnr}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.customer")}</p>
            <p className='text-neutral-500'>{detail_info.name}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.customer_code")}</p>
            <p className='text-neutral-500'>{customer?.clientcode}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.amount")}</p>
            <p className='text-neutral-500'>{detail_info.amount}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.currency")}</p>
            <p className='text-neutral-500'>EUR</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.type")}</p>
            <p className='text-neutral-500'>{detail_info.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_place_order.coins") : t("order_and_pickup.o_place_order.notes")}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.created")}</p>
            <p className='text-neutral-500'>{format(convertToDate(detail_info.dtcreation) || new Date(), "dd/MM/yyyy")}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.collection")}</p>
            <p className='text-neutral-500'>{format(convertToDate(detail_info.date) || new Date(), "dd/MM/yyyy")}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.keepsafe")}</p>
            <p className='text-neutral-500'>{detail_info.keepsafe}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.status")}</p>
            <p className='text-neutral-500'>{orderdraftstate && getOrderDraftState(detail_info.status, orderdraftstate)?.description}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.reference")}</p>
            <p className='text-neutral-500'>{detail_info.reference}</p>
          </div>
          <div className='flex gap-2'>
            <p className='font-semibold'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.auto_ref")}</p>
            <p className='text-neutral-500'>{detail_info.autoreference}</p>
          </div>
        </div>
      </div>

      <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
        <div className='flex justify-between items-center'>
          <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.subtitle_p2")} {selected_index+1} {t("order_and_pickup.o_announce_pickup.o_pickup_detail.subtitle_p3")} {detail.length}</h2>
          {detail.length > 1 && 
          <div className='flex gap-2'>
            <button onClick={()=>{setselected_index(prev=>prev-1)}} disabled={selected_index === 0} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronLeft className='w-5 text-neutral-500'/></button>
            <button onClick={()=>{setselected_index(prev=>prev+1)}} disabled={(selected_index+1) === detail.length} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronRight className='w-5 text-neutral-500'/></button>
          </div>
          }
        </div>
        
        <div className='border'>
          <div className='grid grid-cols-3 bg-slate-100 py-2 px-4 border-b'>
            <i className='uppercase text-sm font-medium text-muted-foreground'></i>
            <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.count")}</p>
            <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("order_and_pickup.o_announce_pickup.o_pickup_detail.amount")}</p>
          </div>
          <div className='flex flex-col gap-4 p-4'>
            <Coupure cn={isCoupureCN(detail[selected_index].coupures)} coupures={detail[selected_index].coupures} show_total/>
          </div>
        </div>
      </div>

    </div>
    }
    
  </>
  )
}

export default Detail