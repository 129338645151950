import React, { HTMLAttributes, useState } from 'react'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../../../components/ui/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { Pagination } from '../../../../components/ui/pagination';
import { splitArrayIntoChunks } from '../../../../lib/utils';
import { convertToDate } from '../../../../lib/date';
import { format } from 'date-fns';
import { Button } from '../../../../components/ui/button';
import { Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ExportTable from '../../../../components/portal/export/ExportTable';
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export';
import useAuth from '../../../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

type Props = {
    keepsafes: Keepsafe[]
}


const CollectedKeepsafesTable : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({keepsafes, className, ...props}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("track_and_trace.partner"), t("track_and_trace.customer_nr"), t("track_and_trace.customer_code"),t("track_and_trace.customer"), t("track_and_trace.date_collected"), t("track_and_trace.keepsafe"), t("track_and_trace.bag_id"), t("track_and_trace.bag_barcode"), t("track_and_trace.type"), t("track_and_trace.executed")]
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
       <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(keepsafes, ['partnerid', 'partnername', 'stop.relnr', 'customerclientcode','stop.name',  'date', 'barcode', 'boxid', 'boxbarcode', 'cn', 'executed'], {partners:partners, customers:customers, relnr:'stop.relnr'})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table className={className} {...props}>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>{t("track_and_trace.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.date_collected")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.keepsafe")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.bag_id")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.bag_barcode")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.type")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.executed")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {keepsafes && splitArrayIntoChunks(keepsafes, 20)[currentPage - 1] && splitArrayIntoChunks(keepsafes, 20)[currentPage - 1].map((keepsafe, index)=>{  
                    let partner;
                    let customer;
                    if(keepsafe.stop) {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === keepsafe.stop.relnr))
                    partner = partners.find(obj => obj.relnr === relation?.id)
                    customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === keepsafe.stop.relnr)
                    }

                    return (
                    <TableRow className='cursor-pointer' onClick={()=>{navigate(`collection/${keepsafe.barcode}`, {state: keepsafe})}} key={index}>
                        <TableCell>{partner && partner?.name}</TableCell>
                        <TableCell>{keepsafe.stop.name}</TableCell>
                        <TableCell>{customer?.clientcode}</TableCell>
                        <TableCell>{convertToDate(keepsafe.date) ? format(convertToDate(keepsafe.date) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell>{keepsafe.barcode}</TableCell>
                        <TableCell>{keepsafe.boxid}</TableCell>
                        <TableCell>{keepsafe.boxbarcode}</TableCell>
                        <TableCell>{keepsafe.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</TableCell>
                        <TableCell>{keepsafe.executed ? t("track_and_trace.yes") : t("track_and_trace.no")}</TableCell>
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={keepsafes.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default CollectedKeepsafesTable