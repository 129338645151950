import toast from "react-hot-toast";
import axios from "../axios";
import { convertToDate, convertToHHMM } from "../date";
import { format, isValid } from "date-fns";

export function createExportObject(titles: string[], rows: string[][]): Export {
    
    const exportObject: Export = {
        title: titles.map(name => ({ name })),
        row: rows.map(rowData => ({
            data: rowData.map(value => ({ value })),
        })),
    };

    return exportObject;
}

function getNestedValue<T>(item: T, keys: (keyof T)[]): string {
    let value: any = item;

    for (const key of keys) {
        value = value[key];

        if (value === undefined || value === null) {
            return '';
        }
    }

    return value.toString();
}

export function createExportRows<T>(array: T[], keys: (keyof T | 'partnerid' | 'partnername' | 'customername' | 'customerclientcode')[] | string[], pc?:{partners:Partner[], customers:PartnerCustomers[], relnr:string, orderstate?:OrderState[] | null, complaintcode?: ComplaintCode[] | null}): string[][] {
    let lng = window.localStorage.getItem('i18nextLng')
    console.log(array);
    if(lng !== 'nl' && lng !== 'fr' && lng !== 'en'){ lng = 'en' }
    const rows: string[][] = array.map((item, ind) => {
        return keys.map(key => {
            const keyArray = key.toString().split('.');
            let value: any = item;

            for (const k of keyArray) {
                const isDate = k.toLowerCase().substring(0,2) === 'dt' || k.toLowerCase().includes('date')
                if(isDate) {
                    value = isValid(convertToDate(value[k])) ? format(convertToDate(value[k]) || new Date(1900,1,1), 'dd/MM/yyyy') : ''
                }
                else if(k.toLowerCase().substring(0,2)==='tm'){
                    value = convertToHHMM(value[k])
                }
                else if(k === 'partnerid' && pc){
                    const relnrKeyArr = pc.relnr.split('.');
                    let relnr_value = value;
                    for(const rk of relnrKeyArr){ relnr_value = relnr_value[rk] }

                    

                    if(pc.relnr==='clientcode'){
                        let relation = pc.customers.find(obj => obj.eindklant.some(e => e.clientcode === relnr_value))
                        let partner = pc.partners.find(obj => obj.relnr === relation?.id)
                        value = partner?.relnr
                    } else {
                        let relation = pc.customers.find(obj => obj.eindklant.some(e => e.relnr === relnr_value))
                        let partner = pc.partners.find(obj => obj.relnr === relation?.id)
                        value = partner?.relnr
                    }         
                }
                else if(k === 'partnername' && pc){
                    const relnrKeyArr = pc.relnr.split('.');
                    let relnr_value = value;
                    for(const rk of relnrKeyArr){ relnr_value = relnr_value[rk] }

                    if(pc.relnr==='clientcode'){
                        let relation = pc.customers.find(obj => obj.eindklant.some(e => e.clientcode === relnr_value))
                        let partner = pc.partners.find(obj => obj.relnr === relation?.id)
                        value = partner?.name
                    } else {
                        let relation = pc.customers.find(obj => obj.eindklant.some(e => e.relnr === relnr_value))
                        let partner = pc.partners.find(obj => obj.relnr === relation?.id)
                        value = partner?.name
                    } 
                }
                else if(k === 'customername' && pc){
                    const relnrKeyArr = pc.relnr.split('.');
                    let relnr_value = value;
                    for(const rk of relnrKeyArr){ relnr_value = relnr_value[rk] }

                    let customer = pc.customers.flatMap(obj => obj.eindklant).find(e => e.relnr === relnr_value)

                    if(pc.relnr==='clientcode'){
                        customer = pc.customers.flatMap(obj => obj.eindklant).find(e => e.clientcode === relnr_value)
                    }
 
                    value = customer?.name
                }
                else if(k === 'customerclientcode' && pc){
                    const relnrKeyArr = pc.relnr.split('.');
                    let relnr_value = value;
                    for(const rk of relnrKeyArr){ relnr_value = relnr_value[rk] }
                    
                    let customer = pc.customers.flatMap(obj => obj.eindklant).find(e => e.relnr === relnr_value)
                    value = customer?.clientcode
                }
                else if(k === 'status' && pc && pc.orderstate){
                    if(value[k]?.length === 3){
                        value = pc.orderstate.find(i=>i.code===value[k]) ? pc.orderstate.find(i=>i.code===value[k])?.description : value[k]
                    }
                }
                else if(k === 'code' && pc && pc.complaintcode){
                    const c = pc.complaintcode.find(i=>i.code.toLowerCase()===value[k].toLowerCase())
                    if(c){ value = c.description }
                    else { value = value[k] }
                }
                else if(value?.hasOwnProperty(k) && k.toLowerCase() === 'cn'){
                    let res_coins = 'Coins'
                    let res_notes = 'Notes'
                    if(lng === 'nl'){ res_coins = 'Munten'; res_notes = 'Biljetten' }
                    if(lng === 'fr'){ res_coins = 'Monnaies'; res_notes = 'Billets' }
                    if(lng === 'en'){ res_coins = 'Coins'; res_notes = 'Notes' }
                    value = value[k].toLowerCase() === 'c' ? res_coins : res_notes
                }
                else if(keyArray.includes('coupure')){
                    if(parseFloat(k) > 0){
                        let coupureValue = parseFloat(keyArray[1])
                        if(isNaN(coupureValue)){ coupureValue = 0}
                        // @ts-ignore
                        value = `${item.coupure.find((c:any)=>c.coupure === coupureValue)?.qty || 0}`
                    }
                    else {
                        value = "0"
                    }
                }
                else { 
                    value = value[k];
                }

                if(typeof(value) === 'boolean' && value.toString() === 'true'){
                    let res = 'Yes'
                    if(lng === 'nl'){ res = 'Ja' }
                    if(lng === 'fr'){ res = 'Oui' }
                    if(lng === 'en'){ res = 'Yes' }
                    value = res
                }
                else if(typeof(value) === 'boolean' && value.toString() === 'false'){
                    let res = 'No'
                    if(lng === 'nl'){ res = 'Nee' }
                    if(lng === 'fr'){ res = 'Non' }
                    if(lng === 'en'){ res = 'No' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'VPL'){
                    let res = 'Obliged'
                    if(lng === 'nl'){ res = 'Verplicht' }
                    if(lng === 'fr'){ res = 'Obligé' }
                    if(lng === 'en'){ res = 'Obliged' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'OAF'){
                    let res = 'On call'
                    if(lng === 'nl'){ res = 'Op afroep' }
                    if(lng === 'fr'){ res = 'On call' }
                    if(lng === 'en'){ res = 'On call' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'opa'){
                    let res = 'On request'
                    if(lng === 'nl'){ res = 'Op aanvraag' }
                    if(lng === 'fr'){ res = 'Sur demande' }
                    if(lng === 'en'){ res = 'On request' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'rfk'){
                    let res = 'Rattrappage customer error'
                    if(lng === 'nl'){ res = 'Rattrappage fout klant' }
                    if(lng === 'fr'){ res = 'Erreur client rattrappage' }
                    if(lng === 'en'){ res = 'Rattrappage customer error' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'rfc'){
                    let res = 'Rattrappage Loomis error'
                    if(lng === 'nl'){ res = 'Rattrappage fout Loomis' }
                    if(lng === 'fr'){ res = 'Erreur Loomis rattrappage' }
                    if(lng === 'en'){ res = 'Rattrappage Loomis error' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'pri'){
                    let res = 'Privatief'
                    if(lng === 'nl'){ res = 'Privatief' }
                    if(lng === 'fr'){ res = 'Privatief' }
                    if(lng === 'en'){ res = 'Privatief' }
                    value = res
                }
                else if(value && value.toString().toUpperCase() === 'ext'){
                    let res = 'Extra'
                    if(lng === 'nl'){ res = 'Extra' }
                    if(lng === 'fr'){ res = 'Extra' }
                    if(lng === 'en'){ res = 'Extra' }
                    value = res
                }
                if (value === undefined || value === null) {
                    return '';
                }
            }

            return value.toString();
        });
    });

    return rows;
}

export function TESTcreateExportRows<T>(array: T[], keys: (keyof T)[]): string[][] {
    const rows: string[][] = array.map(item => {
        return keys.map(key => {
            const value = item[key]?.toString() || '';
            return value;
        });
    });

    return rows;
}

export async function createExport(token:string, extension:'XLS'|'XLSX', object:Export) {
    let errorMessage = 'Er liep iets mis'
    try {
        const lng = window.localStorage.getItem('i18nextLng')
        let loadingMessage = 'Uw bestand wordt klaargemaakt...'
        let successMessage = 'Bestand geëxporteerd!'
        
        if(lng && (lng.toLowerCase() === 'nl' || lng.toLowerCase() === 'en' || lng.toLowerCase() === 'fr')){
            if(lng.toLowerCase() === 'en'){
                loadingMessage = 'Your file is being prepared...'
                successMessage = 'File exported!'
                errorMessage = 'Something went wrong'
            }
            if(lng.toLowerCase() === 'fr'){
                loadingMessage = 'Votre dossier est en cours de préparation...'
                successMessage = 'Fichier exporté!'
                errorMessage = "Quelque chose s'est mal passé"
            }
        }

        toast.loading(loadingMessage, { id:'createExport' })
        const {data} = await axios.post(`export/excel`, {
            token: token,
            extension: extension,
            ...object
        })
        if(data.errorcode === 0 && data.object.filename) { 
            window.open(data.object.filename); 
            toast.success(successMessage, { id:'createExport' }) 
        }
        else { toast.error(`[${data.errorcode}] ${data.message}`, { id:'createExport' }) }
    } catch (error) {
        console.log(error)
        toast.error(errorMessage, { id:'createExport' })
    }
}