import axios from "../axios"

export const getCustomerList = async (auth:Auth, relnr:string|number) => {
    try {
      if(relnr === process.env.REACT_APP_RDM_NUM){ return []; }
      if(!relnr) return  []

        const { data } = await axios.post(`customer/search`, {
          token: auth.data?.token,
          partner: relnr
        })
        
        if(data.errorcode === 0){
          if(!data.object.partner){ throw new Error('Geen klanten gevonden voor deze partner') }
          if(data.object.partner[0]){
                let list = data.object.partner[0].eindklant
                
                if(auth.data?.relnr && auth.data.role > 3) {
                    let relnrArr = auth.data.relnr.split(',')
                    list = list.filter((i:any)=>relnrArr.includes(i.relnr.toString()))
                }

                list = list.filter((i:Customer)=>i.dtinactive.length<1)
                return list as Customer[]
          }
          else {
            //No customers found
            throw new Error('Geen klanten gevonden voor deze partner')
          }
        }
        else {
            throw new Error(`Error [${data.errorcode}] - ${data.message}`)
        }
      } catch (error) {
        console.log(error)
        throw new Error('Something went wrong...')
      }
}