"use client"
 
import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../lib/utils"
import { Button } from "./button"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "./command"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"


type Item = {
    value: string | number;
    label: string | number;
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    placeholder?: string;
    input_placeholder?: string;
    nothing_found?: string;
    options: Item[];
    onValueChange?: (value:Item) => void;
    onChangeValue?: (value:Item|null) => void; // DEMO DOELEINDEN - OM TE KUNNEN DESELECTEREN
    selectedValue?: string | 'clear';
}
 
export const Combobox : React.FC<Props> = ({placeholder='Select...', input_placeholder='Search...', nothing_found='No items found', options, onValueChange, onChangeValue, selectedValue, className, ...props}) => {
  const [open, setOpen] = React.useState(false)
  const [value, setValue] = React.useState('')

  React.useEffect(() => {
    if(selectedValue){
      if(selectedValue === 'clear'){
        setValue('')
        return;
      }
      setValue(selectedValue)
    }
  }, [selectedValue])
  
  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          {...props}
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-full justify-between", className)}
        >
          {value
            ? options.find((framework) => framework.value.toString().toLowerCase() === value.toLowerCase())?.label
            : placeholder}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-full p-0">
        <Command
          filter={(value, search) => {
            if (
                value
                .toLowerCase()
                .includes(search?.toLowerCase())
            )
              return 1;
            return 0;
          }}
        >
          <CommandInput placeholder={input_placeholder} />
          <CommandEmpty>{nothing_found}</CommandEmpty>
          <CommandGroup className='overflow-y-auto max-h-60'>

            {options.map((framework) => (
              <CommandItem
                key={framework.value}
                onSelect={(currentValue) => {
                  setValue(framework.value.toString() === framework.value.toString() ? "" : framework.value.toString())
                  onValueChange && onValueChange(framework)
                  onChangeValue && onChangeValue(value.toString() === framework.value.toString() ? null : framework)
                  setOpen(false)
                }}
                value={framework.label.toString()}
              >
                <Check
                  className={cn(
                    "mr-2 h-4 w-4",
                    value === framework.value.toString() ? "opacity-100" : "opacity-0"
                  )}
                />
                {framework.label}
              </CommandItem>
            ))}
            
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  )
}