import React, { useEffect, useRef, useState } from 'react'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { useNavigate } from 'react-router-dom'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { RadioGroup, RadioGroupItem } from '../../../../components/ui/radio-group'
import { DatePicker } from '../../../../components/ui/datepicker'
import { format, isBefore, isSameMonth, isToday, subMonths, subWeeks } from 'date-fns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Tablist, TablistItem } from '../../../../components/ui/tablist'
import Coins from './input/Coins'
import Notes from './input/Notes'
import { isInterface } from '../../../../lib/utils'
import PlannedStopsCalendar from '../../../../components/portal/stop/PlannedStopsCalendar'
import { DayObject } from '../../../../components/portal/stop/calendar/Calendar'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../lib/date'
import { CoinsIcon, Eye, EyeOff, HelpCircle, Info } from 'lucide-react'
import Concept from './concept/Concept'
import { useQuery } from '@tanstack/react-query'
import PreviousOrders from './orders/PreviousOrders'
import { getCustomerList } from '../../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'
import ReactGA from "react-ga4";
import { createTicket } from '../../../../lib/ticket/create-ticket'
import { CONFIG } from '../../../../config'

type Subpage = 'c' | 'n' | ''

const Main = () => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const navigate = useNavigate()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const coupure = useSelector((state:RootState)=>state.coupure)

    const [selected_subpage, setselected_subpage] = useState<Subpage>('');
    const [denom, setdenom] = useState<Denom | StateType>('loading');
    const [view_form, setview_form] = useState<boolean>(true);

    const isEdit = useRef(false);
    const isEditDraft = useRef<null | number>(null)

    const [selected_date, setselected_date] = useState<Date | undefined>();
    const [selected_event, setselected_event] = useState<DayObject | null>(null);
    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [customers_list, setcustomers_list] = useState<Customer[] | null>();
    const [reference, setreference] = useState<string>('');
    const [coupures, setcoupures] = useState<CoupureInput[] | null>(null); 

    const {refetch:refetch_concept} = useQuery<null | OrderDraft[]>({
      queryKey: ['order_concept'],
      queryFn: async () => {
          try {
              const {data} = await axios.post(`order/draft/search`, {
                  token: auth.data?.token,
                  partner: auth.data?.partner,
                  status: '001'
              })
              if(data.errorcode === 0 && Array.isArray(data.object.orderdraft)) { return data.object.orderdraft }
              else { return null; }
          } catch (error) {
              console.log(error)
              return null;
          }
      },
      enabled: false
    })

    const {refetch:refetch_orders} = useQuery<OrderDraft[] | null>({
      queryKey: ['previous_orders'],
      queryFn: async () => {
          try {
              const {data} = await axios.post(`order/draft/search`, {
                  token: auth.data?.token,
                  startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                  enddate: formatDateToYYYYMMDD(new Date()),
                  partner: auth.data?.partner,
                  status: '009',
                  searchby: 'C'
              })
              if(data.errorcode === 0 && Array.isArray(data.object.orderdraft)) { return data.object.orderdraft }
              else { return null; }
          } catch (error) {
              console.log(error)
              return null
          }
      }
    })

    useEffect(()=>{
      if (auth.data?.partner.split(",").length === 1) {
          handlePickPartner(auth.data.partner.split(",")[0])
      }
    }, [])


    const handlePickPartner = async (relnr:string) => {
      try {
        if(selected_partner!==relnr){setselected_customer('clear')}
        setselected_partner(relnr);
        setcustomers_list(null);

        const list = await getCustomerList(auth, relnr)
        if(list){ setcustomers_list(list) }
        if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
      } catch (error) {
        console.log(error)
        toast.error(t("order_and_pickup.o_place_order.toast_wrong"))
      }

      try {
          const {data} = await axios.post(`partner/denom/search`, {
              token: auth.data?.token,
              partner: relnr
          })
          if(data.errorcode !== 0) { toast.error(t("order_and_pickup.o_place_order.toast_no_list")); setdenom('error');  return; }
          else {
            if(!data.object[0]) { throw new Error()}
            data.object[0].denom.coins.sort((a:any,b:any)=> b.coupure - a.coupure)
            data.object[0].denom.notes.sort((a:any,b:any)=> b.coupure - a.coupure)
            console.log(data.object[0])
            setdenom(data.object[0].denom)
          }
      } catch (error) {
          console.log(error)
          toast.error(t("order_and_pickup.o_place_order.toast_no_list"));
          setdenom('error');
      }
    }

    const onChangeInput = (coupure:CoupureType, qty:number) => {
      qty = Math.round(qty)
      if(coupures) {

          let tmp = [...coupures]
          const existingIndex = coupures.findIndex(item => item.coupure === coupure);

          if(existingIndex !== -1){
            tmp[existingIndex] = {qty:qty, coupure:coupure}
            if(qty === 0){ tmp.splice(existingIndex, 1) }
          }
          else {
            tmp.push({qty:qty, coupure:coupure}) 
          }
          

          setcoupures(tmp);
      }
      else {
          setcoupures([{coupure:coupure, qty:qty}])
      }
    };

    const onPickDate = (v:DayObject) => {
      if(v.cn !== 'CN'){ 
        setselected_subpage(v.cn?.toLowerCase() as Subpage) 
      } else { 
        setselected_subpage('c') 
      } 
      setselected_event(v)
    }

    function isCoupuresValid() {
      let valid = false;
      if(coupures){
        let tmp = [...coupures]
        if(selected_subpage === 'c'){
          valid = tmp.filter(i=>parseFloat(i.coupure) <= 2 && i.qty > 0).length > 0
        }
        if(selected_subpage === 'n'){
          valid = tmp.filter(i=>parseFloat(i.coupure) > 2 && i.qty > 0).length > 0
        }
      }
      return valid;
    }

  const saveDraft = async () => {
    try {
      if(!selected_customer) { toast.error(t("order_and_pickup.o_place_order.toast_select_customer")); return; }
      if(selected_subpage === '') { toast.error(t("order_and_pickup.o_place_order.toast_select_type")); return; }
      if(!selected_event?.date) { toast.error(t("order_and_pickup.o_place_order.toast_select_date")); return; }
      if(!coupures) { toast.error(t("order_and_pickup.o_place_order.toast_select_order")); return; }

      type Body = {
        token: string | undefined;
        relnr: string;
        cn: "c" | "n";
        date: string;
        reference: string;
        coupures: CoupureInput[];
        id?: number;
      }

      const obj:Body = {
        token: auth.data?.token,
        relnr: selected_customer,
        cn: selected_subpage,
        date: formatDateToYYYYMMDD(selected_event.date || new Date),
        reference: reference,
        coupures: coupures
      }

      console.log('Aangemaakt:', obj)

      toast.loading(t("order_and_pickup.o_place_order.toast_loading_order"), {id:'saveDraft'})

      let URL = 'order/draft/create'
      if(isEdit.current && isEditDraft.current){ 
        URL = 'order/draft/update'
        obj.id = isEditDraft.current
      }

      const {data} = await axios.post(`${URL}`, obj)
      if(data.errorcode !== 0) { throw new Error(data.errorcode) }
      isEdit.current = false;
      isEditDraft.current = null;

      toast.success(t("order_and_pickup.o_place_order.toast_success_order"), {id:'saveDraft'})
      setcoupures(null);
      refetch_concept();
      refetch_orders()
      
      ReactGA.event({
        category: 'Order draft',
        action: 'order_draft_success',
      })
    } catch (error) {
      console.log(error);
      toast.error(t("order_and_pickup.o_place_order.toast_error_order"), {id:'saveDraft'})

      ReactGA.event({
        category: 'Order draft',
        action: 'order_draft_failed',
      })
    }
  }

  const saveOrder = async () => {
    isEdit.current = false;
    try {
      if(!selected_customer) { toast.error(t("order_and_pickup.o_place_order.toast_select_customer")); return; }
      if(selected_subpage === '') { toast.error(t("order_and_pickup.o_place_order.toast_select_type")); return; }
      if(!selected_event?.date) { toast.error(t("order_and_pickup.o_place_order.toast_select_date")); return; }
      if(!coupures) { toast.error(t("order_and_pickup.o_place_order.toast_select_order")); return; }

      if(isBefore(selected_event.date, new Date)){ toast.error(t("order_and_pickup.o_place_order.toast_select_date")); return; }

      const obj = {
        token: auth.data?.token,
        relnr: selected_customer,
        cn: selected_subpage,
        date: formatDateToYYYYMMDD(selected_event.date || new Date),
        reference: reference,
        coupures: coupures
      }

      console.log('Aangemaakt:', obj)
      

      toast.loading(t("order_and_pickup.o_place_order.toast_loading_order"), {id:'saveDraft'})
      const {data} = await axios.post(`order/draft/create`, obj)
      if(data.errorcode !== 0) { throw new Error(data.errorcode) }

      if(!auth.data?.role){ throw new Error('No role defined') }
      console.log(`Concept created, trying to ${auth.data.role < 5 ? '/validate' : '/tovalidate'}`)
      if(auth.data.role < 5){
        await axios.post(`order/draft/validate`, { token:auth.data?.token, id: data.object.id })

        ReactGA.event({
          category: 'Order validated',
          action: 'order_create_validate_success',
          value: auth.data.role
        })
      }
      else {
        await axios.post(`order/draft/tovalidate`, { token:auth.data?.token, orders: data.object.id })
        ReactGA.event({
          category: 'Order tovalidate',
          action: 'order_create_tovalidate_success',
          value: auth.data.role
        })
      }
      
      toast.success(t("order_and_pickup.o_place_order.toast_success_order"), {id:'saveDraft'})
      setcoupures(null);
      refetch_concept();

      
      
    } catch (error) {
      console.log(error);
      let errMessage = t("order_and_pickup.o_place_order.toast_error_order")
      if(typeof(error) === 'number'){
        if(error === 2301){ errMessage = t("errors.error-2301") }
        if(error === 2302){ errMessage = t("errors.error-2302") }
        if(error === 2303){ errMessage = t("errors.error-2303") }
        if(error === 2304){ errMessage = t("errors.error-2304") }
        if(error === 2305){ errMessage = t("errors.error-2305") }
        if(error === 2306){ errMessage = t("errors.error-2306") }
        if(error === 2307){ errMessage = t("errors.error-2307") }
        if(error === 2308){ errMessage = t("errors.error-2308") }
        if(error === 2309){ errMessage = t("errors.error-2309") }
        if(error === 2310){ errMessage = t("errors.error-2310") }
        if(error === 2311){ errMessage = t("errors.error-2311") }
        if(error === 2312){ errMessage = t("errors.error-2312") }
        if(error === 2313){ errMessage = t("errors.error-2313") }
        if(error === 2314){ errMessage = t("errors.error-2314") }
        if(error === 2315){ errMessage = t("errors.error-2315") }
        if(error === 2316){ errMessage = t("errors.error-2316") }
        if(error === 2317){ errMessage = t("errors.error-2317") }
      }

      toast.error(errMessage, {id:'saveDraft'})
      ReactGA.event({
        category: 'Order',
        action: 'order_failed',
      })

      try {
        await createTicket(auth, `
          PAGE: place order \n
          BODY:
          relnr: ${selected_customer},\n
          cn: ${selected_subpage},\n
          date: ${formatDateToYYYYMMDD(selected_event?.date || new Date)},\n
          reference: ${reference},\n
          coupures: ${JSON.stringify(coupures)}\n
          ERROR: ${error}
        `, error)
      } catch (error) {}
    }    
  }

  const onEdit = (draft:OrderDraft) => {
    isEdit.current = true;
    isEditDraft.current = draft.id
    window.scrollTo(0, 0);

    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === draft.relnr))
    let partner = partners.find(obj => obj.relnr === relation?.id)
    if(partner) { handlePickPartner(partner.relnr.toString()); } else { toast.error('Error'); return; }

    setselected_customer(draft.relnr.toString())

    let dt = convertToDate(draft.dtdelivery)
    if(dt) { 
      setselected_date(dt); 
      onPickDate({
        isSameMonth: isSameMonth(dt, new Date()),
        isToday: isToday(dt),
        isBeforeToday: isBefore(dt, new Date()),
        day: format(dt, "d"),
        date: dt,
        events: [],
        cn: draft.cn.toUpperCase() as 'C' | 'N',
        isSelected: true,
      }) 
    }
    
    setreference(draft.reference)

    let tmp_draft_coupures = [...draft.coupure] 
    tmp_draft_coupures.map(i=>i.qty=(i.qty/parseFloat(i.coupure)))
    tmp_draft_coupures.map(i=>i.coupure=i.coupure.toString() as CoupureType)
    setcoupures(tmp_draft_coupures)
  }

  const customerObject = Array.isArray(customers_list) ? customers_list.find(i=>i.relnr.toString()===selected_customer?.toString()) : undefined
  const isCustomerBulk = customerObject ? customerObject.bulk : false
  if(isCustomerBulk && isInterface(denom, {} as Denom)){
    if(denom.coins){
      denom.coins = denom.coins.map((c)=>{
        let u = {...c}
        if(u.perqty > 0){ u.perqty = 1}
        return u
      })
    }
    if(denom.notes){
      denom.notes = denom.notes.map((c)=>{
        let u = {...c}
        if(u.perqty > 0){ u.perqty = 1}
        return u
      })
    }
  }

  function isRequiredReference () {
    if(!selected_partner){ return false }
    const includes = CONFIG.order_and_collect.order.requiredReference_relnrs.includes(selected_partner)
    return includes
  }

  function isComplete() {
    const requiredRef = isRequiredReference()
    if(requiredRef){
      if(reference.length < 1){ return false }
    }
    return true
  }

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>

        <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_place_order.link_orders")}</p>}, {title:t("order_and_pickup.o_place_order.link_place_order")}]}/>
        <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_place_order.title")}</h1>

        <div className={`flex flex-col ${view_form && 'gap-4'} p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>

          <div className='flex justify-between'>
            <h2 className='text-lg'>{t("order_and_pickup.o_place_order.subtitle")}</h2>
            <button onClick={()=>{setview_form(prev=>!prev)}} className='w-8 h-8 flex justify-end items-start opacity-50 hover:opacity-100'>{view_form ? <Eye strokeWidth={1.5}/> : <EyeOff strokeWidth={1.5}/>}</button>
          </div>

          <div className={`${view_form ? '' : 'max-h-0'} duration-300 overflow-hidden flex flex-col gap-4`}>

            <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                <div>
                    <Label htmlFor='partner'>{t("order_and_pickup.o_place_order.partner")}</Label>
                    <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("order_and_pickup.o_place_order.partner_placeholder")||""} input_placeholder={t("order_and_pickup.o_place_order.search_placeholder") || ""}/>
                </div>

                <div>
                    <Label htmlFor='end_customer'>{t("order_and_pickup.o_place_order.customer")}</Label>
                    <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_subpage(''); setcoupures(null); setselected_date(undefined); setselected_event(null); setselected_customer(value.value.toString()) }} options={Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:item.name})) : []} placeholder={t("order_and_pickup.o_place_order.customer_placeholder")||""} input_placeholder={t("order_and_pickup.o_place_order.search_placeholder") || ""}/>
                </div>

                <div>
                    <Label htmlFor='deliverydate'>{t("order_and_pickup.o_place_order.date_delivery")}</Label>
                    <PlannedStopsCalendar page='order' onSelectDay={(v)=>{onPickDate(v)}} disabled={selected_customer==='clear' || !(selected_partner !== null && selected_customer !== null && selected_customer)} partnerid={selected_partner ? parseFloat(selected_partner) : 0} clientrelnr={selected_customer ? parseFloat(selected_customer) : 0} setselectedDate={setselected_date} selectedDate={selected_date}/>
                </div>

                <div>
                    <Label htmlFor='reference' className='flex items-center gap-1 mb-1'>{t("order_and_pickup.o_place_order.reference")}{isRequiredReference() && <span className='text-primary'>*</span>}</Label>
                    <Input value={reference} onChange={({currentTarget})=>{setreference(currentTarget.value)}} id='reference'/>
                </div>
            </div> 

            {selected_event?.cn === 'CN' && 
            <Tablist onValueChange={(value)=>{setselected_subpage(value as Subpage)}} value={selected_subpage}>
                <TablistItem value='c'>{t("order_and_pickup.o_place_order.coins")}</TablistItem>
                <TablistItem value='n'>{t("order_and_pickup.o_place_order.notes")}</TablistItem>      
            </Tablist>
            }
            
            {(denom === 'loading' && selected_subpage !== '') && <p>{t("order_and_pickup.o_place_order.loading")}</p>}
            {(denom === 'error' && selected_subpage !== '') && <p>{t("order_and_pickup.o_place_order.error")}</p>}
            {isInterface(denom, {} as Denom) &&
            <>
            {(isCustomerBulk && (selected_subpage === 'c' ||selected_subpage === 'n')) && <p className='bg-neutral-800/10 pr-3 pl-2 py-1 rounded-full w-fit font-medium text-sm flex items-center gap-2 select-none'><CoinsIcon className='w-4 h-4'/> {t("order_and_pickup.o_place_order.bulk_mode")}</p>}
            {selected_subpage === 'c' && <Coins bulk={isCustomerBulk} onChangeInput={onChangeInput} denom={denom} coupures={coupures}/>}
            {selected_subpage === 'n' && <Notes bulk={isCustomerBulk} onChangeInput={onChangeInput} denom={denom} coupures={coupures}/>}
            </>
            }

            {isCoupuresValid() && 
            <div>
              <Button disabled={!isComplete()} onClick={()=>{saveOrder()}} className='w-fit'>{t("order_and_pickup.o_place_order.button")}</Button>
              <Button disabled={!isComplete()} onClick={()=>{saveDraft()}} className='w-fit' variant='link'>{t("order_and_pickup.o_place_order.button_concept")}</Button>
            </div>
            }

          </div>
        </div>

        <PreviousOrders />

        <Concept onEdit={(draft)=>{onEdit(draft)}}/>
    </div>
    </>
  )
}

export default Main