import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useFlags } from "flagsmith/react";

import Layout from "./app/Layout";
import RequireAuth from "./context/RequireAuth";
import Management from "./app/pages/Management/page";
import Login from "./app/pages/Login/page";
import Register from "./app/pages/Register/page";
import Unauthorized from "./app/pages/Unauthorized/page";
import Dashboard from "./app/pages/Dashboard/page";
import useAuth from "./hooks/useAuth";
import PersistLogin from "./app/pages/PersistLogin/page";
import Projects from "./app/pages/Projects/page";
import ForgotPassword from "./app/pages/ForgotPassword/page";
import Portal from "./app/pages/Portal/page";

import { setDefaultOptions } from "date-fns";
import { nl, fr, enUS } from "date-fns/locale";

import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Users from "./app/pages/Management/Users/page";
import Planning from "./app/pages/Planning/page";
import TrackAndTrace from "./app/pages/TrackAndTrace/layout";
import Complaints from "./app/pages/Complaints/Complaints";
import ResetPassword from "./app/pages/ResetPassword/page";
import { routes } from "./routeConfig";
import TwoFactor from "./app/pages/PersistLogin/TwoFactor";
import AnnouncePickup from "./app/pages/OrderAndPickup/AnnouncePickup/AnnouncePickup";
import PlaceOrder from "./app/pages/OrderAndPickup/PlaceOrder/PlaceOrder";
import OverviewCollections from "./app/pages/OrderAndPickup/OverviewCollections/OverviewCollections";
import OrderAndPickup from "./app/pages/OrderAndPickup/Home/OrderAndPickup";
import OverviewOrders from "./app/pages/OrderAndPickup/OverviewOrders/OverviewOrders";
import MessageCenter from "./app/pages/MessageCenter/MessageCenter";
import OverviewConsumables from "./app/pages/OrderAndPickup/OverviewConsumables/OverviewConsumables";
import ValidateOrders from "./app/pages/OrderAndPickup/ValidateOrders/ValidateOrders";
import Settings from "./app/pages/Settings/Settings";
import Pensions from "./app/pages/Pensions/Pensions";
import Sip from "./app/pages/Sip/Sip";
import Interventions from "./app/pages/Sip/Interventions/Interventions";
import ATM from "./app/pages/Sip/ATM/ATM";
import Assets from "./app/pages/Sip/Assets/Assets";
import SIPPlanning from "./app/pages/Sip/Planning/SIPPlanning";
import NotFound from "./app/pages/Error/NotFound";

import ReactGA from "react-ga4";
import OrderConsumables from "./app/pages/OrderAndPickup/OrderConsumables/OrderConsumables";
import RequireRight from "./context/RequireRight";
import RequireRelnr from "./context/RequireRelnr";
import ErrorBoundary from "./components/portal/error/ErrorBoundary";

function App() {
  const { t, i18n } = useTranslation()
  const { auth } = useAuth();

  useEffect(()=>{
    ReactGA.send({hitType:'page_view', page:window.location.pathname})
  }, [])

  useEffect(()=>{
    switch (i18n.language) {
      case 'nl':
        setDefaultOptions({ locale: nl})
        break;
      case 'fr':
        setDefaultOptions({ locale: fr})
        break;
      case 'en':
          setDefaultOptions({ locale: enUS})
          break;
      default:
        setDefaultOptions({ locale: enUS})
        break;
    }
  }, [i18n.language])

  if(auth.status === 'unauthenticated') {
    console.error("Unauthenticated")
    localStorage.clear();;
    return (
      <Navigate to="/login" replace/>
    )
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>

        {/* public routes */}
        <Route path="login" element={<Login />} />      
        <Route path="register" element={<Register />}/>
        <Route path="forgot-password" element={<ForgotPassword />}/>
        <Route path="reset-password" element={<ResetPassword />}/>
        <Route path="unauthorized" element={<Unauthorized />}/>

        {/* all authentication needed */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth allowedRoles={[0,1,2,3,4,5]}/>}>
            
              <Route element={<Portal />}>
              
                {routes.dashboard.map((path, index)=>(
                  <Route path={`${path}`} element={<Dashboard />} key={index}/>
                ))}

                {routes.schedule.map((path, index)=>(
                  <Route path={`${path}/*`} element={<Planning/>} key={index}/>
                ))}

                {/* I have to put these routes here because otherwise the routes are running twice (causing TFA to mail twice) */}
                {routes.order_and_pickup.map((path, index)=>(
                  <Route path={`${path}/*`} key={index}>  
                    <Route element={<RequireRight right="order"/>}>
                      <Route path="overzicht-bestellingen/*" element={<OverviewOrders />} />
                    </Route>

                    <Route element={<RequireAuth allowedRoles={[1,2]}/>}>
                      <Route path="te-valideren-bestellingen/*" element={<ValidateOrders />} />
                    </Route>
                    
                    <Route element={<RequireRight right="announce"/>}>
                      <Route path="overzicht-ophalingen/*" element={<OverviewCollections />} />
                    </Route>

                    <Route element={<RequireRight right="consumables"/>}>
                      <Route path="overzicht-verbruiksartikelen/*" element={<OverviewConsumables />} />
                      <Route path="verbruiksartikelen-bestellen/*" element={<OrderConsumables />}/>
                    </Route>
                        
                    <Route element={<TwoFactor />}>
                      <Route element={<RequireRight right="announce"/>}><Route path="ophaling-aankondigen/*" element={<AnnouncePickup />} /></Route>
                      <Route element={<RequireRight right="order"/>}><Route path="bestelling-plaatsen/*" element={<PlaceOrder />} /></Route>
                    </Route>

                    <Route index element={<OrderAndPickup />} />
                  </Route>
                ))}
                
                <Route element={<RequireRight right="ttatm2t" rightList={["ttatm2t", "ttatme2e", "ttcollection", "ttdelivery", "ttsafepoint", "ttstops"]}/>}>
                {routes.track_and_trace.map((path, index)=>(
                  <Route path={`${path}/*`} element={<TrackAndTrace/>} key={index}/>
                ))}
                </Route>

                <Route element={<RequireRight right="sipexecutedinterventions" rightList={["sipexecutedinterventions", "sipmanagements", "sipassets", "sipplanninginterventions"]}/>}>
                  <Route path="sip/*">
                    <Route element={<RequireRight right="sipexecutedinterventions"/>}><Route path="uitgevoerde-interventies/*" element={<Interventions />}/></Route>
                    <Route element={<RequireRight right="sipmanagements"/>}><Route path="beheer-atm/*" element={<ATM />}/></Route>
                    <Route element={<RequireRight right="sipassets"/>}><Route path="assets/*" element={<Assets />} /></Route>
                    <Route element={<RequireRight right="sipplanninginterventions"/>}><Route path="planning-interventies/*" element={<SIPPlanning />} /></Route>
                    
                    <Route index element={<Sip />}/>
                  </Route>
                </Route>
                
                <Route element={<RequireRelnr relnr='15287' type='partner'/>}>
                  <Route element={<RequireRight right="pension"/>}>
                    <Route path="pensioenen" element={<Pensions />}/>
                  </Route>
                </Route>
                

                <Route element={<RequireRight right="complaint"/>}>
                  <Route path="complaints/*" element={<Complaints />}/>
                </Route>

                <Route element={<RequireRight right="message"/>}>
                  <Route path="berichtencentrum/*" element={<MessageCenter />}/>
                </Route>
                
                <Route element={<RequireRight right="adminedit"/>}>
                  <Route element={<RequireAuth allowedRoles={[1]}/>}>
                    <Route path="instellingen/*" element={<Settings />}/>
                  </Route>
                </Route>

                {/* protected admin routes */}
                <Route element={<RequireRight right="userlist"/>}>
                  <Route element={<RequireAuth allowedRoles={[1,2]}/>}>
                    <Route path="gebruikers/*" element={<Users />} />
                    <Route path="management" element={<Management />} />
                  </Route>
                </Route>
              </Route>

          </Route>
        </Route>

        {/* catch all */}
        <Route path="*" element={<NotFound />}/>

      </Route>
    </Routes>
  );
}

export default App;
