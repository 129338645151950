import { ActionType, Action } from "../action-types";

const initialState:Partner[] = [];

const partnersReducer = (state:Partner[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_PARTNERS:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default partnersReducer;