import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import toast, { Toaster } from 'react-hot-toast'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { formatCashAmount } from '../../../../../lib/utils'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { addDays, format, isBefore } from 'date-fns'
import { getOrderDraftState } from '../../../../../lib/general/params'
import { CheckCircle, Edit2, Trash2 } from 'lucide-react'
import TooltipForce from '../../../../../components/ui/tooltip-force'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog'
import { AlertDialogAction } from '@radix-ui/react-alert-dialog'
import { Button } from '../../../../../components/ui/button'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { useTranslation } from 'react-i18next'
import ReactGA from "react-ga4";
import { CONFIG } from '../../../../../config'

type Props = {
    onEdit: (draft:OrderDraft) => void;
}

const Concept : React.FC<Props> = ({onEdit}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const {data:drafts, isFetching, refetch} = useQuery<null | OrderDraft[]>({
        queryKey: ['order_concept'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`order/draft/search`, {
                    token: auth.data?.token,
                    partner: auth.data?.partner,
                    status: '001'
                })
                if(data.errorcode === 0 && Array.isArray(data.object.orderdraft)) { return data.object.orderdraft }
                else { return null; }
            } catch (error) {
                console.log(error)
                return null;
            }
        }
    })

  return (
    <>
    <Toaster />
    <div className='p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
        <h2 className='text-lg'>{t("order_and_pickup.o_place_order.concept_title")} ({drafts?.length || 0})</h2>

        <div>
            {!drafts 
            ? <>{isFetching ? <LoadingScreen className='h-[250px]'/> : <p>{t("order_and_pickup.o_place_order.concept_none")}</p>}</>
            : 
            <div className='border rounded-sm mt-4'>
                <Table className='border-collapse'>
                    <TableHeader className='bg-slate-100'>
                        <TableRow className='uppercase text-muted-foreground font-medium'>
                            <TableHead></TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.partner")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.customer_p2")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.customer_code")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.type")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.amount")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.date_delivery")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.date_created")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.status")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.created_by")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.reference")}</TableHead>
                            <TableHead>{t("order_and_pickup.o_place_order.auto_ref")}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {drafts.map((draft, index)=> {
                            let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === draft.relnr))
                            let partner = partners.find(obj => obj.relnr === relation?.id)
                            let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === draft.relnr)

                            return (
                                <TableRow key={index}>
                                    <TableCell>
                                        <div className='flex items-center'>
                                            <ValidateButton draft={draft} onSuccess={refetch}/>
                                            <EditButton onClick={()=>{onEdit(draft)}}/>
                                            <DeleteButton draft={draft} onSuccess={refetch}/>
                                        </div>
                                    </TableCell>
                                    <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                    <TableCell className='whitespace-nowrap'>{draft.name}</TableCell>
                                    <TableCell>{customer?.clientcode}</TableCell>
                                    <TableCell>{draft.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_place_order.coins") : t("order_and_pickup.o_place_order.notes")}</TableCell>
                                    <TableCell className='text-right'>{formatCashAmount(draft.amount)}</TableCell>
                                    <TableCell>{format(convertToDate(draft.dtdelivery) || new Date(), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{format(convertToDate(draft.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(draft.tmcreation)}</TableCell>
                                    <TableCell>{getOrderDraftState(draft.status, orderdraftstate)?.description}</TableCell>
                                    <TableCell>{draft.username}</TableCell>
                                    <TableCell>{draft.reference}</TableCell>
                                    <TableCell>{draft.autoreference}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>
            }
        </div>
    </div>
    </>
  )
}

const ValidateButton = ({draft, onSuccess}:{draft:OrderDraft, onSuccess:()=>void}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    function isRequiredReference () {
        if(!draft.relnr){ return false }
        const includes = CONFIG.order_and_collect.order.requiredReference_relnrs.includes(draft.relnr.toString())
        return includes
    }

    async function validateDraft() {
        try {
            if(!draft.dtdelivery){ toast.error(t("order_and_pickup.o_place_order.toast_select_date")); return; }
            if(isBefore(convertToDate(draft.dtdelivery) || new Date, new Date)){ toast.error(t("order_and_pickup.o_place_order.toast_select_date")); return; }
            
            if(isRequiredReference()){if(!draft.reference){ toast.error(t("order_and_pickup.o_place_order.reference_required")); return; }}

            if(!auth.data?.role){ throw new Error('No role defined') }
            console.log(`Concept validating, trying to ${auth.data.role < 5 ? '/validate' : '/tovalidate'}`)
            let result:any;
            if(auth.data.role < 5){
                result = await axios.post(`order/draft/validate`, { token:auth.data?.token, id: draft.id })

                ReactGA.event({
                category: 'Concept Order validated',
                action: 'concept_order_create_validate_success',
                value: auth.data.role
                })
            }
            else {
                result = await axios.post(`order/draft/tovalidate`, { token:auth.data?.token, orders: draft.id })
                ReactGA.event({
                category: 'Concept Order tovalidate',
                action: 'concept_order_create_tovalidate_success',
                value: auth.data.role
                })
            }

            if(result.data.errorcode!==0){ throw new Error(result.data.errorcode) }

            onSuccess()
            toast.success(t("order_and_pickup.o_announce_pickup.o_concept.toast_success_p2"), {id:'validateDraft'})
        } catch (error) {
            toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p4"), {id:'validateDraft'})
            console.log(error)
        }
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_validate")}</p>}>
                <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-green-500'><CheckCircle className='w-5'/></button>
            </TooltipForce>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-xl font-semibold'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_title")}</h2>
                    <p className='text-neutral-500 text-sm'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_description")}</p>
                    <div className='flex justify-end gap-2'>
                        <AlertDialogCancel className='border-none' asChild><Button variant='ghost'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_cancel")}</Button></AlertDialogCancel>
                        <AlertDialogAction onClick={()=>{validateDraft()}} asChild><Button>{t("order_and_pickup.o_announce_pickup.o_concept.alert_action")}</Button></AlertDialogAction>
                    </div>
                </div>
            </AlertDialogContent>
        </AlertDialog>
    )
}

const EditButton = ({onClick}:{onClick:()=>void}) => {
    const {t} = useTranslation()
    return (
        <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_edit")}</p>}>
            <button onClick={onClick} className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-orange-500'><Edit2 className='w-5'/></button>
        </TooltipForce>
    )
}

const DeleteButton = ({draft, onSuccess}:{draft:OrderDraft, onSuccess:()=>void}) => {
    const {auth} = useAuth();
    const {t} = useTranslation()

    async function deleteDraft() {
        try {
            toast.loading(t("order_and_pickup.o_announce_pickup.o_concept.toast_loading"), {id:'deleteDraft'})
            const {data} = await axios.post(`order/draft/delete`, {
                token: auth.data?.token,
                id: draft.id
            })
            if(data.errorcode !== 0) { console.log(data); throw new Error() }
            onSuccess()
            toast.success(t("order_and_pickup.o_announce_pickup.o_concept.toast_success"), {id:'deleteDraft'})
        } catch (error) {
            toast.error(t("order_and_pickup.o_announce_pickup.o_concept.toast_error_p3"), {id:'deleteDraft'})
            console.log(error)
        }
    }

    return (
        <AlertDialog>
            <AlertDialogTrigger asChild>
                <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_concept.tooltip_delete")}</p>}>
                    <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-red-500'><Trash2 className='w-5'/></button>
                </TooltipForce>
            </AlertDialogTrigger>
            <AlertDialogContent>
                <div className='flex flex-col gap-4'>
                    <h2 className='text-xl font-semibold'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_title")}</h2>
                    <p className='text-neutral-500 text-sm'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_description_p2")}</p>
                    <div className='flex justify-end gap-2'>
                        <AlertDialogCancel className='border-none' asChild><Button variant='ghost'>{t("order_and_pickup.o_announce_pickup.o_concept.alert_cancel")}</Button></AlertDialogCancel>
                        <AlertDialogAction onClick={()=>{deleteDraft()}} asChild><Button>{t("order_and_pickup.o_announce_pickup.o_concept.alert_action_p2")}</Button></AlertDialogAction>
                    </div>
                </div>
            </AlertDialogContent>
        </AlertDialog>
    )
}

export default Concept