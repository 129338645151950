import { createContext, useState } from "react";

const AuthContext = createContext<AuthContext | null>(null);

export const AuthProvider = ({ children } : { children:React.ReactNode}) => {
    const [auth, setAuth] = useState<Auth>({
        data: null,
        status: null
    });

    const storedPersist = sessionStorage.getItem('persist');
    const parsedPersist = Boolean(storedPersist)
    const [persist, setpersist] = useState(parsedPersist);

    return (
        <AuthContext.Provider value={{ auth, setAuth, persist, setpersist }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;