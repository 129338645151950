import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { Toaster, toast } from 'react-hot-toast'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { isInterface } from '../../../../../lib/utils'
import { convertToDate, convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date'
import ColliDetail from '../../../../../components/portal/colli/ColliDetail'
import ColliOverviewSafepoint from '../../../../../components/portal/colli/ColliKeepsafeOverview'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import ColliDetailOverview from '../../../../../components/portal/colli/ColliDetailOverview'
import { subDays } from 'date-fns'
import ColliKeepsafeOverview from '../../../../../components/portal/colli/ColliKeepsafeOverview'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import EmptyFeedback from '../../../../../components/portal/feedback/EmptyFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import { useTranslation } from 'react-i18next'

const E2ECollectionColli = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const location = useLocation()
    const {relnr, colliid} = useParams()
    const navigate = useNavigate();

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    const [has_state, sethas_state] = useState(true);
    const [stop, setstop] = useState<Stop | StateType>(null);
    const [colli, setcolli] = useState<Colli | StateType>(null);

    const [order, setorder] = useState<Order | StateType>(null);

    useEffect(()=>{
        if(!location.state){
            sethas_state(false)
            return
        }
        setstop(location.state)
        fetch_colli()
    }, [])

    useEffect(()=>{
        if(!has_state){
            const instance = lottie.loadAnimation({
                container: errorContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: errorAnimation
            })
            return () => instance.destroy()
        }
    }, [has_state])

    async function fetch_colli() {
        try {
            setcolli('loading')
            const { data } = await axios.post(`colli/search`, {
                token: auth.data?.token,
                id: colliid,
                equal: 0
            })
        
            if(data.errorcode === 0){
                if(Array.isArray(data.object.colli)){
                    const c = data.object.colli[0] as Colli
                    setcolli(c)
                    if(c.orderid) {
                        fetch_order(c)
                    }
                }
                else {
                toast.error(t("track_and_trace.o_detail.toast_no_colli"))
                console.log(data)
                }
                
            }
            else {
                toast.error(`[${data.errorcode}] - ${data.message}`)
                console.log(data)
                setcolli('error')
            }
        } catch (error) {
          console.log(error)
          toast.error(t("track_and_trace.o_detail.toast_wrong"))
          setcolli('error')
        }
    }

    async function fetch_order(colli:Colli) {
        try {
            setorder('loading')
            const { data } = await axios.post(`order/search`, {
                token: auth.data?.token,
                id: colli.orderid,
                startdate: formatDateToYYYYMMDD(convertToDate(colli.dtcreation.toString()) || new Date()), //dtcreation
                enddate: formatDateToYYYYMMDD(convertToDate(colli.dtmodif.toString()) || new Date()) //dtmodif
            })
            if(data.errorcode === 0 && Array.isArray(data.object.order)){
                setorder(data.object.order[0])
            }
            else {
                setorder('error')
                toast.error(`[${data.errorcode}] - ${data.message}`)
            }
        } catch (error) {
            console.log(error)
            toast.error(t("track_and_trace.o_detail.toast_wrong_order"))
            setorder('error')
        }
      }

    function returnTypeLabel(type:string) {
        switch (type.toLowerCase()) {
            case 'vpl':
                return t("track_and_trace.vpl")
            case 'oaf':
                return t("track_and_trace.oaf")
            case 'opa':
                return t("track_and_trace.opa")
            case 'rfk':
                return t("track_and_trace.rfk")
            case 'rfc':
                return t("track_and_trace.rfc")
            case 'pri':
                return t("track_and_trace.pri")
            case 'ext':
                return t("track_and_trace.ext")
            default:
                return ''
        }
    }

  return (
    <>
        <Toaster />
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_detail.link_tt")}</p>}, {title:<a onClick={()=>{navigate(`/track-en-trace/atm_e2e_manage/${relnr}`, {state:stop})}} className='cursor-pointer'>{`${t("track_and_trace.o_detail.link_atm")} ${relnr}`}</a>}, {title:`${t("track_and_trace.o_detail.colli")} ${colliid}`}]}/>
                <h1 className='text-2xl font-semibold'>Track & Trace - colli</h1>
            </div>

            {!has_state &&
            <>
            <div className={`flex flex-col items-center justify-center`}>
                <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
                <h2 className='text-2xl font-semibold mb-4'>{t("track_and_trace.o_detail.error_title")}</h2>
                <p className='text-center'>{t("track_and_trace.o_detail.error_description_p5")}<br></br>{t("track_and_trace.o_detail.error_description_p2")} <Link to='/track-en-trace' className='font-medium underline'>{t("track_and_trace.o_detail.error_description_p3")}</Link> {t("track_and_trace.o_detail.error_description_p4")}</p>
            </div>
            </>
            }

            {has_state &&
            <>
            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>      
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_atm")}</h2>
                {stop === 'loading' && <SpinnerFeedback />}
                {stop === 'empty' && <EmptyFeedback />}
                {stop === 'error' && <ErrorFeedback />}
                {isInterface(stop, {} as Stop) &&
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>ID</p>
                        <p className='text-neutral-500'>{stop.id}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.relation_nr")}</p>
                        <p className='text-neutral-500'>{stop.relnr}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_stop")}</p>
                        <p className='text-neutral-500'>{stop.date}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.executed")}</p>
                        <p className='text-neutral-500'>{stop.executed ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.ride")}</p>
                        <p className='text-neutral-500'>{stop.ritid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.customer")}</p>
                        <p className='text-neutral-500'>{stop.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.arrived")}</p>
                        <p className='text-neutral-500'>{convertToHHMM(stop.tmarrival)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.departure")}</p>
                        <p className='text-neutral-500'>{convertToHHMM(stop.tmdepart)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.extra")}</p>
                        <p className='text-neutral-500'>{stop.extra ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.route_code")}</p>
                        <p className='text-neutral-500'>{stop.routecode}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.description")}</p>
                        <p className='text-neutral-500'>{stop.description}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.error")}</p>
                        <p className='text-neutral-500'>{stop.fault}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.type")}</p>
                        <p className='text-neutral-500'>{returnTypeLabel(stop.type)}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.catch")}</p>
                        <p className='text-neutral-500'>{stop.rattrappage ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                </div>
                }   
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_colli")}</h2>
                {colli === 'loading' && <SpinnerFeedback />}
                {colli === 'empty' && <EmptyFeedback />}
                {colli === 'error' && <ErrorFeedback />}
                {isInterface(colli, {} as Colli) && <ColliDetail colli={colli}/>}
            </div>
            
            <div className='flex border flex-col gap-4 rounded-sm py-4 mt-4 md:mt-8'>
            {(order === 'loading' && !isInterface(stop, {} as Stop)) && <SpinnerFeedback />}
            {order === 'empty' && <EmptyFeedback />}
            {order === 'error' && <ErrorFeedback />}
            {isInterface(colli, {} as Colli) && isInterface(stop, {} as Stop) && 
            <ColliKeepsafeOverview colliid={colli.id} keepsafeDate={stop.date}/>
            }
            </div>
            
            </>
            }
        </div>
    </>
  )
}

export default E2ECollectionColli