import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import Detail from './atm/Detail'

const ATM = () => {
  return (
    <Routes>
      <Route path="" element={<Main />} />
      <Route path=":atmid/:atmdate/:interventioncode" element={<Detail />} />
    </Routes>
  )
}

export default ATM