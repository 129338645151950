import { format } from 'date-fns'
import React from 'react'
import { convertToDate } from '../../../lib/date'
import Coupure from '../coupure/Coupure'
import { formatCashAmount } from '../../../lib/utils'
import { useTranslation } from 'react-i18next'

type Props = {
    announcement: Announcement
}

const Announcement : React.FC<Props> = ({announcement}) => {
    const {t} = useTranslation()
  return (
    <div className='overflow-hidden'>
        <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.amount")}</p>
                <p className='text-neutral-500'>{formatCashAmount(announcement.amount)}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.date_created")}</p>
                <p className='text-neutral-500'>{format(convertToDate(announcement.dtcreation) || new Date(), "dd/MM/yyyy")}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.communication")}</p>
                <p className='text-neutral-500'>{announcement.communication}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.currency")}</p>
                <p className='text-neutral-500'>{announcement.currency}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.status")}</p>
                <p className='text-neutral-500'>{announcement.state}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_announcement.o_announcement.filename")}</p>
                <p className='text-neutral-500'>{announcement.filename}</p>
            </div>
        </div>

        <div className='h-px w-full bg-neutral-200 my-4'></div>

        <div className='flex flex-col border rounded-sm'>
            <div className='grid grid-cols-3 px-4 py-2 bg-slate-100 text-muted-foreground font-medium border-b'>
                <p></p>
                <p>{t("components.o_announcement.o_announcement.count")}</p>
                <p>{t("components.o_announcement.o_announcement.total")}</p>
            </div>
            <div className='p-4 flex flex-col gap-4'>
                <Coupure cn={announcement.cn.toLowerCase() as 'c' | 'n'} coupures={announcement.coupure} show_total/>
            </div>
        </div>
        
    </div>
  )
}

export default Announcement