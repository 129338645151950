type DataType = {
    total: number;
    perPage: number;
    current: number;
}

export const pagination = (data:DataType) => {
    const { total, perPage, current = 1}  = data;
    const pages = Math.ceil(total/perPage);
  
    if(isNaN(total)) throw new Error('Total param should be number');
    if(isNaN(perPage)) throw new Error('perPage param should be number');
  
    let pagination = generatePagination(pages, current);
  
    return pagination;
};

const addSeparator = (current:number, page:number, offset:number) => {
    const separator = `...`;
    return Math.abs(page - current) > offset ? separator : null;
}

const generatePagination = (pages:number, current:number) => {
    const maxNumberOfElements = 5;
    const firstPage           = 1;
    const lastPage            = pages;
    const offset              = Math.ceil(maxNumberOfElements / 2);
    const offsetLeft          = Array.from({ length: offset }, (_, index) => Math.abs(index - current) <= firstPage || Math.abs(index - current) === current ? null : Math.abs(index - current)).reverse();
    const offsetRight         = Array.from({ length: offset }, (_, index) => (current + index) >= lastPage || (current + index) === current ? null : (current + index));

  
    let pagination = [
      firstPage,
      addSeparator(current, firstPage, offset),
      ...offsetLeft,
      current !== firstPage && current !== lastPage ? current: null,
      ...offsetRight,
      addSeparator(current, lastPage, offset),
      lastPage
    ];
  
    return pagination.filter(item => item !== null)
    .map((item, index, items) => {
      return {
        value: item,
        isSelected: item === current
      };
    });
};