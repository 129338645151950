import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, Route, Routes, useNavigate, useParams } from 'react-router-dom'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { useMutation, useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { isInterface } from '../../../../../lib/utils'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { convertToDate } from '../../../../../lib/date'
import { format, isValid } from 'date-fns'
import { Tablist, TablistItem } from '../../../../../components/ui/tablist'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import Rights from './Rights'
import SpinnerFeedback from '../../../../../components/portal/feedback/SpinnerFeedback'
import ErrorFeedback from '../../../../../components/portal/feedback/ErrorFeedback'
import Logs from './Logs'
import ErrorScreen from '../../../../../components/portal/feedback/ErrorScreen'
import { Button } from '../../../../../components/ui/button'
import { Edit2, Loader2 } from 'lucide-react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog'
import { NumberedStep, ProgressSteps } from '../../../../../components/ui/progress-steps'
import { Label } from '../../../../../components/ui/label'
import { Input } from '../../../../../components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../../components/ui/select'
import Partners from '../add/Partners'
import AddRights from '../add/Rights'
import { useTranslation } from 'react-i18next'

const EditDetail = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const {username} = useParams()
    const navigate = useNavigate()

    const roles = useSelector((state:RootState)=>state.roles) 
    const partners = useSelector((state:RootState)=>state.partners)

    const [selected_subpage, setselected_subpage] = useState('rechten');

    const {data:user, isFetching, refetch} = useQuery<User | null>({
        queryKey: [`user_${username}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`user/search`, {
                    token: auth.data?.token,
                    username:username
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.user){return null}
                return data.object.user[0]
            } catch (error) {
                console.log(error)
                return null;
            }
        }
    })

    const {data:rights, isFetching:isFetchingRights, refetch:refetchRights} = useQuery<UserRight | null>({
        queryKey: [`rights_${username}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`rights`, {
                    token: auth.data?.token,
                    username: username
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.right){return null}
                return data.object.right[0]
            } catch (error) {
                console.log(error) 
                return null
            }
        }
    })

    const EditMain = () => {
        const [current, setCurrent] = useState(0);
        const [email, setemail] = useState('');
        const [firstname, setfirstname] = useState('');
        const [lastname, setlastname] = useState('');
        const [role, setrole] = useState(0);
        const [language, setlanguage] = useState('');
        const [relnr, setrelnr] = useState('');
        const [partner, setpartner] = useState('');

        const [new_rights, setnew_rights] = useState<UserRight | undefined>(rights || undefined);

        useEffect(()=>{
            if(user){
                setemail(user.username)
                setfirstname(user.firstname)
                setlastname(user.lastname)
                setlanguage(user.language)
                setrole(user.role)
                setrelnr(user.relnr)
                setpartner(user.partner)
            }
        }, [user])

        const {mutate, isLoading} = useMutation({
            mutationFn: async () => {
                let obj = {
                    token: auth.data?.token,
                    username: email,
                    firstname: firstname,
                    lastname: lastname,
                    language: language,
                    role: role,
                    relnr: relnr,
                    partner: partner
                }
                
                try {
                    toast.loading(t("users.o_edit.toast_saving_p2"), {id:'updateUser'})
                    const {data} = await axios.post(`user/update`, obj)
                    if(data.errorcode!==0){throw new Error(data.message)}
                    toast.success(t("users.o_edit.toast_saved_p2"), {id:'updateUser'})

                    const {data:data_rights} = await axios.post(`right/update`, {token:auth.data?.token, username:email, ...new_rights})
                    if(data_rights.errorcode!==0){ toast.error(t("users.o_edit.toast_error_p2")) }
                    
                    window.location.reload()
                } catch (error) {
                    toast.error(t("users.o_edit.toast_error_p3"), {id:'updateUser'})
                    console.log(error)
                }

                return null
            }
        })

        function isAbleNext() {
            let result = false;

            if(current === 0){
                if(email !== '' && language !== '' && role > 0){
                    result = true
                }
            }

            if(current === 1){
                if(role === 2 || role === 3){
                    //Check has partner
                    if(partner && partner.length > 0){
                        result = true
                    }
                }
                if(role === 4 || role === 5){
                    //Check has partner & relnr
                    if(partner && partner.length > 0 && relnr && relnr.length > 0){
                        result = true
                    }
                }
            }

            return !result
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild><Button disabled={partners.length<1 || isLoading || auth.data?.username === user?.username} className='gap-2'><Edit2 className='w-4'/>{t("users.o_edit.button_edit")}</Button></AlertDialogTrigger>
                <AlertDialogContent className='w-9/12 overflow-auto max-h-[98vh] max-w-[98vw] sm:max-w-[90vw] mx-4'>
                    <div className='overflow-auto'>
                        {role !== 1 ?
                        <ProgressSteps current={current}>
                            <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.data")}</p>}></NumberedStep>
                            <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.settings")}</p>}></NumberedStep>     
                            <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.rights")}</p>}></NumberedStep>
                        </ProgressSteps>
                        :
                        <ProgressSteps current={current}>
                            <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.data")}</p>}></NumberedStep>                
                            <NumberedStep title={<p className='font-semibold'>{t("users.o_edit.rights")}</p>}></NumberedStep>
                        </ProgressSteps>
                        }
                    </div>

                    {/* Gegevens */}
                    <div className={`${current !== 0 && 'hidden'} p-4 shadow-sm border rounded-sm`}>
                        <div className='flex justify-between'>
                            <h2 className='text-lg'>{t("users.o_edit.data")}</h2>
                        </div>
                        
                        <div className={`my-4 duration-300 grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4`}>
                            <div className='sm:col-span-full'>
                                <Label htmlFor='email'>{t("users.o_edit.mail")}</Label>
                                <Input disabled value={email} onChange={(e) => { setemail(e.currentTarget.value); }} id='email'/>
                            </div>

                            <div>
                                <Label htmlFor='firstname'>{t("users.o_edit.firstname")}</Label>
                                <Input value={firstname} onChange={(e) => { setfirstname(e.currentTarget.value) }} id='firstname'/>
                            </div>

                            <div>
                                <Label htmlFor='lastname'>{t("users.o_edit.lastname")}</Label>
                                <Input value={lastname} onChange={(e) => { setlastname(e.currentTarget.value) }} id='lastname'/>
                            </div>

                            <div>
                                <Label htmlFor='email'>{t("users.o_edit.role")}<span className='text-primary ml-1'>*</span></Label>
                                <Select value={role.toString()} onValueChange={(v) => { setrole(parseFloat(v)) }}>
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        {auth.data?.role === 1 && 
                                        <>
                                        <SelectItem value={"1"}>Loomis Admin</SelectItem>
                                        <SelectItem value={"2"}>Partner Admin</SelectItem>
                                        </>
                                        }                     
                                        <SelectItem value={"3"}>Partner Superuser</SelectItem>
                                        <SelectItem value={"4"}>User</SelectItem>
                                        <SelectItem value={"5"}>Lite User</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>

                            <div>
                                <Label htmlFor='email'>{t("users.o_edit.language")}<span className='text-primary ml-1'>*</span></Label>
                                <Select value={language.toLowerCase()} onValueChange={(v) => { setlanguage(v) }}>
                                    <SelectTrigger>
                                        <SelectValue />
                                    </SelectTrigger>
                                    <SelectContent>
                                        <SelectItem value="n">Nederlands</SelectItem>
                                        <SelectItem value="f">Francais</SelectItem>
                                        <SelectItem value="e">English</SelectItem>
                                    </SelectContent>
                                </Select>
                            </div>
                        </div>
                    </div>

                    {/* Partner Instellingen */}
                    <div className={`${(current !== 1 || user?.role === 1) && 'hidden'} p-4 shadow-sm border rounded-sm`}>
                        <div className='flex justify-between'>
                            <h2 className='text-lg'>{t("users.o_edit.settings")}</h2>
                        </div>

                        <Partners selectedPartners={partner} selectedRelnrs={relnr} changeCustomer={(value)=>{setrelnr(value)}} changePartner={(value)=>{setpartner(value)}} role={role.toString()}/>
                    </div>

                    {/* Gebruikersrechten */}
                    <div className={`${((current !== 2 && user?.role !== 1) || (current !== 1 && user?.role === 1)) && 'hidden'} p-4 shadow-sm border rounded-sm`}>
                        <div className='flex justify-between'>
                            <h2 className='text-lg'>{t("users.o_edit.rights")}</h2>
                        </div>
                        <AddRights role={role.toString()} new_rights={new_rights} setnew_rights={setnew_rights}/>
                    </div>


                    <div className='flex gap-2 justify-between'>
                    {current === 0 ?
                        <AlertDialogCancel asChild><Button variant='outline'>{t("users.o_edit.button_cancel")}</Button></AlertDialogCancel>
                        :
                        <Button disabled={isLoading} onClick={() => { setCurrent(prev => prev - 1) }} variant='outline'>{t("users.o_edit.button_previous")}</Button>
                    }
                    
                    {((current !== 2 && user?.role !== 1) || (current !== 1 && user?.role === 1)) ?
                    <Button onClick={() => { setCurrent(prev => prev + 1) }} disabled={isAbleNext()}>{t("users.o_edit.button_next")}</Button>
                    :
                    <AlertDialogAction asChild><Button onClick={()=>{mutate()}} disabled={isLoading}>
                        {
                        isLoading ? 
                        <Loader2 className='animate-spin'/>
                        :
                        t("users.o_edit.button_save_edit")
                        }
                    </Button></AlertDialogAction>
                    }
                    </div>   
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    return (
        <>
        <Toaster />
        {(!isInterface(user, {} as User) && isFetching) && <LoadingScreen />}
        {(!isInterface(user, {} as User) && !isFetching) && <ErrorScreen />}
        
        {isInterface(user, {} as User) && 
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{title:<p onClick={()=>{navigate('/gebruikers')}} className='cursor-pointer'>{t("users.o_edit.link_users")}</p>}, {title:`${t("users.o_edit.link_edit")}`}]}/>
                <h1 className='text-2xl font-semibold'>{t("users.o_edit.link_edit")}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <div className='flex justify-between'>
                    <h2 className='text-lg'>{t("users.o_edit.title_detail")}</h2>
                    <EditMain />
                </div>
                
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2 grid-flow-column'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.username")}</p>
                        <p className='text-neutral-500 text-ellipsis overflow-hidden'>{user.username}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.name")}</p>
                        <p className='text-neutral-500'>{user.firstname} {user.lastname}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.language")}</p>
                        <p className='text-neutral-500'>{user.language}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.last_login")}</p>
                        <p className='text-neutral-500'>{isValid(new Date(user.lastlogin)) && format(new Date(user.lastlogin), "dd/MM/yyyy HH:mm")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.partner")}</p>
                        <p className='text-neutral-500 overflow-hidden text-ellipsis'>{user.partner}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.customer")}</p>
                        <p className='text-neutral-500 overflow-hidden text-ellipsis'>{user.relnr}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.role")}</p>
                        <p className='text-neutral-500'>{roles && roles.find(i=>i.id===user.role)?.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.failed_tries")}</p>
                        <p className='text-neutral-500'>{user.unsuccessfullattempts}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.created_on")}</p>
                        <p className='text-neutral-500'>{format(convertToDate(user.dtcreated) || new Date, "dd/MM/yyyy")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.verified")}</p>
                        <p className='text-neutral-500'>{user.verified ? t("users.o_edit.yes") : t("users.o_edit.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("users.o_edit.last_try")}</p>
                        <p className='text-neutral-500'>{isValid(new Date(user.lastattempt)) && format(new Date(user.lastattempt), "dd/MM/yyyy HH:mm")}</p>
                    </div>
                </div>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm py-4 mt-4 md:mt-8'>
                <Tablist onValueChange={(value)=>{setselected_subpage(value)}} value={selected_subpage}>
                    <TablistItem value='rechten'>{t("users.o_edit.rights_p2")}</TablistItem>
                    <TablistItem value='log'>{t("users.o_edit.log")}</TablistItem>      
                </Tablist>

                {selected_subpage === 'rechten' && 
                <div className='px-4'>
                    {(!isInterface(rights, {} as UserRight) && isFetchingRights) && <SpinnerFeedback /> }
                    {(!isInterface(rights, {} as UserRight) && !isFetchingRights) && <ErrorFeedback /> }
                    {(isInterface(rights, {} as UserRight)) && <Rights rights={rights} refetchRights={()=>{refetchRights()}}/>}
                </div>
                }

                {selected_subpage === 'log' && 
                <div className='px-4'>
                    <Logs user={user}/>
                </div>
                }
            </div>
        </div>
        }
        </>
    )
}

export default EditDetail