import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { isInterface } from '../../../../../lib/utils'
import { useQuery } from '@tanstack/react-query'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Button } from '../../../../../components/ui/button'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { useTranslation } from 'react-i18next'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { format, isValid } from 'date-fns'

const Detail = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {intervention} = useParams()
    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.customers);

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>
    const [selected_document, setselected_document] = useState<number>(0)

    const {data:detail, isFetching} = useQuery<Intervention | null>({
        queryKey: [`intervention_${intervention}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`intervention/search`, {
                    token: auth.data?.token,
                    atmid: intervention
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.intervention){throw new Error('empty')}
                return data.object.intervention[0]

            } catch (error) {
                toast.error(t("sip.o_interventions.toast_wrong_interventions"))
                console.log(error)
                return null;
            }
        }
    })

    let relation = detail && customers.find(obj => obj.eindklant.some(e => e.relnr === detail.relnr))
    let partner = partners.find(obj => obj.relnr === relation?.id)
    let customer = detail && customers.flatMap(obj => obj.eindklant).find(e => e.relnr === detail.relnr)

    useEffect(() => {
        if (!detail) {
            const instance = lottie.loadAnimation({
                container: errorContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: errorAnimation
            })
            return () => instance.destroy()
        }
    }, [detail])

    const {data:intervention_document, isFetching:isFetching_document} = useQuery<string[], null>({
        queryKey: [`intervention_doc_X`],
        queryFn: async () => {
            try {
                console.log(detail)
                const {data} = await axios.post(`intervention/document`, {
                    token: auth.data?.token,
                    code: detail?.interventioncode
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                console.log(data)
                return data.object.filename
            } catch (error) {
                console.log(error);
                toast.error(t("sip.o_interventions.toast_wrong_document"))
                return null
            }
        },
        enabled: !!detail
    })

    return (
    <>
    <Toaster />

    {((isFetching) && (!isInterface(detail, {} as AnnouncemenDetail[]))) &&
        <LoadingScreen />
    }

    {(detail === null) &&
        <div className={`flex flex-col items-center justify-center`}>
            <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
            <h2 className='text-2xl font-semibold mb-4'>{t("sip.o_interventions.error_title")}</h2>
            <p className='text-center'>{t("sip.o_interventions.error_description")}<br></br>{t("sip.o_interventions.error_description_p2")} <Link to='/sip/uitgevoerde-interventies' className='font-medium underline'>{t("sip.o_interventions.error_description_p3")}</Link> {t("sip.o_interventions.error_description_p4")}</p>
        </div>
    }

    {(isInterface(detail, {} as AnnouncemenDetail[])) &&
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex flex-col'>
                <Breadcrumb items={[{ title: <p onClick={() => { navigate('/sip') }} className='cursor-pointer'>{t("sip.o_interventions.link_sip")}</p> }, { title: <p onClick={() => { navigate('/sip/uitgevoerde-interventies') }} className='cursor-pointer'>{t("sip.o_interventions.link_interventions")}</p> }, { title: `${t("sip.o_interventions.intervention")} ${intervention}` }]} />
                <h1 className='text-2xl font-semibold'>{t("sip.o_interventions.title_intervention_detail")}</h1>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("sip.o_interventions.title_intervention_detail")}</h2>
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.partner")}</p>
                        <p className='text-neutral-500'>{partner?.name}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.customer")}</p>
                        <p className='text-neutral-500'>{customer?.name}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.date_created")}</p>
                        <p className='text-neutral-500'>{isValid(convertToDate(detail.dtcreation)) && format(convertToDate(detail.dtcreation) || new Date, "dd/MM/yyyy")} {convertToHHMM(detail.tmcreation)}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.date_intervention")}</p>
                        <p className='text-neutral-500'>{detail.dtintervention} {detail.tmintervention}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.type_intervention")}</p>
                        <p className='text-neutral-500'>{detail.operation}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.atm_id")}</p>
                        <p className='text-neutral-500'>{detail.idautomate}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.brand")}</p>
                        <p className='text-neutral-500'>{detail.brand}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.model")}</p>
                        <p className='text-neutral-500'>{detail.model}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.description")}</p>
                        <p className='text-neutral-500'>{detail.description}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.status")}</p>
                        <p className='text-neutral-500'>{detail.state}</p>
                    </div>

                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("sip.o_interventions.incident")}</p>
                        <p className='text-neutral-500'>{detail.incident}</p>
                    </div>
                </div>
            </div>

            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                {(!intervention_document && isFetching_document) && <p>{t("sip.o_interventions.loading")}</p>}
                {intervention_document && 
                <>
                {intervention_document.length > 1 && 
                <div className='flex gap-2'>
                    <button disabled={selected_document===0} onClick={()=>{setselected_document(prev=>prev-1)}} className='border rounded-full h-10 w-10 flex justify-center items-center text-neutral-500 hover:text-primary hover:bg-red-50 duration-100 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronLeft className='w-5' /></button>
                    <button disabled={selected_document===(intervention_document.length-1)} onClick={()=>{setselected_document(prev=>prev+1)}} className='border rounded-full h-10 w-10 flex justify-center items-center text-neutral-500 hover:text-primary hover:bg-red-50 duration-100 disabled:opacity-50 disabled:cursor-not-allowed'><ChevronRight className='w-5' /></button>
                </div> 
                }
                <div className='min-h-[500px] overflow-hidden border rounded-sm relative'>
                    <object className='min-h-[500px] h-full w-full' data={intervention_document[selected_document]} type='application/pdf'>
                        <p>{t("sip.o_interventions.no_preview")}</p>
                        <Link to={intervention_document[selected_document]}><Button>{t("sip.o_interventions.download")}</Button></Link>
                    </object>
                </div>
                </>
                }
            </div>
        </div>
    }
    </>
  )
}

export default Detail