import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate, useParams, Link } from 'react-router-dom'
import useAuth from '../../../../../hooks/useAuth'
import { Toaster, toast } from 'react-hot-toast'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import { isInterface } from '../../../../../lib/utils'
import axios from '../../../../../lib/axios'
import { convertToDate, formatDateToYYYYMMDD } from '../../../../../lib/date'

import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { addDays, format } from 'date-fns'

import { Tablist, TablistItem } from '../../../../../components/ui/tablist'
import Announcement from '../../../../../components/portal/announcement/Announcement'
import Count from '../../../../../components/portal/count/Count'
import Transport from '../../../../../components/portal/transport/Transport'
import History from '../../../../../components/portal/history/History'
import Scans from '../../../../../components/portal/scans/Scans'

import ColliOverviewSafepoint from '../../../../../components/portal/colli/ColliKeepsafeOverview'
import { useTranslation } from 'react-i18next'
import { Button } from '../../../../../components/ui/button'
import { ChevronLeft } from 'lucide-react'

const Collection = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const { t } = useTranslation()
    const { barcode } = useParams()
    const location = useLocation()

    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    const [has_state, sethas_state] = useState(true);
    

    const [keepsafe, setkeepsafe] = useState<Keepsafe | StateType>(null);

    useEffect(()=>{
        if(barcode){
            if(!location.state){
                //User did not navigate to this page via the table (state is empty, state contains the keepsafe object)
                sethas_state(false)
                return;
            }

            setkeepsafe(location.state)


            //console.log(barcode)
            console.log(location.state)
        }
    },[])

    useEffect(()=>{
        if(!has_state){
            const instance = lottie.loadAnimation({
                container: errorContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                animationData: errorAnimation
            })
            return () => instance.destroy()
        }
    }, [has_state])

  return (
    <>
        <Toaster />
        <div className="overflow-auto relative w-full p-4 md:p-8">
            <div className='flex items-center justify-between'>
                <div className='flex flex-col'>
                    <Breadcrumb items={[{title:<p onClick={()=>{navigate('/track-en-trace')}} className='cursor-pointer'>{t("track_and_trace.o_detail.link_tt")}</p>}, {title:`${t("track_and_trace.o_detail.link_keepsafe")} ${barcode}`}]}/>
                    <h1 className='text-2xl font-semibold'>{t("track_and_trace.o_detail.title_collection")}</h1>
                </div>

                <Link to="/track-en-trace"><Button variant='ghost' className='gap-2'><ChevronLeft className='w-4 h-4'/> {t("track_and_trace.o_detail.back")}</Button></Link>
            </div>

            {has_state &&
            <>
            <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
                <h2 className='text-lg'>{t("track_and_trace.o_detail.title_detail_keepsafe")}</h2>
                {isInterface(keepsafe, {} as Keepsafe) &&
                <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>ID</p>
                        <p className='text-neutral-500'>{keepsafe.colliid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.date_collected")}</p>
                        <p className='text-neutral-500'>{format(convertToDate(keepsafe.date) || new Date(), "dd/MM/yyyy")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.type")}</p>
                        <p className='text-neutral-500'>{keepsafe.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.barcode")}</p>
                        <p className='text-neutral-500'>{keepsafe.barcode}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.keepsafe_id")}</p>
                        <p className='text-neutral-500'>{keepsafe.boxid}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.relation_nr")}</p>
                        <p className='text-neutral-500'>{keepsafe.stop.relnr}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.executed")}</p>
                        <p className='text-neutral-500'>{keepsafe.executed ? t("track_and_trace.o_detail.yes") : t("track_and_trace.o_detail.no")}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.customer")}</p>
                        <p className='text-neutral-500'>{keepsafe.stop.name}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.route_code")}</p>
                        <p className='text-neutral-500'>{keepsafe.routecode}</p>
                    </div>
                    <div className='flex gap-2'>
                        <p className='font-semibold'>{t("track_and_trace.o_detail.keepsafe_barcode")}</p>
                        <p className='text-neutral-500'>{keepsafe.boxbarcode}</p>
                    </div>
                </div>
                }
            </div>

            <div className='flex border flex-col gap-4 rounded-sm py-4 mt-4 md:mt-8'>
                {location.state?.colliid && <ColliOverviewSafepoint colliid={location.state.colliid} keepsafeDate={location.state.date}/>}
            </div>
            </> 
            }

            {!has_state && 
            <div className={`flex flex-col items-center justify-center`}>
                <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
                <h2 className='text-2xl font-semibold mb-4'>{t("track_and_trace.o_detail.error_title")}</h2>
                <p className='text-center'>{t("track_and_trace.o_detail.error_description_p8")}<br></br>{t("track_and_trace.o_detail.error_description_p2")} <Link to='/track-en-trace' className='font-medium underline'>{t("track_and_trace.o_detail.error_description_p3")}</Link> {t("track_and_trace.o_detail.error_description_p4")}</p>
            </div>
            }
        </div>
    </>
  )
}

export default Collection

export type SafepointSubpage = 'announcement' | 'count' | 'transport' | 'history' | 'scans'