import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table';
import ExportTable from '../../../../../components/portal/export/ExportTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import useAuth from '../../../../../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export';
import { Pagination } from '../../../../../components/ui/pagination';
import { splitArrayIntoChunks } from '../../../../../lib/utils';
import { format } from 'date-fns';
import { convertToHHMM, formatDateToYYYYMMDD } from '../../../../../lib/date';
import { useTranslation } from 'react-i18next';

type Props = {
    services: ATMService[]
}

const ATMTable : React.FC<Props> = ({services}) => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const navigate = useNavigate()
    const headerArr = [ t("sip.o_atm.edited"), t("sip.o_atm.technical_intervention"), t("track_and_trace.partner_nr"), t("sip.o_atm.partner"), t("sip.o_atm.customer_code"), t("sip.customer"),  t("sip.o_atm.atm_id"), t("sip.o_atm.number_management"), t("sip.o_atm.date_management"), t("sip.o_atm.time_management"), t("sip.o_atm.date_edit"), t("sip.o_atm.time_edit")]


    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

    return (
    <>
        {auth.data?.right.export &&
            <div className='flex justify-end mt-4'>
                <ExportTable onDownload={(extension) => { createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(services, [ 'modified', 'linkedintervention', 'partnerid', 'partnername', 'clientcode', 'customername', 'idautomate', 'interventioncode', 'date', 'time', 'dateupdate', 'timeupdate'], {partners:partners, customers:customers, relnr:'clientcode'}))) }} />
            </div>
        }

        <div className='my-4 border rounded-sm'>
            <Table>
                <TableHeader className='bg-slate-100 relative'>
                    <TableRow>
                        <TableHead className='uppercase'>{t("sip.o_atm.edited")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.technical_intervention")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.partner")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.customer")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.customer_code")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.atm_id")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.number_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.date_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.time_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.date_edit")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_atm.time_edit")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {services && splitArrayIntoChunks(services, 20)[currentPage - 1] && splitArrayIntoChunks(services, 20)[currentPage - 1].map((service, index) => {
                        
                        let relation = customers.find(obj => obj.eindklant.some(e => e.clientcode === service.clientcode))
                        let partner = partners.find(obj => obj.relnr === relation?.id)
                        let customer = customers.flatMap(obj => obj.eindklant).find(e => e.clientcode === service.clientcode)
                        
                        return (
                        <TableRow onClick={()=>{navigate(`${service.idautomate}/${formatDateToYYYYMMDD(new Date(service.date))}/${service.interventioncode}`)}} className='cursor-pointer' key={index}>
                            <TableCell>{service.modified ? t("sip.o_atm.yes") : t("sip.o_atm.no")}</TableCell>
                            <TableCell>{service.linkedintervention}</TableCell>
                            <TableCell>{partner?.name}</TableCell>
                            <TableCell>{customer?.name}</TableCell>
                            <TableCell>{service.clientcode}</TableCell>
                            <TableCell>{service.idautomate}</TableCell>
                            <TableCell>{service.interventioncode}</TableCell>
                            <TableCell>{format(new Date(service.date), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{convertToHHMM(service.time)}</TableCell>
                            <TableCell>{format(new Date(service.dateupdate), "dd/MM/yyyy")}</TableCell>
                            <TableCell>{convertToHHMM(service.timeupdate)}</TableCell>
                        </TableRow>
                        )
                    })}
                    
                </TableBody>
            </Table>
        </div>

        <Pagination totalItems={services.length} itemsPerPage={20} page={currentPage} onPageChange={(page) => { setcurrentPage(parseFloat(page)) }} />
    </>
  )
}

export default ATMTable