import React, { useState } from 'react'
import { Pagination } from '../../../../../components/ui/pagination'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import useAuth from '../../../../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { splitArrayIntoChunks } from '../../../../../lib/utils'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type Props = {
    interventions: Intervention[]
}


const InterventionsTable : React.FC<Props> = ({interventions}) => {
    const { auth } = useAuth()
    const { t } = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("sip.o_interventions.partner"), t("track_and_trace.customer_nr"),  t("sip.o_interventions.customer_code"), t("sip.o_interventions.customer_p2"), t("sip.o_interventions.atm_id"), t("sip.o_interventions.brand"), t("sip.o_interventions.model"), t("sip.o_interventions.number_management"), t("sip.o_interventions.date_management"), t("sip.o_interventions.time_management"), t("sip.o_interventions.type_intervention"), t("sip.o_interventions.description"), t("sip.o_interventions.status"), t("sip.o_interventions.incident")]

    const navigate = useNavigate()

    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

    return (
    <>
        {auth.data?.right.export &&
            <div className='flex justify-end mt-4'>
                <ExportTable onDownload={(extension) => { createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(interventions, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername',  'idautomate', 'brand', 'model', 'interventioncode', 'dtintervention', 'tmintervention', 'operation', 'description', 'state', 'incident'], {partners:partners, customers:customers, relnr:'relnr'}))) }} />
            </div>
        }

        <div className='my-4 border rounded-sm'>
            <Table>
                <TableHeader className='bg-slate-100 relative'>
                    <TableRow>
                        <TableHead className='uppercase'>{t("sip.o_interventions.partner")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.customer_p2")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.customer_code")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.atm_id")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.brand")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.model")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.number_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.date_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.time_management")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.type_intervention")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.description")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.status")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_interventions.incident")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                        {interventions && splitArrayIntoChunks(interventions, 20)[currentPage - 1] && splitArrayIntoChunks(interventions, 20)[currentPage - 1].map((intervention, index) => {
                            let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === intervention.relnr))
                            let partner = partners.find(obj => obj.relnr === relation?.id)
                            let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === intervention.relnr)

                            return (
                                <TableRow onClick={() => { navigate(`${intervention.idautomate}`) }} className='cursor-pointer' key={index}>
                                    <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                                    <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                                    <TableCell>{customer?.clientcode}</TableCell>
                                    <TableCell>{intervention.idautomate}</TableCell>
                                    <TableCell>{intervention.brand}</TableCell>
                                    <TableCell>{intervention.model}</TableCell>
                                    <TableCell>{intervention.interventioncode}</TableCell>
                                    <TableCell>{intervention.dtintervention ? format(convertToDate(intervention.dtintervention) || new Date, "dd/MM/yyyy") : ''}</TableCell>
                                    <TableCell>{convertToHHMM(intervention.tmintervention)}</TableCell>
                                    <TableCell>{intervention.operation}</TableCell>
                                    <TableCell>{intervention.description}</TableCell>
                                    <TableCell>{intervention.state}</TableCell>
                                    <TableCell>{intervention.incident}</TableCell>
                                </TableRow>
                            )
                        })}
                </TableBody>
            </Table>
        </div>
        
        <Pagination totalItems={interventions.length} itemsPerPage={20} page={currentPage} onPageChange={(page) => { setcurrentPage(parseFloat(page)) }} />
    </>
  )
}

export default InterventionsTable