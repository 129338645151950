import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import useAuth from '../../../hooks/useAuth';
import { Button } from '../../../components/ui/button';
import { Lock, Unlock } from 'lucide-react';
import security from '../../../assets/images/secured.svg';
import { InputProps } from '../../../components/ui/input';
import axios from '../../../lib/axios'
import { Toaster, toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';


const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return (
    <input
        {...props}
        ref={ref}
        className='text-center border-b-2 w-10 py-1 text-xl focus:outline-none focus:border-primary'
        maxLength={1}
    />
  );
});

const TwoFactor = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()
    const [is_authenticated, setis_authenticated] = useState(isTwoFactorAuthenticated());
    const [is_loading, setis_loading] = useState(false);
    const [code, setcode] = useState<string[]>(['', '', '', '', '', '']);

    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    useEffect(()=>{
      if(!is_authenticated) { resetAuthenticationStatus(); console.log('sm'); sendMail() }
    }, [is_authenticated])

    function isTwoFactorAuthenticated() {
      const authenticationStatus = localStorage.getItem('authenticationStatus');
      const authenticationTimestamp = parseFloat(localStorage.getItem('authenticationTimestamp') || '');
      
      if (authenticationStatus === 'authenticated') {
          const currentTime = new Date().getTime();
          if (currentTime - authenticationTimestamp <= 30 * 60 * 1000) {
              return true; // User is authenticated and within the 30-minute window
          }
      }
      
      return false;
    }

    function setTwoFactorAuthenticated() {
      localStorage.setItem('authenticationStatus', 'authenticated');
      localStorage.setItem('authenticationTimestamp', new Date().getTime().toString());
    }

    function resetAuthenticationStatus() {
      localStorage.removeItem('authenticationStatus');
      localStorage.removeItem('authenticationTimestamp');
    }

    const checkCode = async () => {
      try {
        setis_loading(true)
        const { data } = await axios.post(`oauth2/result`, {
          token: auth.data?.token,
          code: code.join('')
        })
        console.log(data)
        if(data.errorcode === 0){
          //Authenticated
          setis_authenticated(true)
          setTwoFactorAuthenticated()
        }
        else {
          //Not authenticated
          resetAuthenticationStatus()
          console.log(data)
          setis_authenticated(false)
          setcode(['', '', '', '', '',''])
          toast.error(t("tfa.toast_error"))
        }
        
      } catch (error) {
        console.log(error)
        setis_authenticated(false)
        setcode(['', '', '', '', '',''])
        toast.error(t("tfa.toast_error_p2"))
      } finally {
        setis_loading(false)
      }
    }

    const sendMail = async () => {
      try {
        
        const { data } = await axios.post(`oauth2/request`, {
          token: auth.data?.token
        })
        if(data.errorcode === 0){
          toast.success(t("tfa.toast_success"), { id:'sendMail' })
        }
        else {
          toast.error(t("tfa.toast_error_p3"), { id:'sendMail' })
        }
      } catch (error) {
        console.log(error)
        toast.error(t("tfa.toast_error_p3"), { id:'sendMail' })
      }
    }

    const handleInputChange = (index: number, value: string) => {
        const newValues = [...code];
        newValues[index] = value;
        setcode(newValues);
    
        // Focus the next input field if typing, or previous if deleting
        if (value && index < 5) {
          inputRefs.current[index + 1]?.focus();
        } else if (!value && index > 0) {
          inputRefs.current[index - 1]?.focus();
        }
    };

    useEffect(()=>{
      if(code.filter(i=>i!=='').length === 6){ checkCode() }
    }, [code])

    const handleArrowKey = (index: number, keyCode: number) => {
        if (keyCode === 37 && index > 0) {
          inputRefs.current[index - 1]?.focus();
        } else if (keyCode === 39 && index < inputRefs.current.length - 1) {
          inputRefs.current[index + 1]?.focus();
        }
    };

  return (
    <>
      {!is_authenticated && 
      <>
      <Toaster />
      <div className='w-full p-4 md:p-8 flex justify-center items-center h-full'>
          <div className='rounded-sm shadow-md border w-fit max-w-2xl p-8 flex flex-col gap-4 items-center'>
              <img className='h-32' src={security} alt="Security" />

              <h1 className='font-bold text-xl'>{t("tfa.title")}</h1>
              <p className='font-medium text-center'>{t("tfa.description")} <span className='font-semibold'>{auth.data?.username}</span> {t("tfa.description_p2")}</p>

              <div className='flex gap-2'>
              {code.map((value, index) => (
                  <Input
                  autoFocus={index===0}
                  key={index}
                  value={value}
                  ref={(input) => (inputRefs.current[index] = input)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange(index, e.target.value)}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => handleArrowKey(index, e.keyCode)}
                  />
              ))}
              </div>

              <div className='w-full flex justify-between items-end mt-10'>
                  <p className='text-sm'>{t("tfa.description_p3")}<br></br>{t("tfa.description_p4")} <span onClick={()=>{sendMail()}} className='text-primary font-semibold cursor-pointer'>{t("tfa.description_p5")}</span></p>
                  <Button onClick={()=>{checkCode()}} disabled={(!(code.every(value => value !== ''))) || is_loading} className='flex gap-2'>
                      {(code.every(value => value !== '')) ? <Unlock className='w-4'/> : <Lock className='w-4'/>}
                      {!is_loading ? t("tfa.button_next") : t("tfa.button_loading")}
                  </Button>
              </div>
          </div>
      </div>
      </>
      }

      {is_authenticated &&
      <Outlet />
      }
    </>
  )
}

export default TwoFactor