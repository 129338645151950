import React, { useEffect, useState } from 'react'
import { Tablist, TablistItem } from '../../ui/tablist'
import Coupure from '../coupure/Coupure'
import { isInterface } from '../../../lib/utils'
import History from '../history/History'
import Scans from '../scans/Scans'
import { toast } from 'react-hot-toast'
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date'
import axios from 'axios'
import useAuth from '../../../hooks/useAuth'
import { format } from 'date-fns'
import Transport from '../transport/Transport'
import SpinnerFeedback from '../feedback/SpinnerFeedback'
import ErrorFeedback from '../feedback/ErrorFeedback'
import EmptyFeedback from '../feedback/EmptyFeedback'
import { useTranslation } from 'react-i18next'

type ColliSubpage = 'order' | 'transport' | 'history' | 'scans'
type Transport = {
    destination: Destination;
    origin: Origin
}
type Props = {
    colli:Colli;
    order:Order;
}
const ColliDetailOverview : React.FC<Props> = ({colli, order}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const [selected_subpage, setselected_subpage] = useState<ColliSubpage>('order');

    const [transport, settransport] = useState<Transport | StateType>(null);
    const [history, sethistory] = useState<HistoryProp[] | StateType>(null);
    const [scans, setscans] = useState<Scan[] | StateType>(null);
    const [coupure, setcoupure] = useState<Coupure[] | StateType>(null);

    useEffect(()=>{
        fetch_transport()
        fetch_history()
        fetch_scans()
        fetch_coupure()
    }, [])

    async function fetch_transport() {
        try {
          settransport('loading')
          const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}colli/transport`, {
            token: auth.data?.token,
            colliid: colli.id
          }
          )
          if(data.errorcode === 0){
            if(data.object.destination || data.object.origin) {
              settransport(data.object)
            }
            else {
              settransport('empty')
            }
          }
          else {
            console.log(data)
            toast.error(t("components.o_colli.o_overview.toast_error"))
            settransport('error')
          }
          
        } catch (error) {
          if(axios.isCancel(error)){
            console.log('Transport call canceled');
            return
          }
    
          console.log(error)
          toast.error(t("components.o_colli.o_overview.toast_wrong"))
          settransport('error')
        }
      }
    
      async function fetch_history() {
        try {
          sethistory('loading')
          const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}colli/history`, {
            token: auth.data?.token,
            colliid: colli.id
          })
          if(data.errorcode === 0 && Array.isArray(data.object.history)){
            sethistory(data.object.history)
          }
          else {
            console.log(data)
            sethistory('error')
            toast.error(t("components.o_colli.o_overview.toast_error_p2"))
          }
        } catch (error) {
          console.log(error)
          toast.error(t("components.o_colli.o_overview.toast_error_p2"))
          sethistory('error')
        }
      }
    
      async function fetch_scans() {
        try {
          if(isInterface(colli, {} as Colli)){
            setscans('loading')
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}scan/search`, {
              token: auth.data?.token,
              idcolli: colli.id,
              startdate: formatDateToYYYYMMDD(new Date(colli.dtcreation)),
              enddate: formatDateToYYYYMMDD(new Date(colli.dtmodif)),
              equal: 1
            })
            if(data.errorcode === 0){
              if(Array.isArray(data.object.scan)){
                setscans(data.object.scan)
              }
              else {
                setscans('empty')
              }
            }
            else {
              console.log(data);
              toast.error(t("components.o_colli.o_overview.toast_error_p3"))
              setscans('error')
            }
          }
          else {
            console.log(colli)
            setscans('error')
            toast.error(t("components.o_colli.o_overview.toast_error_p4"))
          }
        } catch (error) {
          console.log(error)
          setscans('error')
          toast.error(t("components.o_colli.o_overview.toast_error_p3"))
        }
      }

      async function fetch_coupure() {
        try {
            setcoupure('loading')
            const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}order/detail`, {
                token: auth.data?.token,
                orderid: order.id,
            })
            if(data.errorcode === 0){
                setcoupure(data.object.coupure)
            }
            else {
                toast.error(`Error [${data.errorcode}]: ${data.message}`)
                setcoupure('error')
            }
        } catch (error) {
            setcoupure('error')
            toast.error(t("components.o_colli.o_overview.toast_error_p5"))
            console.log(error)
        }
      }
    
  return (
    <div className='flex border flex-col gap-4 rounded-sm py-4 mt-4 md:mt-8'>
        <Tablist value={selected_subpage} onValueChange={(value)=>{ setselected_subpage(value as ColliSubpage) }}>
            <TablistItem value='order'>{t("components.o_colli.o_overview.order")}</TablistItem>
            <TablistItem value='transport'>{t("components.o_colli.o_overview.transport")}</TablistItem>
            <TablistItem value='history'>{t("components.o_colli.o_overview.history")}</TablistItem>
            <TablistItem value='scans'>{t("components.o_colli.o_overview.scannings")}</TablistItem>
          </Tablist>
          <div className='px-4'>
            {selected_subpage === 'order' &&
            <>
                {coupure === 'loading' && <SpinnerFeedback />} 
                {coupure === 'error' && <ErrorFeedback />}
                {coupure === 'empty' && <EmptyFeedback />}
                {isInterface(order, {} as Order) && isInterface(coupure, {} as Coupure[]) && 
                <div className='border'>
                    <div className='grid grid-cols-3 bg-slate-100 py-2 px-4 border-b'>
                        <i className='uppercase text-sm font-medium text-muted-foreground'></i>
                        <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("components.o_colli.o_overview.count")}</p>
                        <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("components.o_colli.o_overview.amount")}</p>
                    </div>
                    <div className='flex flex-col gap-4 p-4'>
                        <Coupure currency={order.currency} cn={order.cn.toLowerCase() as 'c' | 'n'} coupures={coupure} show_total/>
                    </div>
                </div>
                }
            </>
            }

            {selected_subpage === 'transport' &&
            <div>
              {transport === 'loading' && <SpinnerFeedback />} 
              {transport === 'error' && <ErrorFeedback />}
              {transport === 'empty' && <EmptyFeedback />}
              {isInterface(transport, {} as Transport) &&
              <Transport transport={transport}/>
              }
            </div>
            }

            {selected_subpage === 'history' &&
            <div>
              {history === 'loading' && <SpinnerFeedback />} 
              {history === 'error' && <ErrorFeedback />}
              {history === 'empty' && <EmptyFeedback />}
              {isInterface(history, {} as HistoryProp[]) && <div className='border'><History history={history}/></div>}
            </div>
            }

            {selected_subpage === 'scans' &&
            <div>
              {scans === 'loading' && <SpinnerFeedback />} 
              {scans === 'error' && <ErrorFeedback />}
              {scans === 'empty' && <EmptyFeedback />}
              {isInterface(scans, {} as Scan[]) && <div className='border'><Scans scans={scans}/></div>}
            </div>
            }
        </div>
    </div>
  )
}

export default ColliDetailOverview