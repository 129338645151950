import React, { useEffect, useState } from 'react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog'
import { Button } from '../../ui/button'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'
import { Toaster, toast } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import Calendar, { DayObject } from './calendar/Calendar'
import { formatDateToYYYYMMDD } from '../../../lib/date'
import { addMonths, format, lastDayOfMonth, subMonths } from 'date-fns'
import { X } from 'lucide-react'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

type Props = {
    partnerid: number;
    clientrelnr: number;
    disabled?: boolean;

    setselectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
    selectedDate: Date | undefined;

    onSelectDay: (v:DayObject) => void;
    page?: 'order' | 'announce' | 'consumables'
}

const PlannedStopsCalendar : React.FC<Props> = ({page, partnerid, clientrelnr, disabled, setselectedDate, selectedDate, onSelectDay}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const customers:PartnerCustomers[] = useSelector((state:RootState)=>state.customers)
    const [planning_events, setplanning_events] = useState<PlanningEvent[] | StateType>(null);

    const [currentMonth, setcurrentMonth] = useState(new Date());

    const {data:holidays} = useQuery<Holiday[] | null>({
        queryKey: [`holidays`],
        queryFn: async () => {
            try {
                const { data } = await axios.post(`holiday/search`, {
                    token: auth.data?.token,
                    startdate: '20240101',
                    enddate: formatDateToYYYYMMDD(addMonths(new Date(), 6))
                })
                if(data.errorcode === 0){
                    if(Array.isArray(data.object.holiday)){
                        return data.object.holiday
                    }
                    else {
                        return null;
                    }
                }
                else {
                    return null;
                }
            } catch (error) {
                return null;
            }
        }
    })
    
    useEffect(()=>{
        async function getData(){
            const coupures = await getCoupures();
            const denom = await getDenom() as Denom | null
            const holidays = await getHoliday()

            // console.log(coupures)
            // console.log(denom)
            // console.log(holidays)
        }
        if(!disabled){
            getData()
        }
    }, [disabled])

    useEffect(()=>{
        async function getData() {
            const sd = format(subMonths(currentMonth, 1), 'yyyy-MM-01');
            const ed = lastDayOfMonth(addMonths(currentMonth, 2));            

            const planning = await getPlanning(new Date(sd), ed)
            setplanning_events(planning)
        }
        if(!disabled){
            getData()
        }
    }, [disabled, currentMonth, clientrelnr])

    const {data:partnersettings} = useQuery<PartnerSettings | null>({
        queryKey: [`partnersettings_${partnerid}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/search`, {token:auth.data?.token, partner:partnerid})
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.partner){return null}
                if(!data.object.partner[0].settings){throw new Error('empty')}
                return data.object.partner[0].settings
            } catch (error) {
                console.log(error)
                return null
            }
        }
    })

    const getCoupures = async () => {
        try {
            const { data } = await axios.post(`param/coupure`, {
                token: auth.data?.token
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object.coupure)){
                    return data.object.coupure
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getDenom = async () => {
        try {
            const { data } = await axios.post(`partner/denom/search`, {
                token: auth.data?.token,
                partner: partnerid
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object)){
                    return data.object[0].denom
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getHoliday = async () => {
        try {
            const { data } = await axios.post(`holiday/search`, {
                token: auth.data?.token,
                startdate: '20240101',
                enddate: formatDateToYYYYMMDD(addMonths(new Date(), 6))
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object.holiday)){
                    return data.object.holiday
                }
                else {
                    return null;
                }
            }
            else {
                return null;
            }
        } catch (error) {
            return null;
        }
    }

    const getPlanning = async (startDate:Date, endDate:Date) => {
        try {
            const { data } = await axios.post(`planning/search`, {
                token: auth.data?.token,
                startdate: formatDateToYYYYMMDD(startDate),
                enddate:formatDateToYYYYMMDD(endDate),
                cn: '',
                relnr: clientrelnr,
                clientcode: ''
            })
            if(data.errorcode === 0){
                if(Array.isArray(data.object.planning)){
                    return data.object.planning
                }
                else {
                    return null
                }
            }
            else {
                return null
            }
        } catch (error) {
            console.log(error)
            return null
        }
    }

  return (
    <>
    <Toaster />
    <AlertDialog>
        <AlertDialogTrigger disabled={disabled} asChild>
            <Button className='w-full justify-start' variant='outline'>
                {selectedDate ? format(selectedDate, "dd/MM/yyyy") : t("components.o_stop.o_calendar.placeholder")}
            </Button>
        </AlertDialogTrigger>
        <AlertDialogContent className='relative'>

            <AlertDialogCancel asChild><button className='absolute w-10 border-none !p-0 top-0 right-0 opacity-50 hover:opacity-100 hover:bg-transparent'><X className='w-5'/></button></AlertDialogCancel>
            
            <Calendar holidays={holidays} page={page} partnersettings={partnersettings} onSelectDate={(date)=>{onSelectDay(date); setselectedDate(date.date)}} events={planning_events} currentMonth={currentMonth} setcurrentMonth={setcurrentMonth} selectedDate={selectedDate} setselectedDate={setselectedDate}/>
            
        </AlertDialogContent>
    </AlertDialog>
    </>
  )
}

export default PlannedStopsCalendar