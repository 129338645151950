import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Breadcrumb } from '../../../../components/ui/breadcrumb'
import { Label } from '../../../../components/ui/label'
import { Combobox } from '../../../../components/ui/combobox'
import { useNavigate } from 'react-router-dom'
import axios from '../../../../lib/axios'
import useAuth from '../../../../hooks/useAuth'
import { Eye, EyeOff, Loader2 } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../redux'
import { Input } from '../../../../components/ui/input'
import { DatePicker } from '../../../../components/ui/datepicker'
import { Button } from '../../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import { RadioGroup, RadioGroupItem } from '../../../../components/ui/radio-group'
import { formatDateToYYYYMMDD } from '../../../../lib/date'
import { subWeeks } from 'date-fns'
import { isInterface } from '../../../../lib/utils'
import OrdersTable from './tables/OrdersTable'
import { getCustomerList } from '../../../../lib/fetch/customer'
import WindowsDatePicker from '../../../../components/ui/windows-datepicker'
import { useTranslation } from 'react-i18next'

const Main = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const navigate = useNavigate()
    const partners = useSelector((state:RootState)=>state.partners);

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [selected_clientcode, setselected_clientcode] = useState('');
    const [selected_startdate, setselected_startdate] = useState<Date>(subWeeks(new Date(), 2));
    const [selected_enddate, setselected_enddate] = useState<Date>(new Date());
    const [selected_filter, setselected_filter] = useState<string | undefined>('C');

    const {data:orders, refetch, isFetching} = useQuery<OrderDraft[] | null | 'empty'>({
        queryKey: ['validate_orders'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`order/draft/search`, {
                    token: auth.data?.token,
                    partner: selected_partner,
                    relnr: selected_customer,
                    clientcode: (selected_customer !== 'clear' ? selected_customer : '') || '',
                    startdate: formatDateToYYYYMMDD(selected_startdate),
                    enddate: formatDateToYYYYMMDD(selected_enddate),
                    searchby: selected_filter,
                    status: '005'
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!data.object.orderdraft){toast.error(t("order_and_pickup.o_validate_orders.toast_no_found")); return 'empty';}
                return data.object.orderdraft
            } catch (error) {
                console.log(error);
                toast.error(t("order_and_pickup.o_validate_orders.toast_error"))
                return;
            }
        },
        enabled: false
    })
    

    useEffect(()=>{
        if (auth.data?.partner.split(",").length === 1) {
          handlePickPartner(auth.data.partner.split(",")[0])
        }
    }, [])
  
    const handlePickPartner = async (relnr:string) => {
        try {
            if(selected_partner!==relnr){setselected_customer('clear')}
            setselected_partner(relnr);
            setcustomers_list(null);
            const list = await getCustomerList(auth, relnr)
            if(list){ setcustomers_list(list) }
            if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
        } catch (error) {
          console.log(error)
          toast.error(t("order_and_pickup.o_validate_orders.toast_wrong"))
        }
    }

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.relnr}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>

        <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_validate_orders.link_orders")}</p>}, {title:`${t("order_and_pickup.o_validate_orders.link_tovalidate")}`}]}/>
        <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_validate_orders.title")}</h1>

        <div className={`flex flex-col p-4 shadow-sm border rounded-sm mt-4 md:mt-8`}>
            <div className={`duration-300 overflow-hidden flex flex-col gap-4`}>
                <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                    <div>
                        <Label htmlFor='partner'>{t("order_and_pickup.o_validate_orders.partner")}</Label>
                        <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("order_and_pickup.o_validate_orders.partner_placeholder") || ""} input_placeholder={t("order_and_pickup.o_validate_orders.search_placeholder") || ""}/>
                    </div>

                    <div>
                        <Label htmlFor='end_customer'>{t("order_and_pickup.o_validate_orders.customer")}</Label>
                        <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("order_and_pickup.o_validate_orders.customer_placeholder") || ""} input_placeholder={t("order_and_pickup.o_validate_orders.search_placeholder") || ""}/>
                    </div>
                    <div>
                        <Label htmlFor='clientcode'>{t("order_and_pickup.o_validate_orders.customer_code")}</Label>
                        <Input value={selected_clientcode} onChange={({currentTarget})=>setselected_clientcode(currentTarget.value)} id='clientcode'/>
                    </div>

                    <div className='col-span-full'>
                        <Label htmlFor='filter'>{t("order_and_pickup.o_validate_orders.filter")}</Label>
                        <RadioGroup onValueChange={(value)=>{setselected_filter(value)}} value={selected_filter} className='flex gap-4 flex-wrap mt-1'>
                            <div className='flex gap-2'>
                                <RadioGroupItem value='C' id='r_1'/>
                                <Label htmlFor='r_1'>{t("order_and_pickup.o_validate_orders.date_created_between")}</Label>
                            </div>
                            <div className='flex gap-2'>
                                <RadioGroupItem value='D' id='r_2'/>
                                <Label htmlFor='r_2'>{t("order_and_pickup.o_validate_orders.date_collection_between")}</Label>
                            </div>
                        </RadioGroup>
                    </div>

                    <div>
                        <Label htmlFor='startdate'>{t("order_and_pickup.o_validate_orders.start_date")}</Label>
                        <WindowsDatePicker value={selected_startdate} onDateChange={(date)=>{if(date){setselected_startdate(date)}}} id='startdate' placeholder='Kies een datum'/>
                    </div>

                    <div>
                        <Label htmlFor='enddate'>{t("order_and_pickup.o_validate_orders.end_date")}</Label>
                        <WindowsDatePicker value={selected_enddate} onDateChange={(date)=>{if(date){setselected_enddate(date)}}} id='enddate' placeholder='Kies een datum'/>
                    </div>
                </div>
                <Button onClick={()=>{refetch()}} disabled={isFetching} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("order_and_pickup.o_validate_orders.button")}</Button>
            </div>
        </div>
        
        {isInterface(orders, {} as OrderDraft[]) && 
        <OrdersTable orders={orders} callRefetch={()=>{refetch()}}/>
        }
    </div>
    </>
  )
}

export default Main