import React, { useEffect, useState } from 'react'
import { Button } from '../../../../components/ui/button'
import { Tablist, TablistItem } from '../../../../components/ui/tablist'
import Manual from './filters/Manual';
import Spread from './filters/Spread';
import { Breadcrumb } from '../../../../components/ui/breadcrumb';
import { useNavigate } from 'react-router-dom';
import Concept from './concept/Concept';
import PreviousPickups from './pickups/PreviousPickups';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

const Main = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const [selected_subpage, setselected_subpage] = useState<Subpage>('manual');
  const [selected_draft, setselected_draft] = useState<AnnouncementDraftSearch>()

  function editDraft(draft:AnnouncementDraftSearch) {
    setselected_draft(draft);
    setselected_subpage('manual');
  }
  
  useEffect(()=>{
    if(selected_draft){ setselected_draft(undefined) }
  }, [selected_draft])

  return (
    <>
    <Toaster />
    <div className='overflow-auto relative w-full p-4 md:p-8'>

        <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_announce_pickup.link_orders")}</p>}, {title:t("order_and_pickup.o_announce_pickup.link_announce")}]}/>
        <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_announce_pickup.title")}</h1>

        <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
            <Tablist onValueChange={(value)=>{setselected_subpage(value as Subpage)}} value={selected_subpage}>
                <TablistItem value='manual'>{t("order_and_pickup.o_announce_pickup.manual")}</TablistItem>
                <TablistItem value='spreadsheet'>{t("order_and_pickup.o_announce_pickup.spreadsheet")}</TablistItem>      
            </Tablist>
            
            <div className={`${selected_subpage !== 'manual' && 'hidden'}`}><Manual defaultDraft={selected_draft}/></div>
            <div className={`${selected_subpage !== 'spreadsheet' && 'hidden'}`}><Spread /></div>
        </div>

        <PreviousPickups />

        <Concept onEdit={(draft)=>{editDraft(draft)}}/>
    </div>
    </>
  )
}

export default Main

type Subpage = 'manual' | 'spreadsheet'