import React from "react";

const Management : React.FC = () => {
    return (
        <>
        <h1>Management</h1>
        </>
    )
}

export default Management;