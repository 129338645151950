import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../components/ui/button'
import { Label } from '../../../components/ui/label'
import { Input } from '../../../components/ui/input'
import { Combobox } from '../../../components/ui/combobox'
import usePartnerStore from '../../../zustand/usePartnerStore'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'
import { useMutation, useQuery } from '@tanstack/react-query'
import { Calendar } from '../../../components/ui/calendar'
import PlanningCalendar from '../../../components/portal/planning/PlanningCalendar'
import { formatDateToYYYYMMDD } from '../../../lib/date'
import { addMonths, subMonths } from 'date-fns'
import { Loader2 } from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { getCustomerList } from '../../../lib/fetch/customer'
import { useTranslation } from 'react-i18next'

const Overview = () => {
  const { auth } = useAuth()
  const {t} = useTranslation()
  const partners = useSelector((state:RootState)=>state.partners);

  const [selectedDate, setselectedDate] = useState<Date | undefined>();
  const [currentMonth, setcurrentMonth] = useState(new Date());

  const [selected_partner, setselected_partner] = useState<string | null>(null);
  const [selected_customer, setselected_customer] = useState<string | null>(null);
  const [planning_customer, setplanning_customer] = useState<string | null>(null);
  const [selected_customercode, setselected_customercode] = useState('');

  const [customers_list, setcustomers_list] = useState<Customer[] | null>()

  const {data:planning, refetch, isFetching} = useQuery<PlanningEvent[] | null>({
    queryKey: ['planning'],
    queryFn: async () => {
      try {
        let obj = {
          token: auth.data?.token,
          startdate: formatDateToYYYYMMDD(subMonths(currentMonth, 2)),
          enddate: formatDateToYYYYMMDD(addMonths(currentMonth, 2)),
          relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
          clientcode: selected_customercode
        }
        
        setplanning_customer(selected_customer)
        const {data} = await axios.post(`planning/search`, obj)
  
        if(data.errorcode !== 0) { throw new Error(data.message) }
        if(!data.object.planning) { toast.error(t("planning.toast_no_found")); throw new Error('Stops empty'); }
        return data.object.planning
      } catch (error) {
        console.log(error);
        return null;
      }      
    },
    enabled: false
  })

  const {data:holiday, refetch:refetch_holiday} = useQuery<Holiday[] | null>({
    queryKey: ['holiday'],
    queryFn: async () => {
      try {
        const {data} = await axios.post(`holiday/search`, {
          token: auth.data?.token,
          startdate: formatDateToYYYYMMDD(subMonths(currentMonth, 2)),
          enddate: formatDateToYYYYMMDD(addMonths(currentMonth, 2)),
        })
        if(data.errorcode !== 0 || !data.object.holiday){ throw new Error(data.message) }
        return data.object.holiday;
      } catch (error) {
        return null;
      }
    },
    enabled: false
  })

  useEffect(()=>{
    if(Array.isArray(partners)){
        if(partners.length === 1){
          handlePickPartner(partners[0].relnr.toString())
        }
    }
}, [partners])

  const handlePickPartner = async (relnr:string) => {
    try {
      if(selected_partner!==relnr){setselected_customer('clear')}
      setselected_partner(relnr);
      setcustomers_list(null);
      const list = await getCustomerList(auth, relnr)
      if(list){ setcustomers_list(list) }
      if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
    } catch (error) {
      console.log(error)
      toast.error(t("planning.toast_error"))
    }
  }

  let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
  partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

  let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
  customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("planning.title")}</h1>
            {auth.data?.right.transportrequest && <Link to="add"><Button>{t("planning.request_stop")}</Button></Link>}
        </div>

        <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
          <div className='flex justify-between'>
            <h2 className='text-lg'>{t("planning.filters")}</h2>
          </div>

          <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
            <div>
              <Label htmlFor='partner'>{t("planning.partner")}</Label>
              <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("planning.partner_placeholder") || ""} input_placeholder={t("planning.search_placeholder") || ""}/>
            </div>

            <div>
              <Label htmlFor='end_customer'>{t("planning.customer")}<span className='ml-1 text-primary'>*</span></Label>
              <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("planning.customer_placeholder") || ""} input_placeholder={t("planning.search_placeholder") || ""}/>
            </div>
            
            <div>
              <Label htmlFor='customer_code'>{t("planning.customer_code")}</Label>
              <Input value={selected_customercode} onChange={(e) => { setselected_customercode(e.currentTarget.value) }} id='customer_code'/>
            </div>
          </div>

          <Button disabled={isFetching || (!selected_customer && selected_customercode==='') || selected_customer === 'clear'} onClick={()=>{refetch_holiday(); refetch()}} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("planning.search")}</Button>
        </div>

        {(planning && selected_customer === planning_customer && selected_customer) && 
        <div className='shadow-sm border rounded-sm mt-4 md:mt-8 relative'>
          {isFetching && 
          <div className='z-[1] absolute top-0 left-0 w-full h-full bg-white/80 backdrop-blur-sm flex justify-center items-center'>
            <p>{t("planning.loading")}</p>
          </div>
          }

          <PlanningCalendar holiday={holiday} events={planning} selected_customer={selected_customer} onSelectDate={(d)=>{}} selectedDate={selectedDate} setselectedDate={setselectedDate} setcurrentMonth={setcurrentMonth} currentMonth={currentMonth}/>
        </div>
        }

    </div>
    </>
  )
}

export default Overview