import React from 'react'
import { Route, Routes } from 'react-router-dom'
import HomePage from './home/page'
import AddPage from './add/page'

const ForeignCurrency = () => {
  return (
    <Routes>
        <Route path="*" element={<HomePage />}/>
        <Route path="add" element={<AddPage />}/>
    </Routes>
  )
}

export default ForeignCurrency