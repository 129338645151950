"use client"
 
import * as React from "react"
import { addDays, format } from "date-fns"
import { Calendar as CalendarIcon } from "lucide-react"
import { DateRange } from "react-day-picker"
 
import { cn } from "../../lib/utils"
import { Button } from "./button"
import { Calendar } from "./calendar"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "./popover"
import { nlBE, enUS, fr } from "date-fns/locale"
 
interface DatePickerWithRangeProps extends React.HTMLAttributes<HTMLDivElement> {
  placeholder?: string;
  onDateChange: (value:DateRange | undefined) => void;
  locale?: 'nlBE' | 'enUS' | 'fr';
  value?: DateRange | undefined
}

const DatePickerWithRange : React.FC<DatePickerWithRangeProps> = ({className, placeholder, onDateChange, locale='nlBE', value}) => {
  const [date, setDate] = React.useState<DateRange | undefined>({
    from: undefined,
    to: undefined,
  })

  React.useEffect(()=>{
    setDate(value)
  }, [value])
 
  return (
    <div className={cn("grid gap-2", className)}>
      <Popover>
        <PopoverTrigger asChild>
          <Button
            id="date"
            variant={"outline"}
            className={cn(
              "w-full justify-start text-left font-normal",
              !date && "text-muted-foreground"
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {date?.from ? (
              date.to ? (
                <>
                  {format(date.from, "LLL dd, y")} -{" "}
                  {format(date.to, "LLL dd, y")}
                </>
              ) : (
                format(date.from, "LLL dd, y")
              )
            ) : (
              <span>{placeholder}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          <Calendar
            initialFocus
            mode="range"
            defaultMonth={date?.from}
            selected={date}
            onSelect={(range) => { setDate(range); onDateChange(range) }}
            numberOfMonths={2}
            locale={locale === 'nlBE' ? nlBE : locale === 'fr' ? fr : locale === 'enUS' ? enUS : nlBE}
          />
        </PopoverContent>
      </Popover>
    </div>
  )
}

export { DatePickerWithRange };