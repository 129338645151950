import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'
import { convertToDate, convertToHHMM } from '../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type ScansProps = {
    scans: Scan[]
}

const Scans : React.FC<ScansProps> = ({scans}) => {
  const {t} = useTranslation()
  return (
    <Table>
      <TableHeader className='bg-slate-100'>
        <TableRow>
          <TableHead>{t("components.o_scans.date")}</TableHead>
          <TableHead>{t("components.o_scans.time")}</TableHead>
          <TableHead>{t("components.o_scans.barcode")}</TableHead>
          <TableHead>{t("components.o_scans.vault")}</TableHead>
          <TableHead>{t("components.o_scans.remark")}</TableHead>
          <TableHead>{t("components.o_scans.execution")}</TableHead>
          <TableHead>IDPDA</TableHead>
          <TableHead>{t("components.o_scans.key")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {scans && scans.map((h, i) => {
          return (
            <TableRow key={i}>
              <TableCell className='whitespace-nowrap'>{format(convertToDate(h.dtcreation) || new Date(), "dd/MM/yyyy")}</TableCell>
              <TableCell>{convertToHHMM(h.tmcreation)}</TableCell>
              <TableCell>{h.barcode}</TableCell>
              <TableCell>{h.vault}</TableCell>
              <TableCell>{h.remark}</TableCell>
              <TableCell>{h.function}</TableCell>
              <TableCell>{h.idpda}</TableCell>
              <TableCell>{h.sleutel ? t("components.o_scans.yes") : t("components.o_scans.no")}</TableCell>
            </TableRow>
          )
        })}
      </TableBody>
    </Table>
  )
}

export default Scans