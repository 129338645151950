import { addDays, addHours, addMinutes, addMonths, differenceInDays, endOfMonth, endOfWeek, format, isBefore, isSameDay, isSameMonth, isToday, parse, startOfMonth, startOfWeek, subDays, subMinutes, subMonths, subSeconds } from 'date-fns';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import React, { useState } from 'react'

import smallCoinAndNote from '../../../../assets/images/smallCoinAndNote.png'
import smallCoin from '../../../../assets/images/smallCoin.png'
import smallNote from '../../../../assets/images/smallNote.png'
import { convertToDate } from '../../../../lib/date';
import { combineCNValues, isInterface } from '../../../../lib/utils';
import { AlertDialogCancel } from '../../../ui/alert-dialog';
import { EventProps } from 'react-big-calendar';

export type DayObject = {
    isSameMonth: boolean,
    isToday: boolean,
    isSelected: boolean,
    isBeforeToday: boolean,
    day: string,
    date: Date,
    events: PlanningEvent[],
    cn?: 'C' | 'N' | 'CN',

    
}

type Props = {
    events: PlanningEvent[] | StateType,
    setcurrentMonth: React.Dispatch<React.SetStateAction<Date>>,
    currentMonth: Date,
    setselectedDate: React.Dispatch<React.SetStateAction<Date | undefined>>,
    selectedDate: Date | undefined,

    onSelectDate: (date:DayObject) => void,
    partnersettings?: PartnerSettings | null,

    page?: 'announce' | 'order' | 'consumables'
    holidays?: Holiday[] | null
}

const Calendar : React.FC<Props> = ({page, events, setcurrentMonth, currentMonth, setselectedDate, selectedDate, onSelectDate, partnersettings, holidays}) => {
    const Header = () => {
        const dateFormat = "MMMM yyyy"

        function prevMonth() {
            setcurrentMonth(subMonths(currentMonth, 1))
        }

        function nxtMonth() {
            setcurrentMonth(addMonths(currentMonth, 1))
        }

        return (
            <div className='grid grid-cols-3'>
                <div className='flex items-center gap-1'>
                    <button onClick={prevMonth} className='flex items-center justify-center duration-300 border rounded-full w-9 h-9 hover:bg-primary/10 hover:border-primary/10'><ChevronLeft className='w-4'/></button>
                    <button onClick={nxtMonth} className='flex items-center justify-center duration-300 border rounded-full w-9 h-9 hover:bg-primary/10 hover:border-primary/10'><ChevronRight className='w-4' /></button>
                </div>
                <div className='flex items-center justify-center font-semibold capitalize'>
                    <p>{format(currentMonth, dateFormat)}</p>
                </div>
                <div className='flex items-center justify-end'></div>
            </div>
        )
    }
    
    const Days = () => {
        const dateFormat = "EEE";
        const days = []
        let startDate = startOfWeek(currentMonth)

        for (let i = 0; i < 7; i++) {
            days.push({
                weekday: format(addDays(startDate, i), dateFormat)
            })
        }

        return (
            <div className='grid grid-cols-7 mt-4'>
                {days.map((day, index) => {
                    return (
                        <div className='flex justify-center py-2 text-sm font-medium capitalize text-neutral-500 first:rounded-tl-sm last:rounded-tr-sm' key={index}>{day.weekday}</div>
                    )
                })}
            </div>
        )
    }

    const searchEvents = (day:Date) => {
        const ev:PlanningEvent[] = [];

        if(isInterface(events, {} as PlanningEvent[])){
            events.forEach(event => {
                if(isSameDay(convertToDate(event.date) || new Date(1970,1,1), day) ){
                    ev.push(event)
                }
            });
        }

        return ev;
    }
    
    const Cells = () => {
        const dateFormat = "d";

        const monthStart = startOfMonth(currentMonth);
        const monthEnd = endOfMonth(monthStart);
        const startDate = startOfWeek(monthStart);
        const endDate = endOfWeek(monthEnd);

        const rows = []

        let days:DayObject[] = []
        let day = startDate;
        day.setHours(new Date().getHours())
        day.setMinutes(new Date().getMinutes())
        day = addMinutes(day, 5)

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {

                let isSameDayVar = false;
                if(selectedDate){
                    isSameDayVar = isSameDay(selectedDate, day)
                }

                days.push({
                    isSameMonth: isSameMonth(day, monthStart),
                    isToday: isToday(day),
                    isSelected: isSameDayVar,
                    isBeforeToday: isBefore(day, new Date()),
                    day: format(day, dateFormat),
                    date: day,
                    events: searchEvents(day),
                    cn: combineCNValues(searchEvents(day))
                })
                day = addDays(day, 1)
            }
            rows.push(days);
            days = []
        }

        function isDateAvailable(orderdate:number, ordertime:string, calendarDate:Date, holidayDates:number[]) {  

            let diffDays = 0;
            let today = new Date()
            let tempDate = new Date(calendarDate);

            //disabled because loomis has to validate; it is possible to order on holidays
            //if(holidayDates.includes(+tempDate)){ return false }

            while(tempDate >= today) {
                tempDate.setDate(tempDate.getDate() - 1)

                if(tempDate < today){ continue; }
                
                if (tempDate.getDay() === 0 || tempDate.getDay() === 6 || holidayDates.includes(+tempDate)) {
                    continue;
                }

                diffDays++;                
            }
            
            calendarDate.setHours(parseFloat(ordertime.split(':')[0]))
            calendarDate.setMinutes(parseFloat(ordertime.split(':')[1]))
            if(isBefore(calendarDate, new Date)) { return false }

            if(diffDays > Math.abs(orderdate)) { return true }
            else if(diffDays === Math.abs(orderdate)){
                // D+n is equal to orderdate -> check hours
                const currentTime = new Date();
                const [hour, minute] = ordertime.split(':').map(Number)
                if (currentTime.getHours() < hour || (currentTime.getHours() === hour && currentTime.getMinutes() <= minute)) {
                    return true;
                }
            }

            return false
        }

        const holidayDates = holidays?.map((h)=>convertToDate(h.date)?.getTime() || 0) || []

        let amountPartnerSettingsDays =  Math.abs(partnersettings ? parseFloat(partnersettings.orderdate) : 2)
        const amountPartnerSettingsHours = partnersettings ? partnersettings.ordertime : '12:00'

        let announce_amountPartnerSettingsDays =  Math.abs(partnersettings ? parseFloat(partnersettings.announcementdate) : 2)
        const announce_amountPartnerSettingsHours = partnersettings ? partnersettings.announcementtime : '12:00'

        return (
            <div>
                {rows.map((row, ind) => {
                    return (
                        <div className='grid grid-cols-7' key={ind}>
                            {row.map((day, i) => {

                                let isDaysBetweenMore = false

                                if(page === 'order'){
                                    if(isNaN(amountPartnerSettingsDays)){ amountPartnerSettingsDays = 1 }
                                    isDaysBetweenMore = isDateAvailable(amountPartnerSettingsDays, amountPartnerSettingsHours, day.date, holidayDates)
                                }
                                else if(page === 'announce'){
                                    if(isNaN(announce_amountPartnerSettingsDays)){ announce_amountPartnerSettingsDays = 1 }
                                    isDaysBetweenMore = isDateAvailable(announce_amountPartnerSettingsDays, announce_amountPartnerSettingsHours, day.date, holidayDates)
                                }
                                
                                return (
                                    <AlertDialogCancel asChild key={i}>
                                    <button onClick={()=>{ onSelectDate(day) }} disabled={!isDaysBetweenMore || (day.events.length === 0)} className='relative flex flex-col items-center justify-center h-auto py-3 border-none hover:bg-transparent group disabled:opacity-20'>
                                        <div className={`${day.isSelected && '!bg-primary !text-white'} duration-200 w-8 h-8 rounded-full flex justify-center items-center group-disabled:bg-transparent group-disabled:text-black group-hover:bg-primary/70 group-hover:text-white`}>
                                            <p className='text-sm font-semibold'>{day.day}</p>
                                        </div>

                                        {day.events.length > 0 &&
                                        <div className={`${day.isSelected && '!opacity-100'} absolute bottom-0 opacity-50 group-hover:opacity-100`}>
                                        {combineCNValues(day.events) === 'CN' && <img className='w-5' src={smallCoinAndNote} alt="cn" />}
                                        {combineCNValues(day.events) === 'C' && <img className='w-5' src={smallCoin} alt="cn" />}
                                        {combineCNValues(day.events) === 'N' && <img className='w-5' src={smallNote} alt="cn" />}
                                        </div>
                                        }
                                        
                                    </button>
                                    </AlertDialogCancel>
                                )
                            })}
                        </div>
                    )
                })}
            </div>
        )
    }

  return (
    <div>
        <Header />
        <Days />
        <Cells />
    </div>
  )
}

export default Calendar