import axios from "../axios";

export async function createTicket(auth:Auth | null, description:string, error:unknown) {
    try {
        return ''
        // await axios.post(`https://apinirah.qastan.be/Nirah/ticket/create`, {
        //     token: 'B0E269B3150B63F81C38A712F8F6AC6161293E3E7473227ED297C628E779D1C1EDA620B42B158E876E84E517B094F4D530FBEDC66B120AB6000E4C3C0000C938F35BD0FABC846B2CE42591AD8FACEC82EBAB51971AA05317F9294ED957187A8A850797DF797F47A53F954EE2F7B37A4287B57881F56853EFFCF33C3C89A0031207916F5FC03F81CD05756017EAAD0F378DFE60B4873E07E59958221D5FEAF5B8828994D3E49E44BFD96C7FC4C2064989',
        //     subject: `Loomis Portaal - ERROR - ${auth?.data?.username}`,
        //     description: `
        //         URL ${window.location.pathname} \n\n\n
        //         USER ${JSON.stringify(auth)} \n\n\n
        //         DESCRIPTION ${description}
        //         ERROR ${error} \n\n\n`,
        //     assigned: 60,
        //     company: 'FIT'
        // })

    } catch (error) {
        
    }
}