import { AlertTriangle, CheckCircle, Clock, Gem, Loader2 } from 'lucide-react'
import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from '../../../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { formatDateToYYYYMMDD } from '../../../../../lib/date'
import { endOfMonth, startOfMonth } from 'date-fns'
import { formatCashAmount } from '../../../../../lib/utils'
import { Skeleton } from '../../../../../components/ui/skeleton'
import { useTranslation } from 'react-i18next'

const Order = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const {data:orders, isFetching:orders_isFetching} = useQuery<OrderDraft[] | null>({
        queryKey: [`orders`],
        queryFn: async () => {
            try {
                let obj:any = { token:auth.data?.token, startdate:formatDateToYYYYMMDD(startOfMonth(new Date)), enddate:formatDateToYYYYMMDD(endOfMonth(new Date)), searchby:"C" }
                // No results when passing partner/relnr, good relnr-results without (probably filtered by token)
                // if(auth.data?.partner) { obj.partner = auth.data.partner }
                // if(auth.data?.relnr) { obj.relnr = auth.data.relnr }
                const {data} = await axios.post(`order/draft/search`, obj)
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.orderdraft)){return null}
                return data.object.orderdraft
            } catch (error) {
                console.log(error);
                return null
            }
        }
    })

  return (
    <div className='flex flex-col gap-4 shadow-sm border rounded-sm p-4'>
        <h2 className='text-lg'>{t("order_and_pickup.order_title")}</h2>

        <div className='flex gap-2 items-center'>
            <div className='w-10 h-10 rounded-sm flex justify-center items-center bg-red-50'><Gem className='text-primary'/></div>
            {(orders_isFetching && !orders) 
            ? <Skeleton size='2xl' className='w-[250px]'/>
            : <h3 className='font-semibold text-2xl whitespace-nowrap'>€ {formatCashAmount(orders ? orders.map(i=>i.amount).reduce((total, curr)=>total+=curr, 0) : 0)}</h3>
            }
            
            
        </div>

        <div className='h-px w-full bg-neutral-200'></div>

        <div className='flex gap-4 flex-wrap'>
            <div>
                <p className='font-semibold text-sm text-neutral-600'>{t("order_and_pickup.notes")}</p>
                {(orders_isFetching && !orders) 
                ? <Skeleton className='w-[100px]'/>
                :<p className='font-medium whitespace-nowrap'>€ {formatCashAmount(orders ? orders.filter(i=>i.cn.toLowerCase()==='n').map(i=>i.amount).reduce((total, curr)=>total+=curr, 0) : 0)}</p>
                }
            </div>
            <div>
                <p className='font-semibold text-sm text-neutral-600'>{t("order_and_pickup.coins")}</p>
                {(orders_isFetching && !orders) 
                ? <Skeleton className='w-[100px]'/>
                : <p className='font-medium whitespace-nowrap'>€ {formatCashAmount(orders ? orders.filter(i=>i.cn.toLowerCase()==='c').map(i=>i.amount).reduce((total, curr)=>total+=curr, 0) : 0)}</p>
                }
            </div>
        </div>

        <div className='h-px w-full bg-neutral-200'></div>

        <div className='flex justify-between gap-4 flex-wrap'>
            <div>
                <p className='flex items-center gap-1 font-semibold text-sm text-orange-600'><span><Clock className='w-4'/></span>{t("order_and_pickup.pending")}</p>
                {(orders_isFetching && !orders) 
                ? <Skeleton className='w-[50px]'/>
                : <p className='font-medium'>{orders ? orders.filter(i=>i.status==="002"||i.status==="006").length : 0}</p>}
            </div>
            <div>
                <p className='flex items-center gap-1 font-semibold text-sm text-green-600'><span><CheckCircle className='w-4'/></span>{t("order_and_pickup.validated")}</p>
                {(orders_isFetching && !orders) 
                ? <Skeleton className='w-[50px]'/>
                : <p className='font-medium'>{orders ? orders.filter(i=>i.status==="003"||i.status==="009").length : 0}</p>}
            </div>
            <div>
                <p className='flex items-center gap-1 font-semibold text-sm text-red-600'><span><AlertTriangle className='w-4'/></span>{t("order_and_pickup.denied")}</p>
                {(orders_isFetching && !orders) 
                ? <Skeleton className='w-[50px]'/>
                : <p className='font-medium'>{orders ? orders.filter(i=>i.status==="004"||i.status==="008").length : 0}</p>}
            </div>
        </div>
        
        <div className='flex gap-2'>
        {(auth.data?.right.order&&auth.data.role > 2) && 
            <Link to="/bestellen-en-ophalen/bestelling-plaatsen"><Button>{t("order_and_pickup.order_order_button")}</Button></Link>
        }
            <Link to="/bestellen-en-ophalen/overzicht-bestellingen"><Button variant='outline'>{t("order_and_pickup.order_search_button")}</Button></Link>
        </div>  
    </div>
  )
}

export default Order