import Overview from './Overview'
import Detail from './Detail'
import { Routes, Route } from 'react-router-dom'

const Keepsafe = () => {
    return (
    <>
    <Routes>
        <Route path="/*" element={<Overview />} />
        <Route path="/:colliid" element={<Detail />}/>
    </Routes>
    </>
    )
}

export default Keepsafe