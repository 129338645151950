import React, { useState } from 'react'
import { AlertDialog, AlertDialogCancel, AlertDialogContent, AlertDialogTitle, AlertDialogTrigger } from '../../ui/alert-dialog'
import { HelpCircle, X } from 'lucide-react'
import { Button } from '../../ui/button';
import MoveRight from '../../../assets/icons/MoveRight';
import { useTranslation } from 'react-i18next';

type Item = {
    label: string;
    url: string;
    subtitle_id?: number
}

type Subtitles = {
    id: number;
    title: string;
}

type FaqProps = {
    title?: string
    items?: Item[]
    subtitles?: Subtitles[]
}

const Faq : React.FC<FaqProps> = ({title, items, subtitles}) => {
    const {t} = useTranslation()
    const [selected_index, setselected_index] = useState<null | number>(null);

  return (
    <AlertDialog>
        <AlertDialogTrigger asChild>
            <button className='w-10 h-10 rounded-full fixed border right-2 bottom-2 flex justify-center items-center z-50 bg-slate-50 shadow-md'>
                <HelpCircle className='w-5 text-slate-600' strokeWidth={1.5}/>
            </button>
        </AlertDialogTrigger>
        <AlertDialogContent className={`${(typeof selected_index === 'number') && 'w-full max-w-5xl'} max-h-[95vh] overflow-auto gap-2`}>
            <div className='flex justify-between'>
                <AlertDialogTitle>FAQ {title && '-'} {title}</AlertDialogTitle>
                <AlertDialogCancel className='h-8 border-none hover:bg-transparent text-neutral-400 hover:text-neutral-800 p-0 -translate-y-2'><X className='w-4 '/></AlertDialogCancel>
            </div>
            
            {!(typeof selected_index === 'number') && 
            <div className='flex flex-col gap-2'>
            {items?.map((item, index) => {

                const subtitle = subtitles?.find(i=>i.id===item.subtitle_id)     
                const isFirst = item === items.filter(i=>i.subtitle_id===item.subtitle_id)[0]

                return (
                    <React.Fragment key={index}>
                    {isFirst && <p className='mt-2 font-medium'>{subtitle?.title}</p>}
                    <div onClick={()=>{setselected_index(index)}}>
                        <p className='underline cursor-pointer opacity-60 hover:opacity-100 duration-200'>{item.label}</p>
                    </div>
                    </React.Fragment>
                )
            })}
            </div>
            }

            {(typeof(selected_index) === 'number' && items) &&
            <div className='flex flex-col gap-2'>
                <Button onClick={()=>{setselected_index(null)}} size='sm' variant='outline' className='w-fit gap-2'><MoveRight className='rotate-180 w-4'/> {t("components.o_faq.button_back")}</Button>
                <iframe src={items[selected_index].url} className='w-full h-full min-h-[50vh]'></iframe>
            </div>
            }
            
        </AlertDialogContent>
    </AlertDialog>
  )
}

export default Faq