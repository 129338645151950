import { ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function deepMerge(
  target?: {} | null,
  ...sources: Array<null | {} | undefined | null>
): // eslint-disable-next-line @typescript-eslint/no-explicit-any
any {
  target = target || {};
  const len = sources.length;
  let obj;
  let value;
  for (let i = 0; i < len; i++) {
    obj = sources[i] || {};
    for (let key in obj) {
      // @ts-ignore
      if (typeof obj[key] !== undefined) {
        // @ts-ignore
        value = obj[key];
        if (isCloneable(value)) {
          // @ts-ignore
          target[key] = deepMerge(
            /* eslint-disable-next-line no-mixed-operators */
            // @ts-ignore
            target[key] || (Array.isArray(value) && []) || {},
            value
          );
        } else {
          // @ts-ignore
          target[key] = value;
        }
      }
    }
  }
  return target;
}

function isCloneable(obj: unknown) {
  /* eslint-disable-next-line eqeqeq */
  return Array.isArray(obj) || {}.toString.call(obj) == '[object Object]';
}

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  const result: T[][] = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }
  return result;
}

export const formatCashAmount = (number:number) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  const formattedAmount = number.toLocaleString('en-US', options);
  return formattedAmount
}

export const isInterface = <T>(variable: any, interfaceObject: T): variable is T => {
  if (interfaceObject === null || typeof interfaceObject !== 'object') {
    return false;
  }

  if (typeof interfaceObject === 'object') {
    if (variable === null || typeof variable !== 'object') {
      return false;
    }

    const interfaceKeys = Object.keys(interfaceObject as object) as Array<keyof T>;
    return interfaceKeys.every((key) => {
      const interfaceValue = interfaceObject[key];
      const variableValue = variable[key];
      return isInterface(variableValue, interfaceValue);
    });
  } else if (typeof interfaceObject === 'function') {
    return variable instanceof interfaceObject;
  } else {
    return typeof variable === typeof interfaceObject;
  }
};

export function combineCNValues(arr:PlanningEvent[]) {
  const cnArr:any = []
  arr.forEach((ev:any) => {
      if(!cnArr.includes(ev.cn)){
          cnArr.push(ev.cn)
      }
  });
  cnArr.sort();
  return cnArr.join('')
}

export function isCoupureCN(coupures:Coupure[]): 'c' | 'n' {
  let result: 'c' | 'n' = 'c';

  coupures.forEach(i => parseFloat(i.coupure) > 2 ? result = 'n' : null)

  return result;
}

export function hasOnePartner(partners:string) {
  
}