import React, { useState } from 'react'
import useAuth from '../../../../../hooks/useAuth'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import { splitArrayIntoChunks } from '../../../../../lib/utils'
import { useNavigate } from 'react-router-dom'
import { Pagination } from '../../../../../components/ui/pagination'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { format } from 'date-fns'
import { Printer } from 'lucide-react'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../../lib/axios'
import { useTranslation } from 'react-i18next'

type Props = {
  consumables: ConsumableOrder[]
}



const ConsumablesTable : React.FC<Props> = ({consumables}) => {
  const {auth} = useAuth()
  const {t} = useTranslation()
  const headerArr = [t("track_and_trace.partner_nr"), t("order_and_pickup.o_overview_consumables.partner"), t("track_and_trace.customer_nr"), t("order_and_pickup.o_overview_consumables.customer_code"), t("order_and_pickup.o_overview_consumables.customer_p2"), t("order_and_pickup.o_overview_consumables.date_delivery"), t("order_and_pickup.o_overview_consumables.date_created"), t("order_and_pickup.o_overview_consumables.time_created")]
  const navigate = useNavigate()

  const partners = useSelector((state:RootState)=>state.partners);
  const customers = useSelector((state:RootState)=>state.customers);

  const [currentPage, setcurrentPage] = useState<number>(1);

  const downloadConsumable = async (consumable_id:number) => {
    try {
      toast.loading(t("order_and_pickup.o_overview_consumables.toast_loading"), {id:`download_consumable_${consumable_id}`})
      const {data} = await axios.post(`consumable/print`, {token:auth.data?.token, id:consumable_id})
      if(data.errorcode!==0){throw new Error(data.message)}
      window.open(data.object.filename)
      toast.success(t("order_and_pickup.o_overview_consumables.toast_success"), {id:`download_consumable_${consumable_id}`})
    } catch (error) {
      toast.error(t("order_and_pickup.o_overview_consumables.toast_error_p2"), {id:`download_consumable_${consumable_id}`})
    }
  }

  return (
    <>
    <Toaster />

    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(consumables, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername',  'dtdelivery', 'dtcreated', 'tmcreated'],{partners:partners, customers:customers, relnr:'relnr'})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table>
          <TableHeader className='bg-slate-100 relative'>
            <TableRow>
              <TableHead></TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.partner")}</TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.customer_p2")}</TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.customer_code")}</TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.date_delivery")}</TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.date_created")}</TableHead>
              <TableHead className='uppercase'>{t("order_and_pickup.o_overview_consumables.time_created")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {consumables && splitArrayIntoChunks(consumables, 20)[currentPage - 1] && splitArrayIntoChunks(consumables, 20)[currentPage - 1].map((consumable, index) => {
              let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === consumable.relnr))
              let partner = partners.find(obj => obj.relnr === relation?.id)
              let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === consumable.relnr)

              return (
                  <TableRow key={index}>
                      <TableCell><button onClick={()=>{downloadConsumable(consumable.id)}} className='h-8 w-8 flex justify-center items-center opacity-50 hover:opacity-80'><Printer className='w-4'/></button></TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{partner?.name}</TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{customer?.name}</TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{consumable.customercode}</TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{format(convertToDate(consumable.dtdelivery) || new Date(), "dd/MM/yyyy")}</TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{format(convertToDate(consumable.dtcreated) || new Date(), "dd/MM/yyyy")}</TableCell>
                      <TableCell className='cursor-pointer' onClick={()=>{navigate(`${consumable.id}`)}}>{convertToHHMM(consumable.tmcreated)}</TableCell>
                    </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </div>

    <Pagination totalItems={consumables.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default ConsumablesTable