import { ActionType, Action } from "../action-types";

const initialState:Role[] = [];

const rolesReducer = (state:Role[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_ROLES:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default rolesReducer;