import { useRef, useState } from 'react'
import { Button } from '../../../../../components/ui/button'
import { CheckIcon, EditIcon, Eye, EyeOff, Trash2Icon, XIcon, ShieldBanIcon, ShieldCheckIcon } from 'lucide-react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../../../../components/ui/accordion';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table';
import { Checkbox } from '../../../../../components/ui/checkbox';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import axios from '../../../../../lib/axios';
import useAuth from '../../../../../hooks/useAuth';
import lottie from 'lottie-web'
import ErrorAnimation from '../../../../../assets/animations/error.json'
import InactivePopup from '../toolbar/inactive';
import ActivePopup from '../toolbar/active';

type GroupRowProps = {
    deviescurrency: DeviesCurrency
    toggleAll?: () => void;
    toggleItem?: (devies_id: number) => void;
    selected_divies?: number[];
}

const GroupRow = ({deviescurrency, toggleAll=()=>{}, toggleItem=()=>{}, selected_divies=[]}: GroupRowProps) => {


    return (
        <AccordionItem value={`eur-${0}`}>
            <AccordionTrigger className='px-4 border-y bg-slate-200'>{deviescurrency.deviescurrency_name}</AccordionTrigger>
            <AccordionContent>
                <Table>
                    <TableHeader className='bg-slate-100'>
                        <TableRow>
                            <TableHead><div className='flex items-center justify-center'><Checkbox onCheckedChange={(v)=>{toggleAll()}} checked={selected_divies.length === deviescurrency.a_devies.length} /></div></TableHead>
                            <TableHead>Waarde</TableHead>
                            <TableHead>Gewicht</TableHead>
                            <TableHead className='whitespace-nowrap'>Munt / Biljet</TableHead>
                            <TableHead>Aankondigen</TableHead>
                            <TableHead>Bestellen</TableHead>
                            <TableHead>Minimum</TableHead>
                            <TableHead>Maximum</TableHead>
                            <TableHead>Verpakt per</TableHead>
                            <TableHead>Actief</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                        {Array.isArray(deviescurrency.a_devies) && deviescurrency.a_devies.map((devies,i)=>(
                        <TableRow key={i}>
                            <TableCell><div className='flex items-center justify-center'><Checkbox onCheckedChange={(v)=>{toggleItem(devies.deviesid)}} checked={selected_divies.includes(devies.deviesid)} /></div></TableCell>
                            <TableCell>{devies.value}</TableCell>
                            <TableCell>{devies.weight}</TableCell>
                            <TableCell>{devies.cn.toLowerCase() === 'c' ? 'Munten' : 'Biljetten'}</TableCell>
                            <TableCell>
                                {devies.announce 
                                ? <div className='flex items-center justify-center w-5 h-5 text-white bg-green-600 rounded-full'><CheckIcon className='w-4 h-4'/></div>
                                : <div className='flex items-center justify-center w-5 h-5 text-white bg-red-600 rounded-full'><XIcon className='w-4 h-4'/></div>}
                            </TableCell>
                            <TableCell>
                                {devies.order 
                                ? <div className='flex items-center justify-center w-5 h-5 text-white bg-green-600 rounded-full'><CheckIcon className='w-4 h-4'/></div>
                                : <div className='flex items-center justify-center w-5 h-5 text-white bg-red-600 rounded-full'><XIcon className='w-4 h-4'/></div>}
                            </TableCell>
                            <TableCell>{devies.min}</TableCell>
                            <TableCell>{devies.max}</TableCell>
                            <TableCell>{devies.unit}</TableCell>
                            <TableCell>{devies.active ? 'Ja' : 'Nee'}</TableCell>
                        </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </AccordionContent>
        </AccordionItem>
    )
}

const HomePage = () => {
    const navigate = useNavigate()

    const {auth} = useAuth()

    const errorRef = useRef<HTMLDivElement | null>(null)

    const [selected_devies, setselected_devies] = useState<number[]>([]);

    const { data:deviescurrencies, isLoading:isLoadingDC, refetch:refetchDC } = useQuery<DeviesCurrency[] | null>({
        queryKey: ['devies-currency'],
        queryFn: async () => {
            try {
                const { data } = await axios.get('/deviescurrency/get', { params: {
                    token: auth.data?.token
                }})
                if(data.errorcode!==0){ throw new Error(data.message) }
                return data.object
            } catch (error) {
                console.log(error)
                if(errorRef.current){
                    lottie.loadAnimation({
                        container: errorRef.current,
                        renderer: 'svg',
                        loop: true,
                        autoplay: true,
                        animationData: ErrorAnimation,
                    })
                }
                toast.error('Er is een fout opgetreden bij het ophalen van de vreemde valuta', { id:'devies-currency' })
                return null
            }
        },
        refetchOnWindowFocus: false
    })

    // toggle all from deviescurrency
    const toggleAllByGroup = (deviescurrency_id: number) => {
        if(!Array.isArray(deviescurrencies)){ return }

        // get all ids from group
        const all_ids = deviescurrencies.filter(dc=>dc.deviescurrency_id===deviescurrency_id)[0].a_devies.map(d=>d.deviesid)
        const current_selected = selected_devies.filter(i=>all_ids.includes(i))

        if(current_selected.length === all_ids.length){
            // Remove all current_selected from selected
            setselected_devies(selected_devies.filter(i=>!all_ids.includes(i)))
        }
        else {
            // Add all ids to selected
            setselected_devies([...selected_devies].concat(all_ids))
        }

        
    }

    // toggle specific from deviescurrency
    const toggleItemByGroup = (devies_id: number) => {
        if(selected_devies.includes(devies_id)){
            // Remove current_selected from selected
            setselected_devies(selected_devies.filter(i=>i!==devies_id))
        }
        else {
            // Add current_selected to selected
            setselected_devies([...selected_devies, devies_id])
        }
    }

  return (
    <div className="w-full p-4 overflow-auto max-h-[100dvh] md:p-8">
        <div className='flex items-center justify-between'>
            <h1 className='text-2xl font-semibold'>Vreemde valuta</h1>
            <Link to="add"><Button>Nieuwe vreemde valuta</Button></Link>
        </div>

        <div>
            <div className='sticky z-10 flex items-center p-2 mt-4 bg-white border rounded-sm shadow-sm -top-4 md:-top-8 md:mt-8'>
                <div className='flex gap-1'>
                    
                    <Button onClick={()=>{navigate(`edit/${selected_devies[0]}`)}} disabled={selected_devies.length !== 1} size="sm" variant='ghost' className='flex items-center gap-2 bg-white whitespace-nowrap'><EditIcon strokeWidth={1} className='w-5 text-orange-600'/>Wijzigen</Button>
                    <InactivePopup 
                        selected_ids={selected_devies}
                        trigger={<Button disabled={selected_devies.length < 1} size="sm" variant='ghost' className='flex items-center gap-2 bg-white whitespace-nowrap'><ShieldBanIcon strokeWidth={1} className='w-5 text-red-600'/>Inactief maken</Button>}
                        onSuccess={()=>{refetchDC()}}
                    />
                    <ActivePopup
                        selected_ids={selected_devies}
                        trigger={<Button disabled={selected_devies.length < 1} size="sm" variant='ghost' className='flex items-center gap-2 bg-white whitespace-nowrap'><ShieldCheckIcon strokeWidth={1} className='w-5 text-green-600'/>Actief maken</Button>}
                        onSuccess={()=>{refetchDC()}}
                    />
                </div>
            </div>

            {Array.isArray(deviescurrencies) &&
            <div className='mt-4 border rounded-sm md:mt-8'>

                <Accordion type='multiple' defaultValue={['eur-0']}>
                    {deviescurrencies.map((deviescurrency,i)=>(
                        <GroupRow selected_divies={selected_devies} toggleItem={(devies_id)=>{toggleItemByGroup(devies_id)}} toggleAll={()=>{toggleAllByGroup(deviescurrency.deviescurrency_id)}} deviescurrency={deviescurrency} key={`deviescurrency-${deviescurrency.deviescurrency_id}`}/>
                    ))}
                </Accordion>
                
            </div>
            }

            <div className={`${(!Array.isArray(deviescurrencies) && !isLoadingDC) ? 'flex' : 'hidden'} flex-col items-center justify-center gap-4 py-8`}>
                <div className='overflow-hidden w-60 h-60' ref={errorRef}></div>
                <p className='text-xl text-neutral-400'>Geen vreemde valuta gevonden</p>
            </div>
            
        </div>
    </div>
  )
}

export default HomePage