import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import Detail from './orders/Detail'

const OverviewOrders = () => {
  return (
    <Routes>
        <Route path="" element={<Main />}/>
        <Route path=":order" element={<Detail />} />
    </Routes>
  )
}

export default OverviewOrders