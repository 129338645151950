import React from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table'
import { convertToDate } from '../../../lib/date'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

type Props = {
    stopDetail: StopDetail,
    stop: Stop
}

const Pickup : React.FC<Props> = ({stopDetail, stop}) => {
    const navigate = useNavigate()
    const {t} = useTranslation()

  return (
    <Table>
        <TableHeader className='bg-slate-100'>
            <TableRow>
                <TableHead>Id</TableHead>
                <TableHead>{t("components.o_stop.o_pickup.barcode")}</TableHead>
                <TableHead>{t("components.o_stop.o_pickup.keepsafe_id")}</TableHead>
                <TableHead>{t("components.o_stop.o_pickup.keepsafe_barcode")}</TableHead>
                <TableHead>{t("components.o_stop.o_pickup.date_collection")}</TableHead>
            </TableRow>
        </TableHeader>
        <TableBody>
            {stopDetail && stopDetail.pickup && stopDetail.pickup.map((detail, index) => {
                return (
                <TableRow key={index} onClick={()=>{navigate(`collection/colli/${detail.colli}`, { state: stop})}} className='cursor-pointer'>
                    <TableCell>{detail.colli}</TableCell>
                    <TableCell>{detail.barcode}</TableCell>
                    <TableCell>{detail.boxid}</TableCell>
                    <TableCell>{detail.boxbarcode}</TableCell>
                    <TableCell>{format(convertToDate(detail.date) || new Date(), "dd/MM/yyyy")}</TableCell>
                </TableRow>
                )
            })}
        </TableBody>
    </Table>
  )
}

export default Pickup