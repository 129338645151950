import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'

const Assets = () => {
  return (
    <Routes>
      <Route path="" element={<Main />} />
    </Routes>
  )
}

export default Assets