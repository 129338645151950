'use client'

import React, {HTMLAttributes, useState} from 'react'

interface TablistProps extends HTMLAttributes<HTMLDivElement> {
    value?: string;
    onValueChange?: (value:string) => void;
}

export const Tablist : React.FC<TablistProps> = ({onValueChange, value, children, ...props}) => {
    const modifiedChildren = React.Children.map(children, (child:any, index) => {
        if(!child) return;

        return React.cloneElement(child, {
            isActive: value === child.props.value,
            onValueChange
        })
    })

    return (
        <div {...props} className='overflow-x-auto overflow-y-hidden inline-flex h-10 items-center text-muted-foreground w-full justify-start rounded-none border-b bg-transparent p-0'>
            {modifiedChildren}
        </div>
    )
}

interface TablistItemProps extends HTMLAttributes<HTMLButtonElement> {
    value?: string;
    isActive?: boolean;
    onValueChange?: (value:string) => void;
}

export const TablistItem : React.FC<TablistItemProps> = ({isActive, value, children, onValueChange, ...props}) => {
    
    const handleClick = () => {
        if(value && onValueChange) {
            onValueChange(value)
        }
    }

    return (
        <button {...props} onClick={()=>{ handleClick() }} data-state={isActive ? 'active' : 'inactive'} className='inline-flex items-center justify-center whitespace-nowrap py-1.5 text-sm ring-offset-background focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50  relative rounded-none border-b-2 border-b-transparent bg-transparent px-4 pb-3 pt-2 font-semibold text-muted-foreground shadow-none transition-none data-[state=active]:border-b-primary data-[state=active]:text-primary data-[state=active]:shadow-none'>
            {children}
        </button>
    )
}