import { ActionType } from "../action-types"

export const setRoles = (roles:Role[]) => {
    return ({
        type: ActionType.SET_ROLES,
        payload: roles 
    })
}

export const setRole = (role:Role[]) => {
    return ({
        type: ActionType.SET_ROLE,
        payload: role 
    })
}

export const setPartners = (partners:Partner[]) => {
    return ({
        type: ActionType.SET_PARTNERS,
        payload: partners 
    })
}

export const setCustomers = (customers:PartnerCustomers[]) => {
    return ({
        type: ActionType.SET_CUSTOMERS,
        payload: customers 
    })
}

export const setUnfilteredCustomers = (unfiltered_customers:PartnerCustomers[]) => {
    return ({
        type: ActionType.SET_UNFILTERED_CUSTOMERS,
        payload: unfiltered_customers 
    })
}

export const addFilter = (page:string, filterDetail:FilterDetail) => ({
    type: ActionType.ADD_FILTER,
    payload: {page, filterDetail},
});
  
export const editFilter = (page:string, filterDetail:FilterDetail) => ({
    type: ActionType.EDIT_FILTER,
    payload: {page, filterDetail},
});
  
export const removeFilter = (page:string, key:string) => ({
    type: ActionType.REMOVE_FILTER,
    payload: { page, key },
});

export const setOrderstate = (orderstate:OrderState[]) => ({
    type: ActionType.SET_ORDERSTATE,
    payload: orderstate
})

export const setOrderDraftState = (orderdraftstate:OrderDraftState[]) => ({
    type: ActionType.SET_ORDERDRAFTSTATE,
    payload: orderdraftstate
})

export const setCoupure = (coupure:ParamCoupure[]) => ({
    type: ActionType.SET_COUPURE,
    payload: coupure
})

export const setComplaintCode = (complaintscode:ComplaintCode[]) => ({
    type: ActionType.SET_COMPLAINTCODE,
    payload: complaintscode 
})