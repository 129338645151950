import useAuth from "../../../hooks/useAuth";
import { format } from "date-fns"
import WeekPlanning from "./WeekPlanning";
import MonthPlanning from "./MonthPlanning";
import { useEffect, useState } from "react";
import { Combobox } from "../../../components/ui/combobox";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux";
import OverviewOrders from "./OverviewOrders";
import OverviewComplaints from "./OverviewComplaints";
import OverviewPickup from "./OverviewPickup";
import { getCustomerList } from "../../../lib/fetch/customer";
import { useTranslation } from "react-i18next";
import UrgentMessage from "./UrgentMessage";

const Dashboard : React.FC = () => {
    const { t } = useTranslation()
    const { auth } = useAuth();
    const partners = useSelector((state:RootState)=>state.partners);
   
    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);

    const [customers_list, setcustomers_list] = useState<Customer[] | null>();

    useEffect(()=>{
        if(Array.isArray(partners)){
            if(partners.length === 1){
                handlePickPartner(partners[0].relnr.toString())
            }
        }
    }, [partners])

    const handlePickPartner = async (relnr:string | null) => {
        try {
            if(!relnr){ setselected_partner(null); setcustomers_list(null); setselected_customer(null); return; }
            if(selected_partner!==relnr){setselected_customer('clear')}
            setselected_partner(relnr);
            setcustomers_list(null);
        
            const list = await getCustomerList(auth, relnr)
            if(list){ setcustomers_list(list) }
            if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
        } catch (error) {
          console.log(error)
        }
    }

    return (
        <div className="w-full p-4 md:p-8">
            <div className="border-b pb-2 flex items-end justify-between">
                <div>
                    <p className="text-sm text-slate-400">{format(new Date(), "d MMM yyyy")}</p>
                    <p className="text-2xl"><span className='font-semibold'>{t("dashboard.title")} {auth.data?.firstname}</span>, {t("dashboard.title_p2")}!</p>
                </div>
                
                <div className="flex-col sm:flex-row flex gap-4">
                    <div>
                        <Combobox disabled={partners?.length === 1} onChangeValue={(v)=>{ handlePickPartner(v?.value.toString() || null) }} id='partner' selectedValue={selected_partner ? selected_partner : undefined} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("dashboard.partner_placeholder")||""} input_placeholder={t("dashboard.search")||""}/>
                    </div>

                    <div>
                        <Combobox onChangeValue={(v)=>{ setselected_customer(v?.value.toString() || null) }} disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' options={Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []} placeholder={t("dashboard.customer_placeholder")||""} input_placeholder={t("dashboard.search")||""}/>
                    </div>
                </div>
            </div>

            <div className="mt-4 md:mt-8 grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4">
                <UrgentMessage />

                <div className="border shadow-sm rounded-sm sm:col-span-3">
                    <div className="p-4">
                        <h2 className="text-lg">{t("dashboard.week_planning")}</h2>
                        <WeekPlanning selected_customer={selected_customer}/>
                    </div>
                </div>

                <div className="border shadow-sm rounded-sm col-span-3 lg:col-span-1">
                    <div className="p-4">
                        <h2 className="text-lg">{t("dashboard.month_planning")}</h2>
                        <MonthPlanning selected_partner={selected_partner} selected_customer={selected_customer}/>
                    </div>
                </div>

                {auth.data?.right.order && 
                <div className="border shadow-sm rounded-sm col-span-2 sm:col-span-3 lg:col-span-2">
                    <div className="p-4">
                        <h2 className="text-lg">{t("dashboard.overview_orders")}</h2>
                        <OverviewOrders selected_customer={selected_customer}/>
                    </div>
                </div>
                }
                
                {auth.data?.right.ttcollection && 
                <div className="border shadow-sm rounded-sm col-span-2">
                    <div className="p-4">
                        <h2 className="text-lg">{t("dashboard.overview_collections")}</h2>
                        <OverviewPickup selected_customer={selected_customer}/>
                    </div>
                </div>
                }
                
                {auth.data?.right.complaint && 
                <div className="border shadow-sm rounded-sm col-span-2">
                    <div className="p-4">
                        <h2 className="text-lg">{t("dashboard.overview_complaints")}</h2>
                        <OverviewComplaints selected_customer={selected_customer}/>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default Dashboard;