import React, { useEffect, useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { Label } from '../../../components/ui/label'
import { Combobox } from '../../../components/ui/combobox'
import { Input } from '../../../components/ui/input'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'
import { useSelector } from 'react-redux'
import { RootState } from '../../../redux'
import { DatePicker } from '../../../components/ui/datepicker'
import { subDays } from 'date-fns'
import { Button } from '../../../components/ui/button'
import { useQuery } from '@tanstack/react-query'
import { formatDateToYYYYMMDD } from '../../../lib/date'
import { Loader2 } from 'lucide-react'
import { isInterface } from '../../../lib/utils'
import PensionsTable from './tables/PensionsTable'
import { getCustomerList } from '../../../lib/fetch/customer'
import WindowsDatePicker from '../../../components/ui/windows-datepicker'
import Faq from '../../../components/portal/faq/Faq'
import { useTranslation } from 'react-i18next'

const Pensions = () => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const partners = useSelector((state:RootState)=>state.partners);

    const [selected_partner, setselected_partner] = useState<string | null>('15287');
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [selected_customercode, setselected_customercode] = useState('');
    const [selected_startdate, setselected_startdate] = useState<Date>(subDays(new Date(), 3));
    const [selected_enddate, setselected_enddate] = useState<Date>(new Date());
    const [route, setroute] = useState('');
    const [assigned, setassigned] = useState('');
    const [locker, setlocker] = useState('');

    const [customers_list, setcustomers_list] = useState<Customer[] | null>()

    const {data:pensions, refetch, isFetching} = useQuery<Pension[] | null>({
        queryKey: ['pensions'],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`pension/search`, {
                    token: auth.data?.token,
                    relnr: (selected_customer !== "clear" && selected_customer !== process.env.REACT_APP_RDM_NUM) ? selected_customer : '',
                    clientcode: selected_customercode,
                    startdate: formatDateToYYYYMMDD(selected_startdate),
                    enddate: formatDateToYYYYMMDD(selected_enddate),
                    assignation: assigned, 
                    route: route,
                    locker: locker
                })
                console.log(data);
                if(data.errorcode!==0){ throw new Error(data.message); }
                if(!data.object.pension){ toast.error(t("pensions.toast_no_pension")); return null; }
                return data.object.pension;
            } catch (error) {
                console.log(error)
                toast.error(t("pensions.toast_wrong_pension"))
                return null;
            }
        },
        enabled: false
    })
    
    
    useEffect(()=>{
        handlePickPartner('15287')
        // if (auth.data?.partner.split(",")[0]) {
        //     handlePickPartner(auth.data.partner.split(",")[0])
        // }
    },[])

    const handlePickPartner = async (relnr:string) => {
        try {
            console.log(relnr, selected_partner)
            if(selected_partner!==relnr){setselected_customer('clear')}
            setselected_partner(relnr);
            setcustomers_list(null);
            const list = await getCustomerList(auth, relnr)
            if(list){ setcustomers_list(list) }
            if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }
        } catch (error) {
          console.log(error)
          toast.error(t("pensions.toast_wrong"))
        }
    }

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []
    customersArr.length > 0 && customersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_customer")})

  return (
    <>
    {auth.data?.right.faq && <Faq title={t("pensions.title")} items={[{label:t("pensions.faq_overview"), url:'https://sway.office.com/s/c5pa8GlbG04a3SYc/embed'}]}/>}
    <Toaster />
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex justify-between items-center'>
            <h1 className='text-2xl font-semibold'>{t("pensions.title")}</h1>
        </div>

        <div className='flex flex-col gap-4 p-4 shadow-sm border rounded-sm mt-4 md:mt-8'>
            <div className='flex justify-between'>
                <h2 className='text-lg'>{t("pensions.filters")}</h2>
            </div>

            <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                <div>
                    <Label htmlFor='partner'>{t("pensions.partner")}</Label>
                    <Combobox disabled id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={Array.isArray(partners) ? partners.map((item) => ({value:item.relnr, label:item.name})) : []} placeholder={t("pensions.partner_placeholder")} input_placeholder={t("pensions.search_placeholder")}/>
                </div>

                <div>
                    <Label htmlFor='end_customer'>{t("pensions.customer")}</Label>
                    <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("pensions.customer_placeholder")} input_placeholder={t("pensions.search_placeholder")}/>
                </div>
                
                <div>
                    <Label htmlFor='customer_code'>{t("pensions.customer_code")}</Label>
                    <Input value={selected_customercode} onChange={(e) => { setselected_customercode(e.currentTarget.value) }} id='customer_code'/>
                </div>

                <div className='col-span-full grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
                    <div>
                        <Label htmlFor='startdate'>{t("pensions.date_start_payment")}<span className='text-primary ml-1'>*</span></Label>
                        <WindowsDatePicker value={selected_startdate} onDateChange={(date)=>{date && setselected_startdate(date)}}/>
                    </div>

                    <div>
                        <Label htmlFor='enddate'>{t("pensions.date_end_payment")}<span className='text-primary ml-1'>*</span></Label>
                        <WindowsDatePicker value={selected_enddate} onDateChange={(date)=>{date && setselected_enddate(date)}}/>
                    </div>
                </div>

                <div>
                    <Label htmlFor='route'>{t("pensions.route")}</Label>
                    <Input value={route} onChange={(e) => { setroute(e.currentTarget.value) }} id='route'/>
                </div>

                <div>
                    <Label htmlFor='assigned'>{t("pensions.assigned")}</Label>
                    <Input value={assigned} onChange={(e) => { setassigned(e.currentTarget.value) }} id='assigned'/>
                </div>

                <div>
                    <Label htmlFor='locker'>{t("pensions.locker")}</Label>
                    <Input value={locker} onChange={(e) => { setlocker(e.currentTarget.value) }} id='locker'/>
                </div>
            </div>

            <Button disabled={isFetching} onClick={() => { refetch() }} className='w-fit'>{isFetching ? <Loader2 className='animate-spin'/> : t("pensions.search")}</Button>
        </div>

        {isInterface(pensions, {} as Pension[]) && <PensionsTable pensions={pensions}/>}
    </div>
    </>
  )
}

export default Pensions