import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { formatCashAmount, isInterface } from '../../../../../lib/utils'
import { useQuery } from '@tanstack/react-query'
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth'
import { Link, useNavigate, useParams } from 'react-router-dom'
import errorAnimation from '../../../../../assets/animations/error.json'
import lottie from 'lottie-web'
import { Breadcrumb } from '../../../../../components/ui/breadcrumb'
import Coupure from '../../../../../components/portal/coupure/Coupure'
import { convertToDate } from '../../../../../lib/date'
import { format } from 'date-fns'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import LoadingScreen from '../../../../../components/portal/feedback/LoadingScreen'
import { getOrderDraftState } from '../../../../../lib/general/params'
import { useTranslation } from 'react-i18next'

const Detail = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const { order } = useParams()
    const {auth} = useAuth()
    const errorContainer = useRef() as MutableRefObject<HTMLDivElement>

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const {data:detail, isFetching:isFetching_detail} = useQuery<CoupureInput[] | null>({
        queryKey: [`order_detail_${order}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`order/draft/detail`, {
                    token: auth.data?.token,
                    id: order
                })
                if(data.errorcode === 0){ return data.object.coupure }
                else { throw new Error(data.object) }
            } catch (error) {
                return null
            }
        }
    })

    const {data:detail_info, isFetching:isFetching_info} = useQuery<OrderDraft | null>({
        queryKey: [`order_detail_info_${order}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`order/draft/search`, {
                    token: auth.data?.token,
                    id: order
                })
                if(data.errorcode === 0){ return data.object.orderdraft[0] }
                else { throw new Error(data.message) }
            } catch (error) {
                return null
            }
        }
    })

    useEffect(()=>{
        if(!detail || !detail_info){
        const instance = lottie.loadAnimation({
            container: errorContainer.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: errorAnimation
        })
        return () => instance.destroy()
        }
    }, [detail, detail_info])

    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === detail_info?.relnr))
    let partner = partners.find(obj => obj.relnr === relation?.id)
    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === detail_info?.relnr)

    return (
    <>
    <Toaster /> 

    {((isFetching_detail || isFetching_info) && (!isInterface(detail, {} as AnnouncemenDetail[]))) && 
    <LoadingScreen />
    }

    {(detail === null || detail_info === null) && 
    <div className={`flex flex-col items-center justify-center`}>
      <div className='w-[240px] h-[240px]' ref={errorContainer}></div>
      <h2 className='text-2xl font-semibold mb-4'>{t("order_and_pickup.o_overview_orders.error_title")}</h2>
      <p className='text-center'>{t("order_and_pickup.o_overview_orders.error_description")}<br></br>{t("order_and_pickup.o_overview_orders.error_description_p2")} <Link to='/bestellen-en-ophalen/overzicht-bestellingen' className='font-medium underline'>{t("order_and_pickup.o_overview_orders.error_description_p3")}</Link> {t("order_and_pickup.o_overview_orders.error_description_p4")}</p>
    </div>
    }

    {(isInterface(detail, {} as CoupureInput[]) && isInterface(detail_info, {} as OrderDraft)) && 
    <div className="overflow-auto relative w-full p-4 md:p-8">
        <div className='flex flex-col'>
          <Breadcrumb items={[{title:<p onClick={()=>{navigate('/bestellen-en-ophalen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_orders.link_orders")}</p>}, {title:<p onClick={()=>{navigate('/bestellen-en-ophalen/overzicht-bestellingen')}} className='cursor-pointer'>{t("order_and_pickup.o_overview_orders.link_overview")}</p>}, {title:`${t("order_and_pickup.o_overview_orders.link_order")} ${order}`}]}/>
          <h1 className='text-2xl font-semibold'>{t("order_and_pickup.o_overview_orders.detail_title")}</h1>
        </div>

        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            <h2 className='text-lg'>{t("order_and_pickup.o_overview_orders.detail_subtitle")}</h2>
            <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.partner_nr")}</p>
                    <p className='text-neutral-500'>{partner?.relnr}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.partner")}</p>
                    <p className='text-neutral-500'>{partner?.name}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.relation_nr")}</p>
                    <p className='text-neutral-500'>{detail_info.relnr}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.customer_nr")}</p>
                    <p className='text-neutral-500'>{detail_info.name}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.customer_code")}</p>
                    <p className='text-neutral-500'>{customer?.clientcode}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.amount")}</p>
                    <p className='text-neutral-500'>{formatCashAmount(detail_info.amount)}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.currency")}</p>
                    <p className='text-neutral-500'>EUR</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.type")}</p>
                    <p className='text-neutral-500'>{detail_info.cn.toLowerCase() === 'c' ? t("order_and_pickup.o_overview_orders.coins") : t("order_and_pickup.o_overview_orders.notes")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.created")}</p>
                    <p className='text-neutral-500'>{format(convertToDate(detail_info.dtcreation) || new Date(), "dd/MM/yyyy")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.delivery")}</p>
                    <p className='text-neutral-500'>{format(convertToDate(detail_info.dtdelivery) || new Date(), "dd/MM/yyyy")}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.state")}</p>
                    <p className='text-neutral-500'>{orderdraftstate && getOrderDraftState(detail_info.status, orderdraftstate)?.description}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.reference")}</p>
                    <p className='text-neutral-500'>{detail_info.reference}</p>
                </div>
                <div className='flex gap-2'>
                    <p className='font-semibold'>{t("order_and_pickup.o_overview_orders.auto_ref")}</p>
                    <p className='text-neutral-500'>{detail_info.autoreference}</p>
                </div>
            </div>
        </div>

        <div className='flex border flex-col gap-4 rounded-sm p-4 mt-4 md:mt-8'>
            <h2 className='text-lg'>{t("order_and_pickup.o_overview_orders.denominations")}</h2>

            <div className='border'>
                <div className='grid grid-cols-3 bg-slate-100 py-2 px-4 border-b'>
                    <i className='uppercase text-sm font-medium text-muted-foreground'></i>
                    <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("order_and_pickup.o_overview_orders.amount")}</p>
                    <p className='uppercase text-sm font-medium text-muted-foreground text-right'>{t("order_and_pickup.o_overview_orders.count")}</p>
                </div>
                <div className='flex flex-col gap-4 p-4'>
                    <Coupure coupures={detail} cn={detail_info.cn.toLowerCase() as 'c' | 'n'} show_total/>
                </div>
            </div>
        </div>
    </div>
    }
    </>
    )
}

export default Detail