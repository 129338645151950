import React, { useState } from 'react'
import { AlertDialog, AlertDialogTrigger, AlertDialogContent, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from '../../../../../components/ui/alert-dialog'
import useAuth from '../../../../../hooks/useAuth';
import { useMutation } from '@tanstack/react-query';
import axios from '../../../../../lib/axios';
import toast from 'react-hot-toast';

type ActivePopupProps = {
    trigger?: React.ReactNode;
    selected_ids?: number[];
    onSuccess?: () => void;
}

const ActivePopup = ({trigger, selected_ids, onSuccess}:ActivePopupProps) => {
  const { auth } = useAuth()
  const [open, setopen] = useState(false);

  const { mutate, isLoading } = useMutation({
    mutationFn: async () => {
      try {
        if(!Array.isArray(selected_ids)){ return }

        const promises = await Promise.all(selected_ids.map(async (id)=>{
          return await axios.put('devies/modify', {
            token: auth.data?.token,
            deviesid: id,
            active: true
          })
        }))

        await Promise.all(promises)
        
        toast.success('Deze valuta(s) zijn nu inactief', { id:'devies-currency' })
        setopen(false);
        onSuccess&&onSuccess()
      } catch (error) {
        console.log(error)
        toast.error('Er is een fout opgetreden bij het inactief maken van deze valuta(s)', { id:'devies-currency' })
      }
    }
  })

  return (
    <AlertDialog open={open} onOpenChange={(o)=>setopen(o)}>
        {trigger && <AlertDialogTrigger asChild>{trigger}</AlertDialogTrigger>}
        <AlertDialogContent>
            <AlertDialogTitle>Weet je zeker dat je deze valuta(s) actief wilt maken?</AlertDialogTitle>
            <AlertDialogDescription>Dit kan niet ongedaan gemaakt worden.</AlertDialogDescription>

            <AlertDialogFooter>
                <AlertDialogCancel>Annuleren</AlertDialogCancel>
                <AlertDialogAction disabled={isLoading} onClick={()=>{mutate()}}>Actief maken</AlertDialogAction>
            </AlertDialogFooter>
        </AlertDialogContent>
    </AlertDialog>
  )
}

export default ActivePopup