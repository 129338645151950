import { combineReducers } from 'redux'

import rolesReducer from './rolesReducer';
import partnersReducer from './partnersReducer';
import customersReducer from './customersReducer';
import filtersReducer from './filtersReducer';
import orderstateReducer from './orderstateReducer';
import orderdraftstateReducer from './orderdraftstateReducer';
import coupureReducer from './coupureReducer';
import complaintscodeReducer from './complaintscodeReducer';
import roleReducer from './roleReducer';
import unfilteredCustomersReducer from './unfiltered-customersReducer';

const reducers = combineReducers({
    roles: rolesReducer,
    role: roleReducer,
    partners: partnersReducer,
    customers: customersReducer,
    filters: filtersReducer,
    orderstate: orderstateReducer,
    orderdraftstate: orderdraftstateReducer,
    coupure: coupureReducer,
    complaintcode: complaintscodeReducer,

    unfilteredCustomers: unfilteredCustomersReducer,
})

export default reducers; 