import React from 'react'
import { formatCashAmount, isInterface } from '../../../lib/utils'
import { convertToDate } from '../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type Props = {
    colli: Colli
}

const ColliDetail : React.FC<Props> = ({colli}) => {
    const {t} = useTranslation()
  return (
    <>
    {isInterface(colli, {} as Colli) && 
        <div className='grid grid-cols-[repeat(auto-fit,minmax(250px,1fr))] gap-2'>
            <div className='flex gap-2'>
                <p className='font-semibold'>Id</p>
                <p className='text-neutral-500'>{colli.id}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.keepsafe")}</p>
                <p className='text-neutral-500'>{colli.mkpsafe}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.mkpsafe")}</p>
                <p className='text-neutral-500'>{colli.isMkpsafe ? t("components.o_colli.o_detail.yes") : t("components.o_colli.o_detail.no")}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.department")}</p>
                <p className='text-neutral-500'>{colli.department}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.amount")}</p>
                <p className='text-neutral-500'>{formatCashAmount(colli.amount)}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.type")}</p>
                <p className='text-neutral-500'>{colli.cn.toLowerCase() === 'c' ? t("components.o_colli.o_detail.coins") : t("components.o_colli.o_detail.notes")}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.weight")}</p>
                <p className='text-neutral-500'>{colli.weight}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.vault")}</p>
                <p className='text-neutral-500'>{colli.vault}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.date_created")}</p>
                <p className='text-neutral-500'>{convertToDate(colli.dtcreation.toString()) ? format(convertToDate(colli.dtcreation.toString()) || new Date, "dd/MM/yyyy") : ''}</p>
            </div>
            <div className='flex gap-2'>
                <p className='font-semibold'>{t("components.o_colli.o_detail.date_edit")}</p>
                <p className='text-neutral-500'>{convertToDate(colli.dtmodif.toString()) ? format(convertToDate(colli.dtmodif.toString()) || new Date, "dd/MM/yyyy") : ''}</p>
            </div>
        </div>
    }
    </>
  )
}

export default ColliDetail