import React, { useState } from 'react'
import { Sheet, SheetContent } from '../../ui/sheet';
import { Label } from '../../ui/label';
import { Textarea } from '../../ui/textarea';
import { Button } from '../../ui/button';
import { Loader2, MonitorUp, Trash2 } from 'lucide-react';
import html2canvas from 'html2canvas';
import toast, { Toaster } from 'react-hot-toast';
import axios from '../../../lib/axios';
import { useTranslation } from 'react-i18next';
import useAuth from '../../../hooks/useAuth';
import { useLocation } from 'react-router-dom';

type Props = {
    //children: React.ReactNode
}

const ErrorBoundary : React.FC<Props> = () => {
    const {t} = useTranslation()
    const {auth} = useAuth()

    const [show_error, setshow_error] = useState(false);
    const [error_info, seterror_info] = useState('');

    const [fb_description, setfb_description] = useState('');
    const [fb_image, setfb_image] = useState<string | null>(null);

    window.onerror = function (message, source, lineno, colno, error) {
        // You can log the error here or send it to a logging service
        console.log(error);
        setshow_error(true)
        console.error("Error catched in ErrorBoundary");
        seterror_info(error?.stack || '')
        // Return true to prevent the default browser error handling
        return true;
    };

    const takeScreenshot = () => {
        const element = document.getElementById('root'); // Replace with the ID of the element you want to capture
        
        if (element) {
          html2canvas(element).then((canvas) => {
            const dataURL = canvas.toDataURL('image/png');
            setfb_image(dataURL)
          });
        }
    };

    const [isLoading, setisLoading] = useState(false);
    const sendFeedback = async () => {
        try {
            const username = auth.data?.username || ''
            setisLoading(true)
            const {data} = await axios.post(`https://apinirah.qastan.be/Nirah/ticket/create`, {
                token: 'B0E269B3150B63F81C38A712F8F6AC6161293E3E7473227ED297C628E779D1C1EDA620B42B158E876E84E517B094F4D530FBEDC66B120AB6000E4C3C0000C938F35BD0FABC846B2CE42591AD8FACEC82EBAB51971AA05317F9294ED957187A8A850797DF797F47A53F954EE2F7B37A4287B57881F56853EFFCF33C3C89A0031207916F5FC03F81CD05756017EAAD0F378DFE60B4873E07E59958221D5FEAF5B8828994D3E49E44BFD96C7FC4C2064989',
                subject: `Loomis Portaal`,
                description: `
                    URL ${window.location.pathname} \n\n\n
                    USER ${username} \n\n\n
                    DESCRIPTION ${fb_description}
                    ERROR ${error_info} \n\n\n
                    IMAGE ${fb_image}`,
                assigned: 60,
                company: 'FIT'
            })
            console.log(data)
            if(data.errorcode!==0){throw new Error(data.message)}
            toast.success(t("components.o_error.toast_success"))
            setshow_error(false)
        } catch (error) {
            console.log(error)
            toast.error(t("components.o_error.toast_error"))
        } finally {
            setisLoading(false)
        }
    }

  return (
    <>  
        <Toaster />
        <Sheet open={true} onOpenChange={(v)=>{setshow_error(v)}}>
                <SheetContent className='flex flex-col justify-between' hideBlur>
                    <div>
                        <div className='mb-4 border-b pb-4'>
                            <h3 className='text-xl'>{t("components.o_error.title")}</h3>
                        </div>

                        <div className='mb-4 pb-4 border-b'>
                            <p className='text-red-600 font-medium text-center'>{t("components.o_error.description")}</p>
                        </div>

                        <div className='flex flex-col gap-4'>
                            <div>
                                <Label>{t("components.o_error.label_description")}</Label>
                                <Textarea value={fb_description} onChange={({currentTarget})=>{setfb_description(currentTarget.value)}} rows={10} className='h-full max-h-[250px]'/>
                            </div>

                            {/* {fb_image === null && 
                            <div>
                                <p className='text-sm mb-1 font-medium'>{t("components.o_error.label_screenshot")}</p>
                                <Button onClick={()=>{takeScreenshot()}} variant='outline' className='flex gap-2 w-full'><MonitorUp className='w-4'/> {t("components.o_error.button_screenshot")}</Button>
                            </div>
                            }

                            {fb_image !== null &&
                            <div>
                                <p className='text-sm mb-1 font-medium'>{t("components.o_error.label_added_screenshot")}</p>
                                <div className='relative'>
                                    <button onClick={()=>{setfb_image(null)}} className='hover:bg-red-50 hover:border-red-100 absolute -translate-y-3 translate-x-3 right-0 top-0 bg-white shadow-md border flex justify-center items-center w-12 h-12 rounded-full text-red-700'><Trash2 className='w-5'/></button>
                                    <img className='border rounded-md overflow-hidden max-h-[200px] w-full object-cover' src={fb_image} alt="Screenshot" />
                                </div>
                            </div>
                            } */}

                        </div>
                    </div>

                    <Button onClick={()=>{sendFeedback()}} disabled={fb_description === '' || isLoading}>{isLoading ? <Loader2 className='animate-spin'/> : t("components.o_error.button_send")}</Button>
                </SheetContent>
            </Sheet>
    </>
  )
}

export default ErrorBoundary