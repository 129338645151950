import { useState, useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import { useNavigate, useLocation, Link } from "react-router-dom"
import axios from "../../../lib/axios";
import { Trans, useTranslation } from "react-i18next"
import useUserStore from "../../../zustand/useUserStore";
import { CircleDollarSign, Banknote, Landmark, ShieldCheck, PiggyBank, Loader2 } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "../../../components/ui/select";
import { Label } from "../../../components/ui/label";
import { Input } from "../../../components/ui/input";
import logoSmall from "../../../assets/images/logoSmall.jpg";
import { Checkbox } from "../../../components/ui/checkbox";
import { useQuery } from "@tanstack/react-query";
import { Toaster, toast } from "react-hot-toast";
import { z } from "zod";

const ForgotPassword = () => {
    const navigate = useNavigate()
    const { t, i18n } = useTranslation()

    const [email, setemail] = useState('');

    const changeLanguage = (language:string) => {
        i18n.changeLanguage(language)
    }

    async function fetchRequest() {
        return await axios.post('password/request', { email:email.toLowerCase() })
    }

    const { refetch, isFetching } = useQuery(['request_password'], fetchRequest, {
        enabled: false,
        refetchOnWindowFocus: false,
        onSuccess(data) {
            if(data.data.errorcode === 0){
                toast.success(t("forgot_password.toast_success"))
            }
            else {
                // @ts-ignore
                toast.error(t(`errors.error-${data.data.errorcode}`));
            }
        }
    })

    const requestPassword = () => {
        refetch()
    }

    function isComplete() {
        try {
            const s = z.object({
                email: z.string().email()
            })
            s.parse({email:email})
            return true
        } catch (e) {
            return false
        }
    }

  return (
    <>
        <Toaster />
        <div className="fixed top-0 h-12 w-full bg-slate-800"></div>
        <img className="fixed mt-6 h-12 left-1/2 -translate-x-1/2" src={logoSmall} alt="logo"/>

        <div className="h-full grid grid-cols-1 md:grid-cols-2">
            <div className="hidden md:flex bg-gradient-to-tr from-[#EAA792] to-primary flex-col justify-between p-10">
                <i></i>

                <h1 className="text-right text-white font-bold text-4xl">The future of cash<br></br>management is here</h1>

                <ul className="flex justify-between">
                    <li><CircleDollarSign className="h-12 w-12 text-white"/></li>
                    <li><Banknote className="h-12 w-12 text-white"/></li>
                    <li><ShieldCheck className="h-12 w-12 text-white"/></li>
                    <li><Landmark className="h-12 w-12 text-white"/></li>
                    <li><PiggyBank className="h-12 w-12 text-white"/></li>
                </ul>
            </div>
            <div className="bg-slate-100 flex flex-col items-center justify-between mt-12 p-12">
                <div className="w-full flex justify-end">
                    <Select value={i18n.language} onValueChange={(v)=> changeLanguage(v) }>
                        <SelectTrigger className="w-fit">
                            <SelectValue placeholder="Selecteer een taal"/>
                        </SelectTrigger>
                        <SelectContent>
                            <SelectGroup>
                                <SelectItem value="nl">Nederlands</SelectItem>
                                <SelectItem value="fr">Francais</SelectItem>
                                <SelectItem value="en">English</SelectItem>
                            </SelectGroup>
                        </SelectContent>
                    </Select>
                </div>

                <div className="flex flex-col gap-4 w-full">
                    <div>
                        <Button className="-ml-4" variant="link" onClick={()=>{ navigate('/') }}>{t("forgot_password.back")}</Button>
                        <h1 className="text-2xl font-bold">{t("forgot_password.title")}</h1>
                        <p>{t("forgot_password.description")}</p>
                    </div>
                    
                    <div>
                        <Label htmlFor="email">{t("forgot_password.email")}</Label>
                        <Input onChange={(e)=> { setemail(e.currentTarget.value) }} className="bg-white" type="email" id="email" placeholder={t("forgot_password.email")||""} />
                    </div>   
                                    
                    <Button disabled={isFetching || !isComplete()} onClick={requestPassword} className="w-fit">{
                        isFetching ?
                        <Loader2 className='animate-spin'/>
                        :
                        t("forgot_password.button")
                    }</Button>
                </div>

                <i className="h-12 w-full"></i>
                
            </div>
        </div>
        </>
  )
}

export default ForgotPassword