import React, { HTMLAttributes } from "react";

interface TabProps extends HTMLAttributes<HTMLDivElement> {
    value?: string;
    onValueChange?: (value:string) => void;
}

const Tab : React.FC<TabProps> = ({onValueChange, value, children, ...props}) => {
    const modifiedChildren = React.Children.map(children, (child:any, index) => {
        if(!child) return;

        return React.cloneElement(child, {
            isActive: value === child.props.value,
            onValueChange
        })
    })

    return (
        <div {...props} className='inline-flex h-10 items-center justify-center rounded-md bg-muted p-1 text-muted-foreground'>
            {modifiedChildren}
        </div>
    )
}

interface TabItemProps extends HTMLAttributes<HTMLButtonElement> {
    value?: string;
    isActive?: boolean;
    onValueChange?: (value:string) => void;
}

const TabItem : React.FC<TabItemProps> = ({isActive, value, children, onValueChange, ...props}) => {
    
    const handleClick = () => {
        if(value && onValueChange) {
            onValueChange(value)
        }
    }

    return (
        <button {...props} onClick={()=>{ handleClick() }} data-state={isActive ? 'active' : 'inactive'} className='inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-background data-[state=active]:text-foreground data-[state=active]:shadow-sm'>
            {children}
        </button>
    )
}

export { Tab, TabItem }