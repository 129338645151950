import { ActionType, Action } from "../action-types";

const initialState:Role[] = []

const roleReducer = (state:Role[] = initialState, action:Action) => {
    switch (action.type) {
        case ActionType.SET_ROLE:
            state = action.payload
            return state;
        default:
            return state;
    }
}

export default roleReducer;