import React, { useEffect, useState } from 'react'
import { Label } from '../../../../../components/ui/label'
import { Combobox } from '../../../../../components/ui/combobox'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux';
import { Toaster, toast } from 'react-hot-toast';
import axios from '../../../../../lib/axios'
import useAuth from '../../../../../hooks/useAuth';
import { Input } from '../../../../../components/ui/input';
import { Button } from '../../../../../components/ui/button';
import PlannedStopsCalendar from '../../../../../components/portal/stop/PlannedStopsCalendar';
import { format, isBefore, isSameMonth, isToday, isValid, subMonths } from 'date-fns';
import NewKeepsafe from '../../../../../components/portal/announcement/NewKeepsafe';
import { DayObject } from '../../../../../components/portal/stop/calendar/Calendar';
import { combineCNValues, formatCashAmount, isCoupureCN } from '../../../../../lib/utils';
import { Loader2, Search, Trash } from 'lucide-react';
import { Tooltip, TooltipProvider, TooltipTrigger, TooltipContent } from '../../../../../components/ui/tooltip';
import AddKeepsafe from '../../../../../components/portal/announcement/AddKeepsafe';
import { AlertDialog, AlertDialogContent, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog';
import { AlertDialogCancel } from '@radix-ui/react-alert-dialog';
import Coupure from '../../../../../components/portal/coupure/Coupure';
import { calculateTotal } from '../../../../../lib/coupure';
import TooltipForce from '../../../../../components/ui/tooltip-force';
import EditKeepsafe from '../../../../../components/portal/announcement/EditKeepsafe';
import { convertToDate, formatDateToYYYYMMDD } from '../../../../../lib/date';
import { useQuery } from '@tanstack/react-query';
import { isKeepsafeMother } from '../../../../../lib/general/keepsafe';
import { getCustomerList } from '../../../../../lib/fetch/customer';
import { useTranslation } from 'react-i18next';
import ReactGA from "react-ga4";
import { createTicket } from '../../../../../lib/ticket/create-ticket';
import { CONFIG } from '../../../../../config';

type Props = {
  defaultDraft?: AnnouncementDraftSearch
}

const Manual : React.FC<Props> = ({defaultDraft}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const partners = useSelector((state:RootState)=>state.partners);

    const [edit_draft, setedit_draft] = useState<AnnouncementDraftSearch | null>(null);

    const [selected_partner, setselected_partner] = useState<string | null>(null);
    const [selected_customer, setselected_customer] = useState<string | null>(null);
    const [customers_list, setcustomers_list] = useState<Customer[] | null>();
    const [selectedDate, setselectedDate] = useState<Date | undefined>();
    const [reference, setreference] = useState<string>('');

    const [selectedEvent, setselectedEvent] = useState<DayObject | null>(null);

    const [keepsafes, setkeepsafes] = useState<AnnouncementKeepsafe[]>([]);
    
    const { refetch } = useQuery<AnnouncementDraftSearch[] | null>({
      queryKey:['announcement_concept'], 
      queryFn: async () => {
          try {
              const object = {
                  token: auth.data?.token,
                  startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                  enddate: formatDateToYYYYMMDD(new Date()),
                  partner: auth.data?.partner,
                  searchby: 'C',
                  status: '001'
              }
              const { data } = await axios.post(`announcement/draft/search`, object)
              if(data.errorcode === 0 && Array.isArray(data.object.announcementdraft)){
                  return data.object.announcementdraft;
              }
              else {
                //if(data.errorcode === 0){ toast.error('Geen concepten gevonden'); return null; }
                console.log("No concepts")
              }
          } catch (error) {
            toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error"))              
          }
          return null;
      }
    })

    const { refetch:refetchPrevious } = useQuery<AnnouncementDraftSearch[] | null>({
      queryKey: ['announcement_previous'],
      queryFn: async () => {
          try {
              const object = {
                  token: auth.data?.token,
                  startdate: formatDateToYYYYMMDD(subMonths(new Date(), 1)),
                  enddate: formatDateToYYYYMMDD(new Date()),
                  partner: auth.data?.partner,
                  status: '002',
                  searchby: 'C'
              }
              const { data } = await axios.post(`announcement/draft/search`, object)
              if(data.errorcode !== 0) { throw new Error(data.message)}
              else {
                  if(!Array.isArray(data.object.announcementdraft)) { return [] }
                  return data.object.announcementdraft
              }
          } catch (error) {
              toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p2"))
          }
      },
      enabled:false
    })

    useEffect(()=>{
      if(defaultDraft) {
        const getDetail = async () => {
          try {
            const {data} = await axios.post(`announcement/draft/detail`, { token: auth.data?.token, id: defaultDraft.id})
            if(data.errorcode === 0) { 
              setselectedEvent({date:convertToDate(defaultDraft.date) || new Date(), isSameMonth:isSameMonth(new Date(), convertToDate(defaultDraft.date) || new Date()), isToday:isToday(convertToDate(defaultDraft.date) || new Date()), isSelected:true, isBeforeToday:isBefore(convertToDate(defaultDraft.date) || new Date(), new Date()), day:format(convertToDate(defaultDraft.date) || new Date(), "d"), events:[]})
              let ks:AnnouncementKeepsafe = {keepsafe:defaultDraft.keepsafe,detail:[]}
              ks.detail = data.object.detail;
              setkeepsafes([ks])
            }
            else { console.log(data) }
          } catch (error) {
            console.log(error);
          }
        }
        setedit_draft(defaultDraft)
        getDetail();

        setselected_customer(defaultDraft.relnr.toString())
        setselectedDate(convertToDate(defaultDraft.date) || undefined) 
        setreference(defaultDraft.reference) 
      }
    }, [defaultDraft])

    useEffect(()=>{
      if (auth.data?.partner.split(",").length === 1) {
        handlePickPartner(auth.data.partner.split(",")[0])
      }
    }, [])

    const handlePickPartner = async (relnr:string) => {
        try {
          if(selected_partner!==relnr){setselected_customer('clear')}
          setselected_partner(relnr);
          setcustomers_list(null);
    
          console.log(relnr)

          const list = await getCustomerList(auth, relnr)
          console.log(list)
          if(list){ setcustomers_list(list) }
          if(list.length === 1){ setselected_customer(list[0].relnr.toString()) }

        } catch (error) {
          console.log(error)
          toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_wrong"))
        }
    }

    const addPochette = (keepsafe:AnnouncementKeepsafe, pochette:AnnouncemenDetail) => {
      let tmp = [...keepsafes];
      const index = tmp.findIndex(i => i.keepsafe === keepsafe.keepsafe);
      if(index > -1){
        if(!tmp[index].detail) { tmp[index].detail = []}

        const alreadyExists = tmp[index].detail?.find(i=>i.bag.toLowerCase() === pochette.bag.toLowerCase())
        if(alreadyExists) { toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_exists")); return; }

        tmp[index].detail?.push(pochette)
        setkeepsafes(tmp)
        toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success"))
      }
      else {
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p3"))
      }
    }

    const deleteKeepsafe = (keepsafe:AnnouncementKeepsafe) => {
      let tmp = [...keepsafes]
      const index = tmp.indexOf(keepsafe)
      if(index > -1){
        tmp.splice(index, 1)
        setkeepsafes(tmp)
        toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p2"))
      }
      else {
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p4"))
      }
    }

    const deletePochette = (keepsafe:AnnouncementKeepsafe, detail:AnnouncemenDetail) => {
      let tmp = [...keepsafes]
      const index = tmp.indexOf(keepsafe)
      if(index > -1 && tmp[index].detail){
        const sub_index = tmp[index].detail?.indexOf(detail);
        if(sub_index === undefined) { toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p5")); return; }
        if(sub_index > -1){
          tmp[index].detail?.splice(sub_index, 1)
          setkeepsafes(tmp)
          toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p3"))
        }
        else {
          toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p5"))
        }
      }
      else {
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p5"))
      }
    }

    const editKeepsafe = (og:AnnouncementKeepsafe, nw:AnnouncementKeepsafe) => {
      let tmp = [...keepsafes];
      const index = tmp.indexOf(og);
      if(index > -1){
        tmp[index].keepsafe = nw.keepsafe;
        tmp[index].detail = nw.detail;
        setkeepsafes(tmp)
      }
      else {
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_found"))
      }
    }

    const editPochette = (ks:AnnouncementKeepsafe, dt:AnnouncemenDetail, nw:AnnouncemenDetail) => {
      let tmp = [...keepsafes];
      const index = tmp.indexOf(ks);
      if(index > -1){
        const sub_index = tmp[index].detail?.indexOf(dt);
        if(sub_index === undefined){return;}
        if(sub_index > -1){
          // @ts-ignore
          tmp[index].detail[sub_index].bag = nw.bag; tmp[index].detail[sub_index].coupures = nw.coupures;
          
          setkeepsafes(tmp)
        }
        else {
          toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_found_p2"))
        }
      }
      else {
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_found"))
      }
    }

    const [is_saving, setis_saving] = useState(false);
    const onSave = async () => {
      try {
        setis_saving(true)
        if(!selectedDate) { toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_date"), {duration:5000}); return;}
        let status:{fail:AnnouncementKeepsafe[], success:AnnouncementKeepsafe[]} = {fail:[], success:[]}
        let results:any = []

        const promises = keepsafes.map(async (keepsafe, index) => {
          try {
            
            if(!selectedDate) { toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_date"), { id:'saveDraft', duration:5000}); return; }
            if(!isValid(selectedDate)){ toast.error(`${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p10")}`, { id:'saveDraft', duration:5000 }); return; }
            if(isBefore(selectedDate, new Date)){ toast.error(`${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p11")}`, { id:'saveDraft', duration:5000 }); return; }
            const object = {
              token: auth.data?.token,
              relnr: selected_customer,
              cn: isKeepsafeMother(keepsafe) ? 'n' : isCoupureCN(keepsafe.detail[0].coupures),
              date: formatDateToYYYYMMDD(selectedDate),
              reference: reference,
              ...keepsafe
            }
            const { data } = await axios.post(`announcement/draft/create`, object)
            if(data.errorcode === 0) {
              results.push(data.object)
              status.success.push(keepsafe)
            }
            else {
              throw new Error(data.message)
            }
          } catch (err) {
            status.fail.push(keepsafe)
            console.log(err)
          }
        })

        toast.loading(t("order_and_pickup.o_announce_pickup.o_manual.toast_loading"), { id:'saveDraft' })

        await Promise.all(promises);

        //Concept keepsafes saved
        //if(status.success.length === keepsafes.length) { refetch(); toast.success('Concept opgeslagen', { id:'saveDraft', duration:5000 }) }
        if(status.fail.length > 0) { status.fail.forEach(keepsafe => toast.error(`${keepsafe.keepsafe} ${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p6")}`, { duration:5000 })) }

        const promiseValidating = results.map(async (obj:any, index:number) => {
          try {
            const {data} = await axios.post(`announcement/draft/validate`, { token:auth.data?.token, id:obj.id })
            if(data.errorcode!==0){throw new Error(data.message)}
            console.log(data, obj)
          } catch (error) {
            toast.error(`${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p7")} ${obj.id} ${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p6")}`)
          }
        })

        await Promise.all(promiseValidating);

        refetchPrevious()
        toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p4"), { id:'saveDraft' })
        setkeepsafes([])
        ReactGA.event({
          category: 'Announcements',
          action: 'announcement_success',
        })
      } catch (error) {
        ReactGA.event({
          category: 'Announcements',
          action: 'announcement_failed',
        })
      } finally {
        setis_saving(false)
      }
    }

    const onSaveEditDraft = async () => {
      try {
        if(!selectedDate) { toast.error('No date selected!', {duration:5000}); return;}

        toast.loading(t("order_and_pickup.o_announce_pickup.o_manual.toast_loading_p2"), { id:'onSaveEditDraft' })
        const {data} = await axios.post(`announcement/draft/update`, {
          token: auth.data?.token,
          id: edit_draft?.id,
          relnr: selected_customer,
          cn: isCoupureCN(keepsafes[0].detail[0].coupures),
          date: formatDateToYYYYMMDD(selectedDate),
          reference: reference,
          ...keepsafes[0]
        })

        if(data.errorcode === 0){
          toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p5"), { id:'onSaveEditDraft' })
          setkeepsafes([])
          setedit_draft(null)
          refetch();
          ReactGA.event({
            category: 'Announcements',
            action: 'announcement_update_success',
          })
        } else {
          console.log(data);
          toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p8"), { id:'onSaveEditDraft' })
        }

        
      } catch (error) {
        console.log(error);
        toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p8"), { id:'onSaveEditDraft' })
        ReactGA.event({
          category: 'Announcements',
          action: 'announcement_update_failed',
        })
      }
    }

    const onSaveDraft = async () => {
      let status:{fail:AnnouncementKeepsafe[], success:AnnouncementKeepsafe[]} = {fail:[], success:[]}
      const promises = keepsafes.map(async (keepsafe, index) => {
        try {
          
          if(!selectedDate) { toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_no_date"), { id:'saveDraft', duration:5000}); return;}
          const object = {
            token: auth.data?.token,
            relnr: selected_customer,
            cn: isKeepsafeMother(keepsafe) ? 'n' : isCoupureCN(keepsafe.detail[0].coupures),
            date: formatDateToYYYYMMDD(selectedDate),
            reference: reference,
            ...keepsafe
          }
          const { data } = await axios.post(`announcement/draft/create`, object)
          if(data.errorcode === 0) {
            status.success.push(keepsafe)
            ReactGA.event({
              category: 'Announcements',
              action: 'announcement_draft_success',
            })
          }
          else {
            throw new Error(data.message)
          }
        } catch (err) {
          status.fail.push(keepsafe)
          console.log(err)
          ReactGA.event({
            category: 'Announcements',
            action: 'announcement_draft_failed',
          })
        }
      })

      toast.loading(t("order_and_pickup.o_announce_pickup.o_manual.toast_loading_p3"), { id:'saveDraft' })

      await Promise.all(promises);

      if(status.success.length === keepsafes.length) { refetch(); toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p6"), { id:'saveDraft', duration:5000 }) }
      else { 
        status.fail.forEach(keepsafe => toast.error(`${keepsafe.keepsafe} ${t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p6")}`, { duration:5000 }))
      }
      setkeepsafes([])
    }

    function isRequiredReference () {
      if(!selected_partner){ return false }
      const includes = CONFIG.order_and_collect.collect.requiredReference_relnrs.includes(selected_partner)
      return includes
    }
  
    function isComplete() {
      const requiredRef = isRequiredReference()
      if(requiredRef){
        if(reference.length < 1){ return false }
      }
      if(!selected_partner){ return false; }
      if(!selected_customer){ return false; }

      return true
    }

    let partnersArr = Array.isArray(partners) ? (partners.map((item) => ({value:item.relnr, label:item.name}))) : []
    partnersArr.length > 0 && partnersArr.unshift({value:parseFloat(process.env.REACT_APP_RDM_NUM!), label:t("components.o_partners_ddl.no_partner")})

    let customersArr = Array.isArray(customers_list) ? customers_list.map((item)=> ({value:item.relnr, label:`${item.name} - ${item.clientcode}`})) : []



  return (
    <>
    <Toaster />
    <div className='grid grid-cols-[repeat(auto-fit,minmax(300px,1fr))] gap-4'>
        <div>
            <Label htmlFor='partner'>{t("order_and_pickup.o_announce_pickup.o_manual.partner")}</Label>
            <Combobox disabled={partners?.length === 1} id='partner' selectedValue={selected_partner ? selected_partner : undefined} onValueChange={(value) => { handlePickPartner((value.value).toString()) }} options={partnersArr} placeholder={t("order_and_pickup.o_announce_pickup.o_manual.partner_placeholder") || ""} input_placeholder={t("order_and_pickup.o_announce_pickup.o_manual.search_placeholder") || ""}/>
        </div>

        <div>
            <Label htmlFor='end_customer'>{t("order_and_pickup.o_announce_pickup.o_manual.customer")}</Label>
            <Combobox disabled={!Array.isArray(customers_list)} selectedValue={selected_customer ? selected_customer : undefined} id='end_customer' onValueChange={(value) => { setselected_customer(value.value.toString()) }} options={customersArr} placeholder={t("order_and_pickup.o_announce_pickup.o_manual.customer_placeholder") || ""} input_placeholder={t("order_and_pickup.o_announce_pickup.o_manual.search_placeholder") || ""}/>
        </div>

        <div>
            <Label>{t("order_and_pickup.o_announce_pickup.o_manual.date")}</Label>
            <PlannedStopsCalendar page='announce' onSelectDay={(v)=>{setselectedEvent(v)}} disabled={!(selected_partner !== null && selected_customer !== null) || selected_customer === 'clear'} partnerid={selected_partner ? parseFloat(selected_partner) : 0} clientrelnr={selected_customer ? parseFloat(selected_customer) : 0} setselectedDate={setselectedDate} selectedDate={selectedDate}/>
        </div>

        <div>
            <Label htmlFor='reference' className='flex items-center gap-1 mb-1'>{t("order_and_pickup.o_announce_pickup.o_manual.reference")}{isRequiredReference() && <span className='text-primary'>*</span>}</Label>
            <Input value={reference} onChange={({currentTarget})=>{ setreference(currentTarget.value) }}/>
        </div>
    </div>
    
    {(selectedEvent && !edit_draft) && <NewKeepsafe onSave={(keepsafe)=>{if(!keepsafes.find(i => i.keepsafe === keepsafe.keepsafe)){setkeepsafes(prev => [...prev, keepsafe]); toast.success(t("order_and_pickup.o_announce_pickup.o_manual.toast_success_p7"))} else{ toast.error(t("order_and_pickup.o_announce_pickup.o_manual.toast_error_p9")) } }} cn={combineCNValues(selectedEvent?.events).toLowerCase()} selectedDate={selectedEvent.date}/>}

    {(keepsafes && selectedEvent) && Array.isArray(keepsafes) && keepsafes.length > 0 &&
    <div className='mt-4'>
      <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_manual.subtitle")} ({keepsafes.length})</h2>

      <div className='flex flex-col gap-2'>
      {keepsafes.map((keepsafe, index) => {
        return (
          <div className='border p-2 rounded-sm duration-200 hover:border-primary' key={index}>
            <div className='grid grid-cols-3'>

              <div className='flex items-center gap-2'>
                {(keepsafe.detail.length < 1) && <button onClick={()=>{deleteKeepsafe(keepsafe)}} className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-red-500'><Trash className='w-5'/></button>} 
                <p>{t("order_and_pickup.o_announce_pickup.o_manual.serial")}: <span className='font-medium'>{keepsafe.keepsafe}</span></p>
                {isKeepsafeMother(keepsafe) && <AddKeepsafe onSave={(pochette)=>{ addPochette(keepsafe, pochette) }} cn={'n'} mother_keepsafe={keepsafe}/>}

                {/* Hardcoded op cn => Lander zei dat kind enkel N kan zijn zie state vanboven */}
                {/* {!keepsafe.coupures && <AddKeepsafe onSave={(pochette)=>{ addPochette(keepsafe, pochette) }} cn={combineCNValues(selectedEvent?.events).toLowerCase()} mother_keepsafe={keepsafe}/>} */}
              </div>

              {!isKeepsafeMother(keepsafe) &&
              <>
              <div className='flex items-center'>
                <p>{t("order_and_pickup.o_announce_pickup.o_manual.contents")}: <span className='font-medium'>€{formatCashAmount(calculateTotal(keepsafe.detail[0].coupures, isCoupureCN(keepsafe.detail[0].coupures)))}</span></p>
              </div>

              <div className='flex justify-end'>
                <div className='w-fit grid grid-cols-3'>
                  <TooltipProvider delayDuration={0}>
                    <ActionButton coupure={keepsafe.detail[0].coupures} variant='view'/>
                    <ActionButton onEdit={(newObject)=>{editKeepsafe(keepsafe, {keepsafe:newObject.barcode, detail:[{bag:newObject.barcode, coupures:newObject.coupures}]})}} keepsafe={keepsafe} variant='edit'/>
                    <ActionButton onDelete={()=>{deleteKeepsafe(keepsafe)}} variant='delete'/>
                  </TooltipProvider>
                </div>
              </div>
              </>
              }

            </div>
            
            {keepsafe.detail && 
            <div className='flex flex-col gap-2'>
            {isKeepsafeMother(keepsafe) 
            &&
            <>
            {keepsafe.detail.map((detail, ind) => {
              return (
                <div id="card" className='border p-2 rounded-sm grid grid-cols-3 items-center' key={ind}>
                  <p>{t("order_and_pickup.o_announce_pickup.o_manual.serial")}: <span className='font-medium'>{detail.bag}</span></p>
                  <p>{t("order_and_pickup.o_announce_pickup.o_manual.contents")}: <span className='font-medium'>€{formatCashAmount(calculateTotal(detail.coupures, isCoupureCN(detail.coupures)))}</span></p>
                  <div className='flex justify-end'>
                    <TooltipProvider delayDuration={0}>
                      <ActionButton coupure={detail.coupures} variant='view'/>
                      <ActionButton onEdit={(newObject)=>{editPochette(keepsafe, detail, {bag:newObject.barcode, coupures:newObject.coupures})}} keepsafe={{keepsafe:detail.bag, detail:[{bag:detail.bag,coupures:detail.coupures}]}} variant='edit'/>
                      <ActionButton onDelete={()=>{deletePochette(keepsafe, detail)}} variant='delete'/>
                    </TooltipProvider>
                  </div>
                </div>
              )
            })}
            </> 

            }     
            </div>
            }
          </div>
        )
      })}
      </div>

      <div className='flex mt-4 pt-4 border-t'>
        {!edit_draft
        ? <Button disabled={!isComplete() || is_saving} onClick={()=>{onSave()}}>{is_saving ? <Loader2 className='animate-spin'/> : t("order_and_pickup.o_announce_pickup.o_manual.button_save")}</Button>
        : <><Button disabled={!isComplete()} onClick={()=>{onSaveEditDraft()}}>{t("order_and_pickup.o_announce_pickup.o_manual.button_save_edit")}</Button><Button onClick={()=>{setedit_draft(null); setkeepsafes([])}} variant='ghost'>{t("order_and_pickup.o_announce_pickup.o_manual.button_cancel")}</Button></>
        }
        
        {!edit_draft && <Button onClick={()=>{onSaveDraft()}} variant='link'>{t("order_and_pickup.o_announce_pickup.o_manual.button_concept")}</Button>}
      </div>
    </div>
    }
    </>
  )
}

type ActionButtonProps = {
  variant: 'view' | 'edit' | 'delete',
  coupure?: CoupureInput[],
  onDelete?: () => void;
  onEdit?: (object:{barcode:string, coupures:CoupureInput[]}) => void;
  keepsafe?: AnnouncementKeepsafe
}

const ActionButton : React.FC<ActionButtonProps> = ({variant, coupure, onDelete, keepsafe, onEdit}) => {
  const {t} = useTranslation()
  return (
    <Tooltip>
      {variant === 'view' && <><ActionView coupure={coupure}/></>}
      {variant === 'edit' && <><EditKeepsafe onSave={(object)=>{onEdit && onEdit(object)}} keepsafe={keepsafe}/></>}
      {variant === 'delete' && <><TooltipTrigger asChild><button onClick={()=>{onDelete && onDelete()}} className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-90 duration-200 group'><Trash className='w-5 group-hover:text-red-500'/></button></TooltipTrigger><TooltipContent>{t("order_and_pickup.o_announce_pickup.o_manual.tooltip_delete")}</TooltipContent></>}
    </Tooltip>
    )
}

type ActionViewProps = {coupure?: CoupureInput[]}
const ActionView : React.FC<ActionViewProps> = ({coupure}) => {
  const {t} = useTranslation()
  return (
    <AlertDialog>
      <AlertDialogTrigger asChild>

        <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_announce_pickup.o_manual.tooltip_view")}</p>}>
          <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-90 duration-200 group'><Search className='w-5 group-hover:text-blue-500'/></button>
        </TooltipForce>

      </AlertDialogTrigger>
      <AlertDialogContent>
        <h2 className='text-lg'>{t("order_and_pickup.o_announce_pickup.o_manual.view_title")}</h2>
        <div className='border rounded-sm'>
          <div className='grid grid-cols-3 border-b p-2 bg-slate-100'>
            <i></i>
            <p>{t("order_and_pickup.o_announce_pickup.o_manual.count")}</p>
            <p>{t("order_and_pickup.o_announce_pickup.o_manual.total")} (€)</p>            
          </div>
          <div className='flex flex-col gap-2 p-2'>
            {coupure && <Coupure cn={isCoupureCN(coupure as Coupure[])} coupures={coupure as Coupure[]} show_total/> }
          </div>
        </div>
        <AlertDialogCancel asChild>
          <Button variant='secondary'>{t("order_and_pickup.o_announce_pickup.o_manual.close")}</Button>
        </AlertDialogCancel>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default Manual