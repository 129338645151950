import React from 'react'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog'
import { Printer, X } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Button } from '../../ui/button'
import { useMutation } from '@tanstack/react-query'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../lib/axios'
import useAuth from '../../../hooks/useAuth'

type Props = {
    id: number
    type: 'order' | 'announcement'
}

const ExportRow = ({id, type}:Props) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const { mutate:printOrder } = useMutation({
        mutationFn: async () => {
            try {
                toast.loading(`${t("components.o_export.toast_printing")}`, {id:'printOrder'})
                const url = type === 'order' ? 'order/draft/print' : 'announcement/draft/print'
                const {data} = await axios.post(`${url}`, {
                    token: auth.data?.token,
                    id: id
                })
                if(data.errorcode!==0){ throw new Error(data.message) }
                toast.success(`${t("components.o_export.toast_success")}`, {id:'printOrder'})
                window.open(data.object.filename);
            } catch (error) {
                toast.error(`${t("components.o_export.toast_error")}`, {id:'printOrder'})
                console.log(error)
            }
        }
    })

  return (
    <>
    <AlertDialog>
        <AlertDialogTrigger asChild>
            <button onClick={()=>{ }} className='w-7 h-7 flex justify-center rounded-sm items-center border text-neutral-500 hover:bg-neutral-50 hover:text-neutral-700 duration-200'><Printer className='w-4 h-4'/></button>
        </AlertDialogTrigger>
        <AlertDialogContent>
            <div className='flex justify-between'>
                <h2 className='text-lg font-medium'>{t("components.o_export.title_p2")}</h2>
                <AlertDialogCancel className='p-0 border-none' asChild><button className='w-8 h-8'><X className='w-5' strokeWidth={1.5}/></button></AlertDialogCancel>
            </div>
            <div className='flex justify-between'>
                <AlertDialogAction asChild><Button onClick={()=>{ printOrder() }}>{t("components.o_export.button_yes")}</Button></AlertDialogAction>
            </div>
        </AlertDialogContent>
    </AlertDialog>
    </>
  )
}

export default ExportRow