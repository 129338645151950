import { useNavigate } from "react-router-dom"
import useAuth from "../../../../hooks/useAuth"
import { useSelector } from "react-redux"
import { RootState } from "../../../../redux"
import { useState } from "react"
import ExportTable from "../../../../components/portal/export/ExportTable"
import { createExport, createExportObject, createExportRows } from "../../../../lib/general/export"
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../../../../components/ui/table"
import { Pagination } from "../../../../components/ui/pagination"
import { splitArrayIntoChunks } from "../../../../lib/utils"
import { format } from "date-fns"
import { convertToDate } from "../../../../lib/date"
import { useTranslation } from "react-i18next"

type Props = {
    pensions: Pension[]
}

const PensionsTable : React.FC<Props> = ({pensions}) => {
    const {t} = useTranslation()
    const headerArr = [t("pensions.date_payment"), t("pensions.customer_code"), t("pensions.name"), t("pensions.jx"), t("pensions.route"), t("pensions.assigned"), t("pensions.mkpsafe"), t("pensions.pochette"), t("pensions.date_preperation"), t("pensions.date_transport"), t("pensions.date_management"), t("pensions.locker")]

    const { auth } = useAuth()
    const navigate = useNavigate()

    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

    return (
        <>
            {auth.data?.right.export &&
                <div className='flex justify-end mt-4'>
                    <ExportTable onDownload={(extension) => { createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(pensions, ['dtrun', 'clientcode', 'name', 'jour', 'route', 'assignation', 'keepsafe', 'pochette', 'dtprepared', 'dtplanned', 'dtmanagement', 'locker'], { partners: partners, customers: customers, relnr: 'relnr' }))) }} />
                </div>
            }

            <div className='my-4 border rounded-sm'>
                <Table>
                    <TableHeader className='bg-slate-100 relative'>
                        <TableRow>
                            <TableHead className='uppercase'>{t("pensions.date_payment")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.customer_code")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.name")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.jx")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.route")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.assigned")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.mkpsafe")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.pochette")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.date_preperation")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.date_transport")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.date_management")}</TableHead>
                            <TableHead className='uppercase'>{t("pensions.locker")}</TableHead>
                        </TableRow>
                    </TableHeader>
                    <TableBody>
                            {pensions && splitArrayIntoChunks(pensions, 20)[currentPage - 1] && splitArrayIntoChunks(pensions, 20)[currentPage - 1].map((pension, index) => {
                            return (
                                <TableRow key={index}>
                                    <TableCell>{format(new Date(pension.dtrun), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{pension.clientcode}</TableCell>
                                    <TableCell>{pension.name}</TableCell>
                                    <TableCell>{pension.jour}</TableCell>
                                    <TableCell>{pension.route}</TableCell>
                                    <TableCell>{pension.assignation}</TableCell>
                                    <TableCell>{pension.keepsafe}</TableCell>
                                    <TableCell>{pension.pochette}</TableCell>
                                    <TableCell>{format(convertToDate(pension.dtprepared) || new Date(), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{format(new Date(pension.dtplanned), "dd/MM/yyyy")}</TableCell>
                                    <TableCell>{pension.dtmanagement ? format(convertToDate(pension.dtmanagement) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                                    <TableCell>{pension.locker}</TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </div>

            <Pagination totalItems={pensions.length} itemsPerPage={20} page={currentPage} onPageChange={(page) => { setcurrentPage(parseFloat(page)) }} />
        </>
        )
}

export default PensionsTable