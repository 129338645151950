import React, { useState } from 'react'
import { Button } from '../../../../../../components/ui/button'
import { Loader2, Save } from 'lucide-react'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../../../../../../components/ui/tabs'
import { Tab, TabItem } from '../../../../../../components/ui/tab'
import { Checkbox } from '../../../../../../components/ui/checkbox'
import { Input } from '../../../../../../components/ui/input'

import coin_eur_001 from '../../../../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../../../../assets/images/coin-eur-2.png'

import note_eur_5 from '../../../../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../../../../assets/images/note-eur-500.png'
import NewCheckbox from '../../../../../../components/ui/new-checkbox'

import lodash from 'lodash'
import { useQuery } from '@tanstack/react-query'
import toast, { Toaster } from 'react-hot-toast'
import axios from '../../../../../../lib/axios'
import useAuth from '../../../../../../hooks/useAuth'
import { useTranslation } from 'react-i18next'

type Props = {
  denom: PartnerDenom;
  refetch: () => void;
}

const Denom : React.FC<Props>= ({denom, refetch}) => {
  const {auth} = useAuth()
  const {t} = useTranslation()

  const [subpage, setsubpage] = useState('c');
  const [new_denom, setnew_denom] = useState<PartnerDenom>(denom)

  const updatePDenomProperty = (type: 'coins' | 'notes', index: number, key: string, value: number) => {
    setnew_denom((prevDenom) => {
      const updatedDenom = { ...prevDenom };
      updatedDenom[type] = [...prevDenom[type]];
      updatedDenom[type][index] = { ...prevDenom[type][index], [key]: value };
      return updatedDenom;
    });
  };

  const {isFetching, refetch:saveUpdates} = useQuery({
    queryKey: ['update_denom'],
    queryFn: async () => {
      try {
        toast.loading(t("settings.toast_loading"), {id:'saveUpdates'})
        let obj:any = {
          token: auth.data?.token,
          ...new_denom
        }
        obj.partner = obj.partnerid
        delete obj.partnerid
        const {data} = await axios.post(`partner/denom/createorupdate`, obj)
        if(data.errorcode!==0){throw new Error(data.message)}
        toast.success(t("settings.toast_success_p2"), {id:'saveUpdates'})
        refetch()
      } catch (error) {
        console.log(error)
        toast.error(t("settings.toast_error_p4"), {id:'saveUpdates'})
      }
      return null
    },
    enabled: false
  })

  return (
    <>
    <Toaster />
    <div className='flex justify-between mb-4'>
        <Tab onValueChange={(v)=>{setsubpage(v)}} value={subpage}>
            <TabItem value='c'>{t("settings.coins")}</TabItem>
            <TabItem value='n'>{t("settings.notes")}</TabItem>
        </Tab>

        <Button onClick={()=>{saveUpdates()}} disabled={lodash.isEqual(denom, new_denom) || isFetching} className='gap-2'><Save className='w-5'/>{isFetching ? <Loader2 className='animate-spin'/> : t("settings.button_save")}</Button>
    </div>

    <div className='grid grid-cols-2 border-b text-xs py-2 mb-2 font-semibold text-neutral-600'>
      <div>{t("settings.denomination")}</div>
      <div className='grid grid-cols-3 gap-4'>
        <p>{t("settings.minimum")}</p>
        <p>{t("settings.maximum")}</p>
        <p>{t("settings.packed")}</p>
      </div>
    </div>

    {subpage === 'c' &&
    <div className='flex flex-col gap-4'>
      {Array.isArray(new_denom.coins) && new_denom.coins.map((value, index) => {
      return (
        <div className='grid grid-cols-2' key={index}>
          <div className='flex items-center gap-4'>
            <div><NewCheckbox checked={value.maxqty > 0 && value.perqty > 0} disabled/></div>
            <div>
              <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={coin_eur_001} alt="Coin"/></div>
                <p>EUR {value.coupure}</p>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-4'>
            <div><Input value={value.minqty} onChange={({currentTarget})=>{ if(parseFloat(currentTarget.value)>-1){(updatePDenomProperty('coins', index, 'minqty', parseFloat(currentTarget.value)))} }} type='number'/></div>
            <div><Input value={value.maxqty} onChange={({currentTarget})=>{ if(parseFloat(currentTarget.value)>-1){(updatePDenomProperty('coins', index, 'maxqty', parseFloat(currentTarget.value)))} }} type='number'/></div>
            <div><Input value={value.perqty} onChange={({currentTarget})=>{ if(parseFloat(currentTarget.value)>-1){(updatePDenomProperty('coins', index, 'perqty', parseFloat(currentTarget.value)))} }} type='number'/></div>
          </div>  
        </div>
      )
    })}
    </div>
    }

    {subpage === 'n' &&
    <div className='flex flex-col gap-4'>
      {Array.isArray(new_denom.notes) && new_denom.notes.map((value, index) => {
      return (
        <div className='grid grid-cols-2' key={index}>
          <div className='flex items-center gap-4'>
            <div><NewCheckbox checked={value.maxqty > 0 && value.perqty > 0} disabled/></div>
            <div>
              <div className='flex items-center gap-2'>
                <div className='w-10 h-10 border flex justify-center items-center rounded-sm'><img src={note_eur_10} alt="Note"/></div>
                <p>EUR {value.coupure}</p>
              </div>
            </div>
          </div>

          <div className='grid grid-cols-3 gap-4'>
            <div><Input value={value.minqty} onChange={({ currentTarget }) => { if(parseFloat(currentTarget.value)>-1){updatePDenomProperty('notes', index, 'minqty', parseFloat(currentTarget.value))} }} type='number' /></div>
            <div><Input value={value.maxqty} onChange={({ currentTarget }) => { if(parseFloat(currentTarget.value)>-1){updatePDenomProperty('notes', index, 'maxqty', parseFloat(currentTarget.value))} }} type='number' /></div>
            <div><Input value={value.perqty} onChange={({ currentTarget }) => { if(parseFloat(currentTarget.value)>-1){updatePDenomProperty('notes', index, 'perqty', parseFloat(currentTarget.value))} }} type='number' /></div>
          </div>  
        </div>
      )
    })}
    </div>
    }

    </>
  )
}

export default Denom