import React, { useEffect, useState } from 'react'
import { AlertDialog, AlertDialogAction, AlertDialogContent, AlertDialogTrigger } from '../../ui/alert-dialog'
import { AlertDialogCancel } from '@radix-ui/react-alert-dialog'
import { Toaster, toast } from 'react-hot-toast'
import { ArrowLeft, Check, Equal, X } from 'lucide-react'
import { Label } from '../../ui/label'
import { Input } from '../../ui/input'
import { useDebounce } from '@uidotdev/usehooks'
import useAuth from '../../../hooks/useAuth'
import axios from '../../../lib/axios'
import { Tablist, TablistItem } from '../../ui/tablist'

import coin_eur_001 from '../../../assets/images/coin-eur-0.01.png'
import coin_eur_002 from '../../../assets/images/coin-eur-0.02.png'
import coin_eur_005 from '../../../assets/images/coin-eur-0.05.png'
import coin_eur_01 from '../../../assets/images/coin-eur-0.1.png'
import coin_eur_02 from '../../../assets/images/coin-eur-0.2.png'
import coin_eur_05 from '../../../assets/images/coin-eur-0.5.png'
import coin_eur_1 from '../../../assets/images/coin-eur-1.png'
import coin_eur_2 from '../../../assets/images/coin-eur-2.png'

import note_eur_5 from '../../../assets/images/note-eur-5.png'
import note_eur_10 from '../../../assets/images/note-eur-10.png'
import note_eur_20 from '../../../assets/images/note-eur-20.png'
import note_eur_50 from '../../../assets/images/note-eur-50.png'
import note_eur_100 from '../../../assets/images/note-eur-100.png'
import note_eur_200 from '../../../assets/images/note-eur-200.png'
import note_eur_500 from '../../../assets/images/note-eur-500.png'
import { Button } from '../../ui/button'
import { getCookie, setCookie } from '../../../lib/cookies'
import NewCheckbox from '../../ui/new-checkbox'
import { useTranslation } from 'react-i18next'

interface Props {
    cn: Type | 'cn',
    onSave: (obj:AnnouncemenDetail) => void;
    mother_keepsafe: AnnouncementKeepsafe
}

type Type = 'c' | 'n';

const AddKeepsafe : React.FC<Props> = ({cn, onSave, mother_keepsafe}) => {
    const {auth} = useAuth();
    const {t} = useTranslation()

    const [barcode, setbarcode] = useState<string>('');
    const [barcode_error, setbarcode_error] = useState<'correct' | StateType>(null);
    const debouncedBarcode = useDebounce(barcode, 300)

    const [selected_type, setselected_type] = useState<Type>('n');
    //Removed state -> Lander zei kinderen van moeders kunnen enkel notes zijn
    //const [selected_type, setselected_type] = useState<Type>((cn === 'c' || cn === 'cn') ? 'c' : 'n');
    const [coupure_input, setcoupure_input] = useState<CoupureInput[]>();

    useEffect(()=>{
        if(debouncedBarcode !== '' && debouncedBarcode.substring(0,2) === 'BE') {
            const checkBarcode = async () => {
                try {
                    setbarcode_error('loading')
                    const { data } = await axios.post(`barcode/check`, {
                        token: auth.data?.token,
                        barcode: debouncedBarcode
                    })
                    if(data.errorcode === 0){
                        if(data.object.barcode.valid === true && data.object.barcode.remark === ''){
                            setbarcode_error('correct')
                        } else {
                            setbarcode_error('error')
                        }
                    }
                    else {
                        setbarcode_error('error')
                    }
                } catch (error) {
                    setbarcode_error('error')
                    console.log(error)
                }
            }
            checkBarcode()
        }
        else {
            setbarcode_error(null)
        }
    }, [debouncedBarcode])

    const onChangeInput = (coupure:CoupureType, qty:number) => {
        qty = Math.round(qty)
        if(coupure_input) {
            let tmp = [...coupure_input]
            const existingIndex = coupure_input.findIndex(item => item.coupure === coupure);
            
            if(existingIndex !== -1){
                tmp[existingIndex] = {qty:qty, coupure:coupure}
            }
            else {
                tmp.push({qty:qty, coupure:coupure})
            }
            setcoupure_input(tmp);
        }
        else {
            setcoupure_input([{coupure:coupure, qty:qty}])
        }
    };

    const calculateTotal = (cn:'c' | 'n') => {
        let total = 0;

        if(coupure_input) {
            let tmp = [...coupure_input]
            if(cn === 'c') { tmp = tmp.filter(i =>parseFloat(i.coupure) <= 2); }
            else { tmp = tmp.filter(i => parseFloat(i.coupure) > 2) }
            

            tmp.forEach(item => {
                if(!isNaN(item.qty) && !isNaN(parseFloat(item.coupure))){
                    total += item.qty * parseFloat(item.coupure)
                }
            });
        }

        return total;
    }

    const calculateSubtotal = (coupure:CoupureType) => {
        let total = 0;
        if(coupure_input) {
            const item = coupure_input.find(i=>i.coupure===coupure)
            if(item) {
                total = parseFloat(item.coupure) * item.qty
            }
        }
        return Math.round(total * 100)/100 || 0
    }

    const CoinsInput = () => {
        return (
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_001} alt='coin_eur_001'/>
                        <p className='font-medium'>0.01 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.01', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.01')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.01')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_002} alt='coin_eur_002'/>
                        <p className='font-medium'>0.02 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.02', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.02')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.02')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_005} alt='coin_eur_002'/>
                        <p className='font-medium'>0.05 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.05', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.05')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.05')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_01} alt='coin_eur_01'/>
                        <p className='font-medium'>0.10 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.1', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.1')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.1')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_02} alt='coin_eur_02'/>
                        <p className='font-medium'>0.20 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.2', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.2')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.2')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_05} alt='coin_eur_05'/>
                        <p className='font-medium'>0.50 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('0.5', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '0.5')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('0.5')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_1} alt='coin_eur_1'/>
                        <p className='font-medium'>1 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('1', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '1')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('1')}</p></div>
                   </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={coin_eur_2} alt='coin_eur_2'/>
                        <p className='font-medium'>2 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('2', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '2')?.qty || ''} className='w-20' type='number' min={0}/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('2')}</p></div>
                    </div>
                </div>

                <div className='flex justify-end border-t py-2 text-sm'>
                    <p>{t("components.o_announcement.o_addkeepsafe.total")}: <span className='font-medium'>€ {calculateTotal('c')}</span></p> 
                </div>
            </div>
        )
    }

    const NotesInput = () => {
        return (
            <div className='flex flex-col gap-2'>
                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_5} alt='note_eur_5'/>
                        <p className='font-medium'>5 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('5', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '5')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('5')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_10} alt='note_eur_10'/>
                        <p className='font-medium'>10 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('10', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '10')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('10')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_20} alt='note_eur_20'/>
                        <p className='font-medium'>20 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('20', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '20')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('20')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_50} alt='note_eur_50'/>
                        <p className='font-medium'>50 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('50', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '50')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('50')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_100} alt='note_eur_100'/>
                        <p className='font-medium'>100 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('100', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '100')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('100')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_200} alt='note_eur_200'/>
                        <p className='font-medium'>200 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('200', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '200')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('200')}</p></div>
                    </div>
                </div>

                <div className='grid grid-cols-2'>
                    <div className='flex gap-2 items-center'>
                        <img src={note_eur_500} alt='note_eur_500'/>
                        <p className='font-medium'>500 Euro</p>
                    </div>
                    <div className='grid grid-cols-3'>
                        <Input onBlur={({currentTarget})=>{ onChangeInput('500', Math.round(parseFloat(currentTarget.value))) }} defaultValue={coupure_input?.find(i => i.coupure === '500')?.qty || ''} className='w-20' type='number'/>
                        <div className='flex justify-center items-center'><Equal className='w-5' strokeWidth={1}/></div>
                        <div className='flex items-center justify-end'><p className='text-right font-bold'>€ {calculateSubtotal('500')}</p></div>
                    </div>
                </div>

                <div className='flex justify-end border-t py-2 text-sm'>
                    <p>{t("components.o_announcement.o_addkeepsafe.total")}: <span className='font-medium'>€ {calculateTotal('n')}</span></p> 
                </div>
            </div>
        )
    }

    const saveKeepsafe = () => {
        let object:AnnouncemenDetail = {bag:barcode, coupures:[]}

        if(object.bag === ''){
            toast.error(t("components.o_announcement.o_addkeepsafe.toast_error"))
            return;
        }
        if(coupure_input){
            //Keepsafe
            object.coupures = coupure_input

            if(selected_type === 'c'){
                //Keep all coins
                object.coupures = object.coupures.filter(i => parseFloat(i.coupure) <= 2)
            }
            else {
                //Keep all notes
                object.coupures = object.coupures.filter(i => parseFloat(i.coupure) > 2)
            }
        }
        else {
            //Moederkeepsafe
            toast.success(t("components.o_announcement.o_addkeepsafe.toast_success"))
        }
        onSave(object)
        setcoupure_input(undefined)
        setbarcode('')
    }

    type MethodKeepsafe = 'manual' | 'amount'
    //const [method_keepsafe, setmethod_keepsafe] = useState<MethodKeepsafe | null>(getCookie('methodKeepsafe') ? getCookie('methodKeepsafe') as MethodKeepsafe : null);
    const [method_keepsafe, setmethod_keepsafe] = useState<MethodKeepsafe | null>('manual');
    const [remember_method, setremember_method] = useState<boolean>(true);

    const saveMethod = (method:MethodKeepsafe) => {
        if(remember_method){
            setCookie('methodKeepsafe', method)
        }
        setmethod_keepsafe(method)
    }
    
  return (
    <>
    <Toaster />
    <AlertDialog>
        <AlertDialogTrigger asChild><button className='text-primary underline text-sm whitespace-nowrap'>{t("components.o_announcement.o_addkeepsafe.button_add_keepsafe")}</button></AlertDialogTrigger>
        <AlertDialogContent className='max-h-[95vh] overflow-y-auto'>
            {method_keepsafe === null &&
            <div className='flex flex-col gap-4'>
                <div className='flex justify-between items-center'>
                    <h2 className='text-xl font-medium'>{t("components.o_announcement.o_addkeepsafe.keepsafe_question")}</h2>
                    <AlertDialogCancel asChild><button className='opacity-50 hover:opacity-100'><X /></button></AlertDialogCancel>
                </div>
                <div className='flex items-center gap-2'>
                    <NewCheckbox checked={remember_method} onCheckedChange={(v)=>{setremember_method(v)}} id='remember_ks'/>
                    <Label htmlFor='remember_ks'>{t("components.o_announcement.o_addkeepsafe.remember")}</Label>
                </div>
                <div className='flex gap-2'>
                    <Button onClick={()=>{saveMethod('manual')}}>{t("components.o_announcement.o_addkeepsafe.manual")}</Button>
                    <Button onClick={()=>{saveMethod('amount')}} variant='outline'>{t("components.o_announcement.o_addkeepsafe.loose")}</Button>
                </div>
            </div>
            }

            {method_keepsafe === 'manual' && 
            <>
            <div className='flex justify-between items-center'>
                <div className='relative overflow-hidden group'>
                    <div className='flex gap-1 items-center -translate-x-6 group-hover:translate-x-0 duration-100 cursor-pointer' onClick={()=>{setmethod_keepsafe(null)}}>
                        <ArrowLeft className='w-5'/>
                        <h2 className='text-xl font-medium text-black/80'>{t("components.o_announcement.o_addkeepsafe.manual_description")} <span className='font-bold text-black'>'{mother_keepsafe.keepsafe}'</span> {t("components.o_announcement.o_addkeepsafe.manual_description_p2")}</h2>
                    </div>
                </div>

                <AlertDialogCancel asChild><button className='opacity-50 hover:opacity-100'><X /></button></AlertDialogCancel>
            </div>

            <div>
                <Label htmlFor='barcode'>{t("components.o_announcement.o_addkeepsafe.label_barcode")}<span className='text-primary ml-1'>*</span></Label>
                <Input endEnhancer={
                <>
                    {barcode_error === 'correct' && <div className='w-5 h-5 rounded-full bg-green-400 flex justify-center items-center'><Check className='w-3 text-white'/></div>}
                    {barcode_error === 'error' && <div className='w-5 h-5 rounded-full bg-red-400 flex justify-center items-center'><X className='w-3 text-white'/></div>}
                </>} onChange={({currentTarget})=>setbarcode(currentTarget.value)} id='barcode'/>
                {barcode_error === 'error' && <p className='text-sm text-red-600'>{t("components.o_announcement.o_addkeepsafe.barcode_error")}</p>}
            </div>

            {cn === 'cn' && 
            <Tablist value={selected_type} onValueChange={(v)=>{console.log("update"); setselected_type(v as Type)}}>
                <TablistItem value='c'>{t("components.o_announcement.o_addkeepsafe.coins")}</TablistItem>
                <TablistItem value='n'>{t("components.o_announcement.o_addkeepsafe.notes")}</TablistItem>
            </Tablist>
            }

            {selected_type === 'c' && <CoinsInput />}
            {selected_type === 'n' && <NotesInput />}

            <div>
                <AlertDialogAction disabled={barcode === '' || !(calculateTotal('c') > 0 || calculateTotal('n') > 0)} onClick={()=>{saveKeepsafe()}}>{t("components.o_announcement.o_addkeepsafe.button_save_keepsafe")}</AlertDialogAction>
                <Button onClick={()=>{setcoupure_input(undefined)}} variant='ghost'>{t("components.o_announcement.o_addkeepsafe.button_clear_form")}</Button>
            </div>
            </>
            }

            {method_keepsafe == 'amount' &&
            <>
            <div className='flex justify-between items-center'>
                <div className='relative overflow-hidden group'>
                    <div className='flex gap-1 items-center -translate-x-6 group-hover:translate-x-0 duration-100 cursor-pointer' onClick={()=>{setmethod_keepsafe(null)}}>
                        <ArrowLeft className='w-5'/>
                        <h2 className='text-xl font-medium text-black/80'>{t("components.o_announcement.o_addkeepsafe.manual_description")} <span className='font-bold text-black'>'{mother_keepsafe.keepsafe}'</span> {t("components.o_announcement.o_addkeepsafe.manual_description_p2")}</h2>
                    </div>
                </div>
                <AlertDialogCancel asChild><button className='opacity-50 hover:opacity-100'><X /></button></AlertDialogCancel>
            </div>

            <div>
                <Label htmlFor='barcode'>{t("components.o_announcement.o_addkeepsafe.label_barcode")}<span className='text-primary ml-1'>*</span></Label>
                <Input endEnhancer={
                <>
                    {barcode_error === 'correct' && <div className='w-5 h-5 rounded-full bg-green-400 flex justify-center items-center'><Check className='w-3 text-white'/></div>}
                    {barcode_error === 'error' && <div className='w-5 h-5 rounded-full bg-red-400 flex justify-center items-center'><X className='w-3 text-white'/></div>}
                </>} onChange={({currentTarget})=>setbarcode(currentTarget.value)} id='barcode'/>
                {barcode_error === 'error' && <p className='text-sm text-red-600'>{t("components.o_announcement.o_addkeepsafe.barcode_error")}</p>}
            </div>

            <div>
                <Label htmlFor='amount'>{t("components.o_announcement.o_addkeepsafe.total_amount")}</Label>
                <Input id='amount' type='number'/>
            </div>

            <div>
                <AlertDialogAction disabled={barcode === ''} onClick={()=>{saveKeepsafe()}}>{t("components.o_announcement.o_addkeepsafe.button_save_keepsafe")}</AlertDialogAction>
            </div>
            </>
            }
        </AlertDialogContent>
    </AlertDialog>
    </>
  )
}

export default AddKeepsafe