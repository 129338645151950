export const CONFIG:Config = {
    order_and_collect: {
        order: {
            requiredReference_relnrs: []
        },
        collect: {
            requiredReference_relnrs: []
        }
    }
}

interface Config {
    order_and_collect: {
        order: {
            requiredReference_relnrs: string[]
        },
        collect: {
            requiredReference_relnrs: string[]
        }
    }
}