import React, { HTMLAttributes, useEffect, useRef, useState } from 'react'
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from '../../../../components/ui/table';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux';
import { Pagination } from '../../../../components/ui/pagination';
import { formatCashAmount, isInterface, splitArrayIntoChunks } from '../../../../lib/utils';
import { convertToDate } from '../../../../lib/date';
import { format } from 'date-fns';
import { Button } from '../../../../components/ui/button';
import { ChevronsUpDown, Download } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import ExportTable from '../../../../components/portal/export/ExportTable';
import { createExport, createExportObject, createExportRows } from '../../../../lib/general/export';
import useAuth from '../../../../hooks/useAuth';
import { Input } from '../../../../components/ui/input';
import useTable from '../../../../hooks/useTable';
import { useTranslation } from 'react-i18next';
import { getFilterDetail } from '../../../../lib/selectors';

type Props = {
    orders: Order[];
}

const tableHeadClass = 'flex items-center gap-1 group'

const DeliveriesTable : React.FC<Props & HTMLAttributes<HTMLDivElement>> = ({orders, className, ...props}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("track_and_trace.partner"), t("track_and_trace.customer_nr"), t("track_and_trace.customer_code"), t("track_and_trace.customer"), t("track_and_trace.status"), t("track_and_trace.reference"), t("track_and_trace.date_announced"), t("track_and_trace.date_created"), t("track_and_trace.date_out_stock"), t("track_and_trace.date_delivery"), t("track_and_trace.amount"), t("track_and_trace.currency"), t("track_and_trace.type"), t("track_and_trace.concept_nr"), t("track_and_trace.concept_created"), t("track_and_trace.bulk"), '€ 2,00', '€ 1,00', '€ 0,50', '€ 0,20', '€ 0,10', '€ 0,05', '€ 0,02', '€ 0,01', '€ 500', '€ 200', '€ 100', '€ 50', '€ 20', '€ 10', '€ 5']
    const navigate = useNavigate()

    
    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.unfilteredCustomers);
    const orderstate = useSelector((state:RootState)=>state.orderstate);

    const [currentPage, setcurrentPage] = useState<number>(1);

    const {data, sorting, filter, updateFilter, updateSorting} = useTable({data:orders, sorting:{desc:false, id:'dtannounce'}, keysToFilterOn:['clientref','amount', 'currency', 'draftid']})

    const searchBy = useRef<string>('');
    const filters = useSelector((state:RootState)=>state.filters)
    useEffect(()=>{
        let sc = getFilterDetail(filters, 'tt', 'search_criteria');
        if(isInterface(sc, {} as FilterDetail)){
            try {
                sc = JSON.parse(sc.value as string)
                //@ts-ignore
                searchBy.current = (sc.searchby)
            } catch (e){}
        }
    }, [])

  return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-between mt-4'>
        <Input onChange={({currentTarget})=>{setcurrentPage(1); updateFilter(currentTarget.value)}} placeholder='Filter...'/>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(orders, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername',  'status', 'clientref', 'dtannounce', 'dtcreation', 'dtstock', 'dtdelivery', 'amount', 'currency', 'cn', 'draftid', 'dtdraftcreation', 'bulk', 'C2', 'C1', 'C050', 'C020', 'C010', 'C005', 'C002', 'C001', 'N500', 'N200', 'N100', 'N50', 'N20', 'N10', 'N5'], {partners:partners, customers:customers, relnr:'relnr', orderstate:orderstate})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table className={className} {...props}>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    <TableHead className='uppercase'>{t("track_and_trace.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.status")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.reference")}</TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtannounce', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>
                            {t("track_and_trace.date_announced")}
                            <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/>
                        </div>
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtcreation', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>
                            {t("track_and_trace.date_created")}
                            <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/>
                        </div>
                    </TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.date_out_stock")}</TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'dtdelivery', desc:!sorting?.desc})}} className='uppercase'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>
                            {t("track_and_trace.date_delivery")}
                            <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/>
                        </div>
                    </TableHead>
                    <TableHead onClick={()=>{updateSorting({id:'amount', desc:!sorting?.desc})}} className='uppercase pr-0'>
                        <div className={`${tableHeadClass} cursor-pointer hover:underline`}>
                            {t("track_and_trace.amount")}
                            <ChevronsUpDown className='duration-100 w-4 min-w-[16px] opacity-50 group-hover:opacity-100'/>
                        </div>
                    </TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.currency")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.type")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.concept_nr")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.concept_created")}</TableHead>
                    <TableHead className='uppercase'>{t("track_and_trace.bulk")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data && splitArrayIntoChunks(data, 20)[currentPage - 1] && splitArrayIntoChunks(data, 20)[currentPage - 1].map((order, index)=>{
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)
                    
                    return (
                    <TableRow className='cursor-pointer' onClick={()=>{navigate(`delivery/${order.id}?sb=${searchBy.current}`)}} key={index}>
                        <TableCell>{partner?.name}</TableCell>
                        <TableCell>{customer?.name}</TableCell>
                        <TableCell>{customer?.clientcode}</TableCell>
                        <TableCell>{Array.isArray(orderstate) ? (orderstate.find(i=>i.code===order.status) ? orderstate.find(i=>i.code===order.status)?.description : order.status) : order.status}</TableCell>
                        <TableCell>{order.clientref}</TableCell>
                        <TableCell>{convertToDate(order.dtannounce) ? format(convertToDate(order.dtannounce) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell>{convertToDate(order.dtcreation) ? format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell>{convertToDate(order.dtstock) ? format(convertToDate(order.dtstock) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell>{convertToDate(order.dtdelivery) ? format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell className='text-right pr-0'>{formatCashAmount(order.amount)}</TableCell>
                        <TableCell>{order.currency.toLowerCase() === 'eur' ? 'Euro' : order.currency}</TableCell>
                        <TableCell>{order.cn.toLowerCase() === 'c' ? t("track_and_trace.coins") : t("track_and_trace.notes")}</TableCell>
                        <TableCell>{order.draftid}</TableCell>
                        <TableCell>{convertToDate(order.dtdraftcreation) ? format(convertToDate(order.dtdraftcreation) || new Date(), "dd/MM/yyyy") : ''}</TableCell>
                        <TableCell>{order.bulk ? t("track_and_trace.yes") : t("track_and_trace.no")}</TableCell>
                    </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>

    <Pagination totalItems={data.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default DeliveriesTable