import { addDays, addWeeks, endOfWeek, format, isSameDay, isToday, startOfWeek, subWeeks } from 'date-fns';
import { ChevronLeft, ChevronRight, Loader2, Palmtree } from 'lucide-react'
import React, { useEffect, useState } from 'react'
import { Button } from '../../../components/ui/button';
import { useQuery } from '@tanstack/react-query';
import axios from '../../../lib/axios';
import useAuth from '../../../hooks/useAuth';
import { convertToDate, formatDateToYYYYMMDD } from '../../../lib/date';
import { Combobox } from '../../../components/ui/combobox';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux';

import smallCoin from '../../../assets/images/smallCoin.png'
import smallNote from '../../../assets/images/smallNote.png'
import smallCoinAndNote from '../../../assets/images/smallCoinAndNote.png'
import { addFilter } from '../../../redux/actions';
import { getFilterDetail } from '../../../lib/selectors';
import { isInterface } from '../../../lib/utils';
import { useTranslation } from 'react-i18next';

type Props = {
    selected_customer?: string | null
}
const WeekPlanning : React.FC<Props> = ({selected_customer=null}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()

    const customers = useSelector((state:RootState)=>state.customers)
    const filters = useSelector((state:RootState) => state.filters);

    const [start_date, setstart_date] = useState(startOfWeek(new Date()));
    const [customers_list, setcustomers_list] = useState<{ value: string; label: string; }[]>([]);

    const {data:planning, refetch:refetchPlanning, isFetching:isFetchingPlanning} = useQuery<Planning[] | null>({
        queryKey: [`week_planning_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`planning/search`, {
                    token: auth.data?.token,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                    relnr: selected_customer
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.planning)){return null}
                return data.object.planning
            } catch (error) {
                console.log(error)
                return null;
            }
        },
        enabled: !!selected_customer && selected_customer!=='clear'
    })

    const {data:orders, refetch:refetchOrders, isFetching:isFetchingOrders} = useQuery<Order[] | null>({
        queryKey: [`week_orders_${selected_customer}`], 
        queryFn: async () => {
            try {
                if(!auth.data?.right.order){return null}
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`order/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                    searchby: 'S'
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.order)){console.log("Orders empty"); return null}//Emtpy
                return data.object.order
            } catch (error) {
                console.log(error)
                return null
            }
        },
        enabled: !!selected_customer
    })

    const {data:collection, refetch:refetchCollection, isFetching:isFetchingCollection} = useQuery<Keepsafe[] | null>({
        queryKey: [`week_collection_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!auth.data?.right.ttcollection){return null}
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`collection/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.collection)){console.log("Collections empty"); return null}//Emtpy
                return data.object.collection
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!selected_customer
    })

    const {data:safepoint, refetch:refetchSafepoint, isFetching:isFetchingSafepoint} = useQuery<Keepsafe[] | null>({
        queryKey: [`week_safepoint_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!auth.data?.right.ttsafepoint){return null}
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`safepoint/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.safepoint)){console.log("Safepoints empty"); return null}//Emtpy
                return data.object.safepoint
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!selected_customer
    })

    const {data:ete, refetch:refetchEte, isFetching:isFetchingEte} = useQuery<Keepsafe[] | null>({
        queryKey: [`week_ete_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!auth.data?.right.ttatme2e)
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`e2e/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.e2e)){console.log("E2e empty"); return null}//Emtpy
                console.log(data.object);
                return data.object.e2e
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!selected_customer
    })

    const {data:atm, refetch:refetchAtm, isFetching:isFetchingAtm} = useQuery<Keepsafe[] | null>({
        queryKey: [`week_atm2t_${selected_customer}`],
        queryFn: async () => {
            try {
                if(!auth.data?.right.ttatm2t){return null}
                if(!(!!selected_customer && selected_customer!=='clear')){return null}
                const {data} = await axios.post(`atm2t/search`, {
                    token: auth.data?.token,
                    relnr: selected_customer,
                    startdate: formatDateToYYYYMMDD(start_date),
                    enddate: formatDateToYYYYMMDD(endOfWeek(start_date)),
                })
                if(data.errorcode!==0){throw new Error(data.message)}
                if(!Array.isArray(data.object.atm2t)){console.log("Atm2t empty"); return null}//Emtpy
                console.log(data.object);
                return data.object.atm2t
            } catch (error) {
                console.log(error);
                return null
            }
        },
        enabled: !!selected_customer
    })

    useEffect(()=>{
        refetchPlanning()
        refetchOrders()
        refetchCollection()
        refetchSafepoint()
        refetchEte()
        refetchAtm()
    }, [start_date, selected_customer])

    function getPlanningFromDate(planning:Planning[], date:Date) {
        const f = planning.filter(i=>isSameDay((convertToDate(i.date) || new Date('1000')),date))

        return f
    }

    function filterOrdersByDate(orders:Order[], key:keyof Order, date:Date) {
        const formattedDate = formatDateToYYYYMMDD(date)
        const result = orders.filter(i=>i[key] === formattedDate)
        return result
    }

    function filterCollectionByDate(collections:Keepsafe[], key:keyof Keepsafe, date:Date) {
        const formattedDate = formatDateToYYYYMMDD(date)
        const result = collections.filter(i=>i[key] === formattedDate)
        return result
    }

    function returnTypeLabel(type:string) {
        switch (type.toLowerCase()) {
            case 'vpl':
                return t("track_and_trace.vpl")
            case 'oaf':
                return t("track_and_trace.oaf")
            case 'opa':
                return t("track_and_trace.opa")
            case 'rfk':
                return t("track_and_trace.rfk")
            case 'rfc':
                return t("track_and_trace.rfc")
            case 'pri':
                return t("track_and_trace.pri")
            case 'ext':
                return t("track_and_trace.ext")
            default:
                return ''
        }
    }

  return (
    <div className='mt-4 w-full overflow-auto flex flex-col gap-2 relative'>  

        <div className='grid grid-cols-2'>
            <div className='flex gap-1 items-center'>
                <button onClick={()=>{setstart_date(prev=>subWeeks(prev, 1))}} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronLeft className='w-4'/></button>
                <button onClick={()=>{setstart_date(prev=>addWeeks(prev, 1))}} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronRight className='w-4'/></button>
                
                <div className='flex items-center'>
                    <p className='ml-2 text-lg capitalize font-semibold'>{format(start_date, "MMMM")}</p>
                    {(format(start_date, "MMMM") !== format(endOfWeek(start_date), "MMMM")) && <p className='ml-2 text-lg capitalize font-semibold'>- {format(endOfWeek(start_date), "MMMM")}</p>}
                    {(start_date.getFullYear() !== new Date().getFullYear()) && <span className='ml-2 text-lg capitalize font-semibold'>{format(start_date, "yyyy")}</span>}
                    {((isFetchingSafepoint && !safepoint) || (isFetchingCollection && !collection) || (isFetchingPlanning && !planning)) && <Loader2 className='ml-2 w-4 text-neutral-400 animate-spin'/>}
                </div>
            </div>
        </div>
        
        <div className='w-full grid grid-cols-[repeat(7,minmax(100px,1fr))] '>
            {Array(7).fill('').map((v,i)=> {
                let date = addDays(start_date, i)
                return (
                    <div className='border p-2 min-w-[100px] first:rounded-tl-sm last:rounded-tr-sm' key={i}>
                        <p className={`${isToday(date) && 'text-primary'} text-lg font-semibold`}>{format(date, "d")} <span className={`${isToday(date) ? 'text-primary' : 'text-neutral-500'} font-normal text-sm  capitalize`}>{format(date, "EE")}</span></p>
                        
                        <div className='flex flex-col gap-2'>
                            {planning && getPlanningFromDate(planning, date).length > 0 && 
                            <div className='flex flex-col gap-1 max-h-40 overflow-auto'>
                            {getPlanningFromDate(planning, date).map((plan, ind) => {
                                return (
                                    <div className='bg-[#c6dabf] border-[#afcba4] border rounded-sm flex flex-col p-1' key={ind}>
                                        <div className='flex items-center gap-1'>
                                            {plan.cn === 'CN' && <img className='w-4' src={smallCoinAndNote} alt="coin and note"/>}
                                            {plan.cn === 'C' && <img className='w-4' src={smallCoin} alt="coin"/>}
                                            {plan.cn === 'N' && <img className='w-4' src={smallNote} alt="note"/>}
                                            <p className='text-sm font-medium'>Stop</p>
                                        </div>
                                        
                                        <p className='text-sm'>Stoptype: {returnTypeLabel(plan.type)}</p>
                                    </div>
                                )
                            })}
                            </div>
                            }    

                            {Array.isArray(orders) && filterOrdersByDate(orders, 'dtdelivery', date) && filterOrdersByDate(orders, 'dtdelivery', date).map((order, index)=>{
                                return (
                                    <div className='bg-[#c1a5a9] border-[#b49398] border rounded-sm flex flex-col p-1' key={index}>
                                        <div className='flex items-center gap-1'>
                                            {order.cn === 'CN' && <img className='w-4' src={smallCoinAndNote} alt="coin and note"/>}
                                            {order.cn === 'C' && <img className='w-4' src={smallCoin} alt="coin"/>}
                                            {order.cn === 'N' && <img className='w-4' src={smallNote} alt="note"/>}
                                            <p className='text-sm font-medium'>{t("dashboard.o_week_planning.order")}</p>
                                        </div>
                                        <p className='text-sm'>{t("dashboard.o_week_planning.amount")}: €{order.amount}</p>
                                    </div>
                                )
                            })}

                            {Array.isArray(collection) && filterCollectionByDate(collection, 'date', date) && 
                                <div className='flex flex-col gap-1 max-h-40 overflow-auto'>
                                {filterCollectionByDate(collection, 'date', date).map((collect, index) => {
                                    return (
                                        <div className='bg-[#97b1bd] border-[#809aa7] border rounded-sm flex flex-col p-1' key={index}>
                                            <div className='flex items-center gap-1'>
                                                {collect.cn === 'CN' && <img className='w-4' src={smallCoinAndNote} alt="coin and note"/>}
                                                {collect.cn === 'C' && <img className='w-4' src={smallCoin} alt="coin"/>}
                                                {collect.cn === 'N' && <img className='w-4' src={smallNote} alt="note"/>}
                                                <p className='text-sm font-medium'>{t("dashboard.o_week_planning.collection")}</p>
                                            </div>
                                            <p className='text-sm'>{t("dashboard.o_week_planning.executed")}: {collect.executed ? 'Ja' : 'Nee'}</p>
                                            <p className='text-sm'>{t("dashboard.o_week_planning.extra")}: {collect.extra ? 'Ja' : 'Nee'}</p>
                                        </div>
                                    )
                                })}
                                </div>
                            }
                        </div>
                    </div>
                )
            })}
            {(!selected_customer || selected_customer === 'clear') && <div className='col-span-full border border-t-0 p-2 text-sm rounded-b-sm'><p>{t("dashboard.o_week_planning.no_selection")}</p></div>}
        </div>

        
    </div>
  )
}

export default WeekPlanning

{/* <div className='flex gap-1 items-center'>
            <button onClick={()=>{setstart_date(prev=>subWeeks(prev, 1))}} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronLeft className='w-4'/></button>
            <button onClick={()=>{setstart_date(prev=>addWeeks(prev, 1))}} className='w-9 h-9 border rounded-full flex items-center justify-center duration-300 hover:bg-primary/10 hover:border-primary/10'><ChevronRight className='w-4'/></button>
        </div> */}