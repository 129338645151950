import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Main from './Main'
import Detail from './consumables/Detail'

const OverviewConsumables = () => {
  return (
    <Routes>
      <Route path="" element={<Main />}/>
      <Route path=":consumable" element={<Detail />} />
    </Routes>
  )
}

export default OverviewConsumables