import axios from '../lib/axios'
import useAuth from './useAuth'
import { queryclient} from '../index'
import { useDispatch } from 'react-redux';
import { removeFilter } from '../redux/actions';

const useLogout = () => {
  const { setAuth } = useAuth();
  const dispatch = useDispatch()

  const logout = async () => {
    queryclient.clear()
    setAuth({status:null, data: null});
    try {
      dispatch(removeFilter('tt', 'search_criteria'))
      localStorage.clear();
      window.localStorage.removeItem('jwt');
        // const response = await axios.get('/api/logout', {
        //     withCredentials: true
        // })
    } catch (error) {
        console.error(error);
    }
  }

  return logout;
}

export default useLogout