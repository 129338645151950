import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import useAuth from '../../../../../hooks/useAuth'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { format } from 'date-fns'
import { formatCashAmount } from '../../../../../lib/utils'
import { getOrderDraftState } from '../../../../../lib/general/params'
import { useNavigate } from 'react-router-dom'
import { AlertDialog, AlertDialogAction, AlertDialogCancel, AlertDialogContent, AlertDialogTrigger } from '../../../../../components/ui/alert-dialog'
import { CheckCircle, Trash2 } from 'lucide-react'
import TooltipForce from '../../../../../components/ui/tooltip-force'
import toast from 'react-hot-toast'
import axios from '../../../../../lib/axios'
import { Label } from '../../../../../components/ui/label'
import { Textarea } from '../../../../../components/ui/textarea'
import { useTranslation } from 'react-i18next'

type Props = {
    orders: OrderDraft[]
    callRefetch: () => void
}


const OrdersTable : React.FC<Props> = ({orders, callRefetch}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("order_and_pickup.o_validate_orders.partner"),t("order_and_pickup.o_validate_orders.customer_p2"), t("order_and_pickup.o_validate_orders.customer_code"), t("order_and_pickup.o_validate_orders.type"), t("order_and_pickup.o_validate_orders.amount"), t("order_and_pickup.o_validate_orders.date_delivery"), t("order_and_pickup.o_validate_orders.date_created"), t("order_and_pickup.o_validate_orders.status"), t("order_and_pickup.o_validate_orders.created_by"), t("order_and_pickup.o_validate_orders.reference"), t("order_and_pickup.o_validate_orders.auto_ref")]
    const navigate = useNavigate();

    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);
    const orderdraftstate = useSelector((state:RootState)=>state.orderdraftstate)

    const ValidateButton = ({order}:{order:OrderDraft}) => {

        const validateOrder = async () => {
            try {
                

                toast.loading(t("order_and_pickup.o_validate_orders.toast_loading"), {id:'validateOrder'})
                const {data} = await axios.post(`order/draft/validate`,{
                    token: auth.data?.token,
                    id: order.id
                })
                if(data.errorcode!==0){throw new Error()}
                toast.success(t("order_and_pickup.o_validate_orders.toast_success"), {id:'validateOrder'})
                callRefetch()
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_validate_orders.toast_error_p2"), {id:'validateOrder'})
            }
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_validate_orders.alert_title")}</p>}>
                        <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-green-500'><CheckCircle className='w-5'/></button>
                    </TooltipForce>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <div className='flex flex-col gap-2'>
                        <p className='text-lg font-semibold'>{t("order_and_pickup.o_validate_orders.alert_title")}</p>
                        <p className='text-sm text-neutral-500'>{t("order_and_pickup.o_validate_orders.alert_description")}</p>
                    </div>

                    <div className='flex justify-between'>
                        <AlertDialogCancel>{t("order_and_pickup.o_validate_orders.alert_cancel")}</AlertDialogCancel>
                        <AlertDialogAction onClick={()=>{validateOrder()}}>{t("order_and_pickup.o_validate_orders.alert_action")}</AlertDialogAction>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }

    const RemoveButton = ({order}:{order:OrderDraft}) => {

        const removeOrder = async () => {
            try {
                toast.loading(t("order_and_pickup.o_validate_orders.toast_loading_p2"), {id:'removeOrder'})
                const {data} = await axios.post(`order/draft/deny`,{
                    token: auth.data?.token,
                    id: order.id,
                })
                if(data.errorcode!==0){throw new Error()}
                toast.success(t("order_and_pickup.o_validate_orders.toast_success_p2"), {id:'removeOrder'})
                callRefetch()
            } catch (error) {
                console.log(error)
                toast.error(t("order_and_pickup.o_validate_orders.toast_error_p3"), {id:'removeOrder'})
            }
        }

        return (
            <AlertDialog>
                <AlertDialogTrigger asChild>
                    <TooltipForce content={<p className='whitespace-nowrap'>{t("order_and_pickup.o_validate_orders.alert_title_p2")}</p>}>
                        <button className='w-8 h-8 flex justify-center items-center opacity-50 hover:opacity-100 hover:text-red-500'><Trash2 className='w-5'/></button>
                    </TooltipForce>
                </AlertDialogTrigger>
                <AlertDialogContent>
                    <div className='flex flex-col gap-2'>
                        <p className='text-lg font-semibold'>{t("order_and_pickup.o_validate_orders.alert_title_p2")}</p>
                        <p className='text-sm text-neutral-500'>{t("order_and_pickup.o_validate_orders.alert_description_p2")}</p>
                    </div>

                    <div className='flex justify-between'>
                        <AlertDialogCancel>{t("order_and_pickup.o_validate_orders.alert_cancel")}</AlertDialogCancel>
                        <AlertDialogAction onClick={()=>{removeOrder()}}>{t("order_and_pickup.o_validate_orders.alert_action_p2")}</AlertDialogAction>
                    </div>
                </AlertDialogContent>
            </AlertDialog>
        )
    }
    
    return (
    <>
    {auth.data?.right.export && 
    <div className='flex justify-end mt-4'>
        <ExportTable onDownload={(extension)=>{createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(orders, ['partnername', 'customername', 'customerclientcode', 'cn', 'amount', 'dtdelivery', 'dtcreation', 'status', 'username', 'reference', 'autoreference'],{partners:partners, customers:customers, relnr:'relnr'})))}}/>
    </div>
    }

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow>
                    {(auth.data?.role === 1 || auth.data?.role === 2) && <TableHead></TableHead>}
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.partner")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.customer_p2")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.customer_code")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.type")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.amount")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.date_delivery")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.date_created")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.status")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.created_by")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.reference")}</TableHead>
                    <TableHead className='uppercase'>{t("order_and_pickup.o_validate_orders.auto_ref")}</TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {orders && orders.map((order, index) => {
                    let relation = customers.find(obj => obj.eindklant.some(e => e.relnr === order.relnr))
                    let partner = partners.find(obj => obj.relnr === relation?.id)
                    let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === order.relnr)

                    return (
                        <TableRow key={index}>
                            {(auth.data?.role === 1 || auth.data?.role === 2) && <TableCell className='flex items-center'>
                                <ValidateButton order={order}/>
                                <RemoveButton order={order}/>
                            </TableCell> }
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{partner?.name}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{customer?.name}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{customer?.clientcode}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{order.cn.toLowerCase() === 'c' ? 'Munten' : 'Biljetten'}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{formatCashAmount(order.amount)}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{format(convertToDate(order.dtdelivery) || new Date(), "dd/MM/yyyy")}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{format(convertToDate(order.dtcreation) || new Date(), "dd/MM/yyyy")} {convertToHHMM(order.tmcreation)}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{getOrderDraftState(order.status, orderdraftstate)?.description}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{order.username}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{order.reference}</TableCell>
                            <TableCell className='cursor-pointer' onClick={()=>{navigate(`${order.id}`)}}>{order.autoreference}</TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </div>
    </>
  )
}

export default OrdersTable