import { Outlet, useLocation, useNavigate, useOutlet } from "react-router-dom";
import Navigation from "../../../components/portal/Navigation";
import { AnimatePresence } from "framer-motion";
import { motion as m } from 'framer-motion'
import { useEffect } from "react";
import useAuth from "../../../hooks/useAuth";
import axios from "axios";
import lodash from "lodash"
import { useDispatch } from 'react-redux';
import { setRoles, setPartners, setCustomers, setOrderstate, setOrderDraftState, setCoupure, setComplaintCode, setRole, setUnfilteredCustomers } from "../../../redux/actions";
import toast, { Toaster } from "react-hot-toast";
import { useTranslation } from "react-i18next";

let amountRetrys = 3;

const Portal = () => {
  const {t, i18n} = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const outlet = useOutlet();
  const { auth } = useAuth();
  
  useEffect(() => {
    if (auth.data?.role){
      if(process.env.NODE_ENV==='development'){console.log(auth)}

      switch (auth.data.role) {
        case 1: 
          //Admin
          fetchAdminData()
          return;
        case 2:
          //Partner Admin
          fetchPartnerAdminData()
          return;
        case 3:
          //Partner Superuser
          fetchPartnerSuperuserData()
          return;
        case 4:
          //User
          fetchUserData()
          return;
        case 5:
          //Lite User
          fetchLiteUserData()
          return;
      }
    }
    else {
      navigate('/login')
    }
  }, [])

  const language = i18n.language.toLowerCase() === 'nl' ? 'N' : (i18n.language.toLocaleLowerCase() === 'fr' ? 'F' : 'E')

  useEffect(()=>{
    const getComplaintCode = async () => {
      try {
        let lng = language
        if(lng === 'E'){ lng = 'N' }
        const { data } = await axios.post(`${process.env.REACT_APP_BASE_URL}param/complaintcode`, { token: auth.data?.token, language: lng })
        if(data.errorcode!==0){ throw new Error(data.message) }
        if(!data.object.complaintcode){ throw new Error('Complaintcode empty') }
        dispatch(setComplaintCode(data.object.complaintcode))
      } catch (error) {
        console.log(error)
      }
    }
    getComplaintCode()
  }, [i18n.language])
  
  type Key = 'partners' | 'customers' | 'orderstate' | 'orderdraftstate' | 'coupure' | 'complaintcode' | 'roles' | 'role'
  type ResultArr = {var:Key; key?:string; res?:any}[]

  const header = { headers: {'Content-Type':'application/json'}}
  
  
  const generalData = [
    axios.post(`${process.env.REACT_APP_BASE_URL}param/orderstate`, { token: auth.data?.token }, header),
    axios.post(`${process.env.REACT_APP_BASE_URL}param/orderdraftstate`, { token: auth.data?.token }, header),
    axios.post(`${process.env.REACT_APP_BASE_URL}param/coupure`, { token: auth.data?.token }, header),
    axios.post(`${process.env.REACT_APP_BASE_URL}param/complaintcode`, { token: auth.data?.token, language: language }),
    axios.post(`${process.env.REACT_APP_BASE_URL}roles`, { token: auth.data?.token, role: auth.data?.role }, header),
  ]

  const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

  const adminStartupData = async () => {
    try {
      let response = await axios.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}roles`, { token: auth.data?.token }, {...header}),
        axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token }, {timeout: 10000, ...header}),
        ...generalData
      ])
      //const response = await Promise.all([roleRes, partnerRes, customerRes, ...generalRes])

      // Example slow fetching
      // const roleRes = await axios.post(`${process.env.REACT_APP_BASE_URL}roles`, { token: auth.data?.token }, {...header})
      // const partnerRes = await axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token }, header)
      // const customerRes = await axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token }, {...header})
      // await delay(2500)
      // const generalRes = generalData.map(async (requestData) => {
      //   return requestData
      // });

      // const response = await Promise.all([roleRes, partnerRes, customerRes, ...generalRes])

      

      const resultArr:ResultArr  = [
        {var:'roles', key:'role'},
        {var:'partners', key:'partner'}, 
        {var:'customers', key:'partner'}, 
        {var:'orderstate'}, 
        {var:'orderdraftstate'}, 
        {var:'coupure'}, 
        {var:'complaintcode'},
        {var:'role'}
      ]

      for (let i = 0; i < response.length; i++) {
        let k:string = (resultArr[i].key ? resultArr[i].key : resultArr[i].var) || ''
        resultArr[i].res = response[i].data.object[k]
      }

      let resultObj:{[K in Key]?:any} = {}
      resultArr.map(i=>{resultObj[i.var]=i.res})

      return resultObj
    }
    catch (error) {
      amountRetrys--
      console.error(error)
      return null;
    }
  }

  const partneradminStartupData = async () => {
    try {
      const response = await axios.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}roles`, { token: auth.data?.token }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        ...generalData,
      ])
      
      const resultArr:ResultArr  = [
        {var:'roles', key:'role'},
        {var:'partners', key:'partner'}, 
        {var:'customers', key:'partner'}, 
        {var:'orderstate'}, 
        {var:'orderdraftstate'}, 
        {var:'coupure'}, 
        {var:'complaintcode'},
        {var:'role'}
      ]

      for (let i = 0; i < response.length; i++) {
        resultArr[i].res = response[i].data.object[resultArr[i].key || resultArr[i].var]
      }

      let resultObj:{[K in Key]?:any} = {}
      resultArr.map(i=>{resultObj[i.var]=i.res})

      return resultObj
    }
    catch (error) {
      amountRetrys--
      if(amountRetrys > 0){ partneradminStartupData(); return; }
      console.error(error)
      return null;
    }
  }

  const partnersuperuserStartupData = async () => {
    try {
      const response = await axios.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        ...generalData,
      ])
      
      const resultArr:ResultArr  = [
        {var:'partners', key:'partner'}, 
        {var:'customers', key:'partner'}, 
        {var:'orderstate'}, 
        {var:'orderdraftstate'}, 
        {var:'coupure'}, 
        {var:'complaintcode'},
        {var:'role'}
      ]

      for (let i = 0; i < response.length; i++) {
        resultArr[i].res = response[i].data.object[resultArr[i].key || resultArr[i].var]
      }

      let resultObj:{[K in Key]?:any} = {}
      resultArr.map(i=>{resultObj[i.var]=i.res})

      return resultObj
    }
    catch (error) {
      amountRetrys--
      if(amountRetrys > 0){ partnersuperuserStartupData(); return; }
      console.error(error)
      return null;
    }
  }

  const userStartupData = async () => {
    try {
      const response = await axios.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token, partner: auth.data?.partner, relnr:auth.data?.relnr }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        ...generalData,
      ])
      
      const resultArr:ResultArr  = [
        {var:'partners', key:'partner'}, 
        {var:'customers', key:'partner'}, 
        {var:'orderstate'}, 
        {var:'orderdraftstate'}, 
        {var:'coupure'}, 
        {var:'complaintcode'},
        {var:'role'}
      ]

      for (let i = 0; i < response.length; i++) {
        resultArr[i].res = response[i].data.object[resultArr[i].key || resultArr[i].var]
      }

      let resultObj:{[K in Key]?:any} = {}
      resultArr.map(i=>{resultObj[i.var]=i.res})

      return resultObj
    }
    catch (error) {
      amountRetrys--
      if(amountRetrys > 0){ userStartupData(); return; }
      console.error(error)
      return null;
    }
  }

  const liteuserStartupData = async () => {
    try {
      const response = await axios.all([
        axios.post(`${process.env.REACT_APP_BASE_URL}partner/search`, { token: auth.data?.token, partner: auth.data?.partner, relnr:auth.data?.relnr }, header),
        axios.post(`${process.env.REACT_APP_BASE_URL}customer/search`, { token: auth.data?.token, partner: auth.data?.partner }, header),
        ...generalData,
      ])
      
      const resultArr:ResultArr  = [
        {var:'partners', key:'partner'}, 
        {var:'customers', key:'partner'}, 
        {var:'orderstate'}, 
        {var:'orderdraftstate'}, 
        {var:'coupure'}, 
        {var:'complaintcode'},
        {var:'role'}
      ]

      for (let i = 0; i < response.length; i++) {
        resultArr[i].res = response[i].data.object[resultArr[i].key || resultArr[i].var]
      }

      let resultObj:{[K in Key]?:any} = {}
      resultArr.map(i=>{resultObj[i.var]=i.res})

      return resultObj
    }
    catch (error) {
      amountRetrys--
      if(amountRetrys > 0){ liteuserStartupData(); return; }
      console.error(error)
      return null;
    }
  }

  const fetchAdminData = async () => {
    toast.loading(t("portal.toast_loading"), {id:'startup'})
    const startupData = await adminStartupData();
    if(startupData) {
      toast.success(t("portal.toast_success"), {id:'startup'})
      dispatch(setRoles(startupData.roles))
      dispatch(setPartners(startupData.partners))
      const ufc = lodash.cloneDeep(nonFilterCustomersActiveDate(lodash.cloneDeep(startupData.customers)))
      dispatch(setUnfilteredCustomers(ufc))
      dispatch(setCustomers(filterCustomersActiveDate(startupData.customers)))
      dispatch(setOrderstate(startupData.orderstate))
      dispatch(setOrderDraftState(startupData.orderdraftstate))
      dispatch(setCoupure(startupData.coupure))
      dispatch(setComplaintCode(startupData.complaintcode))
      dispatch(setRole(startupData.role))
    }
    else {
      toast.error(t("portal.toast_error"), {id:'startup', duration:5000})
    }
  }

  const fetchPartnerAdminData = async () => {
    toast.loading(t("portal.toast_loading"), {id:'startup'})
    const startupData = await partneradminStartupData();
    if(startupData){
      toast.success(t("portal.toast_success"), {id:'startup'})
      dispatch(setRoles(startupData.roles))
      dispatch(setPartners(startupData.partners))
      const ufc = lodash.cloneDeep(nonFilterCustomersActiveDate(lodash.cloneDeep(startupData.customers)))
      dispatch(setUnfilteredCustomers(ufc))
      dispatch(setCustomers(filterCustomersActiveDate(startupData.customers)))
      dispatch(setOrderstate(startupData.orderstate))
      dispatch(setOrderDraftState(startupData.orderdraftstate))
      dispatch(setCoupure(startupData.coupure))
      dispatch(setComplaintCode(startupData.complaintcode))
      dispatch(setRole(startupData.role))
    }
    else {
      toast.error(t("portal.toast_error"), {id:'startup', duration:5000})
    }
  }

  const fetchPartnerSuperuserData = async () => {
    toast.loading(t("portal.toast_loading"), {id:'startup'})
    const startupData = await partnersuperuserStartupData();
    if(startupData){
      toast.success(t("portal.toast_success"), {id:'startup'})
      dispatch(setPartners(startupData.partners))
      const ufc = lodash.cloneDeep(nonFilterCustomersActiveDate(lodash.cloneDeep(startupData.customers)))
      dispatch(setUnfilteredCustomers(ufc))
      dispatch(setCustomers(filterCustomersActiveDate(startupData.customers)))
      dispatch(setOrderstate(startupData.orderstate))
      dispatch(setOrderDraftState(startupData.orderdraftstate))
      dispatch(setCoupure(startupData.coupure))
      dispatch(setComplaintCode(startupData.complaintcode))
      dispatch(setRole(startupData.role))
    }
    else {
      toast.error(t("portal.toast_error"), {id:'startup', duration:5000})
    }
  }

  const fetchUserData = async () => {
    toast.loading(t("portal.toast_loading"), {id:'startup'})
    const startupData = await userStartupData();
    if(startupData){
      toast.success(t("portal.toast_success"), {id:'startup'})
      dispatch(setPartners(startupData.partners))
      const ufc = lodash.cloneDeep(nonFilterCustomersActiveDate(lodash.cloneDeep(startupData.customers)))
      dispatch(setUnfilteredCustomers(ufc))
      dispatch(setCustomers(filterCustomersActiveDate(startupData.customers)))
      dispatch(setOrderstate(startupData.orderstate))
      dispatch(setOrderDraftState(startupData.orderdraftstate))
      dispatch(setCoupure(startupData.coupure))
      dispatch(setComplaintCode(startupData.complaintcode))
      dispatch(setRole(startupData.role))
    }
    else {
      toast.error(t("portal.toast_error"), {id:'startup', duration:5000})
    }
  }

  const fetchLiteUserData = async () => {
    toast.loading(t("portal.toast_loading"), {id:'startup'})
    const startupData = await liteuserStartupData();
    if(startupData){
      toast.success(t("portal.toast_success"), {id:'startup'})
      dispatch(setPartners(startupData.partners))
      const ufc = lodash.cloneDeep(nonFilterCustomersActiveDate(lodash.cloneDeep(startupData.customers)))
      dispatch(setUnfilteredCustomers(ufc))
      dispatch(setCustomers(filterCustomersActiveDate(startupData.customers)))
      dispatch(setOrderstate(startupData.orderstate))
      dispatch(setOrderDraftState(startupData.orderdraftstate))
      dispatch(setCoupure(startupData.coupure))
      dispatch(setComplaintCode(startupData.complaintcode))
      dispatch(setRole(startupData.role))
    }
    else {
      toast.error(t("portal.toast_error"), {id:'startup', duration:5000})
    }
  } 

  function nonFilterCustomersActiveDate(customers:PartnerCustomers[]) {
    // Iterate through the input array of objects
    if(!customers){ return [] }
    let tmp_customers = [...customers]
    for (let i = 0; i < tmp_customers.length; i++) {
      const eindklant = tmp_customers[i].eindklant;

      // Filter out objects with a non-empty "dtinactive" property
      //const filteredEindklant = eindklant.filter(item => !item.dtinactive);

      // Update the "eindklant" property with the filtered array
      tmp_customers[i].eindklant = eindklant;
    }

    return tmp_customers;
  }

  function filterCustomersActiveDate(customers:PartnerCustomers[]) {
    // Iterate through the input array of objects
    if(!customers){ return [] }
    let tmp_customers = [...customers]
    for (let i = 0; i < tmp_customers.length; i++) {
      const eindklant = tmp_customers[i].eindklant;

      // Filter out objects with a non-empty "dtinactive" property
      const filteredEindklant = eindklant.filter(item => !item.dtinactive);

      // Update the "eindklant" property with the filtered array
      tmp_customers[i].eindklant = filteredEindklant;
    }

    return tmp_customers;
  }

  return (
    <>
    <Toaster />
      <div className='flex-col flex md:flex-row'>
        <Navigation />
        <AnimatePresence mode='wait'>
          <m.main key={useLocation().pathname} className='w-full overflow-auto'>
            {outlet}
          </m.main>
        </AnimatePresence>
        <div className="h-16 md:hidden"></div>
      </div>
    </>
  )
}

export default Portal