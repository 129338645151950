import React, { useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import useAuth from '../../../../../hooks/useAuth'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import { splitArrayIntoChunks } from '../../../../../lib/utils'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Pagination } from '../../../../../components/ui/pagination'
import TooltipForce from '../../../../../components/ui/tooltip-force'
import { convertToDate, convertToHHMM } from '../../../../../lib/date'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

type Props = {
    planning: SIPPlanning[]
}


const PlanningTable : React.FC<Props> = ({planning}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const headerArr = [t("track_and_trace.partner_nr"), t("sip.o_planning.partner"), t("track_and_trace.customer_nr"), t("sip.o_planning.customer_code"), t("sip.o_planning.customer_p2"), t("sip.o_planning.atm_id"), t("sip.o_planning.date_planned"), t("sip.o_planning.time_planned"), t("sip.o_planning.service_point"), t("sip.o_planning.service_type"), t("sip.o_planning.service_type_extra"), t("sip.o_planning.status"), t("sip.o_planning.status_extra")]
    const partners = useSelector((state: RootState) => state.partners);
    const customers = useSelector((state: RootState) => state.unfilteredCustomers);

    const [currentPage, setcurrentPage] = useState<number>(1);

  return (
    <>
        {auth.data?.right.export &&
            <div className='flex justify-end mt-4'>
                <ExportTable onDownload={(extension) => { createExport(auth.data?.token || '', extension, createExportObject(headerArr, createExportRows(planning, ['partnerid', 'partnername', 'relnr', 'customerclientcode', 'customername', 'idautomate', 'dtplanned', 'tmplanned', 'servicepointid', 'type', 'typeextrainfo', 'state', 'stateextrainfo'], {partners:partners, customers:customers, relnr:'relnr'}))) }} />
            </div>
        }

        <div className='my-4 border rounded-sm'>
            <Table>
                <TableHeader className='bg-slate-100 relative'>
                    <TableRow>
                        <TableHead className='uppercase'>{t("sip.o_planning.partner")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.customer_p2")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.customer_code")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.atm_id")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.date_planned")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.time_planned")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.service_point")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.service_type")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.service_type_extra")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.status")}</TableHead>
                        <TableHead className='uppercase'>{t("sip.o_planning.status_extra")}</TableHead>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {planning && splitArrayIntoChunks(planning, 20)[currentPage - 1] && splitArrayIntoChunks(planning, 20)[currentPage - 1].map((planning, index) => {
                        let customer = customers.flatMap(obj => obj.eindklant).find(e => e.relnr === planning.relnr)
                        let partner = partners.find(obj => obj.relnr === customer?.partner)
                        
                        return (
                        <TableRow key={index}>
                            <TableCell className='whitespace-nowrap'>{partner?.name}</TableCell>
                            <TableCell className='whitespace-nowrap'>{customer?.name}</TableCell>
                            <TableCell>{customer?.clientcode}</TableCell>
                            <TableCell>{planning.idautomate}</TableCell>
                            <TableCell>{planning.dtplanned ? format(convertToDate(planning.dtplanned) || new Date, "dd/MM/yyyy") : ''}</TableCell>
                            <TableCell>{convertToHHMM(planning.tmplanned)}</TableCell>
                            <TableCell>{planning.servicepointid}</TableCell>
                            <TableCell>{planning.type}</TableCell>
                            <TableCell>{planning.typeextrainfo}</TableCell>
                            <TableCell>{planning.state}</TableCell>
                            <TableCell>{planning.stateextrainfo}</TableCell>
                        </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>

        <Pagination totalItems={planning.length} itemsPerPage={20} page={currentPage} onPageChange={(page) => { setcurrentPage(parseFloat(page)) }} />
    </>
  )
}

export default PlanningTable