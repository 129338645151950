import React, { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../../../../components/ui/table'
import useAuth from '../../../../../hooks/useAuth'
import ExportTable from '../../../../../components/portal/export/ExportTable'
import { createExport, createExportObject, createExportRows } from '../../../../../lib/general/export'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux'
import { Checkbox } from '../../../../../components/ui/checkbox'
import NewCheckbox from '../../../../../components/ui/new-checkbox'

import { Button } from '../../../../../components/ui/button'
import { Loader2, Save } from 'lucide-react'
import lodash from 'lodash'
import toast from 'react-hot-toast'
import axios from '../../../../../lib/axios'
import { Link } from 'react-router-dom'
import { Pagination } from '../../../../../components/ui/pagination'
import { splitArrayIntoChunks } from '../../../../../lib/utils'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'

type Props = {
    partnerrights: PartnerRight[]
    callRefetch: () => void
    updater: boolean
}

const PartnerName = ({partner_id}:{partner_id:string}) => {
    const { auth } = useAuth()

    const { data } = useQuery<Partner | null>({
        queryKey: [`partner_search_${partner_id}`],
        queryFn: async () => {
            try {
                const {data} = await axios.post(`partner/search`, {
                    token: auth.data?.token,
                    partner: partner_id
                })
                if(data.errorcode!==0){ throw new Error() }
                if(!Array.isArray(data.object.partner)){ throw new Error() }
                return data.object.partner[0]
            } catch (error) {
                return null
            }
        }
    })
    return (
        <>{data?.name}</>
    )
}

const PartnerRightsTable : React.FC<Props> = ({partnerrights, callRefetch, updater}) => {
    const {auth} = useAuth()
    const {t} = useTranslation()
    const partners = useSelector((state:RootState)=>state.partners);
    const customers = useSelector((state:RootState)=>state.customers);

    const headerHeight = '[15rem]'
    const headClass = `max-h-[15rem] uppercase `
    const divClass = `max-h-[15rem] h-full flex items-end justify-center pb-1`
    const pClass = `max-h-[15rem] h-[15rem] [writing-mode:vertical-lr] text-right [text-wrap:balance]`

    const [new_partnerrights, setnew_partnerrights] = useState<PartnerRight[]>(partnerrights);
    const [currentPage, setcurrentPage] = useState<number>(1);

    useEffect(()=>{
        console.log('new rights fetched')
        setnew_partnerrights(partnerrights)
    }, [updater])

    const changeValue = (partnerid:number, key:keyof PartnerRight, value:boolean) => {
        
        setnew_partnerrights((prevState) => {
            // Create a new array with the updated object
            const updatedRights = [...prevState];
            const index = updatedRights.findIndex(i=>i.partnerid===partnerid)
            if(index < 0){ return updatedRights }
            updatedRights[index] = {
              ...updatedRights[index],
              [key]: value,
            };
            return updatedRights;
        });
    }

    const [is_loading, setis_loading] = useState(false);
    const updateRights = async () => {
        try {
            toast.loading(t("settings.toast_loading"), {id:'updateRights'})
            setis_loading(true)

            let new_rights:any = [...new_partnerrights]
            new_rights = new_rights.map((r:any)=>{
                let u = { ...r }
                u.id = r.partnerid
                delete r.partnerid
                return u
            })

            const obj = {
                token: auth.data?.token,
                partner: [...new_rights]
            }
            const {data} = await axios.post(`partnerright/update`, obj)
            if(data.errorcode!==0){ throw new Error(data.message) }

            toast.success(t("settings.toast_success"), {id:'updateRights'})
            callRefetch()
        } catch (error) {
            console.log(error);
            toast.error(t("settings.toast_error_p3"), {id:'updateRights'})
        } finally {
            setis_loading(false)
        }
    }

  return (
    <>
    <div className='flex justify-between mt-4'>
        <Button onClick={()=>{updateRights()}} disabled={lodash.isEqual(new_partnerrights, partnerrights) || is_loading} className='gap-2'><Save className='w-5'/>{is_loading ? <Loader2 className='animate-spin'/> : t("settings.button_save")}</Button>
    </div>

    <div className='my-4 border rounded-sm'>
        <Table>
            <TableHeader className='bg-slate-100 relative'>
                <TableRow className={`h-${headerHeight} max-h-${headerHeight}`}>
                    <TableHead className={headClass}><div className='h-full flex items-end'>{t("settings.relation_nr")}</div></TableHead>
                    <TableHead className={headClass}><div className='h-full flex items-end'>{t("settings.partner")}</div></TableHead>
                    {/* <TableHead className={headClass}><div className={divClass}><p className={pClass}>bestelling plaatsen</p></div></TableHead> */}
                    
                    {new_partnerrights && Object.keys(new_partnerrights[0]).map((key, ind) => {
                        if(typeof new_partnerrights[0][key as keyof PartnerRight] === 'boolean'){
                            return (
                                //@ts-ignore
                                <TableHead className={headClass} key={ind}><div className={divClass}><p className={pClass}>{t(`settings.t_${key}`)}</p></div></TableHead>
                            )
                        }
                    })}

                </TableRow>
            </TableHeader>
            <TableBody>
                {new_partnerrights && splitArrayIntoChunks(new_partnerrights, 20)[currentPage - 1] && splitArrayIntoChunks(new_partnerrights, 20)[currentPage - 1].map((right, index) => {
                    let partner = partners.find(obj => obj.relnr === right.partnerid)

                    // if(!partner){ return <></> } (because invalid partner ids were visible)

                    return (
                        <TableRow key={index}>
                            <TableCell>{right.partnerid}</TableCell>
                            <TableCell>
                                <Link to={`${right.partnerid}`} className='underline text-primary whitespace-nowrap'>{partner?.name}</Link> 
                            </TableCell>
                            {
                            Object.keys(right).map((key, ind) => {
                                const validKey = key as keyof PartnerRight;

                                if(typeof right[validKey] === 'boolean'){
                                    return (
                                        <TableCell key={ind}>
                                            <NewCheckbox onCheckedChange={(v)=>{ changeValue(right.partnerid, validKey, v as boolean) }} checked={right[validKey] as boolean || false}/>
                                        </TableCell>
                                    )
                                }
                            })}
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>

        
    </div>
    <Pagination totalItems={new_partnerrights.length} itemsPerPage={20} page={currentPage} onPageChange={(page)=>{ setcurrentPage(parseFloat(page)) }}/>
    </>
  )
}

export default PartnerRightsTable